import React, { useEffect, useState,useMemo } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import FileDisplay from "../../../Utilities/common/FileDisplay";
import { CountryMIN } from "../../../Utilities/data/CountryMini";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateorUpdate({
  data,
  visible,
  onClose,
  refreshData,
  id,
  standard,
  depList
}) {

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [passwordCheck,setPasswordCheck] = useState("")
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [isEmail,setIsEmail] = useState(true);
  const [createNew, setCreateNew] = useState({
    standards:[],
    name: "",
    user_name: "",
    email: "",
    mobile: "",
    password: "",
    retypePassword:"",
    country:"",
    location:"",
    department:"",
    isEmailSent:""
  });

  useEffect(() => {
    setCreateNew({
      name: data?.name,
      user_name: data?.user_name,
      email: data?.email,
      mobile: data?.mobile,
      // password: data?.password,
      standards:data?.standards||[],
      country:data?.country,
      location:data?.location,
      department:data?.department,
    });
    setIsEmail(data?.isEmailSent)
  }, [data]);

  function handleImageChange(e) {
    // const { files, value } = e.target;
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  }

  const formInputs = [
    {
      inputType: "NuInput",
      name: "name",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Name",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "user_name",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "User Name",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
      
    },
    !data &&{
      inputType: "NuInput",
      name: "password",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Password",
      labelclassname: "w-full",
      type: "password",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuMutiSelect",
      name: "standards",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "Select Standard Access",
      label: "Standard Access",
      labelclassname: "w-full",
      Options:standard,
      customClass: "w-full lg:w-6/12",
    },
  
   !data && {
      inputType: "NuInput",
      name: "retypePassword",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Retype Password",
      labelclassname: "w-full",
      type: "password",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "email",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Email",
      labelclassname: "w-full",
      type: "email",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "imagePathOne",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "User Image",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleImageChange,
    },
    {
      inputType: "NuInput",
      name: "mobile",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Mobile",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuSelect",
      name: "country",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Country",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: CountryMIN,
      
    },
    {
      inputType: "NuInput",
      name: "location",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "City",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
      
    },
    {
      inputType: "NuSelect",
      name: "department",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "Department",
      label: "Department",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: depList,
    },

  ];


  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      mobile: yup
        .string()
        .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
     password:!data && yup.string().min(8,"Password must be Min 8 char").matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()-_=+;:'",.<>?/\\|[\]{} ]+$/,"Password with in alphanumeric and Special char"),
      retypePassword: !data && yup.string().matches(passwordCheck,"Passwords not match").min(8,"Retype Password must be Min 8 char").required("Retype Password is requerd"),
    }),
    onSubmit: (value,{resetForm}) => {
      console.log(value);
      value.isEmailSent = isEmail
      if (data?.userId) {
        let reqObjs = new FormData();
        delete value.retypePassword
        reqObjs.append("userData", JSON.stringify(value));
        if(selectedFile !== null){
         
            reqObjs.append("userImage", selectedFile[0]);
         
        }
        
        axiosInstance.put(`/User/${data.userId}`, reqObjs).then((res) => {
          if (res.data) {
            resetForm()
            onClose();
            refreshData();
            Toast.fire({
              icon: "success",
              title: res.data.message || "User Managment Updated Successfully",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.message || "User Managment Update Unsuccessfully",
            });
          }
        }).catch(err=>{
          Toast.fire({
            icon: "error",
            title: err?.response?.data?.message || err?.response?.data?.error || "Role is not Created",
          });
        });
      } else {
        
        let reqObjs = new FormData();
        delete value.retypePassword
        reqObjs.append("userData", JSON.stringify(value));
        if(selectedFile !== null){
         
          reqObjs.append("userImage", selectedFile[0]);
       
      }
        axiosInstance.post("/User/", reqObjs).then((res) => {
          if (res.data?.status==true) {
            resetForm()
            refreshData();
            onClose();
            Toast.fire({
              icon: "success",
              title: res?.data?.msg || "User is Created",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: res?.data?.msg  || "User is not Created",
            });
          }
        }).catch(err=>{
          Toast.fire({
            icon: "error",
            title: err?.response?.data?.msg  || "User is not Created not Network error",
          });
        }) ;
      }
    },
  });


  useMemo(()=>{
    setPasswordCheck(formik.values.password)
    console.log(formik.values.password);
  },[formik.values.password])

  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }

  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
     formik.resetForm()
      onClose();
    }
  };

  const resetClose = ()=>{
    formik.resetForm()
    onClose()
  }

  if (!visible) return null;

  console.log(isEmail);

  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center "
      >
        <div className="bg-white mt-4 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={resetClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className=" max-h-[calc(100vh-90px)] overflow-y-auto">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
                <div className=" px-6 w-full lg:w-1/2 flex justify-start items-end">
                  <div className=" flex gap-2 justify-center pb-1">
                  <input type="checkbox" className="" checked={isEmail == false ? true : false} onChange={(e)=>setIsEmail(!e.target.checked)} />
                <label className=" text-sm">Click here to stop sending E-Mails to this user.</label>
                  </div>
                </div>
              </div>
              
              <div className=" w-full flex flex-wrap my-3">
                {data?.image_path && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
              <div className="flex items-center justify-end px-6 py-6 rounded-b">
                <button
                  onClick={resetClose}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
