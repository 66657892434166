import React, { useState } from "react";
// import AdminLayout from "../index";
import Axios from "../../../Services/axiosComman";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import useSWR from "swr";
import * as yup from "yup";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import StandardSettingLayout from "../index";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function FrequencySettingsIndex() {
  const fetcher = (url) => Axios.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(`/Frequency/`, fetcher);
  const refreshData = () => {
    mutate(fetcher);
  };

  const [createNew, setCreateNew] = useState({
    frequency_type: "",
  });
  const formInputs = [
    {
      inputType: "NuInput",
      name: "frequency_type",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Frequency Status",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12",
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      frequency_type: yup.string().required("Frequency is requered"),
    }),
    onSubmit: (value) => {
      formik.resetForm({ value: {} });
      axiosInstance.post("/Frequency/", value).then((res) => {
        if (res?.data?.status) {
          refreshData();
          //   onClose();
          Toast.fire({
            icon: "success",
            title: res?.data?.msg || "Frequency is Created",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: res?.data?.msg || "Frequency is not Created",
          });
        }
      });
    },
  });


  const handleDelete = async (id,value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Delete ${value}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let qwe = { id: id };
        if (id) {
          await axiosInstance
            .delete(`/Frequency/${id}/`, { data: qwe })
            .then((res) => {
              if (res?.data?.status) {
                refreshData();
                Toast.fire({
                  icon: "success",
                  title: res?.data?.error || "Frequency Deleted successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: res?.data?.error || "Frequency Deleted unsuccessfully",
                });
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        }
      }
    })
  }



  return (
    <StandardSettingLayout>
      <div className="mt-5 ml-5">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Frequency
        </h4>
      </div>
      <div className="w-10/12 mx-auto mt-5">
       {GetAccess('admin_create')  && <form onSubmit={formik.handleSubmit}>
          <div className="w-full flex lg:justify-center">
            <MainForm formInputs={formInputs} formik={formik} />
            <div className="flex items-center mr-8 mt-7">
              <button
                type="submit"
                className="bg-primary hover:shadow-button text-white text-xs px-6 py-2 rounded-3xl"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
}
        <div className="w-[60%] mt-2 border border-black mx-6 lg:mx-auto px-3 rounded-lg">
          {data?.data?.map((data, i) => (
            <div className="flex justify-between my-3" key={i}>
              <p className="text-xs py-1">{data?.frequency_type}</p>
             {(GetRole()||GetAccess('admin_delete')) && <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={() => {
                  handleDelete(data.id,data.frequency_type);
                }}
              >
                <rect
                  x="0.40332"
                  y="0.158203"
                  width="23.874"
                  height="23.874"
                  rx="5"
                  fill="#FFE1E1"
                />
                <path
                  d="M8.25552 16.861C8.25552 17.6099 8.86825 18.2226 9.61714 18.2226H15.0636C15.8125 18.2226 16.4253 17.6099 16.4253 16.861V10.0529C16.4253 9.304 15.8125 8.69127 15.0636 8.69127H9.61714C8.86825 8.69127 8.25552 9.304 8.25552 10.0529V16.861ZM16.4253 6.64883H14.7232L14.2399 6.16545C14.1173 6.04291 13.9403 5.96802 13.7633 5.96802H10.9175C10.7405 5.96802 10.5635 6.04291 10.4409 6.16545L9.95755 6.64883H8.25552C7.88107 6.64883 7.57471 6.9552 7.57471 7.32964C7.57471 7.70409 7.88107 8.01045 8.25552 8.01045H16.4253C16.7997 8.01045 17.1061 7.70409 17.1061 7.32964C17.1061 6.9552 16.7997 6.64883 16.4253 6.64883Z"
                  fill="#FF5252"
                />
              </svg>
          }
            </div>
          ))}
        </div>
      </div>
    </StandardSettingLayout>
  );
}

export default FrequencySettingsIndex;
