import React, { useEffect, useState } from "react";
// import AdminLayout from "../index";
// import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
// import useSWR from "swr";
import ViewMore from "./viewMore";
import CreateorUpdate from "./form";
// import { GetAccess } from "../../../Utilities/data/role";
// import EmailLayout from "../../Email";
// import SettingsLayout from "../index";
// import Pagination from "../../../Utilities/common/Pagination";
// import paperclip from "../../../Utilities/Image/img/paper-clip.png";
// import AddIcon from "../../../Utilities/Image/svg/add";
import DateFormat from "../../../Utilities/common/NuDate";
import AdminSettingsLayout from "../index";



// const fetcher = (url) => Axios.get(url).then((res) => res.data);

function CertificationCalenderTrackerSettings() {
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [data,setData] = useState([])
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  


  // const { data, error, mutate } = useSWR(`/email_templates/standard/${id}`, fetcher);


  const refreshData = () => {
    Axios.get(`/Standards/`).then(res=>{
      let tempdata = res?.data?.data||[] 
      let finalArr = []
      res?.data?.data && tempdata.forEach(item=>{
        if(item?.isActive&&item?.keyActivated){
          // finalArr.push(item);
          // if(item?.date_details){
          //   console.log(item?.date_details);
          // }
          if(item?.date_details !=[] && item?.date_details !=null && item?.date_details.length>0){
          // console.log("has date detail");
            item?.date_details.map(i=>{
              let temp = {
                name:item?.name,
                start_date:i?.start_date,
                end_date:i?.end_date,
                isActive:i?.isActive,
                id:item?.id,
                last_reset_date:item?.last_reset_date

              }
              finalArr.push(temp)
              // console.log("Final array",finalArr);
            })
          }else{
            // console.log("No date detail");
            finalArr.push(item);
            // console.log("Two Final array",finalArr);

          }

        }
      })
      res?.data?.data && setData(finalArr)
    }).catch()
  };




  useEffect(()=>{
    refreshData()
  },[])



  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
        Certification Calendar Tracker
        </h4>
        {/* <AddIcon onClick={() => setShowMyModal(true)} /> */}
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-2 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[calc(100vh-210px)] shadow-box">
                {data?.map((data, i) => (
                  <div className="" key={i}>
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[10%] py-2">
                        {i+1}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[45%] ">
                        {data.name|| "-"}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[20%] ">
                      { data?.start_date != "None" && data?.start_date != " " && DateFormat({
                                value: data?.start_date,
                                format: "DD-MMM-yyyy",
                              })||"-"}
                      
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[20%] ">
                      { data?.end_date != "None" && data?.end_date != " " && DateFormat({
                                value: data?.end_date,
                                format: "DD-MMM-yyyy",
                              })||"-"}
                      </td>
                      <td className=" w-[5%]">
                        <div>
                          {
                            data?.isActive &&  
                            <ViewMore
                            onClose={handleOnCloseMenu}
                            visible={showMyMenu}
                            data={data}
                            fetcher={refreshData}
                          />
                          }

                        </div>
                      </td>
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateorUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
      />
    </AdminSettingsLayout>
  );
}

export default CertificationCalenderTrackerSettings;

const header = [
  {
    headone: "SI. No",
    width: "w-[10%]",
  },
  {
    headone: "Standard Name",
    width: "w-[45%]",
  },
  {
    headone: "Start Date",
    width: "w-[20%]",
  },
  {
    headone: "End Date",
    width: "w-[20%]",
  },
  {
    headone: " ",
    width: "w-[5%]",
  }
];
