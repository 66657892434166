import React, { useEffect, useState } from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DateFormat from '../../../../Utilities/common/NuDate';
import { OnDownload } from '../../../../Utilities/data/render';

function TrainingXlsx({fileName,data ,status,standardname="",review,Name,startDate,endDate,company,Module,standardId,updateLenth}) {
    const [csvData,setCSVData] = useState([])


    useEffect(()=>{
        if(data){
            let finalData = []
            data && data.map((item,index)=>{
                updateLenth(index+1)
                let tempData = {
                    "Training Program":item?.training_program||"",
                    "Function Responsibility":item?.function_responsibility||"",
                    "Frequency":item?.frequency||"",
                    "Participants":item?.participants||"",
                    "Mandatory for the organization":item?.mandatory||"",
                    "Detail Plan":item?.detail_plan||"",
                    "Planned Date":item?.planned_date!=="None"&&item?.planned_date!==""&&`${DateFormat({value:item?.planned_date, format:"DD-MMM-YYYY"})}`||"",
                    "Status":item?.status_name||"",
                    "Last Update by":item?.last_modified_name || item?.created_name || item?.created_by_name || "",
                    "Artefact":item?.attachment==true?"Yes":""
                }
                finalData.push(tempData);
            })
            setCSVData(finalData);
            
        }
    },[data])

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      OnDownload(standardId,Module)

    };
    return (
      // <div onClick={()=>exportToCSV(csvData,fileName)}>ExportXls</div>
      <button onClick={()=>exportToCSV(csvData,fileName)}>Download</button>
    )
}

export default TrainingXlsx;