import React, { useState,useEffect } from "react";
import Edit from "./form";
import Swal from "sweetalert2";
import axiosInstance from '../../../Services/axiosComman';
import ImplementationView from "./implementationProfile";
import Axios from "../../../Services/axiosComman"
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import { ScreenClose, ScreenOpen } from "../../../Utilities/data/render";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({ id, fetcher, data, visible, onClose, fetcherHeader ,status,sectionNo,moduleId,getImplementation}) {
  console.log("data item",data);
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => {
    setShowMyModal(false);
    ScreenClose({link:"implementation-checklist/implementation-list",id})
  }
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);
  const [viewData, setViewData] = useState([]);
  
  async function getViewPage() {
      await Axios.get(`/implementation-checklist/implementation-list/${id}/`)  
        .then((res) => {
          res.data?.data &&
          setViewData(res.data?.data);
        })
        .catch((err) => console.log(err));
    }




    const handleDelete = async (id,value) => {
      Swal.fire({
        title: 'Are you sure?',
        text: `You want to Delete Sub Section ${value}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        // alert(id)
        if (result.isConfirmed) {
          if(id){
            axiosInstance.delete(`/implementation-checklist/${id}/delete`)
            .then((res) => {
              if (res.status==200) {
                getImplementation()
                fetcherHeader()
                fetcher()
                Toast.fire({
                  icon: "success",
                  title: res.data?.message || "ImplementationCheckList Delete successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: res.data?.message || "ImplementationCheckList delete  unsuccessfully",
                });
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: "error",
                title: error?.response.data?.message || "ImplementationCheckList delete  unsuccessfully",
              });
              console.log(error.message);
            });
          }
        }
      })
    }


    function EditScreen(){
      setShowMyModal(true)
      ScreenOpen({link:"implementation-checklist/implementation-list",id})
    }

    // function OnEdit(){
    //   axiosInstance.put(`/implementation-checklist/implementation-list/${id}/screen_open`,{"isOpen":true}).then(res=>{
    //     console.log("ture");
    //   }).catch(err=>{
    //     console.log("err")
    //   })
    // }

    // function closeEdit(){
    //   axiosInstance.put(`implementation-checklist/implementation-list/${id}/screen_closed`,{"isClosed":false}).then(res=>{
    //     console.log("ture");
    //   }).catch(err=>{
    //     console.log("err")
    //   })
    // }

  return (
    <>
      <div
        className="hover:drop-shadow-sm mr-2 py-2 px-[14px] rounded-3xl bg-white dropdown cursor-pointer relative"
        onClick={() => {setShowMyMenu(!showMyMenu);getViewPage();}}
      >
        <svg
          className="m-auto w-[4px]"
          viewBox="0 0 4 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="1.83391"
            cy="2.38902"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
          <ellipse
            cx="1.83391"
            cy="7.30914"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
          <ellipse
            cx="1.83391"
            cy="12.2293"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
        </svg>
        {/* {showMyMenu ? ( */}
          <div className="hidden dropdown-menu">
            <div
              id="modal"
              onClick={handleOnCloseMenu}
              className=" bg-transparent fixed inset-0 flex justify-center items-center"
            ></div>
            <div className="bg-white rounded-xl w-20 absolute -left-20 -top-1">
              <div className="grid p-1 rounded-t text-xs 2xl:text-sm">
                <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => setShowFile(true)} >
                  View
                </span>
                {GetAccess("activity_edit")&&<span
                  className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer"
                  onClick={EditScreen}
                >
                  Edit
                </span>}
                {(GetRole()||GetAccess("activity_delete")) && 
              <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => {
                handleDelete(id,moduleId);
              }
              }>Delete</span>
                }
              </div>
            </div>
          </div>
      </div>
        <Edit
          onClose={handleOnClose}
          visible={showMyModal}
          data={viewData}
          refreshData={fetcher}
          fetcherHeader={()=>fetcherHeader()}
          status={status}
          sectionNo={sectionNo}
          getImplementation={getImplementation}

        />
        <ImplementationView
          onClose={handleClose}
          viewData={viewData}
          visible={showFile}
          />
      {/* <CreateorUpdate onClose={handleOnClose} visible={showMyModal} refreshData={fetcher} data={data} /> */}
    </>
  );
}

export default Viewmore;
