import React, { useEffect, useState } from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DateFormat from '../../../../Utilities/common/NuDate';
import { OnDownload } from '../../../../Utilities/data/render';

function MasterXlsx({fileName,data ,status,standardname="",review,Name,startDate,endDate,company,Module,standardId,updateLenth}) {
    const [csvData,setCSVData] = useState([])


    useEffect(()=>{
        if(data){
            let finalData = []
            data && data.map((item,index)=>{
                updateLenth(index+1)
                let tempData = {
                    "Document Name	":item?.document_name||"",
                    "Document Description":item?.document_description||"",
                    "Version":item?.version||"",
                    // "User Response":item?.user_response||"",
                    // "User Comment":item?.user_comment||"",
                    "Latest Updated":item?.latest_update!=="None"&&item?.latest_update!==""&&`${DateFormat({value:item?.latest_update, format:"DD-MMM-YYYY"})}`||"",
                    "Status":item?.status_name||"",
                    "Last Update by":item?.last_modified_name || item?.created_name || item?.created_by_name || "",
                    "Artefact":item?.attachment==true?"Yes":""
                }
                finalData.push(tempData);
            })
            setCSVData(finalData);
            
        }
    },[data])

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      OnDownload(standardId,Module)

    };
    return (
      // <div onClick={()=>exportToCSV(csvData,fileName)}>ExportXls</div>
      <button onClick={()=>exportToCSV(csvData,fileName)}>Download</button>
    )
}

export default MasterXlsx;