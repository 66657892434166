import React, { useEffect, useState } from "react";
import AdminLayout from "../index";
import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import ViewMore from "./viewMore";
import CreateorUpdate from "./form";
// import SettingsLayout from "../index";
import DateFormat from "../../../Utilities/common/NuDate";
import Swal from "sweetalert2";
import AdminSettingsLayout from "../index";
import BackupScreen from "./BackUpScreen";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});



// const fetcher = (url) => Axios.get(url).then((res) => res.data);

function BackupAndRestoreSeetingIndex() {
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [data,setData] = useState([])
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const handleFileOnClose = () => setShowFileUpload(false);
  const [complete,setComplete] = useState(0);
  
  function backupFun() {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Backup  ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Backup'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setShowMyModal(true);
        Axios.get(`/Auth/backup`).then(res=>{
          if(res.data.msg=="Backup completed successfully"){
            setComplete(100)
            Toast.fire({
              icon: "success",
              title: res.data.msg || "Data backup was Successful.",
            });
    refreshData()

           setShowMyModal(false);
            Swal.fire({
            icon:"success",
            title: `Backup has completed.`,
            html:`
            Click here to close this window
            `,
            // showConfirmButton: false,
            // timer: 2000,
            width:"35%",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Close',
          });
          }else{
            Toast.fire({
              icon: "error",
              title: res.data.msg || "Data backup was unsuccessful.",
            });
           setShowMyModal(false);

          }
        }).catch((error) => {
          Toast.fire({
            icon: "error",
            title: error?.response?.data?.msg || "Data backup was unsuccessful.",
          });
          setShowMyModal(false);

        })
      }
    })
  }


  // const { data, error, mutate } = useSWR(`/email_templates/standard/${id}`, fetcher);


  const refreshData = () => {
    Axios.get(`/Auth/available_files`).then(res=>{
      let tempdata = res?.data?.data||[] 
      let finalArr = []
      res?.data?.data && tempdata.forEach(item=>{
        // if(item?.isActive&&item?.keyActivated){
          item?.files &&  item?.files.map(file=>{
          finalArr.push(file);
          })
        // }
      })
      res?.data?.data && setData(finalArr)
    }).catch()
  };




  useEffect(()=>{
    refreshData()
  },[])



  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
         Backup & Restore
        </h4>
        <div className=" flex gap-2 items-center">
            <button className=" bg-[#7030A0] py-1 text-xs px-3 text-white rounded-md" onClick={backupFun}>Backup</button>
             {/* <button className=" bg-[#7030A0] py-1 text-xs px-3 text-white rounded-md" onClick={() => setShowFileUpload(true)}> File Upload</button>  */}
        </div>
        {/* <AddIcon onClick={() => setShowMyModal(true)} /> */}
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-2 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[calc(100vh-210px)] shadow-box">
                {data?.map((data, i) => (
                  <div className="" key={i}>
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[10%]">
                        {i+1}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[47%] ">
                        {data.filename|| "-"}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[13%] ">
                        {data.size|| "0 KB"}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[13%] ">
                      { data?.date != "None" && data?.date != " " && DateFormat({
                                value: data?.date,
                                format: "DD-MMM-yyyy",
                              })||"-"}
                      
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[13%] ">
                      {console.log("Date format", new Date(`${data?.date} ${data?.time}`))}
                      { data?.time != "None" && data?.time != " " && DateFormat({
                                value: new Date(`${data?.date} ${data?.time}`) ,
                                format: "HH:mm a",
                              })||"-"}
                      </td>
                      <td className=" w-[4%]">
                        <div>
                        <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data}
                        fetcher={refreshData}
                      />
                        </div>
                      </td>
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateorUpdate
        onClose={handleFileOnClose}
        visible={showFileUpload}
        refreshData={refreshData}
      /> 
      <BackupScreen
      complete={complete}
              //  onClose={handleOnClose}
               visible={showMyModal}
              //  refreshData={refreshData}
      />
    </AdminSettingsLayout>
  );
}

export default BackupAndRestoreSeetingIndex;

const header = [
  {
    headone: "SI. No",
    width: "w-[10%]",
  },
  {
    headone: "File Name",
    width: "w-[47%]",
  },
  {
    headone: "Size",
    width: "w-[13%]",
  },
  {
    headone: " Date",
    width: "w-[13%]",
  },
  {
    headone: "Time",
    width: "w-[13%]",
  },
  {
    headone: " ",
    width: "w-[4%]",
  }
];
