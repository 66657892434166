import React from "react";
import Select from "react-select";


export function VerticalSelect({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  customClass,
  isRequired,
}) {
  const options = Options;

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
    }),
  };
  return (
    <>
      <div className={`relative px-6 py-2  ${customClass}`}>
        {/* <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label> */}
        <div className="mt-2">
          <Select
            options={options}
            value={options.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm font-medium`}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}


function FormikSelectTwo({
  classname,
  labelclassname,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options,
  customClass,
  isRequired,
}) {
  const options = Options;

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e.value,
        name: name,
      },
    });
  };

  const styles = {
    control: (provided) => ({
      ...provided,

      border: "1px solid #e5e5e5",
      outline: "none",
      borderRadius: "10px",
      padding: "1px 0",
      color: "#000",
      textAlign: "left",
      // fontSize:'13px',
      fontWeight: "500",
      width: "100%",
    }),
  };
  return (
    <>
      <div className={`relative px-6 py-2  ${customClass}`}>
        <label className={`${labelclassname} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ""}
        </label>
        <div className="mt-2">
          <Select
            options={options}
            value={options.find((i) => i.value === formik.values[name]) || ""}
            styles={styles}
            className={`${classname} text-xs capitalize 2xl:text-sm font-medium`}
            name={name}
            onChange={onChange || handleChange}
            placeholder={placeholder}
          />
        </div>
        {formik.errors[name] ? (
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}

export default FormikSelectTwo;





export function NuMutiSelect({
  className,
  labelClassName,
  name,
  placeholder,
  onChange,
  formik,
  label,
  Options, // Use lower-case 'options' instead of 'Options'
  customClass,
  isRequired,
}) {
  const handleChange = (selectedOptions) => {
    const data = selectedOptions.map((item) => item.value) || [];
    formik.handleChange({
      target: {
        value: data,
        name: name,
      },
    });
  };

  const selectedValues = Options.filter((option) =>{
      if(formik.values[name]){
        return  formik.values[name].includes(option.value)
      }else {
        return []
      }
  }
  )||[];

  const styles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #e5e5e5',
      outline: 'none',
      borderRadius: '10px',
      padding: '1px 0',
      color: '#000',
      textAlign: 'left',
      width: '100%',
    }),
    option:(styles)=>({
      ...styles,
      backgroundcolor: "#fff",
      color: '#000',
    })
  };

  return (
    <>
      <div className={`relative px-6 py-2 ${customClass}`}>
        <label className={`${labelClassName} text-xs 2xl:text-sm`}>
          {label}
          {isRequired ? <span className="text-red-500"> *</span> : ''}
        </label>
        <div className="mt-2">
          <Select
            isMulti
            options={Options}
            value={selectedValues} // Use 'selectedValues' to set the initial selected options
            styles={styles}
            className={`${className} text-xs capitalize 2xl:text-sm font-medium`}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
          />
        </div>
        {formik.errors[name] && formik.touched[name] ? ( // Check if the field has been touched
          <span className="text-xs font-semibold text-red-500 ">
            {formik.errors[name]}
          </span>
        ) : null}
      </div>
    </>
  );
}




