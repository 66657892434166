import React, { useState, useEffect } from "react";
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import Pagination from "../../../Utilities/common/Pagination";
import DateFormat from "../../../Utilities/common/NuDate";
import Import from "./import";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import { GetAccess } from "../../../Utilities/data/role";
import ApprovelStatus from "../../../Utilities/common/Approvel";
import { GetYear } from "../../../Utilities/common/GetYear";
import MainLayout from "../../../Utilities/Layout/MainLayout";

const fetcher = (url) => Axios.get(url).then((res) => res.data);

function VendorManagementIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [selected, setSelected] = useState(null);
  const [relation, setRelation] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);

  const { data, error, mutate } = useSWR(
    `/ConnectVendor/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`,
    fetcher
  );

  const refreshData = () => {
    mutate(fetcher);
  };
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;

  const Relation = async () => {
    await Axios.get("/Vendor-Management/get").then((res) => {
      let data = res?.data?.data;
      let xyz = [];
      data.forEach((i) => {
        let x = { label: i?.vendor_name, value: i?.id };
        xyz.push(x);
      });
      res?.data && setRelation(xyz);
    });
  };

  useEffect(() => {
    Relation();
  }, []);

  return (
    <>
      <MainLayout
        id={id}
        total={data?.total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
        label="Vendor Management"
        title="Information"
        setShowApprovalModal={setShowApprovalModal}
        hasApproval={false}
      >
        <div className="py-3 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Vendor Management</p>
          <div className="flex">
            <div className=" w-8">
              {GetAccess("information_create") && (
                <svg
                  className="w-7 ml-1 cursor-pointer"
                  alt="plus"
                  onClick={() => setShowMyModal(true)}
                  title="Add"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.61084"
                    y="0.335541"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path
                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Add</title>
                </svg>
              )}
            </div>
            {/* {GetAccess("information_create") && (
              <Import refreshData={refreshData} id={id} />
            )} */}
          </div>
        </div>
        <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className=" text-xs 2xl:text-sm font-medium ">
              {header?.map((header) => (
                <th className={`text-center px-5 py-4 ${header.width}`}>
                  {header?.headone}
                </th>
              ))}
              <div
                className={
                  selected
                    ? "flex items-center justify-start w-[20%]"
                    : "flex items-center justify-start w-[4.5%]"
                }
              ></div>
            </tr>
          </thead>
          <tbody>
            <tr colspan="6">
              <td colspan="6">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data &&
                    data?.data?.map((data, i) => (
                      <div className="">
                        <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex  items-center my-1">
                          <td className="text-center text-gray-900 w-[10%] capitalize ">
                            {data?.serial_number || "#"}
                          </td>
                          <td className="text-center text-gray-900 w-[27%] capitalize ">
                            {data?.vendorDetails?.vendor_name || "-"}
                          </td>
                          <td className="text-center text-gray-900 w-[20%] capitalize ">
                            {data?.vendorDetails?.vendor_type || "-"}
                          </td>
                          <td className="text-center text-gray-900 w-[20%] capitalize  ">
                            <div className=" w-full justify-center items-center">
                            {(data?.attachments.length > 0) && (
                              <img
                                src={paperclip}
                                alt=""
                                className="w-3 h-4 mx-auto"
                              />
                            )}
                            </div>
                          </td>
                          <td className="text-center text-gray-900  w-[20%] capitalize ">
                            {(data?.on_board_date !== "None" &&
                              data?.on_board_date !== "" &&
                              DateFormat({
                                value: data?.on_board_date,
                                format: "DD-MMM-yyyy",
                              })) ||
                              "-"}
                          </td>
                          <td className=" w-[3%]">
                          <ViewMore
                            relation={relation}
                            data={data}
                            fetcher={refreshData}
                          />
                          </td>
                        </tr>
                      </div>
                    ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={refreshData}
          relation={relation}
          id={id}
        />
        <ApprovelStatus
          visible={showApprovalModal}
          onClose={handleApprovalOnClose}
          refreshData={refreshData}
          moduleId={11}
        />
      </MainLayout>
    </>
  );
}

export default VendorManagementIndex;

const header = [
  {
    headone: "Sl No.",
    width: "w-[10%]",
  },
  {
    headone: "Vendor Name",
    width: "w-[27%]",
  },
  {
    headone: "Vendor Type",
    width: "w-[20%]",
  },
  {
    headone: "Documents Type - NDA, SLA, Contract",
    width: "w-[20%]",
  },
  {
    headone: "OnBoard Date",
    width: "w-[20%]",
  },
  {
    headone: "",
    width: "w-[3%]",
  },
];
