import React from "react";
import {ReactComponent as AccessDeniedSvg} from "../Image/icons/accessDenied.svg";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AccessDenied() {
    let history = useHistory();
    return (
        <>
            <div className="w-full h-full flex flex-col justify-center items-center mt-4 mx-auto text-center">
                <div className="w-[50%]">
                    <AccessDeniedSvg/>
                </div>
                <h1 className=" text-lg font-medium my-4">Access Denied , Subscribe the Standard for the access</h1>
                <div className=" flex items-center gap-5">
                <NavLink to="/standard"><button className=" bg-[#e8505b] px-2 text-white py-1 rounded-md" style={{border:"1px solid #e8505b"}}>Home</button></NavLink> 
                <button onClick={history.goBack} className=" px-3 text-[#e8505b] py-1 rounded-md " style={{border:"1px solid #e8505b"}}>Back</button>
                    </div>
            </div>
        </>
    )
}

export default AccessDenied;