import React, { useState } from "react";
import ImgIcon from "../Image/img/imageicon.png";
import DocIcon from "../Image/img/docsicon.png";
import XlIcon from "../Image/img/excelicon.png";
import TxtIcon from "../Image/img/txticon.png";
import FileView, { imagetype } from "./fileView";
export default function IconDisplay({ data,view }) {
  const [show, setShow] = useState(true);
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);
  let ImgPath = data?.split(".").reverse()[0];
  return (
    <>
      { show && (<>
      <div className="text-center w-10 " onClick={() => {
            setShowFile(!showFile)
          }}>
        <div
          className=" w-10 h-10 bg-tableodd rounded-lg group relative cursor-pointer"
        >
          {imagetype.includes(ImgPath) ? (
            <img className="p-2 " src={ImgIcon} alt="" />
          ) : ImgPath == "docx" ? (
            <img className="p-2 w-20" src={DocIcon} alt="" />
          ) : ImgPath == "xlsx" ? (
            <img className="p-2 w-20" src={XlIcon} alt="" />
          ) : ImgPath == "txt" ? (
            <img className="p-2 w-20" src={TxtIcon} alt="" />
          ) : ImgPath == "pdf" ? (
            <svg
              className="px-3 py-2"
              viewBox="0 0 125 165"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37.0479 127.992C38.4862 127.15 40.0581 126.328 41.7636 125.547C40.3229 127.695 38.78 129.773 37.1403 131.773C34.2636 135.236 32.0239 137.075 30.6164 137.65C30.4996 137.699 30.3794 137.74 30.2568 137.773C30.1543 137.631 30.0649 137.48 29.9897 137.321C29.4144 136.191 29.4349 135.102 30.4007 133.623C31.4897 131.927 33.678 129.986 37.0479 127.992ZM62.2702 111.071C61.0477 111.328 59.8353 111.585 58.6127 111.873C60.4236 108.325 62.1366 104.727 63.7497 101.085C65.3737 104.095 67.1217 107.035 68.9894 109.9C66.7599 110.229 64.5099 110.619 62.2702 111.071ZM88.2118 120.719C86.616 119.431 85.1219 118.023 83.7426 116.506C86.0851 116.558 88.2015 116.732 90.0303 117.061C93.2871 117.647 94.8179 118.571 95.3521 119.208C95.5205 119.386 95.6159 119.621 95.6193 119.866C95.5832 120.591 95.3717 121.296 95.0028 121.921C94.7956 122.421 94.4631 122.86 94.0371 123.195C93.825 123.325 93.5751 123.379 93.3282 123.349C92.4035 123.318 90.6775 122.671 88.2118 120.719ZM65.3524 71.9587C64.9414 74.4655 64.2428 77.3422 63.2976 80.4757C62.9494 79.3024 62.6444 78.1167 62.3833 76.921C61.6024 73.2943 61.4894 70.4484 61.9107 68.4758C62.3011 66.6574 63.0408 65.9279 63.9243 65.5683C64.3982 65.3595 64.9001 65.221 65.4141 65.1574C65.5476 65.4656 65.7017 66.1026 65.7428 67.1916C65.7942 68.445 65.6709 70.0375 65.3524 71.969V71.9587Z"
                fill="#7030A0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.3743 0.351257H75.7539C78.4785 0.351839 81.0913 1.43464 83.0176 3.3615L121.103 41.4468C123.03 43.3731 124.112 45.9858 124.113 48.7104V144.186C124.113 149.635 121.948 154.862 118.095 158.715C114.241 162.568 109.015 164.733 103.565 164.733H21.3743C15.9247 164.733 10.6983 162.568 6.84484 158.715C2.99138 154.862 0.826538 149.635 0.826538 144.186V20.899C0.826538 15.4494 2.99138 10.223 6.84484 6.36956C10.6983 2.5161 15.9247 0.351257 21.3743 0.351257V0.351257ZM77.8806 15.7621V36.3098C77.8806 39.0346 78.9631 41.6478 80.8898 43.5746C82.8165 45.5013 85.4297 46.5837 88.1545 46.5837H108.702L77.8806 15.7621ZM23.0695 140.775C23.9941 142.624 25.4325 144.299 27.5694 145.079C29.6961 145.85 31.8023 145.49 33.5283 144.771C36.7954 143.436 40.0522 140.292 43.0419 136.696C46.4631 132.576 50.059 127.172 53.5315 121.182C60.2356 119.195 67.1008 117.8 74.0485 117.011C77.1306 120.946 80.3155 124.336 83.3977 126.771C86.2744 129.032 89.5928 130.912 92.9935 131.056C94.8459 131.147 96.6798 130.651 98.2332 129.638C99.8256 128.6 101.007 127.1 101.87 125.364C102.795 123.504 103.36 121.562 103.288 119.58C103.223 117.625 102.499 115.749 101.233 114.258C98.9112 111.484 95.1099 110.148 91.3702 109.48C86.8337 108.778 82.2309 108.605 77.6546 108.967C73.7902 103.504 70.4207 97.7067 67.5862 91.6449C70.1547 84.8642 72.0759 78.4533 72.9286 73.2136C73.2985 70.9739 73.4937 68.8369 73.4218 66.9054C73.409 64.9882 72.9629 63.0987 72.117 61.3781C71.6296 60.4286 70.9369 59.5994 70.0893 58.9508C69.2417 58.3022 68.2603 57.8504 67.2163 57.6281C65.141 57.1864 63.004 57.6281 61.0417 58.4192C57.1685 59.9603 55.124 63.2479 54.3534 66.8746C53.6035 70.3677 53.9425 74.4362 54.826 78.5458C55.7301 82.7169 57.2712 87.258 59.2438 91.8504C56.0886 99.6976 52.4445 107.339 48.333 114.73C43.0382 116.396 37.9345 118.617 33.1071 121.357C29.3057 123.617 25.9256 126.288 23.8914 129.443C21.7339 132.792 21.0661 136.778 23.0695 140.775Z"
                fill="#7030A0"
              />
            </svg>
          ) : null}
        </div>
      </div>
      </>)
      }
      {view && <FileView path={data} 
      onClose={handleClose}
      visible={showFile}/>}
    </>
  )
}