import React, { useState } from 'react';
import ListLayout from "../../Utilities/Layout/ListLayout";
import StandardLayout from '../../Utilities/Layout/StandardLayout';
import Breadcrumbs from '../../Utilities/common/Breadcrumbs';
import ToggleViews from '../../Utilities/common/ToggleViews';
import Pagination from '../../Utilities/common/Pagination';
import Axios from "../../Services/axiosComman";
import useSWR from 'swr';
import AddIcon from '../../Utilities/Image/svg/add';
import { GetAccess, GetRole } from '../../Utilities/data/role';
import CardView from '../../Utilities/common/CardView';
import TableView from '../../Utilities/common/TableView';
import SubModal from './form';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';



const fetcher = (url) => Axios.get(url).then((res) => res.data);


export default function Help() {
    const {pathname} = useLocation()
    let hasNavitem = pathname.includes("/help/")
    console.log("testibg",hasNavitem);
    const [showMyModal, setShowMyModal] = useState(false);
    const handleOnClose = () => setShowMyModal(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataCount, setDataCount] = useState(20);
    const [view,setView] = useState(localStorage.getItem('view')||false)
    function listView(){
      localStorage.setItem('view',false);
      let View = localStorage.getItem('view')
      setView(false)
    }
    function cardView(){
      localStorage.setItem('view',true);
      let View = localStorage.getItem('view')
      setView(true)
    }
    const { data, error, mutate } = useSWR(
      `/help/?page=${pageIndex}&per_page=${dataCount}`,
      fetcher
    );
  
    const refreshData = () => {
      mutate(fetcher);
    };
  
    let link ="help"
  
  return (
    <>
      <StandardLayout hasInnerNavbar={hasNavitem}>
      <Breadcrumbs title={"Home"} label={"Help"} />
      <div className="w-[97.5%] bg-white shadow-main h-[calc(100vh-120px)] rounded-2xl mx-4">
        <div className="py-[10px] pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">{"Help Documents"}</p>
          <div className=" flex gap-3 justify-center items-center">
            <ToggleViews view={view} listView={listView} cardView={cardView}/>
            <div className=" w-8">
            {(GetRole()||GetAccess("admin_create"))&& (
                <AddIcon onClick={() => setShowMyModal(true)} />
            )}
          </div>
          </div>
        </div>
        {
     view == true? <CardView data={data} accessString="admin_delete" link={link} fetcher={refreshData}/> : <TableView accessString="admin_delete" data={data} link={link} fetcher={refreshData}/>
    }
        <Pagination
          total={data?.total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />

      </div>
      </StandardLayout>
      <SubModal
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={refreshData}
          id={1}
        />
    </>
  )
}
