import {Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import DateFormat from '../../../Utilities/common/NuDate';
import Logo from "../../../Utilities/Image/img/logo.jpg";


const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#fff',
      // margin: 10,
      padding:"15px 30px 60px 30px ",
    //   fontFamily:'Poppins'
    },
    sectionTop: {
        display:'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        width:'100%',
        height:"4%",
        // backgroundColor:'red'
      },
    pageNumber: {
        // position: 'absolute',
        fontSize: 9,
        // bottom: 30,
        // left: 0,
        // right: 0,
        textAlign: 'center',
        color: 'grey',
      },
      image:{
        width:35,
        height:20,
        // marginVertical: 15,
        // marginHorizontal: 100,
      },
      titleCenter:{
        textAlign:'center',
        // backgroundColor:'red',
        width:200
      },
      titleCenterTop:{
        fontSize: 13,
        // margin:10,
        // padding:10,
        marginBottom:5,
        textAlign: 'center',
        // color: 'grey',
        fontWeight:'semibold',
      },
      titleCenterBottom:{
        fontSize:'10px',
        color:'black',
        fontWeight:'normal',
        textAlign:'center',
      },
      titleRight:{
        fontSize:'7px',
        color:'grey',
        fontWeight:'bold'
      },
      sectionBottom:{
        width:"100%",
        height:"4%",
        // backgroundColor:'green',
        display:'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        alignItems:'center',
        position:'absolute',
        bottom:"15px",
        left:"30px"

      },
      sectionMain:{
        width:"100%",
        // height:"92%",
        // backgroundColor:'blue'
      },
      sectionInner:{
        width:"100%",
        // height:'750px',
        // backgroundColor:"#3085d6",
      },
      bottomCenter:{
        width:"33.3%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",
        gap:"3px"
      },
      bottomStart:{
        width:"33.3%",
        textAlign:"left",
        gap:"3px"
      },
      bottomEnd:{
        width:"33.3%",
        textAlign:"right",
        gap:"3px"
      },
      textStyle:{
        fontSize:'8px',
        color: 'grey',
      },
      lastItem:{
        display:"flex",
        flexDirection:"column",
        gap:"2px"
      },
      lastItemTitle:{
        fontSize:"6px"
      }
  });

  let DateNow = DateFormat({value:new Date(), format:'DD-MMM-YYYY' })
  let TimeNow = DateFormat({value:new Date(), format:'hh:mm:ss a' })

  function Date(data){
    if(data){
      return DateFormat({value:data,format:'DD-MMM-YYYY'})
    }else{
      return " "
    }
  }

const MyDoc = ({company,standardname,startDate,endDate,implementation,internal,maintenance,masterListofDocument,mom,securityIncident,auditCalendar,trainingCalendar,vender,managementReview}) => {

  // let pagesCount = [1,2,3]

  function Header ({standardname,companyName,companyLogo}){
    return(
      <View style={styles.sectionTop} fixed>
      <Image style={styles.image} src={companyLogo?companyLogo:Logo} />
      <View style={styles.titleCenter} >
        <Text style={styles.titleCenterTop}>{`${companyName||""}`}</Text>
        <Text style={styles.titleCenterBottom}>{`Progress Status of Standard :  ${standardname}`}</Text>
      </View>
      <View>
        <Text style={styles.titleRight}>
          {/* {` ${DateNow} / ${TimeNow}`} */}
        </Text>
      </View>
    </View>
    )
  }

  function Footer({startDate,endDate}){
    return(
      <View style={styles.sectionBottom} fixed>
        <View style={styles.bottomStart}>
          <Text style={styles.textStyle}>Certification Calendar Tracker</Text>
          <Text style={styles.textStyle}>
          {`${startDate&&startDate!="None"&&startDate!=""&&startDate!=null&&Date(startDate)||""} ${startDate&&startDate!="None"&&startDate!=""&&startDate!=null&&"/"||""} ${endDate&&endDate!="None"&&endDate!=""&&endDate!=null&&Date(endDate)||""}`}
            {/* {`${startDate&&startDate!="None"&&startDate!=""&&Date(startDate)} ${startDate&&startDate!="None"&&startDate!=""&&"/"} ${endDate&&endDate!="None"&&endDate!=""&&Date(endDate)}`} */}
            </Text>
        </View>
        <View style={styles.bottomCenter}>
        <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text style={styles.titleRight}>
          {` ${DateNow} / ${TimeNow}`}
        </Text>
        </View>
        <View style={styles.bottomEnd}>
        {/* <Text style={styles.textStyle}>{review&&review!=""&&review!=null&&review!="null"&&"Reviewed and Approved "||""}</Text> */}
        </View>
      </View>
    )
  }


  function MainContent(){
    return(
      <View style={styles.sectionMain}>
      <TempComponent title="Implemetation Checklist" data={implementation} />
      <TempComponent title="Internal Audit" data={internal} />
      <TempComponent title="Maintenance" data={maintenance} />
      <TempComponent title="Master List of Documents" data={masterListofDocument} />
      <TempComponent title="CISO / MRM meetings" data={mom} />
      <TempComponent title="Security Incidents" data={securityIncident} />
      <TempComponent title="Audit Calendar" data={auditCalendar} />
      <TempComponent title="Training Calendar" data={trainingCalendar} />
      <TempComponentTwo title="Vendor Management" data={vender} />
      <TempComponent title="Management Review" data={managementReview} />
    </View>
    )
  }

    return(
        <Document>
          {/* {
            pagesCount.map((page,idx)=>( */}
              <Page size="A4" style={styles.page} orientation='landscape'>
              <Header standardname={standardname} companyName={company?.companyName} companyLogo={company?.companyLogo} />   
              <MainContent />       
              <Footer startDate={startDate} endDate={endDate} />
            </Page>
            {/* ))
          } */}
      </Document>
    )
  };


  export default MyDoc


  function TempComponent({title,data}){
    const styleTemp =  StyleSheet.create({
        mainView:{
            
            paddingBottom: 12,
            borderBottom:'1px solid grey', 
            display:'flex',
            flexDirection: 'column',
            justifyContent:'space-between',
            width:'100%',
            marginTop:7,
            fontSize:13
            
        },
        title:{
            fontSize:10,
            margin:'6px 0'
        },
        innerView:{
            display:'flex',
            flexDirection: 'row',
            // justifyContent:'space-between',
            // width:'96%',
            gap:8
        },
        innerTitle:{
            fontSize:10,
            color:'gray',
            margin:'2px 0',
            fontWeight:'medium'
        },
        innerText:{
            fontSize:10,
            color:'gray',
            margin:'2px 0',
            fontWeight:'600'
        }
    })
    return(
        <View style={styleTemp.mainView}>
         <Text style={styleTemp.title}>{title} :</Text>
          <View style={styleTemp.innerView}>
            <Text style={styleTemp.innerTitle}>Yet to Start -</Text>
            <Text style={styleTemp.innerText}>{data?.not_yet_start_percentage||data?.Not_yet_start_percentage||0}%</Text>
          </View>
          <View style={styleTemp.innerView}>
            <Text style={styleTemp.innerTitle}>In-Progress -</Text>
            <Text style={styleTemp.innerText}>{data?.in_progress_percentage||0}%</Text>
          </View>
          <View style={styleTemp.innerView}>
            <Text style={styleTemp.innerTitle}>Completed -</Text>
            <Text style={styleTemp.innerText}>{data?.complete_percentage||0}%</Text>
          </View>
          <View style={styleTemp.innerView}>
            <Text style={styleTemp.innerTitle}>Reviewed & Approved -</Text>
            <Text style={styleTemp.innerText}>{data?.verified_and_approved?"Yes":"No"}</Text>
          </View>          
        </View>
    )
  }


  function TempComponentTwo({title,data}){
    const styleTemp =  StyleSheet.create({
        mainView:{
            
            paddingBottom: 12,
            borderBottom:'1px solid grey', 
            display:'flex',
            flexDirection: 'column',
            justifyContent:'space-between',
            width:'100%',
            marginTop:7,
            fontSize:13
            
        },
        title:{
            fontSize:10,
            margin:'6px 0'
        },
        innerView:{
            display:'flex',
            flexDirection: 'row',
            // justifyContent:'space-between',
            // width:'96%',
            gap:8
        },
        innerTitle:{
            fontSize:10,
            color:'gray',
            margin:'2px 0',
            fontWeight:'medium'
        },
        innerText:{
            fontSize:10,
            color:'gray',
            margin:'2px 0',
            fontWeight:'600'
        }
    })
    return(
        <View style={styleTemp.mainView}>
         <Text style={styleTemp.title}>{title} :</Text>
          <View style={styleTemp.innerView}>
            <Text style={styleTemp.innerTitle}>Total Vendors -</Text>
            <Text style={styleTemp.innerText}>{data||0}</Text>
          </View>          
        </View>
    )
  }
