import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { GetRole } from '../data/role';
import axiosInstance from '../../Services/axiosComman';

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({ id, fetcher, link}) {


  const handleDelete = async (id) => {
    Swal.fire({
        title: "Are you sure?",
        text: `You want to Delete Document`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then(async (result) => {
        if (result.isConfirmed) {
            if (id) {
                await axiosInstance.delete(`/${link}/${id}/`)
                  .then((res) => {
                    if (res.status == 200) {
                      fetcher()
                      Toast.fire({
                        icon: "success",
                        title: res.data?.message || "Document Deleted successfully",
                      });
                    } else {
                      Toast.fire({
                        icon: "error",
                        title: res.data?.message || "Document Deleted  unsuccessfully",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error.message);
                  });
              }
        }
      });
    // let qwe = { "id": id }

  }
  return (
<>
<div
        className="hover:drop-shadow-sm mr-2 py-2 px-[7px] rounded-3xl h-8 bg-white dropdown cursor-pointer relative"
        // onClick={() => {
        //   setShowMyMenu(!showMyMenu);
        //   getViewPage();
        // }}
      >
        <svg
          className="m-auto w-[4px]"
          viewBox="0 0 4 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="1.83391"
            cy="2.38902"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
          <ellipse
            cx="1.83391"
            cy="7.30914"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
          <ellipse
            cx="1.83391"
            cy="12.2293"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
        </svg>
        <div className="hidden dropdown-menu">
          <div
            id="modal"
            // onClick={handleOnCloseMenu}
            className="bg-transparent fixed inset-0 flex justify-center items-center "
          ></div>
          <div className="bg-white rounded-xl w-16 absolute -left-16 -top-1">
            <div className="grid p-1 rounded-t">
              <span
                className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer text-sm"
                onClick={() => handleDelete(id)}
              >
                Delete
              </span>
            </div>
          </div>
        </div>
      </div>
</>
  )
}

export default Viewmore