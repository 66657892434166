import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "../../../../Utilities/Image/img/logo.jpg";
import paperclip from "../../../../Utilities/Image/img/paper-clip.png";

import DateFormat from "../../../../Utilities/common/NuDate";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    // margin: 10,
    padding: "15px 30px 60px 30px ",
    //   fontFamily:'Poppins'
  },
  sectionMainTop: {
    width: "100%",
    height: "12%",
    flexDirection: "column",
    // backgroundColor:'green',
    // justifyContent:"flex-end"
  },
  sectionTop: {
    display:'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    alignItems:"center",
    width:'100%',
    height:"50%",
  },
  pageNumber: {
    // position: 'absolute',
    fontSize: 9,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },
  image: {
    width: 35,
    height: "auto",
    paddingVertical: "3px",
    // marginHorizontal: 100,
  },
  titleCenter: {
    textAlign: "center",
    // backgroundColor:'red',
    width: 200,
  },
  titleCenterTop: {
    fontSize: 13,
    // margin:10,
    // padding:10,
    marginBottom: 5,
    textAlign: "center",
    // color: 'grey',
    fontWeight: "semibold",
  },
  titleCenterBottom: {
    fontSize: "10px",
    color: "black",
    fontWeight: "normal",
    textAlign: "center",
  },
  titleRight: {
    fontSize: "7px",
    color: "grey",
    fontWeight: "bold",
  },
  sectionBottom: {
    width:"100%",
    height:"7%",
    // backgroundColor:'green',
    display:'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    alignItems:'center',
    position:'absolute',
    bottom:"15px",
    left:"30px"
  },
  sectionMain: {
    width: "100%",
    // height:"92%",
    // backgroundColor:'blue'
  },
  sectionInner: {
    width: "100%",
    // height:'750px',
    // backgroundColor:"#3085d6",
  },
  tableRow: {
    width: "100%",
    height: "50%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F1E0FF",
  },
  tableTitle: {
    fontSize: "9px",
    color: "black",
    fontWeight: "bold",
  },
  tableOne: {
    width: "5%",
    textAlign: "center",
    paddingVertical: "4px",
  },

  tableTwo: {
    width: "30%",
    textAlign: "center",
    paddingVertical: "4px",
  },
  tableThree: {
    width: "20%",
    textAlign: "center",
    paddingVertical: "4px",
  },
  tableFour: {
    width: "10%",
    textAlign: "center",
    paddingVertical: "4px",
  },
  tableFive: {
    width: "10%",
    textAlign: "center",
    paddingVertical: "4px",
  },
  tableSix: {
    width: "10%",
    textAlign: "center",
    paddingVertical: "4px",
  },
  tableSeven: {
    width: "10%",
    textAlign: "center",
    paddingVertical: "4px",
  },
  tableEight: {
    width: "5%",
    textAlign: "center",
    paddingVertical: "4px",
  },

  company:{
    display:'flex',
    flexDirection:"row",
    gap:"4px",
    justifyContent:"center",
    alignItems:"center"
  },
  bottomCenter:{
    width:"33.3%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    textAlign:"center",
    gap:"3px"
  },
  bottomStart:{
    width:"33.3%",
    textAlign:"left",
    gap:"3px"
  },
  bottomEnd:{
    width:"33.3%",
    textAlign:"right",
    gap:"3px"
  },
  textStyle:{
    fontSize:'8px',
    color: 'grey',
  },
  lastItem:{
    display:"flex",
    flexDirection:"column",
    gap:"2px"
  },
  lastItemTitle:{
    fontSize:"6px"
  }

});

let DateNow = DateFormat({ value: new Date(), format: "DD-MMM-YYYY" });
let TimeNow = DateFormat({ value: new Date(), format: "hh:mm:ss a" });

function Date(data) {
  if (data) {
    return DateFormat({ value: data, format: "DD-MMM-YYYY" });
  } else {
    return " ";
  }
}

const MasterPDF = ({ company, standardname, data,Name,review,startDate,endDate,status,updateLenth }) => {
  // console.log("Com",company,"stan",standardname);
  function Header({ standardname, companyName, companyImg,status }) {
    let CompletedTemp = Number(status?.completed?.percentage||0);
    let CompletedFinal = CompletedTemp.toFixed(1)
    let InProgressTemp = Number(status?.in_progress?.percentage||0);
    let InProgressFinal = InProgressTemp.toFixed(1);
    let YetToStartTemp = Number(status?.yet_to_start?.percentage||0);
    let YetToStartFinal = YetToStartTemp.toFixed(1);
    return (
      <View style={styles.sectionMainTop} fixed>
      <View style={styles.sectionTop} >
        <View style={styles.company}>
        <Image style={styles.image} src={companyImg?companyImg:Logo} />
        <Text style={styles.titleCenterTop}>{`${companyName||""}`}</Text>
        </View>
        <View>
        <Text style={styles.titleCenterBottom}>{`${standardname}`}</Text>
        </View>
        <View>
        <Text style={styles.titleCenterBottom}>{`${Name}`}</Text>
        </View>
        <View style={styles.lastItem}>
          <Text style={styles.lastItemTitle}>Status:</Text>
          <Text style={styles.lastItemTitle}>{`Yet To Start : ${YetToStartFinal||0} %`}</Text>
          <Text style={styles.lastItemTitle}>{`In Progress : ${InProgressFinal||0} %`}</Text>
          <Text style={styles.lastItemTitle}>{`Completed : ${CompletedFinal||0} %`}</Text>
        </View>

    </View>
        <View style={styles.tableRow}>
          <View style={styles.tableOne}>
            <Text style={styles.tableTitle}>Sl.No</Text>
          </View>
          <View style={styles.tableTwo}>
            <Text style={styles.tableTitle}>Document Name</Text>
          </View>
          <View style={styles.tableThree}>
            <Text style={styles.tableTitle}>Document Description</Text>
          </View>
          <View style={styles.tableFour}>
            <Text style={styles.tableTitle}>Version</Text>
          </View>
          <View style={styles.tableFive}>
            <Text style={styles.tableTitle}>Latest Updated On</Text>
          </View>
          <View style={styles.tableSix}>
            <Text style={styles.tableTitle}>Status</Text>
          </View>
          <View style={styles.tableSix}>
            <Text style={styles.tableTitle}>Last Updated By</Text>
          </View>
          <View style={styles.tableEight}>
            <Text style={styles.tableTitle}>Artefact</Text>
          </View>
        </View>
      </View>
    );
  }

  function Footer({startDate,endDate,review}){
    return(
      <View style={styles.sectionBottom} fixed>
        <View style={styles.bottomStart}>
          <Text style={styles.textStyle}>Certification Calendar Tracker</Text>
          <Text style={styles.textStyle}>
          {`${startDate&&startDate!="None"&&startDate!=""&&startDate!=null&&Date(startDate)||""} ${startDate&&startDate!="None"&&startDate!=""&&startDate!=null&&"/"||""} ${endDate&&endDate!="None"&&endDate!=""&&endDate!=null&&Date(endDate)||""}`}
            {/* {`${startDate&&startDate!="None"&&startDate!=""&&Date(startDate)} ${startDate&&startDate!="None"&&startDate!=""&&"/"} ${endDate&&endDate!="None"&&endDate!=""&&Date(endDate)}`} */}
          </Text>
        </View>
        <View style={styles.bottomCenter}>
        <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text style={styles.titleRight}>
          {` ${DateNow} / ${TimeNow}`}
        </Text>
        </View>
        <View style={styles.bottomEnd}>
        <Text style={styles.textStyle}>{review&&review!=""&&review!=null&&review!="null"&&"Reviewed and Approved "||""}</Text>
        </View>
      </View>
    )
  }

  function MainContent() {
    const styleTable = StyleSheet.create({
      table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
      },
    });
    return (
      <View style={styleTable.table}>
        {data &&
          data.map((item, idx) => {
            // updateLenth(idx+1)
            return <TempComponent data={item} key={idx} />;
          })}
        {
          data.length == 0 && <View> <Text style={{fontSize:9,color:"gray",textAlign:"center",marginTop:15}}>NO DATA</Text> </View>
        }
      </View>
    );
  }

  return (
    <Document>
      {/* {
            pagesCount.map((page,idx)=>( */}
      <Page size="A4" style={styles.page} orientation="landscape">
        <Header
          standardname={standardname}
          companyName={company?.companyName}
          companyImg={company?.companyLogo}
          status={status}
        />
        <MainContent />
        <Footer startDate={startDate} endDate={endDate} review={review}/>
      </Page>
      {/* ))
          } */}
    </Document>
  );
};

export default MasterPDF;

function TempComponent({ data }) {
  const styleTemp = StyleSheet.create({
    tableRow: {
      flexDirection: "row",
      borderStyle: "solid",
      borderWidth: 0.2,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
    },
    tableTitle: {
      fontSize: "8px",
      color: "black",
      // fontWeight:'bold'
    },
    tableOne: {
      width: "5%",
      textAlign: "center",
      paddingVertical: "8px",
    },
    tableTwo: {
      width: "30%",
      textAlign: "left",
      paddingVertical: "8px",
    },
    tableThree: {
      width: "20%",
      textAlign: "center",
      paddingVertical: "8px",
    },
    tableFour: {
      width: "10%",
      textAlign: "center",
      paddingVertical: "8px",
    },
    tableFive: {
      width: "10%",
      textAlign: "center",
      paddingVertical: "8px",
    },
    tableSix: {
      width: "10%",
      textAlign: "center",
      paddingVertical: "8px",
      textTransform:"capitalize"

    },
    tableSeven: {
      width: "10%",
      textAlign: "center",
      paddingVertical: "8px",
    },
    tableEight: {
      width: "5%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      // textAlign:"center",
      paddingVertical: "8px",
    },
    cell: { margin: "auto", fontSize: "8px" },
    paperclip: {
      width: 6,
      height: "auto",
    },
  });
  return (
    <View style={styleTemp.tableRow}>
      <View style={styleTemp.tableOne}>
        <Text style={styleTemp.tableTitle}>{data?.serial_number || ""}</Text>
      </View>
      <View style={styleTemp.tableTwo}>
        <Text style={styleTemp.tableTitle}>{data?.document_name || ""}</Text>
      </View>
      <View style={styleTemp.tableThree}>
        <Text style={styleTemp.tableTitle}>{data?.document_description || ""}</Text>
      </View>
      <View style={styleTemp.tableFour}>
        <Text style={styleTemp.tableTitle}>{data?.version || ""}</Text>
      </View>
      <View style={styleTemp.tableFive}>
        <Text style={styleTemp.tableTitle}>{ data?.latest_update && data?.latest_update !="" && data?.latest_update !="None" && Date(data?.latest_update) ||""}</Text>
      </View>
      <View style={styleTemp.tableSix}>
        <Text style={styleTemp.tableTitle}>{data?.status || ""}</Text>
      </View>
      <View style={styleTemp.tableSeven}>
        <Text style={styleTemp.tableTitle}>{data?.last_modified_name || data?.created_name || data?.created_by_name||""}</Text>
      </View>
      <View style={styleTemp.tableEight}>
        {data.attachment && (
          <Image style={styleTemp.paperclip} src={paperclip} />
        )}
      </View>
    </View>
  );
}
