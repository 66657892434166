import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosInstance from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
import FileDisplay from "../../../Utilities/common/FileDisplay";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({
  data,
  visible,
  onClose,
  frequency,
  refreshData,
  trainingCalender,
  id,
  getTraining
}) {
  // const handleOnClose = (e) => {
  //   if (e.target.id === "modal") onClose();
  // };
  const [isLoading,setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    training_program: "",
    function_responsibility: "",
    frequency: "",
    participants: "",
    mandatory: "",
    detail_plan: "",
    planned_date: "",
    artifact: "",
    status: "",
  });

  useEffect(() => {
    setCreateNew({
      training_program: data?.training_program,
      function_responsibility: data?.function_responsibility,
      frequency: data?.frequency,
      participants: data?.participants,
      mandatory: data?.mandatory,
      detail_plan: data?.detail_plan,
      planned_date: data?.planned_date,
      artifact: data?.artifact,
      status: data?.status,
    });
    setFilePathList(data?.artifact)
    setRemoveFilePathList(data?.artifact)
  }, [data]);

  function handleImageChange(e) {
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  }

  const formInputs = [
    {
      inputType: "NuInput",
      name: "training_program",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Training Program",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 pr-4",
    },

    {
      inputType: "NuInput",
      name: "function_responsibility",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Function Responsibility",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 pl-4",
    },
    {
      inputType: "NuSelect",
      name: "frequency",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Frequency",
      label: "Frequency",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 pr-4 md:mt-4",
      Options: frequency
    },
    {
      inputType: "NuInput",
      name: "participants",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Participants",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pl-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "mandatory",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Mandatory for the organization",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pr-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "detail_plan",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Detail Plan",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pl-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "planned_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      max: DateFormat({ value: Date(), format: "DD-MM-yyyy" }),
      label: "Planned Date",
      labelclassname: "w-full",
      type: "date",
      customClass: "w-6/12 pr-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "filePath",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Artefact",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-6/12 pl-4 md:mt-4",
      onChange: handleImageChange,
    },
    {
      inputType: "NuSelect",
      name: "status",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select status",
      label: "Status",
      labelclassname: "w-full",
      // type: 'text',
      style: "w-full",
      customClass: "w-6/12 pl-4",
      Options: trainingCalender,
    }
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   training_program: yup.string().required("Trainingprogram Required"),
    //   frequency: yup.string().required("Frequency Required"),
    //   participants: yup.string().required("Participants Required"),
    //   planned_date: yup.string().required("PlannedDate Required"),
    //   status: yup.string().required("Status Required"),
    // }),
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
      if (data?.id) {
        if(!value.planned_date){
          value.planned_date = "None"
        }
        let finalArr = removefilePathList.filter(items=>  !filePathList.includes(items) )
        value.files_to_remove =finalArr.map(item=>item.replace(window.WEB_APP_API_URL, "localhost"));
        let newData = new FormData();
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            newData.append("attachment_artifact", file);
          });
        } else{
          newData.append("attachment_artifact",[]);
        } 
        value.status = value.status.toLowerCase();
        newData.append("training_data", JSON.stringify(value));
        axiosInstance.put(`/Training-Calender/${data?.id}/screen_closed`,{"isClosed":false}).then(res=>{
        axiosInstance
          .put(`/Training-Calender/${data.id}/`, newData)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
              refreshData();
              getTraining()
              resetForm();
              setSelectedFile(null)
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Training Calendar Updated Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title:res.data.msg || res.data.error || "Training Calendar Update Unsuccessfully",
              });
            }
          }).catch((err)=>{
            setIsLoading(false);
              Toast.fire({
                icon: "error",
                title:err?.response?.data.msg || err?.response?.data.error || "Training Calendar Update Unsuccessfully",
              });
          });
        }).catch(err=>{
          setIsLoading(false);
          console.log("err")
        }) 
      } else {
        let reqObjs = new FormData();

        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            reqObjs.append("attachment_artifact", file);
          });
        } 
        // value.status = value.status ? value.status : "not implemented";
        reqObjs.append("training_data", JSON.stringify(value));

        axiosInstance
          .post(`/Training-Calender/standard/${id}/`, reqObjs)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
              refreshData();
              resetForm();
              getTraining()
              setSelectedFile(null)
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Training Calendar Created Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title:res.data.msg || res.data.error || "Training Calendar Creation Unsuccessfully",
              });
            }
          }).catch((err)=>{
            setIsLoading(false);
              Toast.fire({
                icon: "error",
                title:err?.response?.data.msg || err?.response?.data.error || "Training Calendar Creation Unsuccessfully",
              });
          });
      }
    },
  });

  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }

  const handleOnClose = (e) => {
    if (e.target.id === "modal"){
      setSelectedFile(null)
      formik.resetForm()
      onClose();
    }
  };

  const closeFunction = () =>{
    setSelectedFile(null)
    formik.resetForm()
    onClose()
}

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
          flex justify-center items-center z-10"
      >
        <div className="bg-white mt-4 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-xs 2xl:text-sm font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className=" overflow-y-scroll h-[85vh] ">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex flex-wrap my-3">
              {data?.artifact &&
               data?.artifact !=null &&
               data?.artifact !="None" &&
               data?.artifact.length>0 && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
