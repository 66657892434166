import React, { useEffect, useState } from "react";
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import home from "../../../Utilities/Image/icons/home.svg";
import plus from "../../../Utilities/Image/icons/plus.svg";
import left from "../../../Utilities/Image/icons/left.svg";
import leftdouble from "../../../Utilities/Image/icons/leftdouble.svg";
import right from "../../../Utilities/Image/icons/right.svg";
import rightdouble from "../../../Utilities/Image/icons/rightdouble.svg";
import menu from "../../../Utilities/Image/icons/menu.svg";
import { NavLink, useParams } from "react-router-dom";
import Calender from "./calender";
import overdue from "../../../Utilities/Image/icons/overduemeatballs.svg";
import pending from "../../../Utilities/Image/icons/pendingmeatballs.svg";
import completed from "../../../Utilities/Image/icons/completedmeatballs.svg";
import useSWR from "swr";
import axiosInstance from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { GetYear } from "../../../Utilities/common/GetYear";
import StandardLayout from "../../../Utilities/Layout/StandardLayout";
import Breadcrumbs from "../../../Utilities/common/Breadcrumbs";


function CalenderManagementIndex() {
  const { id } = useParams();
  const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);
  const { data, error, mutate } = useSWR(
    `/Calender-Management/standard/${id}`,
    fetcher
  );
  const refreshData = () => {
    mutate(fetcher);
  };
  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => {
    setShowDetails(true);
  };
  const [dayDetails, setDayDetails] = useState({});
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  let d = new Date(dayDetails.start);
  let day = d.getDay();
  let qwert = data?.data?.filter(function (a) {
    return a.completion_date == dayDetails?.startStr;
  });
  let byReminderDate = data?.data?.filter(function (a) {
    return a.reminder_date == dayDetails?.startStr;
  });
  var pend = qwert?.filter(function (a) {
    return a.status === "in progress";
  });
    var pendByReminder = byReminderDate?.filter(function (a) {
    return a.status === "in progress";
  });
  var comp = qwert?.filter(function (a) {
    return a.status === "completed";
  });
  var compByReminder = byReminderDate?.filter(function (a) {
    return a.status === "completed";
  });
  var over = qwert?.filter(function (a) {
    return a.status === "yet to start";
  });
  var overByReminder = byReminderDate?.filter(function (a) {
    return a.status === "yet to start";
  });
  return (
    <>
    <StandardLayout>
      <Breadcrumbs label="Calendar Management" title="Information" />
      <div className="w-[97.5%] bg-white shadow-main h-[calc(100vh-120px)] rounded-2xl mx-4 overflow-hidden">
        <div className="py-3 px-5 w-6/12 lg:w-full flex items-center justify-between">
          <p className="text-xs 2xl:text-sm text-text">Calendar Management</p>
          <Link to={`/information/task-management/${id}`}><p className=" text-[10px] text-blue-700 bg-gray-100 p-2 rounded shadow-box">Task</p></Link>
        </div>
        <div className="flex gap-2 h-[calc(100vh-175px)] ">
          <div className={`${showDetails ? 'w-[70%]' : 'w-full'} shadow-box`}>
            <Calender
              eventDatas={data?.data}
              Details={handleShowDetails}
              setDayDetails={setDayDetails}
              refreshData={refreshData}
              id={id}
            />
          </div>
          {showDetails ? (
            <div className="w-[29.5%] bg-white rounded-lg shadow-box p-2">
              <h3 className="bg-tabletitle rounded-lg  p-3 text-xs 2xl:text-sm font-medium flex items-center justify-between"><DateFormat value ={dayDetails?.startStr} format="DD-MMM-YYYY" />{`
                  (${day == 0
                  ? "Sunday"
                  : day == 1
                    ? "Monday"
                    : day == 2
                      ? "Tuesday"
                      : day == 3
                        ? "Wednesday"
                        : day == 4
                          ? "Thursday"
                          : day == 5
                            ? "Friday"
                            : day == 6
                              ? "Saturday"
                              : null
                })`}
                <span
                className="h-6 w-6 text-2xl block cursor-pointer"
                onClick={() => setShowDetails(false)}
              >
                <svg
                  className="w-7 h-7"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.842773"
                    y="0.560791"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                  <path
                    d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Close</title>
                </svg>
              </span>
                </h3>
              
              <div className="overflow-y-auto h-[calc(100vh-240px)]">
                <div className="bg-white h-auto rounded-lg shadow-box m-2">
                  <div className="flex justify-between px-4 pt-3 pb-1">
                    <h3 className="text-xs 2xl:text-sm text-overdue font-medium">
                      Yet to start
                    </h3>
                    {/* <img src={overdue} alt="" /> */}
                  </div>
                  <div className="px-4 pb-3 text-xs 2xl:text-sm font-medium">
                  <ul className="list-disc">
                    {over?.map((item) => (
                      <Link to={`/information/task-detail/view/${id}/${item.id}`}><li className=" mx-4 py-1" title={item?.comment}>{item?.title}</li></Link>
                    ))}
                    {overByReminder?.map((item) => (
                      <Link to={`/information/task-detail/view/${id}/${item.id}`}><li className=" mx-4 py-1" title={item?.comment}>{item?.title}</li></Link>
                    ))}
                    </ul>
                    {/* {contentoverdue.map((data,i) => (<p className="py-1" key="i">{data.value}</p>))} */}
                  </div>
                </div>
                <div className="bg-white h-auto rounded-lg shadow-box m-2">
                  <div className="flex justify-between px-4 pt-3 pb-1">
                    <h3 className="text-xs 2xl:text-sm text-[#ffd700] font-medium">
                      In progress
                    </h3>
                    {/* <img src={pending} alt="" /> */}
                  </div>

                  <div className="px-4 pb-3 text-xs 2xl:text-sm font-medium">
                  <ul className="list-disc">
                    {pend?.map((item) => (
                      <Link to={`/information/task-detail/view/${id}/${item.id}`}><li className=" mx-4 py-1" title={item?.comment}>{item?.title||"-"}</li></Link>
                    ))}
                    {pendByReminder?.map((item) => (
                      <Link to={`/information/task-detail/view/${id}/${item.id}`}><li className=" mx-4 py-1" title={item?.comment}>{item?.title||"-"}</li></Link>
                    ))}
                    </ul>
                    {/* {contentpending.map((data,i) => (<p className="py-1" key="i">{data.value}</p>))} */}
                  </div>
                </div>
                <div className="bg-white h-auto rounded-lg shadow-box m-2">
                  <div className="flex justify-between px-4 pt-3 pb-1">
                    <h3 className="text-xs 2xl:text-sm text-completed font-medium">
                      Completed
                    </h3>
                    {/* <img src={completed} alt="" /> */}
                  </div>
                  <div className="px-4 pb-3 text-xs 2xl:text-sm font-medium">
                  <ul className="list-disc">
                    {comp?.map((item) => (
                      <Link to={`/information/task-detail/view/${id}/${item.id}`}><li className=" mx-4 py-1" title={item?.comment}>{item?.title||"-"}</li></Link>
                    ))}
                    {compByReminder?.map((item) => (
                      <Link to={`/information/task-detail/view/${id}/${item.id}`}><li className=" mx-4 py-1" title={item?.comment}>{item?.title||"-"}</li></Link>
                    ))}
                    </ul>
                    {/* {contentcomplete.map((data,i) => (<p className="py-1" key="i">{data.value}</p>))} */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    {/* </div> */}
    </StandardLayout>
    </>
  );
}

export default CalenderManagementIndex;


