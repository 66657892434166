import React, { useEffect, useState } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateorUpdate({
  data,
  visible,
  onClose,
  refreshData,
  id,
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [isLoading,setIsLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    standard_id:"",
    subject:"$title",
    message:"$subtitle",
    status_name:""
  });

  useEffect(() => {
    setCreateNew({
      // module_id: data?.module_id,
      subject: data?.subject,
      message: data?.message,
      standard_id: data?.standard_id,
      status_name: data?.status_name,
    });
  }, [data]);
  const formInputs = [

    {
      inputType: "NuSelect",
      name: "status_name",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "Select Status",
      label: "Status",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: [
        { value: "completed", label: "Completed" },
        { value: "in progress", label: "In Progress" },
        { value: "yet to start", label: "Yet to Start" },
        { value: "review_and_approved", label: "Reviewed and Approved" },
        { value:"reminder",label:"Reminder" },
        { value:"one month alert",label:"Standard Expiry Alert Before One Month" },
        { value:"one week alert",label:"Standard Expiry Alert Before One Week" },
        { value:"one day before alert",label:"Standard Expiry Alert Before One Day" },
        { value:"after one day alert",label:"Standard Expiry Alert After One Day" },
        {value:"Standard Reset",label:"Standard Reset"},
      ],
    },
    {
      inputType: "NuInput",
      name: "subject",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Subject",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full ",
    },
    {
      inputType: "NuTextArea",
      name: "message",
      classname: "border w-full h-20 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Message",
      labelclassname: "w-full",
      style: "w-full",
      customClass: "w-full ",
      rows: "10",
      cols: "50",
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
      if(!data?.id){
        value.standard_id = id;
      }
      if (data?.id) {
        axiosInstance.put(`/email_templates/${data.id}`, value).then((res) => {
          if (res.data) {
            setIsLoading(false);
            resetForm();
            refreshData();
            Toast.fire({
              icon: "success",
              title: res.data.msg || "Email Template Updated Successfully",
            });
            onClose();
          } else {
            setIsLoading(false);

            Toast.fire({
              icon: "error",
              title: res.data.msg || "Email Template Update Unsuccessfully",
            });
          }
        });
      } else {
        // formik.resetForm({ values: {} });
        // let reqObjs = new FormData();
        // reqObjs.append("userData", JSON.stringify(value));
        axiosInstance.post("/email_templates/", value).then((res) => {
          if (res.data) {
            setIsLoading(false);
            refreshData();
            resetForm();
            Toast.fire({
              icon: "success",
              title: res.data.msg || "Email Template is Created",
            });
            onClose();
          } else {
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: res.data.msg || "Email Template is not Created",
            });
          }
        });
      }
    },
  });
  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="overflow-y-auto ">
              <div className="relative px-6 pb-20 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
                <div>
                  <p className=" text-[13px] font-medium mt-4 last:">Note : </p>
                  <p className=" text-xs text-gray-500">Please use this keywords for the Email Template </p>
                  <p className=" text-xs text-gray-500">&#123;title&#125; - Title for the Email Subject </p>
                  <p className=" text-xs text-gray-500">&#123;subtitle&#125; - SubTitle for the Email Message </p>
                  <p className=" text-xs text-gray-500">&#123;name&#125; - User Name for the Email Message </p>
                  <p className=" text-xs text-gray-500">&#123;date&#125; - Date for the Email Message </p>
                  <p className=" text-xs text-gray-500">&#123;status&#125; - Status for the Email Message </p>
                  <p className=" text-xs text-gray-500">&#123;module_name&#125; - Module Name for the Email Message </p>
                </div>
              </div>
              <div className="flex items-center justify-end px-6 py-6 rounded-b">
              <button
                  onClick={onClose}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
