import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../Utilities/common/MainForm";
import Add from "../../Utilities/Image/icons/plus.svg";
import Close from "../../Utilities/Image/icons/close.svg";
import axiosComman from "../../Services/axiosComman";

// import Modal from "./modal"

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({
  data,
  visible,
  onClose,
  refreshData,
  id,
  headerId,
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [isLoading,setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [createNew, setCreateNew] = useState({
    name:""
  });
  useEffect(() => {
    setCreateNew({
      name:data?.name
    });
  }, [data]);
  const [progress, setProgress] = useState(0);
  function handleImageChange(e) {
    const { files, value } = e.target;
    setSelectedFile(files[0]);
  }

  const formInputs = [
    {
      inputType: "NuInput",
      name: "name",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Standard Name",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "imagePathOne",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Standard Logo",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleImageChange,
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .required("Name Required")
    //     .matches(/^(?![0-9]*$)[a-zA-Z0-9 ]+$/, "Enter Valid Name"),
    //   dob: yup.string().required("Date of Birth Required"),
    //   gender: yup.string().required("Gender Required"),
    //   city: yup.string().matches(/^[A-Za-z]+$/, "Enter Valid City Name"),
    //   state: yup.string().required("Place Select State"),
    //   pincode: yup
    //     .string()
    //     .required("Pincode is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Pincode"
    //     )
    //     .min(6, "Invalid Pincode")
    //     .max(6, "Invalid Pincode"),
    //   address: yup
    //     .string()
    //     .matches(
    //       /^(?![0-9]*$)(?![0-9\s]*$)[a-zA-Z0-9'\.\-\s\,\/\(\)\#]+$/,
    //       "Enter Valid Address"
    //     ),
    //   aadhar_no: yup
    //     .string()
    //     .required("Aadhar Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Aadhar Number"
    //     )
    //     .min(12, "Invalid Aadhar Number")
    //     .max(12, "Invalid Aadhar Number"),
    //   mobile_no: yup
    //     .string()
    //     .required("Mobile Number is required")
    //     .matches(
    //       /^((\\[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //       "Enter Valid Mobile Number"
    //     )
    //     .min(10, "Invalid Mobile Number")
    //     .max(10, "Invalid Mobile Number"),
    //   join_date: yup.string().required("Join Date is required"),
    // }),
    onSubmit: async (value,{resetForm}) => {
      setIsLoading(true);

      if (data?.id) {
        data.name = value?.name;
        let reqObjs = new FormData();
        reqObjs.append("standards", selectedFile);
        reqObjs.append("name", value.name);


        axiosComman
          .put(`/Standards/${data?.id}/`, reqObjs)
          .then((res) => {
            if (res.data) {
              setIsLoading(false);
              onClose();
              refreshData();
              resetForm()
              setSelectedFile(null);
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Standard Updated Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || "standard Update Unsuccessfully",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        // value.imagePath = "";
        let reqObjs = new FormData();
        reqObjs.append("standards", selectedFile);
        reqObjs.append("name", value.name);

        axiosComman
          .post("/Standards/", reqObjs)
          .then((res) => {
            if (res.data) {
              onClose();
              refreshData();
              setSelectedFile(null);

              Toast.fire({
                icon: "success",
                title: res.data.msg || "Standard Created Successfully",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: res.data.msg || "Standard Creation Unsuccessfully",
              });
            }
          })
          .catch((error) => console.log(error));
      }
    },
  });
  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-xs 2xl:text-sm font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
              {/* <img src={Close} alt="close" title="close" /> */}
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>

              <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={onClose}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
