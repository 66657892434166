import React from "react";
import Close from "../../../Utilities/Image/icons/close.svg";
import FileDisplay from "../../../Utilities/common/FileDisplay";

export function View({ visible, onClose, data }) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  if (!visible) return null;
  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
    >
      <div className="bg-white p-2 rounded w-1/2 h-[80vh] ">
        <div className="flex items-start justify-between px-5 py-2  rounded-t">
          <h3 className="text-xs 2xl:text-sm font-semibold">{"User View"}</h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={onClose}
          >
            {/* <img src={Close} alt="close" title="close" /> */}
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.842773"
                y="0.560791"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
              <path
                d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Close</title>
            </svg>
          </span>
        </div>
        <div className=" h-[66vh]">
          <div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Name
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8">
                {data.vendor_name||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Vender Type
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8">
                {data.vendor_type|| "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Documents
              </p>
              <div className=" flex flex-wrap">
              {data?.document_type.length>0 && (
                                                    data?.document_type.map(i =>{
                                                      return(
                                                        <div className=" m-1">
                                                          <FileDisplay data={i} view={true}/>
                                                          </div>
                                                      )
                                                    }
                                                        
                                )
              )}
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}
