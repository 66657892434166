import React from "react";
import { ErrorMessage } from "formik";

export function NuInput(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,
    disabled,
    maxLength,
    customClass
  } = props;
  // console.log(max, "date");
  return (
    <div className={`relative px-6 py-2  ${customClass}`} >
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formik.values[name]}
        onChange={onChange||handleChange||formik.handleChange}
        // onBlur={handleBlur}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none"
        }
        multiple
        maxLength={maxLength}
      />
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}

export function NuTextArea(props) {
  let {
    name,
    placeholder,
    label,
    formik,
    handleBlur,
    handleChange,
    isRequired,
    customClass,
    value,
    maxLength
  } = props;
  return (
    <div className={`relative px-6 py-2 ${customClass} `}>
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label} {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        type="text"
        value={value || formik.values[name]}
        onChange={handleChange||formik.handleChange}
        onBlur={handleBlur}
        className={
          "text-input error text-xs 2xl:text-sm font-medium border w-full h-20 px-3 p mt-2 rounded-[10px] outline-none"
        }
        maxLength={maxLength}

      ></textarea>
      {/* <ErrorMessage name={name}> */}
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
      {/* </ErrorMessage> */}
    </div>
  );
}

export function OnlyInput(props) {
  let { inputType, name, ClassName, placeholder, onChange } = props;
  return (
    <input type={inputType} className={ClassName} placeholder={placeholder} />
  );
}

export function NuDate(props) {
  const {
    dateclassname,
    timeclassname,
    dateplaceholder,
    timeplaceholder,
    datename,
    timename,
    datevalue,
    timevalue,
    dateid,
    timeid,
    dateonchange,
    timeonchange,
    dateonclick,
    timeonclick,
    label,
    labelclassname,
    datehandlechange,
    timehandlechange,
    customClass,
    formik
  } = props;
  return (
    <div className={`${customClass}`}>
      <label className={`text-xs 2xl:text-sm ${labelclassname}`}>{label}</label>

      <div className="flex justify-between items-center">
        <div className="mt-2 mr-2">
          <label
            for="default-toggle"
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id="default-toggle"
              className="sr-only peer"
            />
            <div className="w-14 h-8 bg-tableodd peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
          </label>
        </div>
        <input
          type="date"
          className={`${dateclassname} text-xs 2xl:text-sm font-medium`}
          placeholder={dateplaceholder}
          value={datevalue||formik.values[datename]}
          name={datename}
          id={dateid}
          onChange={dateonchange||formik.handleChange}
          onClick={dateonclick}
          handleChange={datehandlechange}
        />
        <input
          type="time"
          className={`${timeclassname} text-xs 2xl:text-sm font-medium`}
          placeholder={timeplaceholder}
          value={timevalue||formik.values[timename]}
          name={timename}
          id={timeid}
          onChange={timeonchange||formik.handleChange}
          onClick={timeonclick}
          handleChange={timehandlechange}
        />
      </div>
      {formik.errors[datename] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[datename]}
        </span>
      ) : null}
      {/* {formik.errors[name] ? <span className="text-xs font-semibold text-red-500">{formik.errors[name]}</span> : null} */}
    </div>
  );
}

export function InputCheck({
  type,
  classname,
  placeholder,
  value,
  name,
  id,
  onchange,
  onclick,
}) {
  return (
    <div>
      <input
        type={type}
        className={classname}
        placeholder={placeholder}
        value={value}
        name={name}
        id={id}
        onChange={onchange}
        onClick={onclick}
      />
    </div>
  );
}

function Input({
  type,
  classname,
  placeholder,
  name,
  id,
  onchange,
  onclick,
  label,
  labelclassname,
  handlechange,
  customClass,
  formik,
}) {
  return (
    <div className={`${customClass}`}>
      <label className={`${labelclassname} text-xs 2xl:text-sm`}>{label}</label>
      <input
        type={type}
        className={`${classname} text-xs 2xl:text-sm font-medium`}
        placeholder={placeholder}
        value={formik.values[name] || ""}
        name={name}
        id={id}
        onChange={onchange}
        onClick={onclick}
        handleChange={handlechange}
      />
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
    </div>
  );
}

export default Input;

export function NuSelect(props) {
  let { name, value, label, isRequired, handleChange, options, optionName } =
    props;
  return (
    <div className="relative px-6 py-2 lg:py-0 lg:w-6/12">
      <label htmlFor={name} className=" text-xs 2xl:text-sm">
        {label} {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <select
        value={value}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-3xl capitalize outline-none"
        }
        name={name}
        id={name}
        onChange={handleChange}
      >
        <option key={""}>Select {optionName}</option>
        {options}
      </select>
      <ErrorMessage name={name}>
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
      </ErrorMessage>
    </div>
  );
}

export function NuUpload(props) {
  let { name, 
    label, 
    isRequired, 
    onChange, 
    value, 
    accept="audio/*,video/*,image/*,.pdf,.doc,.docx,.ppt, .pptx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv",
    multiple=false 
  } = props;
  return (
    <div className="relative px-6 py-2 lg:py-0 w-full ">
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label} {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input
        id={name}
        name={name}
        onChange={onChange}
        type="file"
        accept={accept}
        multiple={multiple}
        className="mt-2 cursor-pointer block w-full text-sm text-slate-500
        file:mr-4 file:py-[9px] file:px-4 file:rounded-l-md
        file:border-0 file:text-sm file:font-semibold
        file:bg-gray-200 file:text-gray-700
        hover:file:bg-gray-400 file:cursor-pointer border rounded-[10px] h-10"
      />
      <span>{value}</span>
    </div>
  );
}

export function NuVerticalInput(props) {
  let {
    name,
    placeholder,
    label,
    handleBlur,
    handleChange,
    formik,
    max,
    min,
    value,
    isRequired,
    type,
    onChange,disabled,
    customClass
  } = props;
  // console.log(max, "date");
  return (
    <div className={`relative flex items-center gap-2 px-3  ${customClass}`} >
      <label htmlFor={name} className="text-xs 2xl:text-sm">
        {label}
        {isRequired ? <span className="text-red-500"> *</span> : ""}
      </label>
      <input disabled={disabled ? true : false}
          // id={name} 
        placeholder={placeholder}
        type={type}
        name={name}
        max={max || ""}
        min={min || ""}
        value={value || formik.values[name]}
        onChange={onChange||handleChange||formik.handleChange}
        // onBlur={handleBlur}
        className={
          "text-xs 2xl:text-sm font-medium border w-full h-10 px-3  rounded-[10px] outline-none"
        }
        multiple
      />
      {formik.errors[name] ? (
        <span className="text-xs font-semibold text-red-500">
          {formik.errors[name]}
        </span>
      ) : null}
      {/* <ErrorMessage name={name}> 
        {(errorMsg) => (
          <div className="pt-2 text-xs font-semibold text-red-500">
            {errorMsg}
          </div>
        )}
       </ErrorMessage> */}
    </div>
  );
}



