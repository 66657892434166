import React,{useEffect,useState} from 'react'
import {useHistory} from 'react-router-dom'

function ProductRoutes(props) {
    const history = useHistory()
    useEffect(()=>{
        // if(!localStorage.getItem('userToken')){
        //     history.push("/login")
        // }


        if(!localStorage.getItem("nEkOtResU")){
            history.push("/")
        }
    },[props.comp])

    const Comp = props.comp
    return (
        <div>
            <Comp />
        </div>
    )
}

export default ProductRoutes;