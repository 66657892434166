import React, { useState, useEffect } from "react";
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import pdf from "../../../Utilities/Image/icons/pdf.svg";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
import Pagination from "../../../Utilities/common/Pagination";
import DateFormat from "../../../Utilities/common/NuDate";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import Import from "./import";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import ApprovelStatus from "../../../Utilities/common/Approvel";
import { GetYear } from "../../../Utilities/common/GetYear";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Export from "../../../Utilities/common/Export";

function MasterListOfDocumentIndex() {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  let { statusFilter } = location?.state || "";
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [selected, setSelected] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [total, setTotal] = useState([]);
  const [status, setStatus] = useState([]);
  const [data, setData] = useState([]);
  const [masterList,setMasterList] = useState([])

  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");

  const getStatus = () => {
    Axios.get("/Status/4/").then((res) => {
      res?.data?.data && setStatus(res.data.data);
    });
  };



  function getAllData() {
    Axios.get(
      `/Master-List-Of-Document/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`
    )
      .then((res) => {
        res.data && setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getFilterData() {
    Axios.get(
      `/Master-List-Of-Document/standard/${id}/?page=${pageIndex}&per_page=${dataCount}&status=${statusFilter}`
    )
      .then((res) => {
        res.data && setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  function getMasterList(){
    Axios.get(`/DashBoard/standard/${id}/master_list_of_document/`).then((res) => {
      res?.data && setMasterList(res.data.data);
    });
  }


  function getMasterData(){
    if (statusFilter) {
      getFilterData();
    } else {
      getAllData();
    }
  }

  useEffect(() => {
    if (statusFilter) {
      getFilterData();
    } else {
      getAllData();
    }
  }, [pageIndex, dataCount]);

  // useEffect(() => {
  //   if (statusFilter) {
  //     getFilterData();
  //   }
  // }, [statusFilter]);

  function clearFilter() {
    getAllData();
    history.replace({
      state: null,
    });
  }

  function getStandard(){
    Axios.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        setStartDate(res?.data?.data.start_date)
        setEndDate(res?.data?.data.end_date)
    }).catch(error=>{
        console.log(error);
    })
  }

  useEffect(() => {
    getStatus();
    // getAllData();
    getStandard()
    getMasterList()
  }, []);

  return (
    <>
      <MainLayout
      id={id}
      total={data?.total}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      dataCount={dataCount}
      setDataCount={setDataCount}
      label="Master List Of Document"
      title="Activity"
      setShowApprovalModal={setShowApprovalModal}
      hasApproval={true}
    >
              <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Documents</p>
          <div className="flex items-center text-xs 2xl:text-sm">
            <p className="mr-2">overall</p>
            <span className="bg-login px-2 py-1 rounded-md">
              {masterList?.complete_percentage||0}%
            </span>
          </div>
          <div className="flex items-center">
            {statusFilter != null ? (
              <div
                className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer"
                onClick={clearFilter}
              >
                clear
              </div>
            ) : null}
            <div className=" w-8">
              {GetAccess("activity_create") && (
                <svg
                  className="w-7 ml-1 cursor-pointer"
                  alt="plus"
                  onClick={() => setShowMyModal(true)}
                  title="Add"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.61084"
                    y="0.335541"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path
                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Add</title>
                </svg>
              )}
            </div>
            {/* )}  */}
            <Export id={id} path={'master_list_of_document'} name={`${standardName} Master List of Document ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}  />

            {GetAccess("activity_create") && (
              <Import getMasterList={getMasterList} refreshData={getAllData} id={id} />
            )}
          </div>
        </div>
        <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className="text-xs 2xl:text-sm font-medium">
              {header.map((header) => (
                <th className={`py-4 px-[25px] ${header.width}`}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr colspan="6 " className="relative px-4">
              <td colspan="8">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data &&
                    data?.data?.map((i) => {
                      return (
                        <tr className="odd:bg-tableodd even:bg-tableeven text-center py-1 text-xs 2xl:text-sm font-medium flex justify-between items-center my-1">
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                            {i.serial_number || "#"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900  whitespace-normal text-left capitalize w-[22%]">
                            {i?.document_name || "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-normal capitalize w-[25%]">
                            {i?.document_description || "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-normal capitalize w-[10%]">
                            {i?.version || "-"}
                          </td>
                          <td className="text-gray-900 whitespace-normal w-[15%]">
                            {DateFormat({
                              value: i?.latest_update,
                              format: "DD-MMM-yyyy",
                            })}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 capitalize whitespace-normal  w-[10%]">
                            {i?.status || "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-normal capitalize w-[10%]">
                            {i.image_path.length > 0 && (
                              <img
                                src={paperclip}
                                alt=""
                                className="w-3 h-4 mx-auto"
                              />
                            )}
                          </td>
                          <td className=" w-[3%]">
                          <ViewMore
                            data={i}
                            fetcher={getMasterData}
                            status={status}
                            getMasterList={()=>getMasterList()}
                          />
                          </td>
                        </tr>
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={getMasterData}
          status={status}
          id={id}
          getMasterList={()=>getMasterList()}

        />
                <ApprovelStatus
          visible={showApprovalModal}
          onClose={handleApprovalOnClose}
          refreshData={getMasterData}
          moduleId={4}
        />
    </MainLayout>
    </>
  );
}

export default MasterListOfDocumentIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "w-[5%]",
  },
  {
    headone: "Document Name",
    width: "w-[22%]",
  },
  {
    headone: "Document Description",
    width: "w-[25%]",
  },
  {
    headone: "Version",
    width: "w-[10%]",
  },
  {
    headone: "Latest Updated",
    width: "w-[15%]",
  },
  {
    headone: "Status",
    width: "w-[10%]",
  },
  {
    headone: "Artefact",
    width: "w-[10%]",
  },
  {
    headone: "",
    width: "w-[3%]",

  },
];
