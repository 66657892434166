import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useSWR from "swr";
import Axios from "../../Services/axiosComman";
import CreateorUpdate from "./form";
import Viewmore from "./viewMore";
import DecryptData from "../../Utilities/common/Decrypt";
import MainPageLayout from "../../Utilities/Layout/MainPageLayout";
import { useStandard } from "../../Utilities/context/StandardContext";
import ShowMsg from "./ShowMsg";

const fetcher = (url) => Axios.get(url).then((res) => res.data?.data);

function MainStandard() {
  const {updateName,updateId} = useStandard();
  const [showMyHeaderModal, setShowMyHeaderModal] = useState(false);
  const handleHeaderOnClose = () => setShowMyHeaderModal(false);

  const { data, error, mutate } = useSWR(`/Standards/`, fetcher);
  const refreshData = () => {
    mutate(fetcher);
  };

  function standardName(value){
    console.log("ddd",value);
    updateName(value.name);
    updateId(value.id);
  }

  const userData = localStorage.getItem("aTaDrEsU")
    ? DecryptData("aTaDrEsU")
    : [];

    const [nameOfStandard,setNameOfStandard] = useState("");
    const [showSubMessage,setShowSubMessage] = useState(false);
  return (
    <MainPageLayout>
      <div className="h-[calc(100vh-170px)] w-full overflow-hidden bg-white shadow-main rounded-xl p-2">
        <div className="w-[95%] h-full grid md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-6 pt-8 pb-8 mx-auto overflow-y-scroll">
          {userData?.role[0].roleName == "scroll_admin" ||
          userData?.role[0].roleName == "admin" ? (
            <>
              {data &&
                data.map((value, idx) => {
                  // const { img, title, style } = value;
                  return (
                    <div
                      className="flex"
                      key={idx}
                      style={{
                        opacity:
                          userData?.role[0].roleName == "scroll_admin" ||
                          (value?.isActive && value?.keyActivated)
                            ? 1
                            : 0.5,
                      }}
                      onClick={
                        ()=>{
                          userData?.role[0].roleName == "scroll_admin" ||
                          (value?.isActive && value?.keyActivated)
                            ? setNameOfStandard("")
                            : setNameOfStandard(value?.name);setShowSubMessage(true)
                        }

                      }
                    >
                      <NavLink
                        style={{
                          pointerEvents:
                          userData?.role[0].roleName == "scroll_admin" ||
                          (value?.isActive && value?.keyActivated)
                            ? "auto"
                            : "none",
                        }}
                        className="w-full"
                        to={`/dashboard/SubDashboard/${value.id}`}
                        onClick={()=>{standardName(value);}}
                      >
                        <div className=" h-48 md:h-36 w-[100%] border flex flex-col items-center justify-center border-borderdark hover:border-0 rounded-xl py-2 m-auto hover:shadow-box">
                          {value?.image_path && (
                            <div className=" w-11/12 overflow-hidden h-[calc(128px-5px)] flex justify-center items-center">
                              <div className=" w-[55%] h-full ">
                              <img
                                src={value?.image_path}
                                alt={value?.name}
                                className={` object-center mx-auto`}
                                width="100%"
                              />
                              </div>
                            </div>
                          )}
                          <p className="my-1 text-center text-sm  md:text-[8px] 2xl:text-xs w-10/12 m-auto">
                            {value?.name || "no title"}
                          </p>
                        </div>
                      </NavLink>
                      {userData?.role[0].roleName == "scroll_admin" && (
                        <Viewmore fetcher={refreshData} data={value} />
                      )}
                    </div>
                  );
                })}
            </>
          ) : (
            <>
              {data &&
                data
                  .filter((item) => userData?.standards.includes(item?.id))
                  .map((value, idx) => {
                    return (
                      <div
                        className="flex"
                        key={idx}
                        style={{
                          pointerEvents:
                            userData?.role[0].roleName == "scroll_admin" ||
                            (value?.isActive && value?.keyActivated)
                              ? "auto"
                              : "none",
                          opacity:
                            userData?.role[0].roleName == "scroll_admin" ||
                            (value?.isActive && value?.keyActivated)
                              ? 1
                              : 0.5,
                        }}
                      >
                        <NavLink
                          className="w-full"
                          to={`/dashboard/SubDashboard/${value.id}`}
                          onClick={()=>{standardName(value);}}
                        >
                          <div className=" h-48 md:h-36 w-[100%] border flex flex-col items-center justify-center border-borderdark hover:border-0 rounded-xl py-2 m-auto hover:shadow-box">
                            {value?.image_path && (
                              <div className=" w-11/12 overflow-hidden h-[calc(128px-5px)] flex justify-center items-center">
                              <div className=" w-[55%] h-full ">
                              <img
                                src={value?.image_path}
                                alt={value?.name}
                                className={` object-center mx-auto`}
                                width="100%"
                              />
                              </div>
                              </div>
                            )}
                          <p className="my-1 text-center text-sm  md:text-[8px] 2xl:text-xs w-10/12 m-auto">
                            {value?.name || "no title"}
                          </p>
                          </div>
                        </NavLink>
                      </div>
                    );
                  })}
            </>
          )}

          <CreateorUpdate
            onClose={handleHeaderOnClose}
            visible={showMyHeaderModal}
            refreshData={refreshData}
          />
          <ShowMsg
           onClose={()=>{setShowSubMessage(false);setNameOfStandard("")}}
           visible={showSubMessage}
           standard={nameOfStandard}
           />
        </div>
      </div>
    </MainPageLayout>
  );
}

export default MainStandard;
