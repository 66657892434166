import React from 'react'

function Breadcrumbs({title,label}) {
  return (
    <div className="mx-12 flex items-center py-2">
    <p className="ml-2 text-xs 2xl:text-sm text-secondary">
      {title} /&nbsp;
      <span className="text-text font-medium">{label}</span>
    </p>
  </div>
  )
}

export default Breadcrumbs