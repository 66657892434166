import Moment from "moment";

export default function DateFormat(props) {

    const { format, add, addString } = props;
    if (!props.value) {
        return ''
    }


    return (
        Moment(props.value)
            .add((add) ? add : 0, (addString) ? addString : "d")
            .format(`${format ? format : "DD-MM-YYYY"}`)
    )

}