import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import DateFormat from "../../../Utilities/common/NuDate";
import Logo from "../../../Utilities/Image/img/logo.jpg";


const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    // margin: 10,
    padding: "15px 30px 60px 30px ",
    //   fontFamily:'Poppins'
  },
  sectionTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "4%",
    // backgroundColor:'red'
  },
  pageNumber: {
    // position: 'absolute',
    fontSize: 9,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },
  image: {
    width: 35,
    height: 20,
    // marginVertical: 15,
    // marginHorizontal: 100,
  },
  titleCenter: {
    textAlign: "center",
    // backgroundColor:'red',
    width: 200,
  },
  titleCenterTop: {
    fontSize: 13,
    // margin:10,
    // padding:10,
    marginBottom: 5,
    textAlign: "center",
    // color: 'grey',
    fontWeight: "semibold",
  },
  titleCenterBottom: {
    fontSize: "10px",
    color: "black",
    fontWeight: "normal",
    textAlign: "center",
  },
  titleRight: {
    fontSize: "7px",
    color: "grey",
    fontWeight: "bold",
  },
  sectionBottom: {
    width: "100%",
    height: "4%",
    // backgroundColor:'green',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: "15px",
    left: "30px",
  },
  sectionMain: {
    width: "100%",
    // height:"92%",
    // backgroundColor:'blue'
  },
  sectionInner: {
    width: "100%",
    // height:'750px',
    // backgroundColor:"#3085d6",
  },
  bottomCenter: {
    width: "33.3%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: "3px",
  },
  bottomStart: {
    width: "33.3%",
    textAlign: "left",
    gap: "3px",
  },
  bottomEnd: {
    width: "33.3%",
    textAlign: "right",
    gap: "3px",
  },
  textStyle: {
    fontSize: "8px",
    color: "grey",
  },
  lastItem: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  lastItemTitle: {
    fontSize: "6px",
  },
});

let DateNow = DateFormat({ value: new Date(), format: "DD-MMM-YYYY" });
let TimeNow = DateFormat({ value: new Date(), format: "hh:mm:ss a" });

function Date(data) {
  if (data) {
    return DateFormat({ value: data, format: "DD-MMM-YYYY" });
  } else {
    return " ";
  }
}

const PicPDF = ({
  company,
  standardname,
  startDate,
  endDate,
  documentImage,
  data,
  implementationImage,
  masterImage,
  mrmImage,
  auditImage,
  internalImage,
  securityImage,
  trainingImage,
  maintenanceImage,
  managementImage,
}) => {
  // let pagesCount = [1,2,3]

  function Header({ standardname, companyName, companyLogo }) {
    return (
      <View style={styles.sectionTop} fixed>
        <Image style={styles.image} src={companyLogo ? companyLogo : Logo} />
        <View style={styles.titleCenter}>
          <Text style={styles.titleCenterTop}>{`${companyName || ""}`}</Text>
          <Text
            style={styles.titleCenterBottom}
          >{`Progress Status of all Standard`}</Text>
        </View>
        <View>
          <Text style={styles.titleRight}>
            {/* {` ${DateNow} / ${TimeNow}`} */}
          </Text>
        </View>
      </View>
    );
  }

  function Footer({ startDate, endDate }) {
    return (
      <View style={styles.sectionBottom} fixed>
        <View style={styles.bottomStart}>
          <Text style={styles.textStyle}>Certification Calendar Tracker</Text>
          <Text style={styles.textStyle}>
            {`${
              (startDate &&
                startDate != "None" &&
                startDate != "" &&
                startDate != null &&
                Date(startDate)) ||
              ""
            } ${
              (startDate &&
                startDate != "None" &&
                startDate != "" &&
                startDate != null &&
                "/") ||
              ""
            } ${
              (endDate &&
                endDate != "None" &&
                endDate != "" &&
                endDate != null &&
                Date(endDate)) ||
              ""
            }`}
            {/* {`${startDate&&startDate!="None"&&startDate!=""&&Date(startDate)} ${startDate&&startDate!="None"&&startDate!=""&&"/"} ${endDate&&endDate!="None"&&endDate!=""&&Date(endDate)}`} */}
          </Text>
        </View>
        <View style={styles.bottomCenter}>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text style={styles.titleRight}>{` ${DateNow} / ${TimeNow}`}</Text>
        </View>
        <View style={styles.bottomEnd}>
          {/* <Text style={styles.textStyle}>{review&&review!=""&&review!=null&&review!="null"&&"Reviewed and Approved "||""}</Text> */}
        </View>
      </View>
    );
  }

  function MainContent() {
    return (
      <View style={styles.sectionMain}>
        {data &&
          data.map((item, index) => (
            <View key={index}>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: 7,
                  marginBottom: 7,
                  fontWeight: 500,
                }}
              >
                {item.name || ""}
              </Text>

              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  flexWrap: "wrap",
                }}
              >
                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Documentation Preparation:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Implementation CheckList :
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Master List of Documents:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    CISO/MRM Meeting MoM:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Audit Schedule:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Internal Audit Checklist:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Security Incident Review:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Training Calendar:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Maintenance:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: "49%",
                    padding: 6,
                    border: "1px solid gray",
                    borderRadius: 6,
                  }}
                >
                  <Text
                    style={{ fontSize: 9, color: "gray", textAlign: "left" }}
                  >
                    Management Review:
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Yet To Start : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        In Progress : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Completed : {100}
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Review & Approved : {100}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 86,
                      }}
                    >
                      <Image src={documentImage} />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ))}
      </View>
    );
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header
          standardname={standardname}
          companyName={company?.companyName}
          companyLogo={company?.companyLogo}
        />
        <MainContent />
        <Footer startDate={startDate} endDate={endDate} />
      </Page>
    </Document>
  );
};

export default PicPDF;
