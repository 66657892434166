import React from 'react'

function Label({ classname, labelName}) {
  return (
      <div className=' w-full'>
          <label className={`${classname} block`}>{labelName}</label>
    </div>
  )
}

export default Label