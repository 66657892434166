import React, { useEffect, useState } from 'react'
import ReportIndex from './index';
import { NavLink, useParams } from "react-router-dom";
import DateFormat from '../../../Utilities/common/NuDate';
import axiosInstance from '../../../Services/axiosComman';
import { useSelecteddate } from '../../../Utilities/context/SelectDate';
// import { NuSelect } from '../../../Utilities/common/Input';


function ReportsListIndex() {
  const {selectedDate,updateDate} = useSelecteddate()
  const [allOldData,setAllOldData] =useState([])
  const [listOfOld,setListOfOld] = useState([])
  const [value,setValue] = useState(false)
  const [error,setError] = useState(false)
  // const [defaultValue,setDefaultValue] = useState("")
  const [reportData,setReportData] = useState({})
    const { id } = useParams();

    function getAllOldData(value){
      axiosInstance
      .get(`/Standards/getAllResetDate/${value}`)
      .then((res) => {
        let tempdata = res?.data?.data?.current_data|| {};
        let tempObj = ""
        if(tempdata){
          tempObj = {
            end_date:tempdata?.end_date||"",
            id:tempdata?.name||"",
            reset_date:"",
            standard_id:tempdata?.id||"",
            start_date:tempdata?.start_date||"",
            reset_count:0,
          }
        }
        // setDefaultValue(tempdata?.name)
        let finalArr = [];
        let tempResetData = res?.data?.data?.reset_data||[]
        
        tempResetData.push(tempObj)
  
        let finalListOfData = tempResetData 
        // && tempResetData.map(i=>({...i,type: i?.reset_date? 2:1}))
  
        // console.log("final Data",finalListOfData);
  
        res?.data?.data && setAllOldData(finalListOfData)
        res?.data?.data &&
        tempResetData.forEach((item) => {
            // if (item?.isActive && item?.keyActivated) {
              let temp = {
                value: item?.id,
                label: `${DateFormat({value:item?.start_date,format:"MMM-YYYY"})} to  ${DateFormat({value:item?.end_date,format:"MMM-YYYY"})}`,
              };
              finalArr.push(temp);
            // }
          });
          setListOfOld(finalArr.reverse());
      })
      .catch((err) => {
        console.log(err.response?.data);
      });
    }

    useEffect(()=>{
      getAllOldData(id)
    },[id])

    function handleChange(e){
      // console.log("eeee",e);
      let [data] = allOldData.filter(item=>item.id == e.target.value)
      setValue(e.target.value && true)
      // console.log("data",data);
      setReportData(data)
      updateDate(data)
    }


    useEffect(()=>{
      if(selectedDate !=""){
        let check = allOldData.filter(item=>item.id == selectedDate.id)
        if(check){
          // console.log("dddd",selectedDate);
          setValue(true)
          setReportData(selectedDate)
        }else{
          setValue(false)
          setReportData({})
        }
      }
    },[selectedDate])

    // console.log("te",defaultValue);

  return (
    <ReportIndex>
     <div className="w-full h-[calc(100vh-170px)] overflow-hidden bg-white shadow-main rounded-r-xl rounded-b-xl pb-4">
      <div className='mx-4 py-2'>
        <label className=' text-sm font-bold block'>Year List</label>
        <select value={selectedDate?.id||""}  onChange={handleChange} className=' w-3/12 px-2 py-2 border-[1px] rounded-lg text-sm focus:border-blue-400 focus:outline-blue-500' placeholder='select year'>
          <option value="" className=' py-1'>select year</option>
          {
            listOfOld&& listOfOld.map((item,idx)=>{
              return <option className=' py-1' value={item?.value} key={idx}>{item?.label}</option>
            })
          }
        </select>
        <div className=' h-3 ml-2 mt-1'>
        {error&&!value && <p className=' text-xs text-red-500 '>Please Select Year</p>}
        </div>
      </div>
     <div className="grid grid-cols-3 gap-8 overflow-y-auto h-[75vh]">
              {report.map((reports) => (
                <div className="text-center text-xs 2xl:text-sm w-full">
                  <h3 className="bg-tabletitle p-3">{reports.title}</h3>
                  {reports.subtitle.map((subtitle) => (
                    <>
                    {
                      value ? 
                      <NavLink  to={{
                        pathname:`${subtitle.path}/${id}`,
                        state:{reportValue:reportData}
                      }}  >
                      <p className="py-2 pl-4 text-left">{subtitle.name}</p>
                    </NavLink>
                    :
                    <p className="py-2 pl-4 text-left cursor-pointer" onClick={()=>setError(true)}>{subtitle.name}</p>
                    }
                    </>
                  ))}
                </div>
              ))}
            </div>
     </div>
    </ReportIndex>
  )
}

export default ReportsListIndex;

const report = [
   {
    title: "Progress",
    subtitle: [{ name: "Overall Progress", path: "/report/pre-defined/overallprogress" }],
   },
    {
      title: "Information",
      subtitle: [
        { name: "Vendor Management", path: "/report/pre-defined/vendor-management-report" },
        { name: "Security Incident", path: "/report/pre-defined/security-incident-report" },
        { name: "Training Calendar", path: "/report/pre-defined/training-calendar-report" },
      ],
    },
    {
      title: "Activity",
      subtitle: [
        {
          name: "Implementation Checklist",
          path: "/report/pre-defined/implementationckeck-list",
        },
        { name: "Internal Audit Checklist", path: "/report/pre-defined/internal-audit" },
        { name: "Maintenance", path: "/report/pre-defined/maintenance-report" },
        { name: "Master List of Documents", path: "/report/pre-defined/master-report" },
        { name: "CISO / MRM Meeting", path: "/report/pre-defined/mrm-report" },
        { name: "Audit Schedule", path: "/report/pre-defined/audit-calendar-report" },
      ],
    },
  ];