import React, { useMemo } from "react";
import ReportIndex from "./index";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosInstance from "../../../Services/axiosComman";
import { useEffect } from "react";
import { useState } from "react";
import {  useParams } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import DataView from "./DataView";
import DateFormat from "../../../Utilities/common/NuDate";
import PDFGenerationScreen from "../PDFGenerationScreen";
import DataViewTest from "./DataviewTest";


const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

function GenerateReportList() {
    const { id } = useParams();
  const [standardname, setStandardName] = useState("");
    const [format, setFormat] = useState("");
     const [company,setCompany] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [standard, setStandard] = useState([]);
    const [status,setStatus] = useState([]);
    const [data,setData] = useState([]);
    const [view,setView] = useState("");
    const [review,setReview] = useState("");
    const [startDate,setStartDate] = useState("");
    const [endDate,setEndDate] = useState("");
    const [module,setModule] = useState("");
    const [allData,setAllData] = useState("");
    const [buttonClick,setButtonClick] = useState(false)
    const [createNew, setCreateNew] = useState({
      report_information: "",
      format:"",
      standard_id:"",
      module_id:"",
      status_name:"",
      from_date:"",
      to_date:"",
      type:0,
      report_year:""
    });

    const [allOldData,setAllOldData] =useState([])
    const [listOfOld,setListOfOld] = useState([])
    const [tempStartDate,setTempStartDate] = useState("");
    const [tempEndDate,setTempEndDate] = useState("");
    const [standardId,setStandardId] = useState("");
    const [dataLength,setDataLength] = useState(0);
    const [pdfDataLenth,setPdfDataLenth] =useState(0)
    
  function getAllStandard() {
    axiosInstance
      .get(`/Standards/`)
      .then((res) => {
        let tempdata = res?.data?.data || [];
        let finalArr = [];
        res?.data?.data && setAllData(res?.data?.data)
        res?.data?.data &&
          tempdata.forEach((item) => {
            if (item?.isActive && item?.keyActivated) {
              let temp = {
                value: item?.id,
                label: item?.name,
              };
              finalArr.push(temp);
            }
          });
        setStandard(finalArr);
      })
      .catch((err) => {
        console.log(err.response?.data);
      });
  }



  useEffect(() => {
    getAllStandard();
    // setCreateNew({...createNew,standard_id:id})
    getCompanyInfo()
  }, []);



const [noData,setNoData] = useState(true);


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   vendor_name: yup
    //     .string()
    //     .required("Name Required"),
    //     vendor_type: yup.string().required("Vender Type is Required"),
    // }),
    onSubmit: (value, { resetForm }) => {
      setView(formik.values.report_information)
      setModule(formik.values.module_id)
      setFormat(formik.values.format)
      setIsLoading(true);
      setButtonClick(true)
      setIsDataLoading(true)
      setDataLength(0)
      setPdfDataLenth(0)
      setData([])
      setNoData(false)
      let FinalData = {...value}
      if(FinalData.from_date == startDate) {
        FinalData.from_date=""
      }

      if(FinalData.to_date == endDate) {
        FinalData.to_date=""
      }
      console.log(FinalData);

      axiosInstance
        .post(`/report/`, FinalData)
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
           let dateValue = res.data.data||[]
             if(dateValue.length>0){
               let fun = dateValue[0].final_status||null
               if(fun =="review_and_approved"){
                setReview(true)
               }else{
                setReview("");
    
              }              
             }
            res.data.data && setData(res.data.data)
            setDataLength(res.data.data.length)
            // res.data?.final_status && setReview(res.data?.final_status)
            res.data?.status && setStatus(res.data?.status)
            
            //   refreshData();
            // setSelectedFile(null)
            // resetForm();
            //   onClose();
            // Toast.fire({
            //   icon: "success",
            //   title: res.data.msg || "Report Generated Successfully",
            // });
            setNoData(true)
          } else {
            setIsDataLoading(false)
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: res.data.msg || "Report Generated Unsuccessfully",
            });
          }
        })
        .catch((err) => {
          setIsDataLoading(false)
          setIsLoading(false);
          Toast.fire({
            icon: "error",
            title: err.response.data.msg || "Report Generated Unsuccessfully",
          });
        });
    },
  });

  console.log("data length",dataLength);
  

  async function getCompanyInfo() {
    await axiosInstance.get(`/company-info/`).then((res) => {
      setCompany(res?.data?.data[0])
    });
  }


  const formInputs = [
    // {
    //   inputType: "NuInput",
    //   name: "vendor_name",
    //   classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
    //   placeholder: "",
    //   label: "Vendor Name",
    //   labelclassname: "w-full",
    //   type: "text",
    //   style: "w-full",
    //   customClass: " w-full lg:w-6/12",
    // },
    {
      inputType: "NuSelect",
      name: "standard_id",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Standard",
      label: "Standard",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full",
      Options: standard || [],
    },
    {
      inputType: "NuSelect",
      name: "report_year",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Report Information",
      label: "Year of Report",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full",
      Options: listOfOld,
    },
    {
      inputType: "NuSelect",
      name: "module_id",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Activity",
      label: "Activity",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full",
      Options: [
        { value: 6, label: "Audit Schedule" },
        { value: 1, label: "Implementation Checklist" },
        { value: 2, label: "Internal Audit Checklist" },
        { value: 3, label: "Maintenance" },
        { value: 4, label: "Master List of Documents" },
        { value: 18, label: "CISO / MRM Meeting " },
        { value: 12, label: "Security Incident" },
        { value: 10, label: "Task Management" },
        { value: 16, label: "Training Calendar" },
        { value: 11, label: "Vendor Management" },
      ],
    },
    {
      inputType: "NuSelect",
      name: "status_name",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Status",
      label: "Status",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full",
      Options: [
        { value: "", label: "All"},
        { value: "completed", label: "Completed" },
        { value: "in progress", label: "In Progress" },
        { value: "yet to start", label: "Yet to Start" },
      ],
    },
    {
      inputType: "NuSelect",
      name: "format",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Format",
      label: "Format",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full",
      Options: [
        { value: "pdf", label: "PDF" },
        { value: "xlsx", label: "XLSX" },
        // { value: "yet to start", label: "Yet to Start" },
      ],
    },
    {
      inputType: "NuInput",
      name: "from_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "From",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full ",
      min: formik.values.type != 0? tempStartDate:"",
      max: formik.values.to_date
    },
    {
      inputType: "NuInput",
      name: "to_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "To",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full ",
      min:formik.values.from_date,
      max:formik.values.type != 0? tempEndDate:""
    },
    {
      inputType: "NuSelect",
      name: "report_information",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Report Information",
      label: "Report Information",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full",
      Options: [
        { value: "Summary", label: "Summary" },
        { value: "Detailed", label: "Detailed" },
      ],
    },
  ];



  useMemo(()=>{
    if(formik.values.standard_id){
      getStandard(formik.values.standard_id)
      setStandardId(formik.values.standard_id)
    }
  },[formik.values.standard_id])


  function getStandard(id){
    axiosInstance.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        // setStartDate(res?.data?.data.start_date)
        // setEndDate(res?.data?.data.end_date)
        // if(res?.data?.data.start_date){
        //    formik.values.from_date = res?.data?.data.start_date;
        // }
        // if(res?.data?.data.end_date){
        //    formik.values.to_date = res?.data?.data.end_date;
        // }
    }).catch(error=>{
        console.log(error);
    })
  }


  useMemo(()=>{
    if(startDate){
      formik.values.from_date=startDate
    }
  },[startDate])

  useMemo(()=>{
    if(endDate){
      formik.values.to_date = endDate
    }
  },[endDate])


  useMemo(()=>{
    // console.log("woking",formik.values.report_year);
    if(formik.values.report_year){
      let [data] = allOldData.filter(item=>item.id == formik.values.report_year)
      formik.values.type = data?.reset_count
      formik.values.from_date = data.start_date
      setTempStartDate(data.start_date)
      setStartDate(data.start_date)
      formik.values.to_date = `${DateFormat({value:data?.end_date,format:"YYYY-MM-DD"})}`
      setTempEndDate(`${DateFormat({value:data?.end_date,format:"YYYY-MM-DD"})}`)
      setEndDate(`${DateFormat({value:data?.end_date,format:"YYYY-MM-DD"})}`)
      // console.log(formik.values);
    }
  },[formik.values.report_year])



  // useMemo(()=>{
  //   if(formik.values.type==1){
  //     formik.values.to_date = endDate
  //     formik.values.from_date=startDate
  //     formik.values.report_year=""
  //   } else if(formik.values.type==2 && !formik.values.report_year ){
  //     formik.values.from_date = ""
  //     formik.values.to_date = ""

  //   }
  // },[formik.values.type])


  function ModuleName(x){
    let Arr = [
      { value: 6, label: "Audit Schedule" },
      { value: 10, label: "Task Management" },
      { value: 1, label: "Implementation Checklist" },
      { value: 2, label: "Internal Audit Checklist" },
      { value: 3, label: "Maintenance" },
      { value: 4, label: "Master List of Documents" },
      { value: 18, label: "CISO / MRM Meeting " },
      { value: 12, label: "Security Incident" },
      { value: 16, label: "Training Calendar" },
      { value: 11, label: "Vendor Management" },
    ]
    let value = Arr.find(item=>item.value == x)
    return value?.label||"";
  }


  function getAllOldData(value){
    axiosInstance
    .get(`/Standards/getAllResetDate/${value}`)
    .then((res) => {
      let tempdata = res?.data?.data?.current_data|| {};
      let tempObj = ""
      if(tempdata){
        tempObj = {
          end_date:tempdata?.end_date||"",
          id:tempdata?.name||"",
          reset_date:"",
          standard_id:tempdata?.id||"",
          start_date:tempdata?.start_date||"",
          reset_count:0,
        }
      }
      let finalArr = [];
      let tempResetData = res?.data?.data?.reset_data||[]
      
      tempResetData.push(tempObj)

      let finalListOfData = tempResetData 
      // && tempResetData.map(i=>({...i,type: i?.reset_date? 2:1}))

      console.log("final Data",finalListOfData);

      res?.data?.data && setAllOldData(finalListOfData)
      res?.data?.data &&
      tempResetData.forEach((item) => {
          // if (item?.isActive && item?.keyActivated) {
            let temp = {
              value: item?.id,
              label: `${DateFormat({value:item?.start_date,format:"MMM-YYYY"})} to  ${DateFormat({value:item?.end_date,format:"MMM-YYYY"})}`,
            };
            finalArr.push(temp);
          // }
        });
        setListOfOld(finalArr.reverse());
    })
    .catch((err) => {
      console.log(err.response?.data);
    });
  }


  useEffect(()=>{
    if(formik.values.standard_id) getAllOldData(formik.values.standard_id)
  },[formik.values.standard_id])

  return (
    <ReportIndex>
      <div className="w-full h-[calc(100vh-170px)]  overflow-y-auto shadow-main rounded-r-xl rounded-b-xl pt-2 pb-8">
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="relative px-6 grid grid-cols-4 justify-between">
              <MainForm formInputs={formInputs} formik={formik} />

              <div className="flex items-end justify-start px-6 my-2 rounded-b text-xs 2xl:text-sm">
              <button
                type="submit"
                disabled={isLoading ? true : false}
                className={` ${
                  isLoading ? " bg-purple-400 cursor-wait" : "bg-primary"
                } hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}
              >
                Generate Report
              </button>
            </div>
            </div>
          </div>
        </form>
        {
            <DataViewTest 
             buttonClick={buttonClick} 
             data={data} 
             status={status} 
             view={view} 
             Name={ModuleName(module)} 
             review={review} 
             startDate={startDate} 
             endDate={endDate}  
             module={module} 
             id={formik.values.standard_id} 
             company={company} 
             format={format} 
             standardname={standardname} 
             standardId={standardId} 
             DataLoading={setIsDataLoading} 
             dataLength={dataLength} 
             PDFLenth={pdfDataLenth} 
             SetPDFLength={setPdfDataLenth}
             NoData={noData}
             />
        }
      </div>

      <PDFGenerationScreen visible={isDataLoading} activity={ModuleName(module)} format={formik.values.format} name={standardname}/>
    </ReportIndex>
  );
}

export default GenerateReportList;
