import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosInstance from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
import FileDisplay from "../../../Utilities/common/FileDisplay";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

 function CreateOrEdit({
  data,
  visible,
  onClose,
  refreshData,
  id,
  status
}) {
  // const handleOnClose = (e) => {
  //   if (e.target.id === "modal") onClose();
  // };
  const [isLoading,setIsLoading] = useState(false);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [createNew, setCreateNew] = useState({
    heading: "",
    group_data: "",
    mom_date: "",
    type_of_meeting: null,
    start_time: "",
    end_time: "",
    regrets: "",
    venue: "",
    chaired_by: "",
    minute_by: "",
    assignedToWhom: "",
    item: "",
    targetDate: "",
    status: null,
  });


  useEffect(()=>{
    setCreateNew({
      heading: data?.heading,
      group_data: data?.group_data,
      mom_date: data?.mom_date,
      type_of_meeting: data?.type_of_meeting,
      start_time: data?.start_time,
      end_time: data?.end_time,
      regrets: data?.regrets,
      venue: data?.venue,
      chaired_by: data?.chaired_by,
      minute_by: data?.minute_by,
      assignedToWhom: data?.assignedToWhom,
      item: data?.item,
      targetDate: data?.targetDate,
      status: data?.status,
      attachments:data?.attachments,
    })
    if(data?.attachments !==null && data?.attachments !=="None" && data?.attachments !=="[]"){
      setFilePathList(data?.attachments)
      setRemoveFilePathList(data?.attachments)
    }
  },[data])


  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFile(files);}

  const formInputs = [
    {
      inputType: "NuInput",
      name: "heading",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Heading",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 pr-4",
    },

    {
      inputType: "NuInput",
      name: "group_data",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Group",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 pl-4",
    },
    {
      inputType: "NuInput",
      name: "mom_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Date",
      labelclassname: "w-full",
      type: "date",
      customClass: "w-6/12 pr-4 md:mt-4",
      // min: DateFormat({
      //   value: Date.now(),
      //   format: "yyyy-MM-DD",
      // }),
    },
    {
      inputType: "NuInput",
      name: "type_of_meeting",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Type of Meeting",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pl-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "start_time",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Start Time",
      labelclassname: "w-full",
      type: "time",
      customClass: "w-6/12 pr-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "end_time",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "End Time",
      labelclassname: "w-full",
      type: "time",
      customClass: "w-6/12 pl-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "regrets",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Regrets",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pl-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "venue",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Venue",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pr-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "chaired_by",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Chaired By",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pl-4 md:mt-4",
    },
    {
      inputType: "NuInput",
      name: "minute_by",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Minutes By",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-6/12 pr-4 md:mt-4",
    },
    {
      inputType: "NuSelect",
      name: "status",
      ClassName: "w-full h-10 px-3 mt-2",
      placeholder: "Select status",
      label: "Status",
      Options:
        // [{value:'open',label:"Status 1"},{value:'closed',label:"Status 2"}],
        status?.map((i) => {
          return {
            value: i.status_type,
            label: i.status_type,
          };
        }),
      customClass: "w-full lg:w-6/12",
      labelclassname: "w-full",
    },
    {
      inputType: "NuInput",
      name: "filePath",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Attachments",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleFileSelect,
    }
  ];



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: yup.object({
    //   heading: yup.string().required("Heading is Required"),
    //   mom_date:yup.string().required("Date is Required"),
    //   venue:yup.string().required("Venue is Required")
    // }),
    onSubmit: (value, { resetForm }) => {
      setIsLoading(true);
      if(data){
        if(!value.mom_date){
          value.mom_date = "None"
        }
        if(!value.start_time){
          value.start_time = "None"
        }
        if(!value.end_time){
          value.end_time = "None"
        }
        let formData = new FormData();
        if(removefilePathList){
          let finalArr = removefilePathList.filter(items=>  !filePathList.includes(items) )
          value.files_to_remove = finalArr.map(item=>item.replace(window.WEB_APP_API_URL, "localhost"));
        }else{
          value.files_to_remove = []
        }
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formData.append("attachment", file);
          });
        }else{
          formData.append("attachment",[])
        } 
  
        formData.append("mom_data", JSON.stringify(value));
        axiosInstance.put(`/Minute-Of-Meeting/${data?.id}/screen_closed`,{"isClosed":false}).then(res=>{
        axiosInstance
          .put(`/Minute-Of-Meeting/${data?.id}/`, formData)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm();
              refreshData();
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || " Created Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.error||res.data.msg || " Creation Unsuccessfully",
              });
            }
          }).catch((err)=>{
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.error ||" Creation Unsuccessfully",
            });
          }
          );
        }).catch(err=>{
          setIsLoading(false);
          console.log("err")
        })
      }else{
      let formData = new FormData();
      
      if(selectedFile !== null){
        selectedFile.forEach((file) => {
          formData.append("attachment", file);
        });
      }else{
        formData.append("attachment",[])
      } 

      formData.append("mom_data", JSON.stringify(value));

      axiosInstance
        .post(`/Minute-Of-Meeting/standard/${id}/`, formData)
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            resetForm();
            refreshData();
            onClose();
            Toast.fire({
              icon: "success",
              title: res.data.msg || " Created Successfully",
            });
          } else {
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: res.data.error||res.data.msg || " Creation Unsuccessfully",
            });
          }
        }).catch((err)=>{
          setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.error ||" Creation Unsuccessfully",
            });
        });
      }
    },
  });


  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }


  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
    setSelectedFile(null)
      formik.resetForm();
      onClose()
    };
  };

  const closeFunction = () =>{
    setSelectedFile(null)
    formik.resetForm()
    onClose()
}

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
      >
        <div className="bg-white mt-4 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className=" overflow-y-scroll h-[85vh]">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex flex-wrap my-3">
                { data?.attachments &&
                    data?.attachments != null &&
                    data.attachments != "None" &&
                    data.attachments.length>0 && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateOrEdit;
