import React, { useState, useEffect } from "react";
import axiosInstance from "../../Services/axiosComman";
import { Dough } from "../../Utilities/common/Doughnut";
import { DoughThree } from "../../Utilities/common/DoughnutThree";
import DoughTwo from "../../Utilities/common/DoughnutTwo";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GetAccess } from "../../Utilities/data/role";
import MainPageLayout from "../../Utilities/Layout/MainPageLayout";
import { useStandard } from "../../Utilities/context/StandardContext";
import BadgeCheck from "../../Utilities/Image/icons/test";
import DecryptData from "../../Utilities/common/Decrypt";


function MainProgress() {
  const { updateName, updateId } = useStandard();

  const history = useHistory();

  const [standard, setStandard] = useState([]);
  // const [data, setData] = useState([]);
  function getStandard() {
    axiosInstance
      .get(`/DashBoard/standard/standard_wise`)
      .then((res) => {
        res.data && setStandard(res.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getStandard();
    // getData();
    updateId("");
    updateName("");
  }, []);

  function genStatusData(statusData, path) {
    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(statusData);
    let Y = Object.values(statusData);
    returnValue.label = X;
    returnValue.data = Y;

    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    if (sum != 0) {
      return (
        <DoughThree
          dataWithLabel={returnValue}
          handleSegmentClick={(event, chartElements) =>
            handleSegmentClick(event, chartElements, path)
          }
        />
      );
    } else {
      return <DoughTwo />;
    }
  }

  function handleSegmentClick(event, chartElements, path) {
    console.log("event", path);
    let color = chartElements[0]?.element?.options?.borderColor;
    if (color == "#007300") {
      history.push({
        pathname: path,
        state: { statusFilter: "completed" },
      });
    } else if (color == "#E60000") {
      history.push({
        pathname: path,
        state: { statusFilter: "yet to start" },
      });
    } else if (color == "#E6C300") {
      history.push({
        pathname: path,
        state: { statusFilter: "in progress" },
      });
    } else {
      return false;
    }
  }

  function getManualStatusData(statusData) {
    let returnValue = {
      label: ["", "", ""],
      data: [],
    };
    if (statusData.status_name == "completed" || statusData.status_name == "review and approved") {
      returnValue.data = [0, statusData.percentage, 0];
      return <Dough dataWithLabel={returnValue} />;
    } else if (statusData.status_name == "in progress") {
      returnValue.data = [0, 0, statusData.percentage];
      return <Dough dataWithLabel={returnValue} />;
    } else if (statusData.status_name == "yet to start") {
      returnValue.data = [statusData.percentage, 0, 0];
      return <Dough dataWithLabel={returnValue} />;
    } else {
      return <DoughTwo />;
    }
  }


  const userData = localStorage.getItem("aTaDrEsU")
  ? DecryptData("aTaDrEsU")
  : [];

  return (
    <MainPageLayout progress={true}>
      <div className="w-full h-[calc(100vh-170px)] overflow-hidden bg-white shadow-main rounded-r-xl rounded-b-xl p-8 flex gap-3">
        <div className="w-full h-full overflow-y-scroll">
          <div className="">
            <div className=" flex  items-center">
              <h2 className="text-xl font-semibold mb-2">Progress</h2>
            </div>

            <div className=" w-full">
            {userData?.role[0].roleName == "scroll_admin" ||
          userData?.role[0].roleName == "admin" ?(
            <>
              {standard.map((item, index) => {
                let tempValue = item?.review_and_approved||[]
                function hasReviewAndApproved(name){
                  let value = tempValue?tempValue.some((i)=>i.module_name==name && i.status_name =='review_and_approved'):false
                  return value
                }
                return (
                  <div className=" w-full mt-4 mb-8" key={index}>
                    <div className=" flex items-center justify-between my-3">
                      <h2>
                        <Link
                          to={`/dashboard/SubDashboard/${
                            item?.standard_id || "#"
                          }`}
                        >
                          {item.name}
                        </Link>
                      </h2>
                      {GetAccess("admin_edit") && (
                        <Link
                          to={`/setting/standard/manual-settings/${item?.standard_id}`}
                        >
                          <div className=" text-xs rounded-md bg-[#7030A0] py-1 px-2 text-white">
                            Manual Update
                          </div>
                        </Link>
                      )}
                    </div>
                    <div className="flex justify-between content-stretch items-stretch gap-2 px-3 my-2">
                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                             Document Preparation
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14 ">
                            {item?.manual_status?.documents_preperations ? (
                              getManualStatusData(
                                item?.manual_status?.documents_preperations
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            item?.manual_status?.documents_preperations?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Implementation Checklist
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.implementation_checklist ? (
                              genStatusData(
                                item?.implementation_checklist,
                                `/activity/implementation-checklist/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("implementation_checklist")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2  text-[10px] text-center">
                            Master List of Documents
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.master_list_of_document ? (
                              genStatusData(
                                item?.master_list_of_document,
                                `/activity/master-list-of-documents/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("master_list_of_document")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  relative">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            CISO/MRM Meeting MoM
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm">
                          <div className=" w-14 my-2 h-14">
                            {item?.mom ? (
                              genStatusData(
                                item?.mom,
                                `/activity/ciso-mom/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("mom")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Audit Schedule
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <Link
                            to={`/activity/audit-calendar/${item?.standard_id}`}
                          >
                            <div className=" w-14 my-2 h-14">
                              {item?.manual_status?.audit_schedule ? (
                                <>
                                  {getManualStatusData(
                                    item?.manual_status?.audit_schedule
                                  )}
                                </>
                              ) : (
                                <DoughTwo />
                              )}
                            </div>
                          </Link>
                          {
                            item?.manual_status?.audit_schedule?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm ">
                          <p className="px-2  text-[10px] text-center ">
                            Internal Audit Checklist
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.internal_audit ? (
                              genStatusData(
                                item?.internal_audit,
                                `/activity/internal-audit/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("internal_audit")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            Security Incident Review
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <Link
                            to={`/information/security-incident/${item?.standard_id}`}
                          >
                            <div className=" w-14 my-2 h-14">
                              {item?.manual_status?.security_incident_review ? (
                                <>
                                  {getManualStatusData(
                                    item?.manual_status
                                      ?.security_incident_review
                                  )}
                                </>
                              ) : (
                                <DoughTwo />
                              )}
                            </div>
                          </Link>
                          {
                            item?.manual_status?.security_incident_review?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            Training Calendar
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.training_calender ? (
                              genStatusData(
                                item?.training_calender,
                                `/information/training-calendar/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("training_calender")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Maintenance
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.maintenance ? (
                              genStatusData(
                                item?.maintenance,
                                `/activity/maintenance/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("maintenance")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }

                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            Management Review
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.manual_status?.management_review ? (
                              getManualStatusData(
                                item?.manual_status?.management_review
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            item?.manual_status?.management_review?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className=" w-full px-3 mt-6 h-6">
                      <div className=" text-xs text-white w-full h-4 flex items-center justify-between bg-gradient-to-r from-green-100 to-green-700 px-1">
                        <div className=" bg-white text-green-700 shadow-sm p-2 rounded">
                          Initiation
                        </div>
                        <div className=" bg-green-700 p-2 rounded">
                          Compliance to Standard
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ):(
            <>
                { standard &&
                standard
                .filter((item) => userData?.standards.includes(item?.standard_id))
                .map((item, index) => {
                  console.log("xyz",item);
                  let tempValue = item?.review_and_approved||[]
                function hasReviewAndApproved(name){
                  let value = tempValue?tempValue.some((i)=>i.module_name==name && i.status_name =='review_and_approved'):false
                  return value
                }
                return (
                  <div className=" w-full mt-4 mb-8" key={index}>
                    <div className=" flex items-center justify-between my-3">
                      <h2>
                        <Link
                          to={`/dashboard/SubDashboard/${
                            item?.standard_id || "#"
                          }`}
                        >
                          {item.name}
                        </Link>
                      </h2>
                      {GetAccess("admin_edit") && (
                        <Link
                          to={`/setting/standard/manual-settings/${item?.standard_id}`}
                        >
                          <div className=" text-xs rounded-md bg-[#7030A0] py-1 px-2 text-white">
                            Manual Update
                          </div>
                        </Link>
                      )}
                    </div>
                    <div className="flex justify-between content-stretch items-stretch gap-2 px-3 my-2">
                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Document Preparation
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14 ">
                            {item?.manual_status?.documents_preperations ? (
                              getManualStatusData(
                                item?.manual_status?.documents_preperations
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            item?.manual_status?.documents_preperations?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Implementation Checklist
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.implementation_checklist ? (
                              genStatusData(
                                item?.implementation_checklist,
                                `/activity/implementation-checklist/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("implementation_checklist")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2  text-[10px] text-center">
                            Master List of Documents
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.master_list_of_document ? (
                              genStatusData(
                                item?.master_list_of_document,
                                `/activity/master-list-of-documents/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("master_list_of_document")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  relative">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            CISO/MRM Meeting MoM
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm">
                          <div className=" w-14 my-2 h-14">
                            {item?.mom ? (
                              genStatusData(
                                item?.mom,
                                `/activity/ciso-mom/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("mom")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Audit Schedule
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <Link
                            to={`/activity/audit-calendar/${item?.standard_id}`}
                          >
                            <div className=" w-14 my-2 h-14">
                              {item?.manual_status?.audit_schedule ? (
                                <>
                                  {getManualStatusData(
                                    item?.manual_status?.audit_schedule
                                  )}
                                </>
                              ) : (
                                <DoughTwo />
                              )}
                            </div>
                          </Link>
                          {
                            item?.manual_status?.audit_schedule?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm ">
                          <p className="px-2  text-[10px] text-center ">
                            Internal Audit Checklist
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.internal_audit ? (
                              genStatusData(
                                item?.internal_audit,
                                `/activity/internal-audit/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("internal_audit")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            Security Incident Review
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <Link
                            to={`/information/security-incident/${item?.standard_id}`}
                          >
                            <div className=" w-14 my-2 h-14">
                              {item?.manual_status?.security_incident_review ? (
                                <>
                                  {getManualStatusData(
                                    item?.manual_status
                                      ?.security_incident_review
                                  )}
                                </>
                              ) : (
                                <DoughTwo />
                              )}
                            </div>
                          </Link>
                          {
                            item?.manual_status?.security_incident_review?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            Training Calendar
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.training_calender ? (
                              genStatusData(
                                item?.training_calender,
                                `/information/training-calendar/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("training_calender")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md  ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center">
                            Maintenance
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.maintenance ? (
                              genStatusData(
                                item?.maintenance,
                                `/activity/maintenance/${item?.standard_id}`
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            hasReviewAndApproved("maintenance")&&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }

                        </div>
                      </div>

                      <div className=" w-28 h-28 flex flex-col items-center justify-between rounded-md ">
                        <div className=" w-full h-8 shadow-sm">
                          <p className="px-2 text-[10px] text-center ">
                            Management Review
                          </p>
                        </div>
                        <div className=" w-full h-16 flex justify-center items-center shadow-sm relative">
                          <div className=" w-14 my-2 h-14">
                            {item?.manual_status?.management_review ? (
                              getManualStatusData(
                                item?.manual_status?.management_review
                              )
                            ) : (
                              <DoughTwo />
                            )}
                          </div>
                          {
                            item?.manual_status?.management_review?.status_name == "review and approved" &&
                            <div className=" absolute bottom-1 right-1">
                            <BadgeCheck />
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className=" w-full px-3 mt-6 h-6">
                      <div className=" text-xs text-white w-full h-4 flex items-center justify-between bg-gradient-to-r from-green-100 to-green-700 px-1">
                        <div className=" bg-white text-green-700 shadow-sm p-2 rounded">
                          Initiation
                        </div>
                        <div className=" bg-green-700 p-2 rounded">
                          Compliance to Standard
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) }


            </div>
          </div>
        </div>
      </div>
    </MainPageLayout>
  );
}

export default MainProgress;
