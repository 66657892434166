import React, { useEffect, useState } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-color"; // Import XLSX from xlsx-color
import DateFormat from '../../../../Utilities/common/NuDate';
import { OnDownload } from '../../../../Utilities/data/render';

function AuditXlsx({ fileName, data, status,standardname="",review,Name,startDate,endDate,company,Module,standardId,updateLenth}) {
    const [csvData, setCSVData] = useState([]);

    let CompletedTemp = Number(status?.completed?.percentage||0);
    let CompletedFinal = CompletedTemp.toFixed(1)
    let InProgressTemp = Number(status?.in_progress?.percentage||0);
    let InProgressFinal = InProgressTemp.toFixed(1);
    let YetToStartTemp = Number(status?.yet_to_start?.percentage||0);
    let YetToStartFinal = YetToStartTemp.toFixed(1);

    useEffect(() => {
        if (data) {
            let finalData = [];
            data.forEach((item,index) => {
                updateLenth(index+1)
                let tempData = {
                    "Ref Number": item?.reference_number || "",
                    "Performed By": item?.performed_by || "",
                    "Process Description": item?.process_description || "",
                    "Audit Description": item?.audit_description || "",
                    "Auditee": item?.auditee || "",
                    "Start Date": item?.start_date !== "None" && item?.start_date !== "" && `${DateFormat({ value: item?.start_date, format: "DD-MMM-YYYY" })}` || "",
                    "End Date": item?.end_date !== "None" && item?.end_date !== "" && `${DateFormat({ value: item?.end_date, format: "DD-MMM-YYYY" })}` || "",
                    "Result": item?.result || "",
                    "Status": item?.status || "",
                    "Last Update by": item?.last_modified_name || item?.created_name || item?.created_by_name || "",
                    "Artefact": item?.attachment == true ? "Yes" : ""
                };
                finalData.push(tempData);
            });
            setCSVData(finalData);
        }
    }, [data]);

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = () => {
        const wb = XLSX.utils.book_new();

        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet([
            [`${company?.companyName||""}`, "","",`${standardname||""}`,"","",`${Name||""}`,"","","Status",`Yet To Start : ${YetToStartFinal}`],
            ["", "","","","","","","","","",`In Progress : ${InProgressFinal} `],
            ["", "","","","","","","","","",`Completed : ${CompletedFinal} `],
            [], // Empty row for spacing
            Object.keys(csvData[0]), // Headers
            ...csvData.map(obj => Object.values(obj)), // Data rows
            [],
            [],
            [],
            [],
            ["Certification Calendar Tracker","","","","","","","","","",""],
            [
            `${startDate&&startDate!="None"&&startDate!=""&&startDate!=null&&DateFormat({value:startDate,format: "DD-MMM-YYYY"})||""} ${startDate&&startDate!="None"&&startDate!=""&&startDate!=null&&"/"||""} ${endDate&&endDate!="None"&&endDate!=""&&endDate!=null&&DateFormat({value:endDate,format: "DD-MMM-YYYY"})||""}`,
            "",
            "",
            "",
            "",
            `${DateFormat({ value: Date(), format: "DD-MMM-YYYY" })}/${DateFormat({ value: Date(), format:'hh:mm:ss a' })}`,"","","","",`${review&&review!=""&&review!=null&&review!="null"&&"Reviewed and Approved "||""}`]
        ]);

        const headers = Object.keys(csvData[0]);
        headers.forEach((header, index) => {
            console.log("iNdx",index);
            const headerCellRef = XLSX.utils.encode_cell({ r: 4, c: index });
            console.log("ssss",headerCellRef);
            if (!ws[headerCellRef]) return;
            ws[headerCellRef].s = { font: { bold: true } };
        });


        // Apply borders to cells containing data from csvData
        const startRow = 4; // Assuming the starting row index of the data rows
        const startCol = 0; // Assuming the starting column index of the data columns
        csvData.forEach((rowData, rowIndex) => {
            Object.keys(rowData).forEach((key, colIndex) => {
                const cellRef = XLSX.utils.encode_cell({ r: startRow + rowIndex+1, c: startCol + colIndex });
                if (!ws[cellRef]) return;
                ws[cellRef].s = { border: { top: { style: "thin" }, bottom: { style: "thin" }, left: { style: "thin" }, right: { style: "thin" } } };
            });
        });

        // // Set cell colors
        ws.A5 = { 
            t: "s", 
            v: "Ref Number",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } }, // Background color
                font: { bold: true }, // Font style (bold)
                alignment: { horizontal: "center" }
            }
          }; 
        ws.B5 = { 
            t: "s", 
            v: "Performed By",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } }, // Background color
                font: { bold: true }, // Font style (bold)
                alignment: { horizontal: "center" }
            }
          };
        ws.C5 = { 
            t: "s", 
            v: "Process Description",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } }, // Background color
                font: { bold: true }, // Font style (bold)
                alignment: { horizontal: "center" }
            }
          };
        ws.D5 = { 
            t: "s", 
            v: "Audit Description",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } }, // Background color
                font: { bold: true }, // Font style (bold)
                alignment: { horizontal: "center" }
            }
          };
        ws.E5 = { 
            t: "s", 
            v: "Auditee",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } }, // Background color
                font: { bold: true }, // Font style (bold)
                alignment: { horizontal: "center" }
            }
        };
        ws.F5 = { 
            t: "s", 
            v: "Start Date",  
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } },
                font: { bold: true },
                alignment: { horizontal: "center" }
            }
        };
        ws.G5 = { 
            t: "s", 
            v: "End Date",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } },
                font: { bold: true },
                alignment: { horizontal: "center" }
            }
          };
        ws.H5 = { 
            t: "s", 
            v: "Result", 
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } },
                font: { bold: true } ,
                alignment: { horizontal: "center" }
            } 
        };
        ws.I5 = { 
            t: "s", 
            v: "Status",  
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } }, 
                font: { bold: true },
                alignment: { horizontal: "center" }
            }
        };
        ws.J5 = { 
            t: "s", 
            v: "Last Update by", 
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } },
                font: { bold: true }, 
                alignment: { horizontal: "center" }
            }
         };
        ws.K5 = { 
            t: "s", 
            v: "Artefact",
            s: { 
                fill: { fgColor: { rgb: "F1E0FF" } },
                font: { bold: true, sz: 10 },
                alignment: { horizontal: "center" },
                padding: { top: 15, bottom: 15 }
            } 
        };
        
        ws['!cols'] = [{ width: 20}, { width: 20 }, { width: 20 }, { width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 },{ width: 20 }];
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        OnDownload(standardId,Module)

    };

    return (
        <button onClick={exportToCSV}>Download</button>
    );
}

export default AuditXlsx;
