import React, { useEffect, useState } from "react";
import { date } from "yup/lib/locale";
// import { NuDate, NuIsEmpty } from './Asset'
import EventCalender from "./Maincal";
// import eventsServices from '../../../services/events.services';
import CreateOrEdit from "./form";
export default function Calender({
  height,
  eventsSource,
  eventDatas,
  TaskData,
  resetData,
  bookingData,
  churchEventsData,
  setTaskData,
  setEventsData,
  setBookingData,
  setChurchEventsData,
  timingList,
  eventList,
  Details,
  setDateDetails,
  setDayDetails,
  refreshData,
  id,
}) {
  const instalData = {
    events: [],
    color: "#4fa746", // an option!
    textColor: "black",
    // backgroundColor:"#4fa746"
  };

  const pend = eventDatas?.filter(function (a) {
    return a.status === "in progress";
  });
  const comp = eventDatas?.filter(function (a) {
    return a.status === "completed";
  });
  const over = eventDatas?.filter(function (a) {
    return a.status === "yet to start";
  });

  const data1 = setClaenderDataEvents(eventDatas);
  const data2 = setDataEvents(pend);
  const data3 = setDataEvents(comp);
  const data4 = setDataEvents(over);
  // const data2=setClaenderDataBooking(bookingData);
  // const data3=setClaenderDataTask(TaskData);
  // const data4=setClaenderDataChurchEvents(churchEventsData)
  //modal doc
  const [selectDay, setSelectDay] = useState("");
  const [eventCreatModalShow, setEventCreatModalShow] = useState(false);
  const [eventData, setEventData] = useState([]);

  const handleCloseEventCreate = () => setEventCreatModalShow(false);
  const handleShowEventCreate = () => {
    setEventCreatModalShow(true);
    // getEveentData();
  };

  // const getEveentData=async()=>{
  //     await eventsServices.getAll().then(result=>{
  //       // console.log('evenvt data',result.data);
  //       setEventData(result.data);

  //     }).catch(e=>ServerError(e));
  //   }

  //end modal doc

  function setDataEvents(data) {
    // console.log("data",data);
    let m = 1;
    for (let i in data) {
      // console.log("i",i);
      for (let j = i; j < data.length; j++) {
        // console.log("j",j);
        if (i !== j) {
          // console.log("i !== j",i !== j);
          if (data[i].completion_date == data[j].completion_date) {
            // console.log("inner if ",data[i].completion_date == data[j].completion_date);
            data.splice(j, 1);
            j -= 1;
            m++;
            // console.log("m",m);
          }
        }
      }
      data[i].count = m;
      m = 1;
      // console.log("data[i].count",data[i].count);
    }
    let eventData = {
      events: [],
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      },
      // color: '#4fa746',   // an option!
      textColor: "black",
      backgroundColor: "white",
    };
    // const testDate = (i) => {
    //   const d = new Date(i);
    //   let text = d.toISOString();
    //   const mdate = text.split("T");
    //   return mdate[0];
    // };
    let tmpEventsData = [];
    if (Array.isArray(data)) {
      data.map((list, idx) => {
        tmpEventsData.push({
          title: list?.count,
          start: list.completion_date,
          end: list.endDate,
          // className:"cal-events cal-shadow cal-container"
          //     textColor: 'white',
          // backgroundColor: '#7030a0'
        });
      });
      data.map((list, idx) => {
        tmpEventsData.push({
          title: list?.count,
          start: list.reminder_date,
          end: list.endDate,
          // className:"cal-events cal-shadow cal-container"
          //     textColor: 'white',
          // backgroundColor: '#7030a0'
        });
      });
      eventData.events = tmpEventsData;
      eventData.textColor =
        data[0]?.status === "in progress"
          ? "#FFFFFF"
          : data[0]?.status === "completed"
          ? "#FFFFFF"
          : data[0]?.status === "yet to start"
          ? "#FFFFFF"
          : "black";
      eventData.backgroundColor =
        data[0]?.status === "in progress"
          ? "#ffd700"
          : data[0]?.status === "completed"
          ? "#008000"
          : data[0]?.status === "yet to start"
          ? "#FF0000"
          : "black";

      return eventData;
    }
  }

  function setClaenderDataEvents(data) {
    let color = "#17a2b8";
    let textColor = "white";

    let eventData = {
      events: [
        // {
        //     start: "2022-10-26",
        //     end: "2022-10-28T18:30:00.000+00:00",
        //     title: "Event DataTable",
        // }
      ],
      eventTimeFormat: {
        // like '14:30:00'
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      },
      // color: '#4fa746',   // an option!
      textColor: "black",
      backgroundColor: "#17a2b8",
    };
    let tmpEventsData = [];
    if (Array.isArray(data)) {
      data.map((list, idx) => {
        tmpEventsData.push({
          title: list.title,
          start: list.reminder_date,
          end: list.completion_date,
          // className:"cal-events cal-shadow cal-container"
          textColor: "white",
          backgroundColor: "#7030a0",
        });
      });

      eventData.events = tmpEventsData;
      eventData.color=color;
      eventData.textColor=textColor;
      return eventData;
    }
  }

  // function setClaenderDataBooking(data){
  //     let color="#4fa746";
  //     let textColor="white";
  //            // return data;
  //     let eventData={
  //         events:[
  //         ],
  //         // color: '#4fa746',   // an option!
  //         //   textColor: 'white',
  //         //   backgroundColor:color
  //         };
  //     let tmpEventsData=[];
  //     if(!NuIsEmpty(data)){
  //         data.map((list,idx)=>{
  //             tmpEventsData.push({
  //                 title:list["comments"],
  //                 // start:new Date(list.dateFrom),
  //                 start:NuDate({value:list.dateFrom,format:"yyyy-MM-DDTHH:mm:ss"}),
  //                 end:NuDate({value:list.dateTo,format:"yyyy-MM-DDTHH:mm:ss"}),
  //                 className:"cal-communityhall-booking cal-shadow cal-container"
  //             })
  //             console.log("calender start",NuDate({value:list.dateFrom,format:"yyyy-MM-DD Thhmm"}))
  //             console.log("calender end",NuDate({value:list.dateFrom,format:"yyyy-MM-DD Thhmm"}))
  //         })

  //     eventData.events=tmpEventsData;
  //     // eventData.color=color;
  //     // eventData.textColor=textColor;
  //     return eventData
  //     }
  // }

  // function setClaenderDataTask(data){
  //     let color="#f2c010";
  //     let textColor="black";
  //            // return data;
  //     let eventData={
  //         events:[
  //         ],
  //         color: '#4fa746',   // an option!
  //           textColor: 'black',
  //           backgroundColor:"#4fa746"
  //         };
  //     let tmpEventsData=[];
  //     if(Array.isArray(data)){
  //         data.map((list,idx)=>{
  //             tmpEventsData.push({
  //                 title:list["comments"],
  //                 start:list["date"],
  //                 // className:"cal-todo-task cal-shadow cal-container"
  //                 // end:list["dateTo"]
  //             })
  //             // tmpEventsData[idx]={
  //             //     title:list["comments"],
  //             //     start:list["dateFrom"],
  //             //     end:list["dateTo"]
  //             // }
  //         })

  //     eventData.events=tmpEventsData;
  //     // eventData.color=color;
  //     // eventData.textColor=textColor;

  //     return eventData
  //     }

  // }
  // function setClaenderDataChurchEvents(data){
  //     let color="#007bff";
  //     let textColor="white";
  //            // return data;
  //     let eventData={
  //         events:[
  //         ],
  //         // color: '#007bff',   // an option!
  //         //   textColor: 'white',
  //           // backgroundColor:"#4fa746"
  //         };
  //     let tmpEventsData=[];
  //     if(Array.isArray(data)){
  //         data.map((list,idx)=>{
  //             {console.log("list from comunityhall events",list)}
  //             tmpEventsData.push({
  //                 title:list["comments"],

  //                 start:NuDate({value:list["dateFrom"],format:"yyyy-MM-DDTHH:mm:ss"}),
  //                 end:NuDate({value:list["dateTo"],format:"yyyy-MM-DDTHH:mm:ss"}),
  //                 className:"cal-church-event cal-shadow cal-container"
  //             })
  //             // tmpEventsData[idx]={
  //             //     title:list["comments"],
  //             //     start:list["dateFrom"],
  //             //     end:list["dateTo"]
  //             // }
  //         })

  //     eventData.events=tmpEventsData;
  //     // eventData.color=color;
  //     // eventData.textColor=textColor;

  //     return eventData
  //     }

  // }

  function OnSelectDay(e) {
    setSelectDay(e);
    // handleShowEventCreate();
    Details();

    // setDateDetails(e.startStr)
    setDayDetails(e);
  }

  return (
    <div className="">
      <div className="mt-3">
        <EventCalender
          eventDatas={eventDatas}
          selectDay={selectDay}
          handleShowEventCreate={handleShowEventCreate}
          weekends={true}
          eventsSource={[data2, data3, data4]}
          // data3,data2,data4
          height={height}
          initialView="dayGridMonth"
          editable={false}
          selectable={true}
          OnSelectDay={OnSelectDay}
          // timeZone='UTC'
        />
      </div>
      <CreateOrEdit
        visible={eventCreatModalShow}
        onClose={handleCloseEventCreate}
        refreshData={refreshData}
        value={selectDay}
        id={id}
      />
      {/* <CreateEventIndex
            id={id}
                handleShow={handleShowEventCreate}
                handleClose={handleCloseEventCreate}
                show={eventCreatModalShow}
                selectInfo={selectDay}
                resetData={resetData}
                taskData={TaskData}
                eventData={eventDatas}
                bookingData={bookingData}
                churchEventsData={churchEventsData}

                setTaskData={setTaskData}
                setEventsData={setEventsData}
                setBookingData={setBookingData}
                setChurchEventsData={setChurchEventsData}
                eventDataList={eventData}

                eventList={eventList}
                timingList={timingList}
                refreshData={refreshData}
            /> */}
    </div>
  );
}
