import axios from "axios";
import { toast } from "react-toastify";
import DecryptData from "../Utilities/common/Decrypt";

// const SERVER_URL = "http://localhost:7080/scroll/";
// const SERVER_URL = "http://127.0.0.1:5000";
const SERVER_URL = window.WEB_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_API_URL;

// console.log("ffff",DecryptData("nEkOtResU"));

// const auth_key = "userToken";

const getAuthKey = () => {
  // const tokenString = sessionStorage.getItem(auth_key);

  const tokenString = localStorage.getItem("nEkOtResU");
  if( tokenString ) {
    return DecryptData("nEkOtResU")
  }else{
    return ""
  }

  
  // if (!userToken) {
  //   toast.warning("Please login again");
  // }
  //  userToken || "";
};

// let token = 'access-token ' + getAuthKey() || "";
let token = getAuthKey() || "";

const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    // Authorization: `Bearer ${token}`,
    access_token: token,
  },
});

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      if (response.data.message === "Token is invalid") {
        toast.warning(response.data.message);
        //   sessionStorage.removeItem(auth_key);userData
        localStorage.removeItem("userData");

        localStorage.removeItem("nEkOtResU");
        window.location =`/`;
      } else {
        resolve(response);
      }
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      localStorage.removeItem("nEkOtResU");
      // sessionStorage.removeItem(auth_key);

      window.location = `/login`;
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;
