import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axiosInstance from '../../../Services/axiosComman';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'
import AdminSettingsLayout from '..';
import { GetAccess, GetRole } from '../../../Utilities/data/role';
import ModelBox from './ShowData';



const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

function StandardResettingIndex() {
    const {id} = useParams();
    const [data, setData] = useState([])
    const [show,setShow] = useState(false)
    const [standardName,setStandardName] = useState("");

    function getStandard(){
        axiosInstance.get(`/Standards/`).then(res=>{
            console.log("res",res.data?.data);
            res.data?.data && setData(res.data?.data)
        }).catch()
    }

    useEffect(()=>{
        getStandard();
    },[])

    const handleReset = async (idx,name) => {
      setStandardName(name)
        Swal.fire({
          title: `Are you sure you want to Reset standard ${name||""} ?`,
          // text: `You want to Reset All Information in the ${name||""} \n\n Standard`,
          html:`All current information of Standard ${name||""} will be archived into Reports. <br>
          Archived information cannot be edited.<br>
          Information on archived standard can be viewed in Reports section.`,
          icon: 'warning',
          confirmButtonColor: '#3AB54A',          
          confirmButtonText: 'Yes',
          showCancelButton: true,
          cancelButtonColor: '#d5d5d5',
          cancelButtonText:"No",
          width:"45%",
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (idx) {
              setShow(true);
              handleResetFunction(idx,name);
              // await Swal.fire({
              //   icon:"info",
              //   title: `Reset standard ${name||""} in progress…`,
              //   html:`Standard ${name||""} information has been archived into Reports.`,
              //   showConfirmButton: false,
              //   timer: 2000,
              //   width:"35%",
              // });
            }
          }
        })
      }


      function handleResetFunction(idx,name){
        axiosInstance.get(`/Standards/data_reset/${idx}`)
        .then( async (res) => {
          setShow(false);
          setStandardName("")
          if (res.data?.status) {
            Toast.fire({
              icon: "success",
              title: res.data?.msg || "Standard  Reset was successfully",
            });
            await Swal.fire({
              icon:"success",
              title: `Reset standard ${name||""} completed.`,
              html:`Go to SETTINGS – Certification Calendar Tracker page <br>
              Input new START Date and END Date <br>
              For SCROLL to create and tracker activities for new Calendar<br>
              Click here to close this window
              `,
              // showConfirmButton: false,
              // timer: 2000,
              width:"35%",
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Close',
            });
          } else {
            Toast.fire({
              icon: "error",
              title: res.data?.msg || "Standard  reset  was  unsuccessfully",
            });
          }
        })
        .catch((error) => {
          setShow(false);
          setStandardName("")
          Toast.fire({
            icon: "error",
            title: error.response?.data?.msg || "Standard  Reset was  unsuccessfully",
          });
        });
      }



  return (
    <AdminSettingsLayout>
            <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Reset the Standard
        </h4>
      </div>
      {
        data && data.map((item,idx)=>{
          return (
            <div key={idx}>
            {
              item?.isActive && item?.keyActivated && (
                <div className='pl-5 mt-3 w-[98.5%] flex justify-between items-center'>
                <div className=' w-[80%] flex items-center gap-4'>
                <div className=' w-32 h-32 rounded-md flex justify-center items-center shadow-box overflow-hidden'>
                 {
                     item?.image_path !=null && item?.image_path !="" ? <img src={item?.image_path} width="110%" alt="#" /> : <p className=' text-primary font-medium text-4xl'>{item?.name&&item?.name.charAt(0)||""}</p>
                 }
                </div>
                 <div className=' text-sm'>{item?.name||"-"}</div>
                 </div> 
                 <div>
                {
                  (GetRole()||GetAccess('data_reset')) && <button className=' bg-primary text-white px-3 py-1 shadow-box rounded-md' onClick={()=>handleReset(item?.id,item?.name)}>Reset</button>
                }  
                </div>
               </div>
              )
            }
           </div>
          )
        })
      }

      <ModelBox 
       visible={show}
       onClose={()=>setShow(false)}
       name={standardName}
      />

    </AdminSettingsLayout>
  )
}

export default StandardResettingIndex;