import React, { useEffect, useState } from "react";
// import AdminLayout from "../index";
// import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
// import useSWR from "swr";
import ViewMore from "./viewMore";
import CreateorUpdate from "./form";
// import { GetAccess } from "../../../Utilities/data/role";
// import EmailLayout from "../../Email";
// import SettingsLayout from "../index";
import Pagination from "../../../Utilities/common/Pagination";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import AddIcon from "../../../Utilities/Image/svg/add";
import AdminSettingsLayout from "../index";



// const fetcher = (url) => Axios.get(url).then((res) => res.data);

function CommonVendorList() {
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [data,setData] = useState([])
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [relation, setRelation] = useState([]);


  // const { data, error, mutate } = useSWR(`/email_templates/standard/${id}`, fetcher);


  const refreshData = () => {
    Axios.get(`/Vendor-Management/get_all?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      console.log("testing",res?.data?.data);
      res?.data && setData(res?.data)
    }).catch()
  };

  const Relation = async () => {
    await Axios.get("/Master-Data/vender_type").then((res) => {
      let data = res?.data?.data;
      let xyz = [];
      data.forEach((i) => {
        let x = { label: i?.value, value: i?.value };
        xyz.push(x);
      });
      res?.data && setRelation(xyz);
    });
  };


  useEffect(()=>{
    refreshData()
    Relation()
  },[])

  useEffect(()=>{
    refreshData()
  },[pageIndex, dataCount])

  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
        Vendor Management
        </h4>
        <AddIcon onClick={() => setShowMyModal(true)} />
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-2 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[calc(100vh-250px)] shadow-box">
                {data?.data?.map((data, i) => (
                  <div className="" key={i}>
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[10%]">
                        {data.serial_number}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[45%] ">
                        {data.vendor_name|| "-"}
                      </td>
                      <td className="text-center text-gray-900 capitalize w-[20%] ">
                        {data.vendor_type|| "-"}
                      </td>
                      <td className=" text-center text-gray-900 w-[20%] ">
                      {data?.document_type.length>0 && (
                            <img
                              src={paperclip}
                              alt=""
                              className="w-3 h-4 mx-auto"
                            />
                          )}
                      </td>
                      <td className=" w-[5%]">
                        <div>
                        <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data}
                        fetcher={refreshData}
                        vendorList={relation}
                      />
                        </div>
                      </td>
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
          total={data?.total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />
      <CreateorUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        vendorList={relation}
      />
    </AdminSettingsLayout>
  );
}

export default CommonVendorList;

const header = [
  {
    headone: "SI. No",
    width: "w-[10%]",
  },
  {
    headone: "Name",
    width: "w-[45%]",
  },
  {
    headone: "Type",
    width: "w-[20%]",
  },
  {
    headone: "Doc",
    width: "w-[20%]",
  },
  {
    headone: " ",
    width: "w-[5%]",
  }
];
