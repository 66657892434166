import React, { useState } from "react";
import AxiosComman from "../../Services/axiosComman";
import Edit from "./form";

function Viewmore({ id, fetcher, data, visible, onClose }) {
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [viewData, setViewData] = useState([]);

  async function getViewPage() {
    await AxiosComman.get(`/Standards/${data.id}`)
      .then((res) => {
        res.data?.data && setViewData(res.data?.data);
      })
      .catch((err) => console.log(err));
  }
  return (
    <>
      <div
        className="hover:drop-shadow-sm mr-2 py-2 px-[7px] rounded-3xl h-8 bg-white dropdown cursor-pointer relative"
        onClick={() => {
          setShowMyMenu(!showMyMenu);
          getViewPage();
        }}
      >
        <svg
          className="m-auto w-[4px]"
          viewBox="0 0 4 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="1.83391"
            cy="2.38902"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
          <ellipse
            cx="1.83391"
            cy="7.30914"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
          <ellipse
            cx="1.83391"
            cy="12.2293"
            rx="1.83"
            ry="1.82988"
            fill="#8C87A6"
          />
        </svg>
        <div className="hidden dropdown-menu">
          <div
            id="modal"
            onClick={handleOnCloseMenu}
            className="bg-transparent fixed inset-0 flex justify-center items-center "
          ></div>
          <div className="bg-white rounded-xl w-20 absolute -left-20 -top-1">
            <div className="grid p-1 rounded-t">
              <span
                className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer"
                onClick={() => setShowMyModal(true)}
              >
                Edit
              </span>
            </div>
          </div>
        </div>
      </div>
      <Edit
        onClose={handleOnClose}
        visible={showMyModal}
        data={data}
        refreshData={fetcher}
      />
    </>
  );
}

export default Viewmore;
