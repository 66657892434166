import React, { useState } from "react";
// import moreIcon from '../../../Components/Images/more.svg'
import AxiosComman from "../../../Services/axiosComman"
import Edit from "./form";
import Swal from "sweetalert2";
import TaskView from "./TaskProfile";
import { ScreenClose, ScreenOpen } from "../../../Utilities/data/render";
import { GetAccess, GetRole } from "../../../Utilities/data/role";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({fetcher, data, status }) {
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);
  
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => {
    ScreenClose({link:"Calender-Management",id:data.id})
    setShowMyModal(false);
  }
  

 
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to Delete`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          await AxiosComman.delete(`/Calender-Management/${id}`)
            .then((res) => {
              if (res.data.status) {
                fetcher()
                Toast.fire({
                  icon: "success",
                  title: res.data?.msg || " Task Deleted successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: res.data?.msg || "  Task Deleted  unsuccessfully",
                });
              }
            })
            .catch((error) => {
              console.log(error.message);
              Toast.fire({
                icon: "error",
                title: error?.response?.data?.msg || "  Task Deleted  unsuccessfully",
              });
            });
        }
      }
    });
  }

  function EditScreen(){
    setShowMyModal(true)
    ScreenOpen({link:"Calender-Management",id:data.id})
  }

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;
  return (
    <>
      <div
        className="hover:drop-shadow-sm mr-2  py-2 px-[14px] rounded-3xl bg-white dropdown cursor-pointer relative"
        // onClick={() => {
        //   setShowMyMenu(!showMyMenu);
        // }}
      >
        <svg className='m-auto w-[4px]' viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#8C87A6" />
          <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#8C87A6" />
          <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#8C87A6" />
        </svg>
        {/* {showMyMenu ? ( */}
          <div className=" hidden dropdown-menu">
            <div id="modal"
              onClick={handleOnCloseMenu}
              className="bg-transparent  fixed inset-0 flex justify-center items-center ">
            </div>
            <div className="bg-white rounded-xl w-20 absolute -left-20 -top-1">
              <div className="grid p-1 rounded-t">
                <span className="p-1 hover:bg-navhover hover:rounded-lg"
                  onClick={() => setShowFile(true) }>View</span>
                {/* {(roleId === Admin||roleId === SuperAdmin) && (<> */}
                <span className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer"
                  onClick={EditScreen}>Edit</span>
                  {
                    (GetRole()||GetAccess("information_delete")) &&
                    <span className="p-1 hover:bg-navhover hover:rounded-lg"
                    onClick={() => {
                      handleDelete(data?.id);
                    }
                    }>Delete</span>
                  }
                {/* </>
                 )} */}
              </div>
            </div>
          </div>
          {/* ) : null} */}
      </div>

      <Edit
        onClose={handleOnClose}
        visible={showMyModal}
        data={data}
        refreshData={fetcher}
        status={status}
      />
      <TaskView
        data={data}
        onClose={handleClose}
        visible={showFile}
      />
    </>
  );
}

export default Viewmore;
