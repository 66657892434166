import React, { useEffect, useState } from "react";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import { useParams,useLocation,useHistory } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import { GetAccess } from "../../../Utilities/data/role";
import ApprovelStatus from "../../../Utilities/common/Approvel";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Import from "./import";
import Export from "../../../Utilities/common/Export";




function AuditCalenderIndex() {
  const { id } = useParams();
  const location = useLocation();
  let { statusFilter } = location?.state||"";
  const history  = useHistory();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [status, setStatus] = useState([]);
  const [data,setData] = useState([]);

  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");

  const refreshData = ()=>{
    Axios.get(`/Audit-Schedule/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      // console.log("res",res?.data);
      res.data && setData(res.data)
    })
  }

  const getStatus = () => {
    Axios.get("/Status/6/").then((res) => {
      res?.data?.data && setStatus(res.data.data);
    });
  };

  function getFilterData(){
    Axios.get(`/Audit-Schedule/standard/${id}/status/${statusFilter}?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data)
    }).catch(error=>{
      console.log(error);
    })
  }

  function getAuditData(){
    if(statusFilter){
      getFilterData()
    }else{
      refreshData()
    }
  }


  useEffect(() => {
    if(statusFilter){
      getFilterData()
    }else{
      refreshData()
    }
  },[pageIndex,dataCount])

  useEffect(() => {
    if(statusFilter){
     getFilterData()
    }
  },[statusFilter])

  function clearFilter(){
    refreshData();
    history.replace({
      state:null
    })
    }

    function getStandard(){
      Axios.get(`Standards/${id}`).then(res=>{
          setStandardName(res?.data?.data.name);
          setStartDate(res?.data?.data.start_date)
          setEndDate(res?.data?.data.end_date)
      }).catch(error=>{
          console.log(error);
      })
    }

  useEffect(() => {
    // refreshData()
    getStandard()
    getStatus();
  }, []);
  return (
    <>
    <MainLayout
      id={id}
      total={data?.count}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      dataCount={dataCount}
      setDataCount={setDataCount}
      label="Audit Schedule"
      title="Activity"
      setShowApprovalModal={setShowApprovalModal}
      hasApproval={false}
    >
              <div className="py-3 pl-5 flex justify-between items-center text-xs 2xl:text-sm  w-[98.5%]">
          <p className=" text-text">Audit Schedule</p>
          <div className="flex">
          {statusFilter !=null ?<div className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer" onClick={clearFilter}>clear</div>:null}
            <div className=" w-8">
              {GetAccess("activity_create") && (
                <svg
                  className="w-7 ml-1 cursor-pointer"
                  alt="plus"
                  onClick={() => setShowMyModal(true)}
                  title="Add"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.61084"
                    y="0.335541"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path
                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Add</title>
                </svg>
              )}
            </div>
            <Export id={id} path={'audit_schedule'} name={ `${standardName} Audit Schedule ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}  />

           {GetAccess("activity_create") && <Import refreshData={refreshData} id={id} />} 

          </div>
        </div>
        <div className=" w-full h-[calc(100vh-215px)] bg-white shadow-box">
          <div className=" w-full ">
            <table className="table-auto w-full">
              <thead className="bg-tabletitle">
                <tr className=" text-xs 2xl:text-sm font-medium">
                  {header?.map((header) => (
                    <th className={` px-1 py-4 ${header.width}`}>
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className="overflow-y-auto h-[calc(100vh-295px)]">
          <table className="table-auto w-full">
           <tbody className="w-full overflow-y-auto h-[calc(100vh-295px)]">
           {data?.data && data?.data?.map((i,idx) => (
                        <tr className="odd:bg-tableodd even:bg-tableeven flex justify-between items-center my-1 py-1 text-xs 2xl:text-sm font-medium" key={idx}>
                        <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                          {i.serial_number || "#"}
                        </td>
                        <td
                          className="mb-2 text-center  text-gray-900 capitalize w-[9%]"
                        >
                          {i?.reference_number || "-"}
                        </td>
                        <td
                          className="mb-2 text-center text-gray-900 capitalize  w-[10%]"
                  
                        >
                          {i?.performed_by || "-"}
                        </td>
                        <td
                          className="mb-2  text-gray-900 capitalize  w-[10%]"
                          // width="10%"
                        >
                          {i?.process_description || "-"}
                        </td>
                        <td
                          className="mb-2  text-gray-900 capitalize  w-[10%]"
                          // width="10%"
                        >
                          {i?.audit_description || "-"}
                        </td>
                        <td
                          className="mb-2  text-gray-900 capitalize text-center w-[10%]"
                          // width="10%"
                        >
                          {i?.auditee || "-"}
                        </td>
                        <td className="mb-2  text-gray-900 text-center  w-[10%]">
                          {(i.start_date !== "None" &&
                            DateFormat({
                              value: i?.start_date,
                              format: "DD-MMM-yyyy",
                            })) ||
                            "-"}
                        </td>
                        <td className="mb-2 text-gray-900 text-center w-[10%]" >
                          {(i.end_date !== "None" &&
                            DateFormat({
                              value: i?.end_date,
                              format: "DD-MMM-yyyy",
                            })) ||
                            "-"}
                        </td>
                        <td
                          className="mb-2  text-gray-900 capitalize text-center overflow-hidden w-[9%]"
                          // width="9%"
                        >
                          {i?.result || "-"}
                        </td>
                        <td
                          className="mb-2  text-gray-900 capitalize text-center  overflow-hidden w-[10%]"
                          // width="10%"
                        >
                          {i?.status || "-"}
                        </td>
                        <td
                          className="px-2 xl:px-2 text-gray-900 w-[4%] "
                          // width="5%"
                        >
                          {i.attachment.length > 0 && (
                            <img
                              src={paperclip}
                              alt=""
                              className="w-3 h-4 mx-auto"
                            />
                          )}
                        </td>
                        <td className=" w-[3%]">
                        <ViewMore
                          data={i}
                          fetcher={getAuditData}
                          status={status}
                        />
                        </td>
                      </tr>
           ))}
           </tbody>
          </table>
          </div>
        </div>
        {/* <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className=" text-xs 2xl:text-sm font-medium">
              {header.map((header) => (
                <th className="text-left px-5 py-4" width={header.width}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr colspan="6">
              <td colspan="6">
                <div className="overflow-y-auto h-[calc(100vh-285px)] shadow-box">
                  {data?.data &&
                    data?.data?.map((i) => {
                      return (
                        <tr className="odd:bg-tableodd even:bg-tableeven flex justify-between items-center my-1 py-1 text-xs 2xl:text-sm font-medium">
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                            {i.serial_number || "#"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize"
                            width="9%"
                          >
                            {i?.reference_number || "-"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize overflow-hidden"
                            width="10%"
                          >
                            {i?.performed_by || "-"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize overflow-hidden"
                            width="10%"
                          >
                            {i?.process_description || "-"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize overflow-hidden"
                            width="10%"
                          >
                            {i?.audit_description || "-"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize overflow-hidden"
                            width="10%"
                          >
                            {i?.auditee || "-"}
                          </td>
                          <td className="mb-2  text-gray-900 " width="10%">
                            {(i.start_date !== "None" &&
                              DateFormat({
                                value: i?.start_date,
                                format: "DD-MMM-yyyy",
                              })) ||
                              "-"}
                          </td>
                          <td className="mb-2 text-gray-900 " width="10%">
                            {(i.end_date !== "None" &&
                              DateFormat({
                                value: i?.end_date,
                                format: "DD-MMM-yyyy",
                              })) ||
                              "-"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize  overflow-hidden"
                            width="9%"
                          >
                            {i?.result || "-"}
                          </td>
                          <td
                            className="mb-2 pl-5 text-gray-900 capitalize  overflow-hidden"
                            width="10%"
                          >
                            {i?.status || "-"}
                          </td>
                          <td
                            className="px-2 xl:px-8 text-gray-900 whitespace-nowrap "
                            width="5%"
                          >
                            {i.attachment.length > 0 && (
                              <img
                                src={paperclip}
                                alt=""
                                className="w-3 h-4 mx-auto"
                              />
                            )}
                          </td>
                          <td className=" w-[2%]">
                          <ViewMore
                            data={i}
                            fetcher={getAuditData}
                            status={status}
                          />
                          </td>
                        </tr>
                        //   ) : (
                        //     ""
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={getAuditData}
          id={id}
          status={status}
        />

<ApprovelStatus
          visible={showApprovalModal}
          onClose={handleApprovalOnClose}
          refreshData={refreshData}
          moduleId={6}
          />
    </MainLayout>
    </>
  );
}

export default AuditCalenderIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "w-[5%]",
  },
  {
    headone: "Ref Number",
    width: "w-[9%]",
  },
  {
    headone: "Performed By",
    width: "w-[10%]",
  },
  {
    headone: "Process Description",
    width: "w-[10%]",
  },
  {
    headone: "Audit Description",
    width: "w-[10%]",
  },
  {
    headone: "Auditee",
    width: "w-[10%]",
  },
  {
    headone: "Start Date",
    width: "w-[10%]",
  },
  {
    headone: "End Date",
    width: "w-[10%]",
  },
  {
    headone: "Results",
    width: "w-[9%]",
  },
  {
    headone: "Status",
    width: "w-[10%]",
  },
  {
    headone: "Artefact",
    width: "w-[4%]",
  },
  {
    headone:"",
    width: "w-[3%]",
  },
];
