import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosInstance from "../../../Services/axiosComman";
import FileDisplay from "../../../Utilities/common/FileDisplay";
import DateFormat from "../../../Utilities/common/NuDate";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({
  data,
  visible,
  onClose,
  refreshData,
  securityIncident,
  relation,
  id,
  getSecurity
}) {
 
  const [isLoading,setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    systems: "",
    type_of_incident: "",
    incident_date: "",
    closure_date: "",
    RCA: "",
    status: "",
    incident_report: "",
  });
  useEffect(() => {
    setCreateNew({
      systems: data?.systems,
      type_of_incident: data?.type_of_incident,
      RCA: data?.RCA,
      incident_date: data?.incident_date,
      closure_date: data?.closure_date,
      status: data?.status,
    });
    setFilePathList(data?.incident_report)
    setRemoveFilePathList(data?.incident_report)
  }, [data]);


  function handleImageChange(e) {
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   systems: yup.string().required("Systems is a Required Field"),
    //   type_of_incident: yup.string().required("Type of Incident is a Required Field"),
    //   RCA: yup.string().required("RCA is a Required Field"),
    //   status: yup.string().required("Status is a Required Field"),
    // }),
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
      if (data?.id) {
        if(!value.closure_date){
          value.closure_date = "None"
        }

        if(!value.incident_date){
          value.incident_date = "None"
        }

        let finalArr = removefilePathList.filter(items=>  !filePathList.includes(items) )
        value.files_to_remove = finalArr.map(item=>item.replace(window.WEB_APP_API_URL, "localhost"));
        let formdata = new FormData();
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formdata.append("attachment", file);
          });
        } else{
          formdata.append("attachment",[]);
        } 
        value.status = value.status.toLowerCase();
        formdata.append("security_incident", JSON.stringify(value));
        axiosInstance.put(`/Security-Incident/${data?.id}/screen_closed`,{"isClosed":false}).then(res=>{ 
        axiosInstance
          .put(`/Security-Incident/${data.id}/`, formdata)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm()
              setSelectedFile(null)
              refreshData();
              getSecurity()
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Security Incident Updated Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || res.data.error || "Security Incident Update Unsuccessfully",
              });
            }
          }).catch((err)=>{
            setIsLoading(false);
              Toast.fire({
                icon: "error",
                title:err?.response?.data.msg || err?.response?.data.error || "Security Incident Update Unsuccessfully",
              });

          });
        }).catch(err=>{
          setIsLoading(false);
          console.log("err")
        })
      } else {
        let formdata = new FormData();
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formdata.append("attachment", file);
          });
        } 
        // value.status = value.status ? value.status : "not implemented";
        formdata.append("security_incident", JSON.stringify(value));
        axiosInstance
          .post(`/Security-Incident/standard/${id}/`, formdata)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm()
              setSelectedFile(null)
              refreshData();
              getSecurity()
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Security Incident Created Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title:res.data.msg || res.data.error || "Security Incident Creation Unsuccessfully",
              });
            }
          }).catch((err)=>{
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title:err?.response?.data.msg || err?.response?.data.error || "Security Incident Creation Unsuccessfully",
            });
          });
      }
    },
  });

  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }

  const formInputs = [
    {
      inputType: "NuInput",
      name: "systems",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "System",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "incident_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Incident Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      max:      DateFormat({
        value: Date.now(),
        format: "yyyy-MM-DD",
      })
    },
    {
      inputType: "NuInput",
      name: "closure_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Closure Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      min:      DateFormat({
        value: formik.values.incident_date,
        format: "yyyy-MM-DD",
      })
    },

    {
      inputType: "NuSelect",
      name: "type_of_incident",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Incident Type",
      label: "Type of Incident",
      labelclassname: "w-full",
      // type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: relation,
    },
    {
      inputType: "NuInput",
      name: "RCA",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "RCA Information / Analysis",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuSelect",
      name: "status",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select status",
      label: "Status",
      labelclassname: "w-full",
      // type: 'text',
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: securityIncident,
      // Options: [
      //   {value:0,label:'status 1'},
      //   {value:1,label:'Status 2'},
      //   {value:2,label:'status 3'},
      // ]
    },
    {
      inputType: "NuInput",
      name: "incident_report",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Incident Report / ATR Report",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleImageChange,
    },
  ];

  const handleOnClose = (e) => {
    if (e.target.id === "modal"){
      setSelectedFile(null)
      formik.resetForm()
      onClose();
    }
  };

  const closeFunction = () =>{
    setSelectedFile(null)
    formik.resetForm()
    onClose()
}

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
    flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12  overflow-y-auto">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex flex-wrap my-3">
              {data?.incident_report &&
               data?.incident_report !=null &&
               data?.incident_report !="None" &&
               data?.incident_report.length>0 && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
