import React, { useEffect, useState } from "react";
import { useParams,useLocation,useHistory } from "react-router-dom";
import CreateorUpdate from "./form";
import HeaderForm from "./headerform";
import ViewMore from "./viewMore";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import Axios from "../../../Services/axiosComman";
import Import from "./ImportAudit";
import Swal from "sweetalert2";
import { GetAccess } from "../../../Utilities/data/role";
import ApprovelStatus from "./approval";
// import { GetYear } from "../../../Utilities/common/GetYear";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Export from "../../../Utilities/common/Export";
import DateFormat from "../../../Utilities/common/NuDate";



const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

// const fetcher = (url) => Axios.get(url).then((res) => res.data);

function InternalAuditIndex() {
  const { id } = useParams();
  const location = useLocation();
  const history  = useHistory();
  let { statusFilter } = location?.state||"";
  const [headerData, setheaderData] = useState();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyHeaderModal, setShowMyHeaderModal] = useState(false);
  const handleHeaderOnClose = () => setShowMyHeaderModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);

  const [selected, setSelected] = useState(null);
  const [headers, setHeaders] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [expandedRow, setexpandedRow] = useState([]);
  const [total, setTotal] = useState(0);
  const [internal, setInternal] = useState([]);
  const [internalAuditStatus, setInternalAuditStatus] = useState([]);
  const [section, setSection] = useState('');
  const [HeaderId, setHeaderId] = useState('');
  const [data, setData] = useState([]);

  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");


  // const { data, error, mutate } = useSWR(
  //   `/internal-audit/standard/${id}?page=${pageIndex}&per_page=${dataCount}`,
  //   fetcher
  // );
  const toggle = (i, section,headerId) => {
    if (selected === i) {
      return setSelected(null);
    }
    setexpandedRow([])
    setSelected(i);
    setSection(section)
    setHeaderId(headerId)
    fetchHeader(section,headerId);
  };



  const fetchHeader = async (section,headerId) => {
    console.log("working");
    if(section){
      await Axios.get(statusFilter?`/internal-audit/standard/${id}/${headerId}/section/${section}/status/${statusFilter}`:`/internal-audit/standard/${id}/${headerId}/section/${section}`).then(
        (res) => {
          setexpandedRow(res.data?.data);
        }
      );
    }else{
      setexpandedRow([])
    }
  };
  

  const getInternal = () => {
    Axios.get(`/DashBoard/standard/${id}/internal_audit/`).then((res) => {
      res?.data && setInternal(res.data.data);
    });
  };
  const getInternalAudit = () => {
    Axios.get("/Status/2/").then((res) => {
      res?.data && setInternalAuditStatus(res.data?.data);
    });
  };


  function getStandard(){
    Axios.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        setStartDate(res?.data?.data.start_date)
        setEndDate(res?.data?.data.end_date)
    }).catch(error=>{
        console.log(error);
    })
  }

  useEffect(() => {
    // getInternalHeader()
    getStandard()
    getInternal();
    getInternalAudit();
  }, []);

  useEffect(() => {
    if(statusFilter){
      getInternalFilterHeader()
    }else{
      getInternalHeader()
    }
  },[pageIndex,dataCount])

  function getInternalFilterHeader(){
    Axios.get(`/internal-audit/standard/${id}/status/${statusFilter}?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data);
      res?.data?.data && setTotal(res?.data?.total_records)
    }).catch(error=>{
      console.log("error",error);
    })
  }

  function getInternalHeader(){
    Axios.get(`/internal-audit/standard/${id}?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data);
      res?.data?.data && setTotal(res?.data?.total_records)
    }).catch(error=>{
      console.log("error",error);
    })
  }

  // useEffect(() => {
  //   if(statusFilter){
  //     getInternalFilterHeader()
  //   }
  // },[statusFilter])




  function clearFilter(){
    getInternalHeader();
    setexpandedRow([])
    setSelected(null)
    history.replace({
      state:null
    })
    }

    


  return (
    <>
      <MainLayout
      id={id}
      total={data?.total_records}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      dataCount={dataCount}
      setDataCount={setDataCount}
      label="Internal Audit Checklist"
      title="Activity"
      setShowApprovalModal={setShowApprovalModal}
      hasApproval={true}
    >
              <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Internal Audit Checklist</p>
          <div className="flex items-center text-xs 2xl:text-sm">
            <p className="mr-2">overall</p>
            <span className="bg-login px-2 py-1 rounded-md">{internal?.complete_percentage||0}%</span>
          </div>
          <div className="flex items-center">
          {statusFilter !=null ?<div className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer" onClick={clearFilter}>clear</div>:null}
          <div className=" w-8">
            {GetAccess("activity_create") && <svg
              className="w-7 ml-1 cursor-pointer"
              alt="plus"
              onClick={() => setShowMyHeaderModal(true)}
              title="Add"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.61084"
                y="0.335541"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path
                d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Add Header</title>
            </svg>}
            </div>
            <Export id={id} path={'internal_audit'} name={`${standardName} internal audit ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} />
           {GetAccess("activity_create")&& <Import getInternal={getInternal} refreshData={getInternalHeader} id={id} />} 
          </div>
        </div>
        <table className="table-auto w-full">
          {/* md:w-[72.6vh] xl:w-full  */}
          <thead className="bg-tabletitle">
            <tr className="text-xs 2xl:text-sm font-medium">
              {header.map((header, i) => (
                <th className={`py-4 ${header.width}`} key={i}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr colspan="6 " className="relative px-4">
              <td colspan="6">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data &&
                    data.data.map((i, indx) => {
                      // let headerData = filterData(i);
                      return (
                        <div className="" key={indx}>
                          <div className="pl-5 pr-2 py-2 mt-1.5 bg-tableeven shadow-box flex justify-between items-center">
                            {/* {headerData &&
                              headerData.map((j, indx) => {
                                return ( */}
                            <span
                              className={
                                selected === indx
                                  ? "text-xs 2xl:text-sm font-semibold text-gray-900 w-full"
                                  : "text-xs 2xl:text-sm font-semibold text-gray-500 w-[40%]"
                              }
                            >
                              <h2 className="leading-5  ">
                                {/* {i?.value[1]} */}
                                {i?.header_no} &nbsp;
                                {i?.header_title}
                              </h2>
                              <h2 className="leading-5 ">
                                {/* {i?.value[2]} */}
                                {i?.section_no} &nbsp;
                                {i?.section}
                              </h2>
                            </span>
                            <div className="flex justify-end w-[5%]">
                              <span
                                className="bg-white w-7 h-7 flex items-center justify-center px-[10px] rounded-[3px] shadow-box cursor-pointer"
                                onClick={() => toggle(indx, i?.section_no,i?.header_id)}
                              >
                                {selected === indx ? (
                                  <i
                                    class="fa fa-angle-up"
                                    style={{ fontSize: 15 }}
                                    title="collapse"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-angle-down"
                                    style={{ fontSize: 15 }}
                                    title="expand"
                                  ></i>
                                )}
                              </span>
                              {
                        GetAccess("activity_create") &&   <span className="w-7">
                                <svg
                                  className="w-7 ml-1 cursor-pointer"
                                  alt="plus"
                                  onClick={() => {
                                    setShowMyModal(true);
                                    setHeaders(i);
                                  }}
                                  viewBox="0 0 52 52"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.61084"
                                    y="0.335541"
                                    width="51.1569"
                                    height="51.1569"
                                    rx="5"
                                    fill="#7030A0"
                                  />
                                  <path
                                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                                    stroke="white"
                                    stroke-width="2.3"
                                    stroke-linecap="round"
                                  />
                                  <title>Add</title>
                                </svg>
                              </span>}
                            </div>
                          </div>

                          <div
                            className={
                              selected === indx
                                ? "tabledescription show mr-1 relative"
                                : "tabledescription mr-1 relative"
                            }
                          >
                            {expandedRow &&
                              expandedRow.map((i) => {
                                return (
                                  <tr className="odd:bg-tableodd even:bg-tableeven flex justify-between items-center text-left my-1 text-xs 2xl:text-sm py-2 font-medium">
                                    <td className="px-2 xl:px-8 text-gray-900 whitespace-normal w-[7%]">
                                      {i.section_no}
                                    </td>
                                    <td className="px-2 xl:px-8 text-gray-900 whitespace-normal w-[10%]">
                                      {i.sub_section_no}
                                    </td>
                                    <td className="pr-2 text-gray-900 text-justify whitespace-normal w-[45%]">
                                      {i.question_activity}
                                    </td>
                                    <td className="text-gray-900 capitalize text-center whitespace-normal w-[15%]">
                                      {i.status}
                                    </td>
                                    <td className="px-2 xl:px-8 text-gray-900 whitespace-normal w-[20%]">
                                      {i.image_path !==null && i.image_path !=="None" && i.image_path !=="[]" && i.image_path.length>0 && (
                                        <img
                                          src={paperclip}
                                          alt=""
                                          className="w-3 h-4 mx-auto"
                                        />
                                      )}
                                  
                                    </td>
                                    <td className=" w-[3%]">
                                    <ViewMore
                                      onClose={handleOnCloseMenu}
                                      visible={showMyMenu}
                                      id={i.id}
                                      fetcher={statusFilter?getInternalFilterHeader:getInternalHeader}
                                      fetcherHeader={()=>fetchHeader(section,HeaderId)}
                                      status={internalAuditStatus}
                                      moduleId={i.sub_section_no}
                                      getInternal={()=>getInternal()}
                                    />
                                    </td>
                                    
                                  </tr>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <HeaderForm
          onClose={handleHeaderOnClose}
          visible={showMyHeaderModal}
          refreshData={statusFilter?getInternalFilterHeader:getInternalHeader}
          id={id}
        />
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          id={id}
          refreshData={statusFilter?getInternalFilterHeader:getInternalHeader}
          headerId={headers}
          status={internalAuditStatus}
          fetcherHeader={()=>fetchHeader(section)}
          getInternal={()=>getInternal()}

          // headerId={data.headerId.id}
        />
                <ApprovelStatus
            visible={showApprovalModal}
            onClose={handleApprovalOnClose}
            refreshData={statusFilter?getInternalFilterHeader:getInternalHeader}
        />
    </MainLayout>
    </>
  );
}

export default InternalAuditIndex;

const header = [
  {
    headone: "Section",
    width: "w-[7%]",
  },
  {
    headone: "Sub - Section",
    width: "w-[10%]",
  },
  {
    headone: "Questions / Activity",
    width: "w-[45%]",
  },
  // {
  //   headone: "User Response",
  //   width: "w-[15%]",
  // },
  // {
  //   headone: "User Comment",
  //   width: "w-[15%]",
  // },
  {
    headone: "Status",
    width: "w-[15%]",
  },
  {
    headone: "Attachment Artefact",
    width: "w-[20%]",
  },
  {
    headone: "",
    width: "w-[3%]",
  },
];
