import React, { useEffect, useState } from "react";
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import { NavLink } from "react-router-dom";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
import Pagination from "../../../Utilities/common/Pagination";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import Import from "./import";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import ApprovelStatus from "./approval";
import { GetYear } from "../../../Utilities/common/GetYear";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Export from "../../../Utilities/common/Export";
import DateFormat from "../../../Utilities/common/NuDate";


function MaintenanceIndex() {
  const { id } = useParams();
  const location = useLocation();
  const history  = useHistory();
  let { statusFilter } = location?.state||"";
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [maintenance, setMaintenance] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [status, setStatus] = useState([]);
  const [data,setData] = useState([]);
  const [total,setTotal] = useState(0);

  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");




  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;

  function getStandard(){
    Axios.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        setStartDate(res?.data?.data.start_date)
        setEndDate(res?.data?.data.end_date)
    }).catch(error=>{
        console.log(error);
    })
  }

  useEffect(() => {
    getMaintenance();
    getStatus();
    getStandard()
    // getAllData();
    getFrequency();
  }, []);

  function getAllData(){
    Axios.get(`/Maintenance/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data)
      res?.data?.data && setTotal(res?.data?.total); 
    }).catch(error=>{
      console.log(error);
    })
  }

  function getFilterData(){
    Axios.get(`/Maintenance/standard/${id}/?page=${pageIndex}&per_page=${dataCount}&status=${statusFilter}`).then(res=>{
      res.data && setData(res.data)
      res?.data?.data && setTotal(res?.data?.total);
    }).catch(error=>{
      console.log(error);
    })
  }

  function getMaintenanceData(){
    if(statusFilter){
      getFilterData()
    }else{
      getAllData()
    }
  }

  const getMaintenance = () => {
    Axios.get(`/DashBoard/standard/${id}/maintenance/`).then((res) => {
      res?.data && setMaintenance(res.data.data);
    });
  };
  const getStatus = () => {
    Axios.get("/Status/3/").then((res) => {
      res?.data?.data && setStatus(res.data.data);
    });
  };
  const getFrequency = () => {
    Axios.get(`/Frequency/`).then((res) => {
      res?.data?.data && setFrequency(res.data.data);
    })
  }

  useEffect(() => {
    if(statusFilter){
      getFilterData()
    }else{
      getAllData()
    }
  },[pageIndex,dataCount])

  // useEffect(() => {
  //   if(statusFilter){
  //    getFilterData()
  //   }
  // },[statusFilter])

  function clearFilter(){
    getAllData();
    history.replace({
      state:null
    })
    }

  return (
    <>
    <MainLayout
      id={id}
      total={data?.total}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      dataCount={dataCount}
      setDataCount={setDataCount}
      label="Maintenance"
      title="Activity"
      setShowApprovalModal={setShowApprovalModal}
      hasApproval={true}
    >
              <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Maintenance</p>
          <div className="flex items-center text-xs 2xl:text-sm">
            <p className="mr-2">overall</p>
            <span className="bg-login px-2 py-1 rounded-md">
              {maintenance?.complete_percentage||0}%
            </span>
          </div>
          <div className="flex items-center">
          {statusFilter !=null ?<div className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer" onClick={clearFilter}>clear</div>:null}
          <div className=" w-8">
          {
                        GetAccess("activity_create") && <svg
              className="w-7 ml-1 cursor-pointer"
              alt="plus"
              onClick={() => setShowMyModal(true)}
              title="Add"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.61084"
                y="0.335541"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path
                d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Add</title>
            </svg>
}
</div>
            {/* )}  */}
            <Export id={id} path={'maintenance'} name={`${standardName} Maintenance ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}  />

           {GetAccess("activity_create") && <Import getMaintenance={getMaintenance} refreshData={getAllData} id={id} />} 
          </div>
        </div>
        <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className="text-xs 2xl:text-sm font-medium">
              {header.map((header, i) => (
                <th
                  className={`px-2 xl:px-[25px] py-4 ${header.width}`}
                  key={i}
                >
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr colspan="6 " className="relative px-4">
              <td colspan="8">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data &&
                    data?.data?.map((i) => {
                      return (
                        <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm text-center font-medium py-1 flex justify-between items-center my-1">
                         <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                            {i.serial_number || "#"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900  w-[35%] break-words  capitalize text-left">
                            {i?.task || "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap w-[15%] capitalize">
                            {/* {findFrequency(i?.frequencyId || "-")} */}
                            {i.frequency_name || "_"}
                          </td>
                          <td className="px-2 xl:px-8 capitalize text-gray-900 whitespace-nowrap w-[15%] ">
                            {i.status_id||"-"}
                            {/* {i.status_id ==1 ? "Open"  : i.status_id ==2 ? 'In Progress' : i.status_id ==3 ? "On Hold" : i.status_id ==4 ? "Completed": i.status_id ==5 ? "Closed" : "-"} */}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap w-[15%] capitalize">
                            {i.comments || "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap w-[12%]">
                            {i.file_path !="None" && i.file_path.length>0 && (
                              <img
                                src={paperclip}
                                alt=""
                                className="w-3 h-4 mx-auto"
                              />
                            )}
                          </td>
                          <td className=" w-[3%]">
                          <ViewMore
                            // onClose={handleOnCloseMenu}
                            // visible={selectedRow === i.id}
                            fetcher={getMaintenanceData}
                            data={i}
                            id={id}
                            maintenanceId={i.id}
                            frequency={frequency}
                            status={status}
                            getMaintenance={()=>getMaintenance()}
                          />
                          </td>
                        </tr>
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CreateorUpdate
          // data={data}
          id={id}
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={getAllData}
          frequency={frequency}
          status={status}
          getMaintenance={()=>getMaintenance()}

        />
        <ApprovelStatus
          visible={showApprovalModal}
          onClose={handleApprovalOnClose}
          refreshData={getAllData}
        />
    </MainLayout>
    </>
  );
}

export default MaintenanceIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "w-[5%]",
  },
  {
    headone: "Maintenance Task",
    width: "w-[35%]",
  },
  {
    headone: "Frequency",
    width: "w-[15%]",
  },
  {
    headone: "Status",
    width: "w-[15%]",
  },
  {
    headone: "Comments",
    width: "w-[15%]",
  },
  {
    headone: "Artefact",
    width: "w-[12%]",
  },
  {
    headone: " ",
    width: "w-[3%]",
  },
];
