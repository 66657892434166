import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import Add from "../../../Utilities/Image/icons/plus.svg";
import Close from "../../../Utilities/Image/icons/close.svg";
import axiosInstance from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
// import Modal from "./modal"

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({
  data,
  visible,
  onClose,
  value,
  id,
  taskData,
  setTaskData,
  resetData,
  eventData,
  eventDataList,
  refreshData,
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };

  const [calenderStatus, setCalenderStatus] = useState([]);
  const [createNew, setCreateNew] = useState({
    task_id: "",
    title: "",
    comment: "",
    completion_date: value.startStr,
    reminder_date: value.startStr,
    status: "",
  });


  useEffect(() => {
    getCalenderStatus();
  }, []);

  const formInputs = [
    {
      inputType: "NuSelect",
      name: "task_id",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Task",
      label: "Task",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: [
        // { value: 0, label: "Select Status" },
        { value: 1, label: "Implementation Checklist"},
        { value: 2, label: "Internal Audit" },
        { value: 3, label: "Maintenance" },
        { value: 4, label: "Master List of Documents "},
        { value: 5, label: "CISO/DPO/ISF Meeting MoM" },
        { value: 6, label: "Audit Schedule" },
      ],
      // isRequired: true,
    },
    // {
    //   inputType: "NuSelect",
    //   name: "title",
    //   classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
    //   placeholder: "Select Title",
    //   label: "Title",
    //   labelclassname: "w-full",
    //   customClass: "w-6/12 pl-4 md:mt-4",
    //   Options: [
    //     // { value: 0, label: "Select Status" },
    //     { value: 0, label: "Task 1" },
    //     { value: 1, label: "Task 2" },
    //     { value: 2, label: "Task 3" },
    //   ],
    //   isRequired: true,
    // },
    {
      inputType: "NuInput",
      name: "title",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Enter Title",
      label: "Title",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "comment",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Comment",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "completion_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Completion Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      // max:      DateFormat({
      //   value: Date.now(),
      //   format: "yyyy-MM-DD",
      // })
      // value: d
    },
    {
      inputType: "NuSelect",
      name: "status",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Status",
      label: "Status",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: calenderStatus.map((i) => {
        return {
          value: i.status_type,
          label: i.status_type,
        };
      }),
      // isRequired: true,
    },
    {
      inputType: "NuInput",
      name: "reminder_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Reminder Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      // value: d
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: yup.object({
    //   task_id: yup.string().required("Task is Required"),
    //   title: yup.string().required("Title is Required"),
    //   comment: yup.string(),
    //   completion_date:yup.date().required("Date is Required"),
    //   status:yup.string().required("Status is Required"),
    // }),
    onSubmit: (value) => {
      //       console.log('new ',value);
      //       data?.incharge_id?
      //       axiosComman.put(`/employee/${data.incharge_id}`,value)
      //       .then(res=>{
      //         try {
      //           if (res.data.status) {
      //             console.log("res.data.status", res.data)
      //             handleClose();
      //             refreshData();
      //             console.log("refresh created")
      //             Toast.fire({
      //               icon: "success",
      //               title: res.data.message || "In-Charge Update Successfully",
      //             });
      //           }
      //         }
      //         catch {
      //             Toast.fire({
      //               icon: "error",
      //               title: res.data.message || "In-Charge Update Unsuccessfully",
      //             });
      //           }
      //       })
      //       :
      formik.resetForm({ values: {} });
      value.standardId = { id: id };
      axiosInstance
        .post(`/Calender-Management/standard/${id}`, value)
        .then((res) => {
          if (res.data.status) {
            onClose();
            refreshData();
            Toast.fire({
              icon: "success",
              title: res.data.msg || "Calendar Created Successfully",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.msg || "Calendar Creation Unsuccessfully",
            });
          }
        });
    },
  });

  const getCalenderStatus = () => {
    axiosInstance.get("/Status/10/").then((res) => {
      res?.data && setCalenderStatus(res.data?.data);
    });
  };

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-50 inset-0 backdrop-blur-sm 
    flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="h-[60vh] overflow-y-auto">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>

              <div className="flex items-center justify-end px-6 py-6 rounded-b">
                <button
                  onClick={onClose}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
