import React, { useEffect, useRef, useState } from "react";
import { useParams,useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import axiosInstance from "../../../Services/axiosComman";
import { useFormik } from "formik";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import { MainForm } from "../../../Utilities/common/MainForm";
import DateFormat from "../../../Utilities/common/NuDate";
import StandardLayout from "../../../Utilities/Layout/StandardLayout";
import Breadcrumbs from "../../../Utilities/common/Breadcrumbs";
import AuditPDF from "../ReportsList/pdf/AuditDetail";
import BackButton from "../../../Utilities/common/BackButton";
import PDFGenerationScreen from "../PDFGenerationScreen";

function AuditCalenderReportIndex() {
  const { id } = useParams();
  let location = useLocation();
  const componentRef = useRef();
  const [showMyModal, setShowMyModal] = useState(false);
  const [data, setData] = useState([]);
  const [standardname, setStandardName] = useState("");
  const [company, setCompany] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState([]);
  const [review, setReview] = useState("");
  const [PDFLenth,SetPDFLength] = useState(0);
  const [dataLength,setDataLength] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);



  function onClose() {
    setShowMyModal(false);
  }

  function handleOnClose(e) {
    if (e.target.id === "modal") onClose();
  }
  const [createNew, setCreateNew] = useState({
    standard_id: "",
    module_id: 6,
    type: 1,
    status_name: "",
    from_date: "",
    to_date: "",
    completion_start_date: "",
    completion_end_date: "",
    reminder_start_date: "",
    reminder_end_date: "",
  });

  const formInputs = [
    // {
    //   inputType: "NuInput",
    //   name: "completion_start_date",
    //   classname: "border w-9/12 h-10 px-3 rounded-lg",
    //   placeholder: "",
    //   label: "Completion Start Date",
    //   labelclassname: "w-full",
    //   type: "date",
    //   customClass: "w-full lg:w-6/12",
    // },

    // {
    //   inputType: "NuInput",
    //   name: "completion_end_date",
    //   classname: "border w-9/12 h-10 px-3 rounded-lg",
    //   placeholder: "",
    //   label: "Completion End Date",
    //   labelclassname: "w-full",
    //   type: "date",
    //   customClass: "w-full lg:w-6/12",
    // },
    // {
    //   inputType: "NuInput",
    //   name: "reminder_start_date",
    //   classname: "border w-9/12 h-10 px-3 rounded-lg",
    //   placeholder: "",
    //   label: "Reminder Start Date",
    //   labelclassname: "w-full",
    //   type: "date",
    //   customClass: "w-full lg:w-6/12",
    // },
    // {
    //   inputType: "NuInput",
    //   name: "reminder_end_date",
    //   classname: "border w-9/12 h-10 px-3 rounded-lg",
    //   placeholder: "",
    //   label: "Reminder End Date",
    //   labelclassname: "w-full",
    //   type: "date",
    //   customClass: "w-full lg:w-6/12",
    // },
    {
      inputType: "NuSelect",
      name: "status_name",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "Select Status",
      label: "Status",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: [
        { value: "completed", label: "Completed" },
        { value: "in progress", label: "In Progress" },
        { value: "yet to start", label: "Yet to Start" },
      ],
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (value, { resetForm }) => { 
      setIsDataLoading(true)
      setData([])
      value.standard_id = id;
      axiosInstance.post(`/report/`, value).then((res) => {
        console.log("res", res.data?.data);
        SetPDFLength(0)
        setDataLength(res?.data?.data.length);
        setData(res.data?.data);
        let dateValue = res.data.data || [];
        if (dateValue.length > 0) {
          let fun = dateValue[0].final_status || null;
          if (fun == "review_and_approved") {
            setReview(true);
          }else{
            setReview("");

          }
        }
        if(dateValue.length == 0){
          setIsDataLoading(false) 
          
        }
        onClose();
      }).catch(err=>{
        setIsDataLoading(false)
        onClose();
      });
    },
  });

  function getStandard() {
    axiosInstance
      .get(`Standards/${id}`)
      .then((res) => {
        setStandardName(res?.data?.data.name);
        // setStartDate(res?.data?.data.start_date);
        // setEndDate(res?.data?.data.end_date);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getCompanyInfo() {
    await axiosInstance.get(`/company-info/`).then((res) => {
      setCompany(res?.data?.data[0]);
    });
  }

  async function getAllData() {
    SetPDFLength(0)
    setDataLength(0);
    let value = {
      standard_id: id,
      module_id: 6,
      type: location.state?.reportValue?.reset_count || 0,
      status_name: "",
      from_date: "",
      // location.state?.reportValue?.start_date || "",
      to_date:"",
        // DateFormat({value:location.state?.reportValue?.reset_date,format:"YYYY-MM-DD"})  ||
        // DateFormat({value:location.state?.reportValue?.end_date,format:"YYYY-MM-DD"})  ||
        // "",
    };
    axiosInstance
      .post(`/report/`, value)
      .then((res) => {
        // setIsDataLoading(false) 
        // console.log(res.data);
        res?.data?.data && setData(res?.data?.data);
        setDataLength(res?.data?.data.length);
        res?.data?.status && setStatus(res?.data?.status);
        let dateValue = res.data.data || [];
        if (dateValue.length > 0) {
          let fun = dateValue[0].final_status || null;
          if (fun == "review_and_approved") {
            setReview(true);
          } else {
            setReview("");
          }
        }
        if(dateValue.length == 0){
          setIsDataLoading(false) 
          
        }
      })
      .catch((err) => {
        setIsDataLoading(false)
        console.log(err?.response?.data, "Error");
      });
  }

  useEffect(() => {
    getCompanyInfo();
    getStandard();
    getAllData();
    setStartDate(location.state?.reportValue?.start_date);
    setEndDate(
      location.state?.reportValue?.reset_date ||
        location.state?.reportValue?.end_date
    );
    setCreateNew({...createNew,type:location.state?.reportValue?.reset_count})
  }, [location.state?.reportValue]);

  // useEffect(()=>{
  //   if(dataLength==PDFLenth){
  //     setIsDataLoading(false)
  //   }else if(PDFLenth == 0){
  //     setIsDataLoading(false)
  //   }
  // },[PDFLenth])

  // useEffect(()=>{
  //   if(data.length ==0){      
  //     setIsDataLoading(false)
  //   }
  // },[data])

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const worker = new Worker(new URL('../pdfWorker/AuditScheduleReport.js', import.meta.url));

    worker.onmessage = function(e) {
      setPdfUrl(e.data);
      setIsDataLoading(false);
    };


    if (data.length > 0) {
      worker.postMessage({
        data,
        status,
        company,
        standardname,
        review,
        startDate,
        endDate,
        Name: `Audit Schedule Report`
      });
    }

    return () => worker.terminate();
  }, [data, company, standardname, startDate, endDate, status, review]);


  return (
    <StandardLayout>
      <div className=" w-[97.5%] flex justify-between items-center">
      <Breadcrumbs title="Reports /Activity" label="Audit Schedule" />
      <BackButton/>
      </div>
      <div className="w-[97.5%] bg-white shadow-main h-[calc(100vh-135px)] overflow-hidden rounded-2xl mx-4">
        <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Audit Schedule</p>

          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <PrintData 
                data={data}
                company={company}
                standardname={standardname}
                review={review}
                startDate={startDate}
                endDate={endDate}
                status={status}
               />
            </div>
          </div>

          <div className="flex gap-3 w-32">
            <button className=" px-2  bg-primary text-white rounded-lg " onClick={() => setShowMyModal(true)}>Filter</button>
            <ReactToPrint
              trigger={() => (
                <button>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    height="22px"
                    width="22px"
                    //   {...props}
                  >
                    <path d="M2.5 8a.5.5 0 100-1 .5.5 0 000 1z" />
                    <path d="M5 1a2 2 0 00-2 2v2H2a2 2 0 00-2 2v3a2 2 0 002 2h1v1a2 2 0 002 2h6a2 2 0 002-2v-1h1a2 2 0 002-2V7a2 2 0 00-2-2h-1V3a2 2 0 00-2-2H5zM4 3a1 1 0 011-1h6a1 1 0 011 1v2H4V3zm1 5a2 2 0 00-2 2v1H2a1 1 0 01-1-1V7a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1h-1v-1a2 2 0 00-2-2H5zm7 2v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-3a1 1 0 011-1h6a1 1 0 011 1z" />
                  </svg>
                </button>
              )}
              content={() => componentRef.current}
              pageStyle={`
                @page {
                  size: landscape;
                  margin-left: 3mm;
                  margin-right: 3mm;
                  margin-top: 0;
                  margin-bottom: 15mm;
                  padding-bottom:10mm;

                }
                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                    print-color-adjust: exact;
                    font-family: Arial, sans-serif;
                    counter-reset: page;
                  }
              `}
            />

{
   pdfUrl && 
   <a href={URL.createObjectURL(pdfUrl)} download={`${standardname}-Audit Schedule Report ${
                  location.state?.reportValue?.start_date &&
                  DateFormat({
                    value: location.state?.reportValue?.start_date,
                    format: "MMM-YYYY",
                  })
                } ${
                  (location.state?.reportValue?.reset_date ||
                    location.state?.reportValue?.end_date) &&
                  "to"
                } ${
                  (location.state?.reportValue?.reset_date ||
                    location.state?.reportValue?.end_date) &&
                  DateFormat({
                    value:
                      location.state?.reportValue?.reset_date ||
                      location.state?.reportValue?.end_date,
                    format: "MMM-YYYY",
                  })
                }.pdf`}>
                       <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="22px"
                      width="22px"
                      //   {...props}
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 00.161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 00-.46-.477c-.2-.12-.443-.179-.732-.179zm.545 1.333a.795.795 0 01-.085.38.574.574 0 01-.238.241.794.794 0 01-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 00.595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 00-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 01.354.454c.079.201.118.452.118.753a2.3 2.3 0 01-.068.592 1.14 1.14 0 01-.196.422.8.8 0 01-.334.252 1.298 1.298 0 01-.483.082h-.563v-2.707zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896z"
                      />
                    </svg>
 </a>

              }
{/* 
            <PDFDownloadLink
              document={
                <AuditPDF
                  company={company}
                  standardname={`Standard ${standardname}`}
                  data={data}
                  Name="Audit Schedule Report"
                  startDate={startDate}
                  endDate={endDate}
                  status={status}
                  review={review}
                  updateLenth={SetPDFLength}

                />
              }
              fileName={`${standardname}-Audit Schedule Report ${location.state?.reportValue?.start_date && DateFormat({value:location.state?.reportValue?.start_date,format:"MMM-YYYY"})} ${( location.state?.reportValue?.reset_date ||location.state?.reportValue?.end_date) && "to"} ${( location.state?.reportValue?.reset_date ||location.state?.reportValue?.end_date) && DateFormat({value:location.state?.reportValue?.reset_date ||location.state?.reportValue?.end_date,format:"MMM-YYYY"})}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <span className=" w-4 h-4 px-1 overflow-hidden border border-black rounded rounded-tr-lg">
                  ...
                </span>
                ) : (
                  <svg
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    height="22px"
                    width="22px"
                    //   {...props}
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 00.161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 00-.46-.477c-.2-.12-.443-.179-.732-.179zm.545 1.333a.795.795 0 01-.085.38.574.574 0 01-.238.241.794.794 0 01-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 00.595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 00-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 01.354.454c.079.201.118.452.118.753a2.3 2.3 0 01-.068.592 1.14 1.14 0 01-.196.422.8.8 0 01-.334.252 1.298 1.298 0 01-.483.082h-.563v-2.707zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896z"
                    />
                  </svg>
                )
              }
            </PDFDownloadLink> */}
          </div>
        </div>

        <div className="table-wrp block h-[calc(100vh-180px)] overflow-y-auto">
        <table className="w-full">
          <thead className=" sticky bg-tabletitle top-0">
            <tr className="text-xs 2xl:text-sm font-medium">
              {header.map((header, i) => (
                <th className={`py-4 ${header.width}`} key={i}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 overflow-y-auto w-full h-full">
            {data &&
              data?.map((item, index) => {
                return (
                  <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium" key={index}>
                    <td className=" py-4 w-[5%] text-center">
                      {item.id || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.reference_number || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.performed_by || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.process_description || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.audit_description || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.auditee || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {(item.start_date != "" && item.start_date != "None" && (
                        <DateFormat
                          value={item.start_date}
                          format="DD-MMM-YYYY"
                        />
                      )) ||
                        ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {(item.end_date != "" && item.end_date != "None" && (
                        <DateFormat
                          value={item.end_date}
                          format="DD-MMM-YYYY"
                        />
                      )) ||
                        ""}
                    </td>
                    <td className="py-4 w-[9%] text-center">
                      {item.result || ""}
                    </td>
                    <td className="py-4 capitalize w-[10%] text-center">
                      {item.status || ""}
                    </td>
                    {/* <td className="py-4 w-[5%] text-center">
                          {(item.attachment && (
                            <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
                          )) ||
                            ""}
                        </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {
                        data.length == 0 && isDataLoading == true &&(
                          
                          <div  className=" w-full font-semibold text-sm text-center flex h-40 justify-center items-center">Fetching Data</div>
                        
                        )
                      }  

                    { 
                       data.length == 0 && isDataLoading == false &&(
                     
                      <div className=" w-full font-semibold text-sm text-center flex h-40 justify-center items-center">NO DATA</div>
                  
                      )} 
      </div>


      </div>
      {showMyModal && (
        <div
          id="modal"
          onClick={handleOnClose}
          className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
    flex justify-center items-center "
        >
          <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
            <div className="flex items-start justify-between px-5 py-2  rounded-t">
              <h3 className="text-base 2xl:text-lg font-semibold">Filter</h3>
              <span
                className="h-6 w-6 text-2xl block cursor-pointer"
                onClick={onClose}
              >
                {/* <img src={Close} alt="close" title="close" /> */}
                <svg
                  className="w-7 h-7"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.842773"
                    y="0.560791"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                  <path
                    d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Close</title>
                </svg>
              </span>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="overflow-y-auto ">
                <div className="relative px-6 pb-20 flex flex-wrap justify-between">
                  <MainForm formInputs={formInputs} formik={formik} />
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b">
                  <button
                    onClick={onClose}
                    className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <PDFGenerationScreen visible={isDataLoading} activity={"AuditSchedule Report"} format={"pdf"} name={standardname}/>
    
    </StandardLayout>
  );
}

export default AuditCalenderReportIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "5%",
  },
  {
    headone: "Ref Number",
    width: "10%",
  },
  {
    headone: "Performed By",
    width: "10%",
  },
  {
    headone: "Process Description",
    width: "15%",
  },
  {
    headone: "Audit Description",
    width: "10%",
  },
  {
    headone: "Auditee",
    width: "10%",
  },
  {
    headone: "Start Date",
    width: "10%",
  },
  {
    headone: "End Date",
    width: "10%",
  },
  {
    headone: "Results",
    width: "10%",
  },
  {
    headone: "Status",
    width: "10%",
  },
  
];

function PrintData({
  data,
  company,
  standardname,
  review,
  startDate,
  endDate,
  status
}) {


  let CompletedTemp = Number(status?.completed?.percentage||0);
  let CompletedFinal = CompletedTemp.toFixed(1)
  let InProgressTemp = Number(status?.in_progress?.percentage||0);
  let InProgressFinal = InProgressTemp.toFixed(1);
  let YetToStartTemp = Number(status?.yet_to_start?.percentage||0);
  let YetToStartFinal = YetToStartTemp.toFixed(1);

  return (
    <div className="w-[96%] mx-auto bg-white  my-6">
      <div className="py-2 pl-5 flex justify-between items-center w-full">
        <div className=" flex justify-start gap-2 items-center">
          {company?.companyLogo && (
            <div className=" w-12 h-auto ">
              <img src={company?.companyLogo} alt="#" width="100%" />
            </div>
          )}
          <div className=" text-sm font-medium">
            {company?.companyName || ""}
          </div>
        </div>
        <div className=" text-sm font-medium">{`Standard ${standardname} `|| ""}</div>
        <div className=" text-sm font-medium">
         Audit Schedule Report
        </div>
        <div className=" text-[9px] font-medium px-1">
          <p>{`Yet To Start : ${YetToStartFinal} %`}</p>
          <p>{`In Progress : ${InProgressFinal} %`}</p>
          <p>{`Completed : ${CompletedFinal} %`}</p>
        </div>
      </div>
      <div className="table-wrp block">
        <table className="w-full">
          <thead className=" sticky bg-tabletitle top-0">
            <tr className="text-xs 2xl:text-sm font-medium">
              {header.map((header, i) => (
                <th className={`py-4 ${header.width}`} key={i}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 overflow-y-auto h-full">
            {data &&
              data?.map((item, index) => {
                return (
                  <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium" key={index}>
                    <td className=" py-4 w-[5%] text-center">
                      {item.id || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.reference_number || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.performed_by || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.process_description || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.audit_description || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {item.auditee || ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {(item.start_date != "" && item.start_date != "None" && (
                        <DateFormat
                          value={item.start_date}
                          format="DD-MMM-YYYY"
                        />
                      )) ||
                        ""}
                    </td>
                    <td className="py-4 w-[10%] text-center">
                      {(item.end_date != "" && item.end_date != "None" && (
                        <DateFormat
                          value={item.end_date}
                          format="DD-MMM-YYYY"
                        />
                      )) ||
                        ""}
                    </td>
                    <td className="py-4 w-[9%] text-center">
                      {item.result || ""}
                    </td>
                    <td className="py-4 capitalize w-[10%] text-center">
                      {item.status || ""}
                    </td>
                    {/* <td className="py-4 w-[5%] text-center">
                          {(item.attachment && (
                            <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
                          )) ||
                            ""}
                        </td> */}
                  </tr>
                );
              })}
          </tbody>
          <tfoot className=" h-8 fixed -bottom-3 w-[96%] ">
            <tr colspan="6" className=" w-full block px-4">
             <td colspan="8 " className=" w-full block ">
             <div className=" text-gray-600 flex justify-between items-end w-full">
              <div>
                <p className=" text-[11px] my-0 leading-[10px]">
                  Certification Calendar Tracker
                </p> 
                <p className=" text-[11px] my-0 leading-[10px]">
                  {`${
                    (startDate &&
                      startDate != "None" &&
                      startDate != "" &&
                      startDate != null &&
                      DateFormat({
                        value: startDate,
                        format: "DD-MMM-YYYY",
                      })) ||
                    ""
                  } ${
                    (startDate &&
                      startDate != "None" &&
                      startDate != "" &&
                      startDate != null &&
                      "/") ||
                    ""
                  } ${
                    (endDate &&
                      endDate != "None" &&
                      endDate != "" &&
                      endDate != null &&
                      DateFormat({ value: endDate, format: "DD-MMM-YYYY" })) ||
                    ""
                  }`}
                </p>
              </div>
              <div className=" text-center text-[11px]">
                {`${DateFormat({
                  value: Date(),
                  format: "DD-MMM-YYYY",
                })}/ ${DateFormat({ value: Date(), format: "hh:mm:ss a" })}`}
              </div>
              <div>
                <p className=" text-[11px] my-0 leading-[10px]">
                  {(review &&
                    review != "" &&
                    review != null &&
                    review != "null" &&
                    "Reviewed and Approved ") ||
                    ""}
                </p>
              </div>
            </div>
             </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
