import React from "react";
import infoCircle from "../../../Utilities/Image/icons/infoCircle.svg"




export default function ModelBox({
  visible,
  onClose,
  name="Test"
 
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-20 fixed z-50 inset-0  
    flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-4 rounded-xl w-4/12 animate-zoom-in">
        <div className=" flex justify-center items-center my-4">
            <img src={infoCircle} alt="#info" />
        </div>
          <div className=" w-full  text-gray-600">
            <p className=" font-semibold my-3  text-3xl text-center">{`Reset standard ${name||""} in progress…`}</p>
             <p className=" font-medium text-lg my-6 text-center">{`Standard ${name||""} information has been archived into Reports.`}</p>
          </div>
        </div>
      </div>
    </>
  );
}
