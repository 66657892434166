import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosComman from "../../../Services/axiosComman";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({
  data,
  visible,
  onClose,
  id,
  refreshData,
}) {
  const [isLoading,setIsLoading] = useState(false);
  const [createNew, setCreateNew] = useState({
    "header_no": "",
    "header_title": "",
  });

  const formInputs = [
    {
      inputType: "NuInput",
      name: "header_no",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Ex: 1",
      label: "Header No.",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 pr-4",
    },
    {
      inputType: "NuInput",
      name: "header_title",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Ex: Header",
      label: "Header",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-6/12 p1-4",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      header_no: yup.string().required("Header No Required"),
      header_title: yup.string().required("Header Required"),
    }),
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
      axiosComman.post(`/Headers/standard/${id}/activity-type/0/`, value).then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          resetForm();
          refreshData();
          onClose();
          Toast.fire({
            icon: "success",
            title: res.data.msg || "Header Created Successfully",
          });
        } else {
          setIsLoading(false);
          Toast.fire({
            icon: "error",
            title: res.data.msg || res.data.error || "Header Creation Unsuccessfully",
          });
        }
      }).catch((err)=>{
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: err?.response?.data.msg || err?.response?.data.error || "Header Creation Unsuccessfully",
        });
      });
    },
  });


  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
      formik.resetForm();
      onClose()
    };
  };

  const closeFunction = () =>{
    // setSelectedFile(null)
    formik.resetForm()
    onClose()
}

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-xs 2xl:text-sm font-semibold">
              {data ? "Edit Header" : "Add Header"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="h-[10rem]">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>

              <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button type="submit" className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
