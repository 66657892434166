import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import CreateorUpdate from "./form";
import EditImg from "../../../Utilities/Image/icons/edit-box-white.svg"
import { GetAccess } from "../../../Utilities/data/role";
import AdminSettingsLayout from "../index";

const fetcher = (url) => Axios.get(url).then((res) => res?.data?.data[0]);

function CompanyInfoIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [show,setShow] = useState(false);
  const { data, error, mutate } = useSWR(`/company-info/`, fetcher);
  const refreshData = () => {
    mutate(fetcher);
  };

  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Company Info
        </h4>
        <div className=" w-8">
          {
            GetAccess("admin_create") &&   
            <>       {
              data ? <span className="w-7 h-7 flex justify-center items-center overflow-hidden rounded-md  bg-[#7030A0] cursor-pointer" onClick={() => setShowMyModal(true)}>
               <img src={EditImg} width={15} alt="#" />
             </span>:        <svg
             className="w-7 ml-1 cursor-pointer"
             alt="plus"
             onClick={() => setShowMyModal(true)}
             title="Add"
             viewBox="0 0 52 52"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
           >
             <rect
               x="0.61084"
               y="0.335541"
               width="51.1569"
               height="51.1569"
               rx="5"
               fill="#7030A0"
             />
             <path
               d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
               stroke="white"
               stroke-width="2.3"
               stroke-linecap="round"
             />
             <title>Add</title>
           </svg>
           }
           </>
          }
        </div>


      </div>
      <div className=" w-full px-2  flex">
      <div className=" mx-auto  px-4">
          <div className=" w-[15rem] h-[15rem] rounded-md border-[2px] flex justify-center items-center border-primary overflow-hidden shadow-box bg-slate-200 ">
            {data?.companyLogo?<img src={data?.companyLogo} alt="#" width="100%" />:null }
          </div>
        </div>
        <div className=" w-9/12 px-4 flex flex-wrap">
          <div className=" w-full my-3">
            <h2 className=" text-xs">Company Name :</h2>
            <p className="  font-semibold text-sm">{data?.companyName||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Address :</h2>
            <p className=" font-semibold text-sm">{data?.address||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">City :</h2>
            <p className=" font-semibold text-sm">{data?.city||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Country :</h2>
            <p className=" font-semibold text-sm">{data?.country||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Website :</h2>
            <p className=" font-semibold text-sm">{data?.companyWebsite||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Email :</h2>
            <p className="  font-semibold text-sm">{data?.email||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Phone No :</h2>
            <p className="  font-semibold text-sm">{data?.mobile||"-"}</p>
          </div>
          {/* <p className=" text-base  font-semibold">SMTP Server/Mail Sever Details</p> */}
          {/* <div className=" w-full my-3">
            <h2 className=" text-xs">Server Name :</h2>
            <p className="  font-semibold text-sm">{data?.server_name||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Port :</h2>
            <p className="  font-semibold text-sm">{data?.port||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Email / User Name :</h2>
            <p className="  font-semibold text-sm">{data?.useremail||"-"}</p>
          </div>
          <div className=" w-full my-3">
            <h2 className=" text-xs">Password / Auth Key :</h2>
            <p className="  font-semibold text-sm">{show?data?.passwordOrAuthkey:"**************" ||"-"}</p>
            <input type="checkbox" onChange={(e)=>setShow(e.target.checked)} /><span className=" text-xs ml-2">Show Password / Auth Key</span>

          </div> */}
        </div>
      </div>

   {  data ?<CreateorUpdate
        data={data}
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        id={id}
      />:<CreateorUpdate
      onClose={handleOnClose}
      visible={showMyModal}
      refreshData={refreshData}
      id={id}
    />
    }
    </AdminSettingsLayout>
  );
}

export default CompanyInfoIndex;

