import React, { useEffect, useState } from 'react';
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DateFormat from '../../../Utilities/common/NuDate';
import axiosInstance from '../../../Services/axiosComman';

function DetailTaskView() {
    let history = useHistory();
    const { id,taskId } = useParams();
    const [data, setData] = useState({})
    const refreshData = () => {
        // mutate(fetcher);
        axiosInstance.get(`/Calender-Management/${taskId}`).then(res=>{
            console.log("res",res?.data?.data);
          res?.data?.data && setData(res?.data?.data)
        }).catch()
      };


    useEffect(() => {
        refreshData();
    },[])
  return (
    <div className="bg-background pb-16 h-[100vh]">
        <InnerNavbar id={id} />
        <div className="mx-12 flex items-center my-2">
        {/* <img src={home} alt="" className="w-3 2xl:w-5" /> */}
        <p className="ml-2 text-xs 2xl:text-sm text-secondary">
          Information /&nbsp;
          <span className="text-text font-medium">Calendar Management / view</span>
        </p>
      </div>
      <div className="w-[97.5%] bg-white shadow-main h-[86vh] rounded-2xl mx-4">
      <div className="py-3 px-5 w-6/12 lg:w-full flex items-center justify-between">
          <p className="text-xs 2xl:text-sm text-text">Task View</p>
          <div className=' w-[12%] flex items-center gap-4'>
          <Link to={`/information/calendar-management/${id}`}>  <p className=" text-[10px] text-blue-700 bg-gray-100 p-2 rounded shadow-box ">Calendar</p></Link>
          <Link to={`/information/task-management/${id}`}><p className=" text-[10px] text-blue-700 bg-gray-100 p-2 rounded shadow-box">Task</p></Link>
          <button className=" text-[12px] text-blue-700 bg-gray-100 p-2 rounded shadow-box" onClick={history.goBack}>Back</button>
          </div>
          </div>
        <div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Task
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {/* {data?.task || "-"} */}
                {data?.task_id == 1 ? "Implementation Checklist" :
                              data?.task_id == 2 ? "Internal Audit" :
                              data?.task_id == 3? "Maintenance" :
                              data?.task_id == 4? "Master List of Documents":
                              data?.task_id == 5? "CISO / MRM Meeting MoM":
                              data?.task_id == 6? "Audit Calendar":
                                "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Title
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.title || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Status
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.status || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Comments
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.comment || "-"}
              </p>
            </div>

            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Completion Date
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.completion_date !=="None" &&<DateFormat value={data.completion_date} format="DD-MMM-YYYY" /> || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Reminder Date
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
              {data?.reminder_date !=="None" &&<DateFormat value={data.reminder_date} format="DD-MMM-YYYY" /> || "-"}
               
              </p>
            </div>

          </div>
      </div>
    </div>
  )
}

export default DetailTaskView;