import React, { useState } from "react";
import Axios from "../../../Services/axiosComman";
import { useFormik } from "formik";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import useSWR from "swr";
// import * as yup from "yup";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CreateorUpdate from "./form";
import { GetAccess } from "../../../Utilities/data/role";
import ViewMore from "./viewMore";
// import SettingsLayout from "../../Settings";
import AdminSettingsLayout from "..";

const fetcher = (url) => Axios.get(url).then((res) => res.data?.data);

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function RoleSettingsIndex() {
  const { id } = useParams();
  const { data, error, mutate } = useSWR(`/Role-Permission/get_by_role`, fetcher);
  const refreshData = () => {
    mutate(fetcher);
  };
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [createNew, setCreateNew] = useState({
    role_name: "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    //  validationSchema:yup.object({
    //   roleName:yup.string()
    //                       .required("Frequency is requered"),
    // }),
    onSubmit: (value) => {
      formik.resetForm({ value: {} });
      axiosInstance.post("/Role/", value).then((res) => {
        if (res?.data?.status) {
          refreshData();
          //   onClose();
          Toast.fire({
            icon: "success",
            title: res?.data?.msg || "Role is Created",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: res?.data?.msg || "Role is not Created",
          });
        }
      });
    },
  });



  return (
    <AdminSettingsLayout>
      <div className="mt-5 w-full flex justify-between items-center px-4">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          User Roles
        </h4>
        {GetAccess('admin_create') &&
          <svg
          className="w-7 ml-1 cursor-pointer"
          alt="plus"
          onClick={() => setShowMyModal(true)}
          title="Add"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.61084"
            y="0.335541"
            width="51.1569"
            height="51.1569"
            rx="5"
            fill="#7030A0"
          />
          <path
            d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
            stroke="white"
            stroke-width="2.3"
            stroke-linecap="round"
          />
          <title>Add</title>
        </svg>
        }
      </div>
      <div className="w-10/12 mx-auto mt-5">
        {/* <form onSubmit={formik.handleSubmit}>
          <div className="w-full flex lg:justify-center">
            <MainForm formInputs={formInputs} formik={formik} />
            <div className="flex items-center mr-8 mt-7">
              <button
                type="submit"
                className="bg-primary hover:shadow-button text-white text-xs px-6 py-2 rounded-3xl"
              >
                Submit
              </button>
            </div>
          </div>
        </form> */}
        <div className="w-[60%] mt-2 border border-black mx-6 lg:mx-auto  px-3 rounded-lg">
          {data?.map((data, i) => (
            <div className="flex justify-between my-3" key={i}>
              <p className="text-xs py-1">{data?.roleName}</p>
              {/* <div className=" flex justify-between items-center">
              {GetAccess('admin_edit') &&<span
                  className="w-6 h-6 flex justify-center items-center overflow-hidden rounded-md mx-2 bg-[#d5b1f1] cursor-pointer"
                  onClick={() => setShowMyModal(true)}
                >
                  <img src={EditImg} width={15} alt="#" />
                </span>
                }
               {GetAccess('admin_edit') && <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                  onClick={() => {
                    handleDelete(data.roleId, data.roleName);
                  }}
                >
                  <rect
                    x="0.40332"
                    y="0.158203"
                    width="23.874"
                    height="23.874"
                    rx="5"
                    fill="#FFE1E1"
                  />
                  <path
                    d="M8.25552 16.861C8.25552 17.6099 8.86825 18.2226 9.61714 18.2226H15.0636C15.8125 18.2226 16.4253 17.6099 16.4253 16.861V10.0529C16.4253 9.304 15.8125 8.69127 15.0636 8.69127H9.61714C8.86825 8.69127 8.25552 9.304 8.25552 10.0529V16.861ZM16.4253 6.64883H14.7232L14.2399 6.16545C14.1173 6.04291 13.9403 5.96802 13.7633 5.96802H10.9175C10.7405 5.96802 10.5635 6.04291 10.4409 6.16545L9.95755 6.64883H8.25552C7.88107 6.64883 7.57471 6.9552 7.57471 7.32964C7.57471 7.70409 7.88107 8.01045 8.25552 8.01045H16.4253C16.7997 8.01045 17.1061 7.70409 17.1061 7.32964C17.1061 6.9552 16.7997 6.64883 16.4253 6.64883Z"
                    fill="#FF5252"
                  />
                </svg>}
              </div> */}
                        <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data}
                        fetcher={refreshData}
                      />
            </div>
          ))}
        </div>
      </div>
      <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={refreshData}
          // data={data}
          id={id}
        />
    </AdminSettingsLayout>
  );
}

export default RoleSettingsIndex;
