import React, { useEffect, useState } from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DateFormat from '../../../../Utilities/common/NuDate';
import { OnDownload } from '../../../../Utilities/data/render';

function VendorXlsx({fileName,data ,status,standardname="",review,Name,startDate,endDate,company,Module,standardId,updateLenth}) {
    const [csvData,setCSVData] = useState([])


    useEffect(()=>{
        if(data){
            let finalData = []
            data && data.map((item,index)=>{
                updateLenth(index+1)
                let tempData = {
                    "Vendor Name":item?.vendor_name||"",
                    "Vendor Type":item?.vendor_type||"",
                    "Documents Type - NDA, SLA, Contract":item?.attachment==true?"Yes":"",
                    "OnBoard Date":item?.on_board_date!=="None"&&item?.on_board_date!==""&&`${DateFormat({value:item?.on_board_date, format:"DD-MMM-YYYY"})}`||"",
                    "Last Update by":item?.last_modified_name || item?.created_name || item?.created_by_name || "",
                }
                finalData.push(tempData);
            })
            setCSVData(finalData);
            
        }
    },[data])

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      OnDownload(standardId,Module)

    };
    return (
      // <div onClick={()=>exportToCSV(csvData,fileName)}>ExportXls</div>
      <button onClick={()=>exportToCSV(csvData,fileName)}>Download</button>
    )
}

export default VendorXlsx;