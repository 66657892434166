import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function BackButton() {
  let history = useHistory()
  return  <button className='text-primary border border-primary px-3 py-[2px] text-sm font-medium rounded-md flex items-center' onClick={history.goBack}>
    <span className="mr-2 flex justify-center items-center">
  <svg
    width="10"
    height="10"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.898 7.70773H4.42869L9.43946 2.69696C9.83991 2.29651 9.83991 1.63936 9.43946 1.23891C9.34447 1.14372 9.23163 1.0682 9.10742 1.01668C8.9832 0.965151 8.85004 0.938629 8.71557 0.938629C8.58109 0.938629 8.44793 0.965151 8.32372 1.01668C8.1995 1.0682 8.08667 1.14372 7.99167 1.23891L1.22509 8.0055C1.1299 8.10049 1.05438 8.21333 1.00285 8.33754C0.951327 8.46176 0.924805 8.59491 0.924805 8.72939C0.924805 8.86387 0.951327 8.99703 1.00285 9.12124C1.05438 9.24546 1.1299 9.35829 1.22509 9.45328L7.99167 16.2199C8.08674 16.3149 8.19959 16.3903 8.3238 16.4418C8.448 16.4932 8.58113 16.5197 8.71557 16.5197C8.85001 16.5197 8.98313 16.4932 9.10733 16.4418C9.23154 16.3903 9.3444 16.3149 9.43946 16.2199C9.53452 16.1248 9.60993 16.012 9.66138 15.8877C9.71282 15.7635 9.7393 15.6304 9.7393 15.496C9.7393 15.3615 9.71282 15.2284 9.66138 15.1042C9.60993 14.98 9.53452 14.8672 9.43946 14.7721L4.42869 9.76132H15.898C16.4627 9.76132 16.9248 9.29926 16.9248 8.73452C16.9248 8.16979 16.4627 7.70773 15.898 7.70773Z"
      fill="#048178"
    />
  </svg>
</span>
    Back
    </button>
}

export default BackButton