import React, { useEffect, useState } from "react";


export const imagetype = ["png", "jpg", "jpeg","PNG","gif","webp","jfif"];
export const filetype = ["docx","doc", "xlsx", "pdf", "txt","pptx"];

function FileView({ id, visible, onClose, data, path,refreshData }) {
  let type = path?.split(".").reverse()[0];
  let ImgName = path?.split("/").reverse()[0];
  const handleOnClose = (e) => {
    refreshData&& refreshData();
    if (e.target.id === "modal") onClose();
  };
  if (!visible) return null;
  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
    >
      <div className="bg-white p-2 rounded w-10/12 h-[95vh] ">
        <div className="flex items-start justify-between px-5 py-2  rounded-t">
          <h3 className="text-xs 2xl:text-sm font-semibold">
            {/* {data.filePath ? data?.filePath: data?.name} */}
            {ImgName}
          </h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={onClose}
          >
            {/* <img src={Close} alt="close" title="close" /> */}
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.842773"
                y="0.560791"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
              <path
                d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>close</title>
            </svg>
          </span>
        </div>
        <div className=" overflow-x-hidden overflow-y-auto h-[87vh]">
          {filetype?.includes(type) ? (
            <iframe 
              src={path}
              width="100%" height="100%" type="text">

              </iframe>
            
          ) : null}
          {imagetype?.includes(type) ? (
            <img
            src={path}
              className='m-auto'
              alt="file not found"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default FileView;
