import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useEffect } from "react";
import axiosInstance from "../../../Services/axiosComman";
import { useState } from "react";
import Logo from "../../../Utilities/Image/img/logo.jpg";
import DateFormat from "../../../Utilities/common/NuDate";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import MyDoc from "./reportPDF";
import StandardLayout from "../../../Utilities/Layout/StandardLayout";
import Breadcrumbs from "../../../Utilities/common/Breadcrumbs";
import BackButton from "../../../Utilities/common/BackButton";
import PicPDF from "./TestPic";
import ChartToImage from "./ChartToImg";
// import { NuDate } from "../../../Utilities/common/Input";

//   console.log("teett",DateT);

function OverAllProgressIndex() {
  const { id } = useParams();
  let location = useLocation();

  const componentRef = useRef();
  const pdfRef = useRef();
  const [standardname, setStandardName] = useState("");
  const [implementation, setImplementation] = useState("");
  const [internal, setInternal] = useState("");
  const [maintenance, setMaintenance] = useState("");
  const [masterListofDocument, setMasterListofDocument] = useState("");
  const [mom, setMom] = useState("");
  const [company, setCompany] = useState([]);
  const [securityIncident, setSecurityIncident] = useState([]);
  const [auditCalendar, setauditCalendar] = useState([]);
  const [trainingCalendar, setTrainingCalendar] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [managementReview, setMaintenanceReview] = useState([]);
  const [standard, setStandard] = useState([]);

  function getStandard() {
    axiosInstance
      .get(`Standards/${id}`)
      .then((res) => {
        setStandardName(res?.data?.data.name);
        res?.data?.data.start_date && setStartDate(res?.data?.data.start_date);
        res?.data?.data.end_date && setEndDate(res?.data?.data.end_date);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function getAllData() {
    await axiosInstance
      .post(`/report/overall`, {
        standard_id: id,
        from_date: location.state?.reportValue?.start_date,
        to_date:
          DateFormat({
            value: location.state?.reportValue?.reset_date,
            format: "YYYY-MM-DD",
          }) ||
          DateFormat({
            value: location.state?.reportValue?.end_date,
            format: "YYYY-MM-DD",
          }) ||
          "",
        type: location.state?.reportValue?.reset_count || 0,
      })
      .then((res) => {
        console.log("dd", res?.data?.data);
        setImplementation(res?.data?.data?.implementation_checklist[0] || "");
        setInternal(res?.data?.data?.internal_audit[0] || "");
        setMaintenance(res?.data?.data?.maintenance[0] || "");
        setMasterListofDocument(res?.data?.data?.master_list[0] || "");
        setMom(res?.data?.data?.mom[0] || "");
        setSecurityIncident(res?.data?.data?.security_incident[0] || "");
        setauditCalendar(res?.data?.data?.audit_schedule[0] || "");
        setTrainingCalendar(res?.data?.data?.training_calender[0] || "");
        setVendor(res?.data?.data?.vendor || "");
        setMaintenanceReview(res?.data?.data?.management_review[0] || "");
      });
  }
  // async function getInternal() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/internal_audit/`)
  //     .then((res) => {
  //       setInternal(res?.data?.data);
  //     });
  // }

  // async function getMaintenance() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/maintenance/`)
  //     .then((res) => {
  //       setMaintenance(res?.data?.data);
  //     });
  // }

  // async function getMasterListofDocument() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/master_list_of_document/`)
  //     .then((res) => {
  //       // setMom(res?.data?.data);
  //       setMasterListofDocument(res?.data?.data);
  //       // console.log("ccc",res?.data?.data);
  //     });
  // }

  // async function getMom() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/minutes_of_meeting/`)
  //     .then((res) => {
  //       // setMom(res?.data?.data);
  //       setMom(res?.data?.data);
  //       // console.log("ccc",res?.data?.data);
  //     });
  // }

  // async function getSecurityIncident() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/security-incident/`)
  //     .then((res) => {
  //       // setMom(res?.data?.data);
  //       setSecurityIncident(res?.data?.data);
  //       // console.log("ccc",res?.data?.data);
  //     });
  // }

  // async function getAuditCalendar() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/audit_calender/`)
  //     .then((res) => {
  //       // setMom(res?.data?.data);
  //       setauditCalendar(res?.data?.data);
  //       // console.log("ccc",res?.data?.data);
  //     });
  // }

  // async function getTrainingCalendar() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/training-calender/`)
  //     .then((res) => {
  //       // setMom(res?.data?.data);
  //       setTrainingCalendar(res?.data?.data);
  //       // console.log("ccc",res?.data?.data);
  //     });
  // }

  // async function getVendor() {
  //   await axiosInstance
  //     .get(`/DashBoard/standard/${id}/vendor/`)
  //     .then((res) => {
  //       // setMom(res?.data?.data);
  //       setVendor(res?.data?.data);
  //       // console.log("ccc",res?.data?.data);
  //     });
  // }

  async function getCompanyInfo() {
    await axiosInstance.get(`/company-info/`).then((res) => {
      setCompany(res?.data?.data[0]);
      console.log("ccc", res?.data?.data[0]);
    });
  }

  function getStandardWise() {
    axiosInstance
      .get(`/DashBoard/standard/standard_wise`)
      .then((res) => {
        res.data && setStandard(res.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getStandardWise();
    getStandard();
    getCompanyInfo();
    getAllData();
  }, []);

  const [documentImage, setDocumentImage] = useState([]);
  const [implementationImage, setImplementationImage] = useState([]);
  const [masterImage, setMasterImage] = useState([]);
  const [mrmImage, setMrmImage] = useState([]);
  const [auditImage, setAuditImage] = useState([]);
  const [internalImage, setInternalImage] = useState([]);
  const [securityImage, setSecurityImage] = useState([]);
  const [trainingImage, setTrainingImage] = useState([]);
  const [maintenanceImage, setMaintenanceImage] = useState([]);
  const [managementImage, setManagementImage] = useState([]);

  const [loading, setLoading] = useState(true);

  const data = {
    labels: ["Red", "Blue", "Yellow"],
    values: [300, 50, 100],
  };

  const handleCapture = (imgData, state, setState) => {
    const newState = [...state, { value: "test", img: imgData }];
    // console.log("final",newState);
    // setState(newState);
    // setLoading(false);
  };

  const handleCaptureDocument = (imgData, standard) => {
    const newState = [...documentImage, { value: standard, img: imgData }];
    if (documentImage.length > 0) {
      setDocumentImage(newState);
    } else {
      setDocumentImage([{ value: standard, img: imgData }]);
    }
    console.log("final Doc", newState);
    console.log("stand Name", standard);
    // setState(newState);
    // setLoading(false);
  };

  return (
    <StandardLayout>
      <div className=" w-[97.5%] flex justify-between items-center">
        <Breadcrumbs title="Reports " label="Overall Progress" />
        <BackButton />
      </div>

      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <PrintData
            company={company}
            standardname={standardname}
            implementation={implementation}
            internal={internal}
            maintenance={maintenance}
            masterListofDocument={masterListofDocument}
            mom={mom}
            securityIncident={securityIncident}
            auditCalendar={auditCalendar}
            trainingCalendar={trainingCalendar}
            vender={vendor}
            managementReview={managementReview}
            endDate={endDate}
            startDate={startDate}
          />
        </div>
      </div>

      {/* <div>
        {
          standard && standard.map((value,index)=>{
            let implementationData = {
              labels: ["Yet to Start", "In Progress", "Completed"],
              values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
                       value.implementation_checklist?.implementation_in_progress_percentage||0, 
                       value.implementation_checklist?.implementation_complete_percentage||0],
            }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            // let implementationData = {
            //   labels: ["Yet to Start", "In Progress", "Completed"],
            //   values: [value.implementation_checklist?.implementation_not_yet_start_percentage||0,
            //            value.implementation_checklist?.implementation_in_progress_percentage||0, 
            //            value.implementation_checklist?.implementation_complete_percentage||0],
            // }
            console.log("called again",index);
            return (
              <>
                      
        <ChartToImage
          data={data}
          onCapture={(e) => handleCaptureDocument(e,value?.name)}
        />

              </>
            )
          })
        }
      </div> */}

      <div className="w-[97.5%] bg-white shadow-main overflow-hidden h-[calc(100vh-135px)] rounded-2xl mx-4">
        <div ref={pdfRef} className=" h-full overflow-y-auto">
          <div className="py-3 pl-5 flex justify-between items-center w-[98.5%]">
            <div className=" w-16 overflow-hidden">
              <img src={Logo} alt="#" />
            </div>
            <div className=" text-center">
              <p className="text-[13px] 2xl:text-[15px] text-text font-semibold capitalize">
                {company?.companyName || ""}
              </p>
              <p className="text-xs 2xl:text-sm text-text">
                Progress Status of Standard : {standardname || "-"}
              </p>
            </div>
            <div className="flex gap-3 w-14 ">
              <ReactToPrint
                trigger={() => (
                  <button>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="22px"
                      width="22px"
                      //   {...props}
                    >
                      <path d="M2.5 8a.5.5 0 100-1 .5.5 0 000 1z" />
                      <path d="M5 1a2 2 0 00-2 2v2H2a2 2 0 00-2 2v3a2 2 0 002 2h1v1a2 2 0 002 2h6a2 2 0 002-2v-1h1a2 2 0 002-2V7a2 2 0 00-2-2h-1V3a2 2 0 00-2-2H5zM4 3a1 1 0 011-1h6a1 1 0 011 1v2H4V3zm1 5a2 2 0 00-2 2v1H2a1 1 0 01-1-1V7a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1h-1v-1a2 2 0 00-2-2H5zm7 2v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-3a1 1 0 011-1h6a1 1 0 011 1z" />
                    </svg>
                  </button>
                )}
                content={() => componentRef.current}
                pageStyle={`
                  @page {
                    size: landscape;
                    margin-left: 3mm;
                    margin-right: 3mm;
                    margin-top: 0;
                    margin-bottom: 15mm;
                    padding-bottom:10mm;
                  }
                  @media print {
                    body {
                      -webkit-print-color-adjust: exact;
                      print-color-adjust: exact;
                      font-family: Arial, sans-serif;
                      counter-reset: page;
                    }
                `}
              />

              {/* <BlobProvider
                document={
                  <MyDoc
                    company={company}
                    standardname={standardname}
                    implementation={implementation}
                    internal={internal}
                    maintenance={maintenance}
                    masterListofDocument={masterListofDocument}
                    mom={mom}
                    securityIncident={securityIncident}
                    auditCalendar={auditCalendar}
                    trainingCalendar={trainingCalendar}
                    vender={vendor}
                    endDate={endDate}
                    startDate={startDate}
                    managementReview={managementReview}
                  />
                }
                fileName={`${standardname} - Overall  Progress Report ${
                  location.state?.reportValue?.start_date &&
                  DateFormat({
                    value: location.state?.reportValue?.start_date,
                    format: "MMM-YYYY",
                  })
                } ${
                  (location.state?.reportValue?.reset_date ||
                    location.state?.reportValue?.end_date) &&
                  "to"
                } ${
                  (location.state?.reportValue?.reset_date ||
                    location.state?.reportValue?.end_date) &&
                  DateFormat({
                    value:
                      location.state?.reportValue?.reset_date ||
                      location.state?.reportValue?.end_date,
                    format: "MMM-YYYY",
                  })
                } .pdf`}
              >
                {({ url, blob }) => (
                  <a href={url} target="_blank">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="22px"
                      width="22px"
                      //   {...props}
                    >
                      <path d="M2.5 8a.5.5 0 100-1 .5.5 0 000 1z" />
                      <path d="M5 1a2 2 0 00-2 2v2H2a2 2 0 00-2 2v3a2 2 0 002 2h1v1a2 2 0 002 2h6a2 2 0 002-2v-1h1a2 2 0 002-2V7a2 2 0 00-2-2h-1V3a2 2 0 00-2-2H5zM4 3a1 1 0 011-1h6a1 1 0 011 1v2H4V3zm1 5a2 2 0 00-2 2v1H2a1 1 0 01-1-1V7a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-1 1h-1v-1a2 2 0 00-2-2H5zm7 2v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-3a1 1 0 011-1h6a1 1 0 011 1z" />
                    </svg>
                  </a>
                )}
              </BlobProvider> */}

              <PDFDownloadLink
                document={
                  <MyDoc
                    company={company}
                    standardname={standardname}
                    implementation={implementation}
                    internal={internal}
                    maintenance={maintenance}
                    masterListofDocument={masterListofDocument}
                    mom={mom}
                    securityIncident={securityIncident}
                    auditCalendar={auditCalendar}
                    trainingCalendar={trainingCalendar}
                    vender={vendor}
                    endDate={endDate}
                    startDate={startDate}
                    managementReview={managementReview}
                  />
                }
                fileName={`${standardname} - Overall  Progress Report ${
                  location.state?.reportValue?.start_date &&
                  DateFormat({
                    value: location.state?.reportValue?.start_date,
                    format: "MMM-YYYY",
                  })
                } ${
                  (location.state?.reportValue?.reset_date ||
                    location.state?.reportValue?.end_date) &&
                  "to"
                } ${
                  (location.state?.reportValue?.reset_date ||
                    location.state?.reportValue?.end_date) &&
                  DateFormat({
                    value:
                      location.state?.reportValue?.reset_date ||
                      location.state?.reportValue?.end_date,
                    format: "MMM-YYYY",
                  })
                } .pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <span className=" w-4 h-4 overflow-hidden">.....</span>
                  ) : (
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="22px"
                      width="22px"
                      //   {...props}
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 00.161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 00-.46-.477c-.2-.12-.443-.179-.732-.179zm.545 1.333a.795.795 0 01-.085.38.574.574 0 01-.238.241.794.794 0 01-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 00.595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 00-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 01.354.454c.079.201.118.452.118.753a2.3 2.3 0 01-.068.592 1.14 1.14 0 01-.196.422.8.8 0 01-.334.252 1.298 1.298 0 01-.483.082h-.563v-2.707zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896z"
                      />
                    </svg>
                  )
                }
              </PDFDownloadLink>
              {/* {loading && (
                <PDFDownloadLink
                  document={
                    <PicPDF
                      data={standard}
                      company={company}
                      standardname={standardname}
                      endDate={endDate}
                      startDate={startDate}
                      documentImage={documentImage}
                      implementationImage={implementationImage}
                      masterImage={masterImage}
                      mrmImage={mrmImage}
                      auditImage={auditImage}
                      internalImage={internalImage}
                      securityImage={securityImage}
                      trainingImage={trainingImage}
                      maintenanceImage={maintenanceImage}
                      managementImage={managementImage}
                    />
                  }
                  fileName={`testttt.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "..." : <p>TEST</p>
                  }
                </PDFDownloadLink>
              )} */}
            </div>
          </div>
          <div className=" w-[96%] h-full mx-auto">
            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-3 xl:text-[15px]  font-medium text-[#1D1818]">
                  Implementation Checklist :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  implementation?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  implementation?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  implementation?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  implementation?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-3 xl:text-[15px]  font-medium text-[#1D1818]">
                  Internal Audit :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  internal?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  internal?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  internal?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  internal?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-3 xl:text-[15px]  font-medium text-[#1D1818]">
                  Maintenance :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  maintenance?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  maintenance?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  maintenance?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  maintenance?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  Master List of Documents :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  masterListofDocument?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  masterListofDocument?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  masterListofDocument?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  masterListofDocument?.verified_and_approved
                    ? "Yes"
                    : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  CISO / MRM meetings :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  mom?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  mom?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  mom?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  mom?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  Security Incidents :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  securityIncident?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  securityIncident?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  securityIncident?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  securityIncident?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  Audit Calendar :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  auditCalendar?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  auditCalendar?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  auditCalendar?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  auditCalendar?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  Training Calendar :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  trainingCalendar?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  trainingCalendar?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  trainingCalendar?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  trainingCalendar?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  Vendor Management :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Total Vendors  - ${
                  vendor || 0
                }`}</p>
              </div>
            </div>

            <div
              className=" pb-6"
              style={{ borderBottom: "1px solid #E1DFEC" }}
            >
              <div className=" px-3">
                <h1 className=" text-[13px] my-4 xl:text-[15px]  font-medium text-[#1D1818]">
                  Management Review :
                </h1>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                  managementReview?.not_yet_start_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                  managementReview?.in_progress_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                  managementReview?.complete_percentage || 0
                } %`}</p>
                <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                  managementReview?.verified_and_approved ? "Yes" : "No" || "No"
                }`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </StandardLayout>
  );
}

export default OverAllProgressIndex;

function PrintData({
  company,
  standardname,
  implementation,
  internal,
  maintenance,
  masterListofDocument,
  mom,
  securityIncident,
  auditCalendar,
  trainingCalendar,
  vender,
  managementReview,
  startDate,
  endDate
}) {
  return (
    <div className="w-[97.5%] bg-white ">
      <div className=" h-full overflow-y-auto">
        <div className="py-3 pl-5 flex justify-between items-center w-[98.5%]">
          <div className=" w-16 overflow-hidden">
            <img src={Logo} alt="#" />
          </div>
          <div className=" text-center">
            <p className="text-[13px] 2xl:text-[15px] text-text font-semibold capitalize">
              {company?.companyName || ""}
            </p>
            <p className="text-xs 2xl:text-sm text-[#6E6985]">
              Progress Status of Standard : {standardname || "-"}
            </p>
          </div>
          <div className="flex gap-3 text-[10px] text-[#6E6985]">
            {/* <DateFormat value={new Date()} format="DD-MMM-YYYY" /> /{" "} */}
            {/* <DateFormat value={new Date()} format="hh:mm" /> */}
          </div>
        </div>
        <div className=" w-[96%] h-full mx-auto">

          <table className=" w-full">
            <thead className=" h-4 bg-red-300">

            </thead>
            <tbody className=" w-full h-[90%]">
            <tr colspan="6" className=" w-full block px-4">
             <td colspan="8 " className=" w-full block ">

             <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Implementation Checklist :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                implementation?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                implementation?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                implementation?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                implementation?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Internal Audit :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                internal?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                internal?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                internal?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                internal?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Maintenance :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                maintenance?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                maintenance?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                maintenance?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                maintenance?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Master List of Documents :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                masterListofDocument?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                masterListofDocument?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                masterListofDocument?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                masterListofDocument?.verified_and_approved
                  ? "Yes"
                  : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                CISO / MRM meetings :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                mom?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                mom?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                mom?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                mom?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Security Incidents :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                securityIncident?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                securityIncident?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                securityIncident?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                securityIncident?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Audit Calendar :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                auditCalendar?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                auditCalendar?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                auditCalendar?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                auditCalendar?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Training Calendar :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                trainingCalendar?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                trainingCalendar?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                trainingCalendar?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                trainingCalendar?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Vendor Management :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Total Vendors  - ${
                vender || 0
              }`}</p>
            </div>
          </div>

          <div className=" pb-6" style={{ borderBottom: "1px solid #E1DFEC" }}>
            <div className=" px-3">
              <h1 className=" text-[13px] my-2 xl:text-[15px]  font-medium text-[#1D1818]">
                Management Review :
              </h1>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Yet to Start  - ${
                managementReview?.not_yet_start_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`In-Progress  - ${
                managementReview?.in_progress_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Completed  - ${
                managementReview?.complete_percentage || 0
              } %`}</p>
              <p className=" font-semibold text-[12px]  xl:text-sm text-[#6E6985]">{`Reviewed & Approved  - ${
                managementReview?.verified_and_approved ? "Yes" : "No" || "No"
              }`}</p>
            </div>
          </div>
             </td>
             </tr>
            </tbody>
            <tfoot className=" h-8 fixed -bottom-3 w-[96%] ">
            <tr colspan="6" className=" w-full block px-4">
             <td colspan="8 " className=" w-full block ">
             <div className=" text-gray-600   flex justify-between items-end w-full">
              <div className=" w-[33.33%]">
                <p className=" text-[11px] my-0 leading-[10px]">
                  Certification Calendar Tracker
                </p>
                <p className=" text-[11px] my-0 leading-[10px]">
                  {`${
                    (startDate &&
                      startDate != "None" &&
                      startDate != "" &&
                      startDate != null &&
                      DateFormat({
                        value: startDate,
                        format: "DD-MMM-YYYY",
                      })) ||
                    ""
                  } ${
                    (startDate &&
                      startDate != "None" &&
                      startDate != "" &&
                      startDate != null &&
                      "/") ||
                    ""
                  } ${
                    (endDate &&
                      endDate != "None" &&
                      endDate != "" &&
                      endDate != null &&
                      DateFormat({ value: endDate, format: "DD-MMM-YYYY" })) ||
                    ""
                  }`}
                </p>
              </div>
              <div className=" w-[33.33%] text-center text-[11px]">
                {`${DateFormat({
                  value: Date(),
                  format: "DD-MMM-YYYY",
                })}/ ${DateFormat({ value: Date(), format: "hh:mm:ss a" })}`}
              </div>
              <div className=" w-[33.33%] h-2">
                {/* <p className=" text-[11px] my-0 leading-[10px]">
                  {(review &&
                    review != "" &&
                    review != null &&
                    review != "null" &&
                    "Reviewed and Approved ") ||
                    ""}
                </p> */}
              </div>
            </div>
             </td>
            </tr>
          </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
