import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosComman from "../../../Services/axiosComman";
import FileDisplay from "../../../Utilities/common/FileDisplay";
import { ScreenClose } from "../../../Utilities/data/render";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({
  data,
  visible,
  onClose,
  refreshData,
  id,
  headerId,
  status,
  fetcherHeader, 
  sectionNo,
  getImplementation
}) {
  // const handleOnClose = (e) => {
  //   if (e.target.id === "modal") onClose();
  // };
  const [isLoading,setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    section_no: "",
    section: "",
    sub_section_no: "",
    sub_section: "",
    optional_sub_section_no: "",
    optional_sub_section: "",
    question_activity: "",
    user_response: "",
    user_comment: "",
    completion_date: "0000-00-00",
    reminder_date: "0000-00-00",
    status: "",
  });
  useEffect(() => {
    setCreateNew({
      section_no: headerId?.section_no || "",
      section: headerId?.section || "",
      sub_section_no: "",
      sub_section: "",
      question_activity: "",
      user_response: "",
      user_comment: "",
      completion_date: "",
      reminder_date: "",
      status: "",
    });
  }, [headerId]);
  useEffect(() => {
    setCreateNew({
      section_no: data?.section_no,
      section: data?.section,
      sub_section_no: data?.sub_section_no,
      sub_section: data?.sub_section,
      question_activity: data?.question_activity,
      user_response: data?.user_response,
      completion_date: data?.completion_date,
      user_comment: data?.user_comment,
      reminder_date: data?.reminder_date,
      status: data?.status,
    });
    if(data?.image_path !==null && data?.image_path !=="None" && data?.image_path !=="[]"){
      setFilePathList(data?.image_path)
      setRemoveFilePathList(data?.image_path)
    }
  }, [data]);


  function handleImageChange(e) {

    const files = Array.from(e.target.files);
    setSelectedFile(files);
  }
  const formInputs = [
    {
      inputType: "NuInput",
      name: "section_no",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Section NO.",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: " w-full lg:w-6/12",
      disabled: data ? true : (headerId?.section_no ? true : false)
    },
    {
      inputType: "NuInput",
      name: "section",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Section",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
      disabled: data ? true : (headerId?.section ? true : false)
    },
    {
      inputType: "NuInput",
      name: "sub_section_no",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Sub-section NO.",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "sub_section",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Sub-section",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuTextArea",
      name: "question_activity",
      classname: "border w-full h-20 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Questions / Activity",
      labelclassname: "w-full",
      style: "w-full",
      customClass: "w-full",
      rows: "6",
      cols: "50",
    },
    {
      inputType: "NuInput",
      name: "user_response",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "User Response",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
      maxLength:25,
    },
    {
      inputType: "NuInput",
      name: "completion_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Completion Date",
      labelclassname: "w-full",
      type: "date",
      customClass: "w-full lg:w-6/12",
    },

    {
      inputType: "NuTextArea",
      name: "user_comment",
      classname: "border w-full h-20 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "User Comment",
      labelclassname: "w-full",
      style: "w-full",
      customClass: "w-full ",
      rows: "6",
      cols: "50",
      maxLength:50
    },
    {
      inputType: "NuSelect",
      name: "status",
      ClassName: "w-full h-10 px-3 mt-2",
      placeholder: "Select status",
      label: "Status",
      Options:
        // [{value:'open',label:"Status 1"},{value:'closed',label:"Status 2"}],
        status?.map((i) => {
          return {
            value: i.status_type,
            label: i.status_type,
          };
        }),
      customClass: "w-full lg:w-6/12",
      labelclassname: "w-full",
    },
    {
      inputType: "NuInput",
      name: "reminder_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Set Remainder Date",
      labelclassname: "w-full",
      type: "date",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "imagePathOne",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Attachment Artefact",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleImageChange,
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (value,{resetForm}) => {
      setIsLoading(true);
      if (data?.id) {
        // closeEdit(data?.id)
        if(removefilePathList){
          let finalArr = removefilePathList.filter(items=>  !filePathList.includes(items) )
          value.files_to_remove = finalArr.map(item=>item.replace(window.WEB_APP_API_URL, "localhost"));
        }else{
          value.files_to_remove = []
        }
        let reqObjs = new FormData();
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            reqObjs.append("attachment_artifact", file);
          });
        }else{
          reqObjs.append("attachment_artifact",[])
        } 
        value.status =
          value.status !== null ? value?.status?.toLowerCase() : value?.status;
        value.completion_date =
          value.completion_date !== "None" ? value.completion_date : null;
        value.reminder_date =
          value.reminder_date !== "None" ? value.reminder_date : null;
        reqObjs.append("implementation_data", JSON.stringify(value));
        axiosComman.put(`implementation-checklist/implementation-list/${data?.id}/screen_closed`,{"isClosed":false}).then(res=>{
          axiosComman
          .put(
            `/implementation-checklist/implementation-list/${data.id}/`,
            reqObjs
          )
          .then((res) => {
            if (res.data.status) {
              refreshData();
              // fetcher()
              fetcherHeader()
              setSelectedFile(null);
              setIsLoading(false);
              resetForm()
              getImplementation()
              Toast.fire({
                icon: "success",
                title:
                  res.data.msg ||
                  "implementationChecklist Updated Successfully",
              });
              
              onClose();
            } else {
              setIsLoading(false)
              Toast.fire({
                icon: "error",
                title:
                  res.data.msg ||
                  "implementationChecklist Update Unsuccessfully",
              });
            }
          })
          .catch((err) => {
            setIsLoading(false)
              Toast.fire({
                icon: "error",
                title: err?.response?.data.msg || err?.response?.data.error ||"implementationChecklist Update Unsuccessfully",
              });
          });
        }).catch(err=>{
          setIsLoading(false);
          console.log("err")
        })

      } else {
        let reqObjs = new FormData();
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            reqObjs.append("attachment_artifact", file);
          });
        } 
        value.status = value.status ? value.status : "";
        reqObjs.append("implementation_data", JSON.stringify(value));

        axiosComman
          .post(
            `/implementation-checklist/standard/${id}/header/${headerId?.header_id}/`,
            reqObjs
          )
          .then((res) => {
            // alert("ssss")
            if (res.data.status) {
              setIsLoading(false)
              // fetcherHeader()
              getImplementation()
              refreshData();
              fetcherHeader()
              setSelectedFile(null);
              resetForm()
              // fetcherHeader(sectionNo);
              Toast.fire({
                icon: "success",
                title: res.data.msg ||"implementationChecklist Add Successfully",
              });
              onClose()
            } else {
              setIsLoading(false)
              Toast.fire({
                icon: "error",
                title:
                  res.data.msg ||"implementationChecklist Added Unsuccessfully",
              });
            }
          }).catch((err) => {
            // alert("Ss")
            setIsLoading(false)
              Toast.fire({
                icon: "error",
                title: err?.response?.data.msg || err?.response?.data.error ||"implementationChecklist Added Unsuccessfully",
              });
          });
      }
    },
  });

  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }


  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
    setSelectedFile(null)
      formik.resetForm();
      onClose()
    };
  };

  const closeFunction = () =>{
    setSelectedFile(null)
    formik.resetForm()
    onClose()
}


  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-xs 2xl:text-sm font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
              {/* <img src={Close} alt="close" title="close" /> */}
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="overflow-y-scroll h-[26rem]">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
                <div className=" w-full flex flex-wrap my-3">
                {   filePathList &&
                    filePathList != null &&
                    filePathList != "None" &&
                    filePathList.length>0  && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
              </div>
              {/* {data?.image_path && (<FileDisplay data={data?.image_path} />)} */}
              <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
