import React, { useState } from "react";
// import AdminLayout from "../index";
import CreateOrUpdate from "./form";
import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
import ViewMore from "./viewMore";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import StandardSettingLayout from "..";

const fetcher = (url) => Axios.get(url).then((res) => res.data?.data);

function ApprovalStatusSettingIndex() {
  const {id} = useParams();
  // const fetcher = (url) => Axios.get(url).then((res) => res.data);
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const { data, error, mutate } = useSWR(`/review_and_approved/${id}`, fetcher);
  const refreshData = () => {
    mutate(fetcher);
  };
  return (
    <StandardSettingLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Approval Status
        </h4>
        <div className=" w-8">
          {
            (GetRole()||GetAccess("review_and_approve")) &&  
        <svg
          className="w-7 ml-1 cursor-pointer"
          alt="plus"
          onClick={() => setShowMyModal(true)}
          title="Add"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.61084"
            y="0.335541"
            width="51.1569"
            height="51.1569"
            rx="5"
            fill="#7030A0"
          />
          <path
            d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
            stroke="white"
            stroke-width="2.3"
            stroke-linecap="round"
          />
          <title>Add</title>
        </svg>
}</div>
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-4 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[calc(100vh-225px)] shadow-box">
                {data?.map((data, i) => (
                  <div className="">
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[15%]">
                        {data.serial_number}
                      </td>
                      <td className="text-center text-gray-900 w-[40%] ">
                        {data.module_id === 1
                          ? "Implementation Checklist"
                          : data.module_id === 2
                          ? "Internal Audit Checklist"
                          : data.module_id === 3
                          ? "Maintenance"
                          : data.module_id === 4
                          ? "Master List of Documents"
                          : data.module_id === 5
                          ? "CISO /  ISF Meeting MoM"
                          : data.module_id === 6
                          ? "Audit Schedule"
                          : data.module_id === 7
                          ? "Standard Document"
                          : data.module_id === 8
                          ? "Explanation of Standard"
                          : data.module_id === 9
                          ? "Benefits of standard"
                          : data.module_id === 10
                          ? "Task Management"
                          : data.module_id === 11
                          ? "Vendor Management"
                          : data.module_id === 12
                          ? "Security Incident"
                          : data.module_id === 13
                          ? "Abbreviations"
                          : data.module_id === 14
                          ? "Q & A Help"
                          : data.module_id === 15
                          ? "Diagrams / Logo's"
                          : data.module_id === 16
                          ? "Training Calendar"
                          : data.module_id === 17
                          ? "Agenda"
                          : data.module_id === 18
                          ? "CISO / MRM Meeting MoM"
                          : "-"}
                      </td>
                      <td className="text-center capitalize text-gray-900 w-[40%] ">
                        {data.status_name == "review_and_approved" ? "review and approved":"" }
                      </td>

                      <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data}
                        fetcher={refreshData}
                      />
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateOrUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
      />
    </StandardSettingLayout>
  );
}

export default ApprovalStatusSettingIndex;

const header = [
  {
    headone: "SI. No",
    width: "w-[15%]",
  },
  {
    headone: "Module",
    width: "w-[40%]",
  },
  {
    headone: "Status",
    width: "w-[40%]",
  },
];
