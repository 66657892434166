import React from 'react'

function AddIcon({onClick}) {
  return (
    <svg
    className="w-7 ml-1 cursor-pointer"
    alt="plus"
    onClick={onClick}
    title="Add"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.61084"
      y="0.335541"
      width="51.1569"
      height="51.1569"
      rx="5"
      fill="#7030A0"
    />
    <path
      d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
      stroke="white"
      strokeWidth="2.3"
      strokeLinecap="round"
    />
    <title>Add</title>
  </svg>
  )
}

export default AddIcon