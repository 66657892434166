import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import MainLayout from '../../../Utilities/Layout/MainLayout';
import { GetAccess } from '../../../Utilities/data/role';
import axiosInstance from '../../../Services/axiosComman';
import ViewMoreTwo from './viewMore';
import DateFormat from '../../../Utilities/common/NuDate';
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import CreateorUpdate from './from';
import Import from './import';
import ApprovelStatus from './approval';
import Export from '../../../Utilities/common/Export';


function MOMIndex() {
  const { id } = useParams();
  const location = useLocation();
  let { statusFilter } = location?.state||"";
  const history  = useHistory();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [showData, setShowData] = useState({});
  const [expandedRow, setexpandedRow] = useState([]);
  const [selected, setSelected] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [moM, setMoM] = useState([]);
  const [data,setData] = useState([]);
  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");

  function getData(){
    axiosInstance.get(`/Minute-Of-Meeting/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`)
    .then(res=>{
      console.log(res?.data);
      res?.data && setData(res?.data)
    })
    .catch(err=>console.log(err))
  }

  function getFilterData(){
    axiosInstance.get(`/Minute-Of-Meeting/standard/${id}/status/${statusFilter}?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data)
    }).catch(error=>{
      setData({
        data:[],
        error:"",
        msg:"No data",
        status: true,
        status_code:404,
        total:0
      });
      console.log(error);
    })
  }

  function getMoM() {
    axiosInstance.get("/Status/18/").then((res) => {
      res?.data && setMoM(res.data?.data);
    });
  }

  function getAuditData(){
    if(statusFilter){
      getFilterData()
    }else{
      getData()
    }
  }


  useEffect(() => {
    if(statusFilter){
      getFilterData()
    }else{
      getData()
    }
  },[pageIndex,dataCount])

  useEffect(() => {
    if(statusFilter){
     getFilterData()
    }
  },[statusFilter])

  function clearFilter(){
    getData();
    history.replace({
      state:null
    })
    }

    function getStandard(){
      axiosInstance.get(`Standards/${id}`).then(res=>{
          setStandardName(res?.data?.data.name);
          setStartDate(res?.data?.data.start_date)
          setEndDate(res?.data?.data.end_date)
      }).catch(error=>{
          console.log(error);
      })
    }

  useEffect(() => {
    getMoM();
    getStandard()
  }, []);


  return (
    <MainLayout
    id={id}
    total={data?.total||data?.total_record||0}
    pageIndex={pageIndex}
    setPageIndex={setPageIndex}
    dataCount={dataCount}
    setDataCount={setDataCount}
    label="CISO / MRM Meeting MoM"
    title="Activity"
    setShowApprovalModal={setShowApprovalModal}
    hasApproval={true}
  >
            <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">CISO / MRM Meeting MoM</p>
          
          <div className="flex items-center">
            {statusFilter !=null ?<div className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer" onClick={clearFilter}>clear</div>:null}
           <div className=" w-8">
            {GetAccess("activity_create") && <svg
              className="w-7 ml-1 cursor-pointer"
              alt="plus"
              onClick={() => setShowMyModal(true)}
              title="Add"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.61084"
                y="0.335541"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path
                d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Add Header</title>
            </svg>}
            </div>
            <Export id={id} path={'mom'} name={`${standardName} CISO / MRM Meeting MoM ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}  />

           {GetAccess("activity_create") && <Import refreshData={getData} id={id} />} 
          </div>
        </div>
        <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="text-xs 2xl:text-sm font-medium">
            {/* {header.map((header, i) => (
              <th className={`px-2 xl:px-8 py-4 ${header.style}`} key={i}>
                {header.headone}
              </th>
            ))} */}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[calc(100vh-210px)] shadow-box">
                {data?.data?.map((i, indx) => (
                  <div className="">
                    <div
                      onClick={() => setShowData(i)}
                      className="pl-5 pr-2 py-2 mt-1.5 text-xs 2xl:text-sm bg-tableeven shadow-box flex justify-between items-center"
                    >
                      <h2
                        className={
                          selected === indx
                            ? "font-semibold text-gray-900 w-[10%]"
                            : "font-medium text-gray-500 w-[10%]"
                        }
                      >
                        
                        {i?.heading||"-"}
                      </h2>
                      <div
                        class={
                          selected === indx
                            ? "grid grid-cols-4 w-[65%]"
                            : "grid grid-cols-4 w-[65%]"
                        }
                      >
                        <div className="py-1">
                          <span>Group: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {/* {i?.Value?.[0].group} */}
                            {i?.group_data||"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Date: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.mom_date!="None"?DateFormat({
                              value: i?.mom_date,
                              format: "DD-MMM-yyyy",
                            }):"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Type of Meeting: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.type_of_meeting||"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Start Time: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.start_time !="None"?i?.start_time:"-" }
                          </span>
                        </div>
                        <div className="py-1">
                          <span>End Time: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.end_time !="None"?i?.end_time:'-'}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Regrets: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.regrets||"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Venue: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.venue||"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Chaired By: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.chaired_by||"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Minutes By: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-900"
                                : "font-medium text-gray-500"
                            }
                          >
                            {i?.minute_by||"-"}
                          </span>
                        </div>
                        <div className="py-1">
                          <span>Status: </span>
                          <span
                            className={
                              selected === indx
                                ? "font-semibold text-gray-100 p-1 bg-[#7030A0] rounded-md capitalize"
                                : "font-medium text-white p-1 bg-[#7030A0] rounded-md capitalize"
                            }
                          >
                            {i?.status||"-"}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-center xl:w-[2%] w-[5%]">
                      {i?.attachments && i?.attachments[0] && (
                                  <img
                                    src={paperclip}
                                    alt=""
                                    className="w-3 h-4 mx-auto"
                                  />
                                )||""}
                      </div>
                      <div className="flex justify-center xl:w-[1%] w-[5%]">

                        <ViewMoreTwo
                            data={i}
                             fetcher={getAuditData}
                             id={id}
                             MoM={moM} 
                         />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          id={id}
          status={moM}
          refreshData={getAuditData}
        />
        <ApprovelStatus
                  visible={showApprovalModal}
                  onClose={handleApprovalOnClose}
                  refreshData={getAuditData}
        />
  </MainLayout>
  )
}

export default MOMIndex;