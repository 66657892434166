import React, { useState } from "react";
import Swal from "sweetalert2";
import axiosInstance from "../../../Services/axiosComman";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function Import({ data, refreshData, id }) {
  const [isLoading,setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState("");
  const handleClose = () => {
    setShowModal(false);
    // formik.resetForm({ values: "" });
  };
  const handleShow = () => {
    setShowModal(true);
    // data && setCreateNew(data);
  };
  const handleOnClose = (e) => {
    if (e.target.id === "modal") setShowModal(false);
  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    let reqObj = new FormData();
    reqObj.append("file", files[0]);
    axiosInstance
      .post(`/Vendor-Management/standard/${id}/import`, reqObj)
      .then((res) => {
        if (res.data.status) {   
          setIsLoading(false); 
          handleClose();
          refreshData();
          Toast.fire({
            icon: "success",
            title: res.data.msg || "Imported Successfully",
          });
        } else {
          setIsLoading(false);
          Toast.fire({
            icon: "error",
            title: res.data.msg || "Import Failed",
          });
        }
      }).catch((err)=>{
        setIsLoading(false)
              Toast.fire({
                icon: "error",
                title: err?.response?.data.msg || err?.response?.data.error ||"Import Failed",
              });
      });
  };

  function handleImageChange(e) {
    const { files, value } = e.target;
    setFiles(files);
  }

  return (
    <>
      <button onClick={handleShow} className="flex items-center px-4">
        <svg
          width="19"
          height="22"
          viewBox="0 0 19 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.44988 17.1511L13.6499 11.9011H10.4999V0.351074H8.39988V11.9011H5.24988L9.44988 17.1511Z"
            fill="#67748E"
          />
          <path
            d="M16.7999 7.70105H12.5999V9.80105H16.7999V19.251H2.09988V9.80105H6.29988V7.70105H2.09988C0.941728 7.70105 -0.00012207 8.6429 -0.00012207 9.80105V19.251C-0.00012207 20.4092 0.941728 21.351 2.09988 21.351H16.7999C17.958 21.351 18.8999 20.4092 18.8999 19.251V9.80105C18.8999 8.6429 17.958 7.70105 16.7999 7.70105Z"
            fill="#67748E"
          />
        </svg>
        <h2 className="text-sm font-medium ml-1 hidden xl:block">Import</h2>
      </button>

      {showModal ? (
        <>
          <div
            id="modal"
            // onClick={handleOnClose}
            className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
          >
            <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
              <div className="flex items-start justify-between px-5 py-2  rounded-t">
                <h3 className="text-xs 2xl:text-sm font-semibold">
                  {data ? "Edit Header" : "Import"}
                </h3>
                <span
                  className="h-6 w-6 text-2xl block cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <svg
                    className="w-7 h-7"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.842773"
                      y="0.560791"
                      width="51.1569"
                      height="51.1569"
                      rx="5"
                      fill="#7030A0"
                    />
                    <path
                      d="M26.4219 26.1392L18.8794 33.6816Z"
                      fill="#6E6985"
                    />
                    <path
                      d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                      stroke="white"
                      stroke-width="2.3"
                      stroke-linecap="round"
                    />
                    <title>Close</title>
                  </svg>
                </span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="relative px-6 flex flex-wrap justify-between">
                  <input type="file" onChange={handleImageChange} name="file" />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={() => handleClose()}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
              </form>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
