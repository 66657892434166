import React, { useState } from "react";
import { imagetype } from "../../../Utilities/common/fileView";
import ImgIcon from "../../../Utilities/Image/img/imageicon.png";
import DocIcon from "../../../Utilities/Image/img/docsicon.png";
import XlIcon from "../../../Utilities/Image/img/excelicon.png";
import TxtIcon from "../../../Utilities/Image/img/txticon.png";
import FileView from "../../../Utilities/common/fileView";
import DateFormat from "../../../Utilities/common/NuDate";
import FileDisplay from "../../../Utilities/common/FileDisplay";

function TrainingCalenderView({ visible, onClose, data, path }) {
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);
  const [showData, setShowData] = useState("");
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  if (!visible) return null;
  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
              flex justify-center items-center z-10"
    >
      <div className="bg-white p-2 rounded w-10/12 h-[95vh] ">
        <div className="flex items-start justify-between px-5 py-2 border-slate-50 border border-b-[#FDFAFF] rounded-t">
          <h3 className="text-base 2xl:text-xl capitalize font-semibold ">
            {data?.training_program !== null? `${data?.training_program} View`:"Traning Calendar View"}
          </h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={onClose}
          >
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.842773"
                y="0.560791"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
              <path
                d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Close</title>
            </svg>
            {/* <img src={Close} alt="close" title="close" /> */}
          </span>
        </div>
        <div className="overflow-auto h-[87vh]">
          <div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Training Program
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.training_program || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Function Responsibility
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.function_responsibility || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Frequency
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.frequency || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Participants
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.participants || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Mandatory For the organization
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.mandatory || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Detailplan
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.detail_plan || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Planned Date
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
             { data?.planned_date != "None" && data?.planned_date != " " && DateFormat({
                                value: data?.planned_date,
                                format: "DD-MMM-yyyy",
                              })||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Artefact
              </p>
              <div className=" w-full flex flex-wrap">
              {data?.artifact && (
                data?.artifact.map(i =>{
                  return(
                    <div className=" m-1">
                      <FileDisplay data={i} view={true}/>
                      </div>
                  )
                }
                    
)
              )}
              </div>
            </div>

            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Status
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize">
                {data?.status || "-"}
              </p>
            </div>
          </div>
        </div>
        <FileView
          path={showData}
          onClose={handleClose}
          visible={showFile}
        />
      </div>
    </div>
  );
}

export default TrainingCalenderView;
