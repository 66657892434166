import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function Logout() {
  return (
    <div className="grid items-center bg py-[1.30rem] bg-center bg-origin-border bg-cover h-screen">
      <div className="lg:flex justify-evenly items-center rounded-3xl shadow-box bg-login w-9/12 lg:w-5/12 h-[30rem] md:h-[33rem] lg:h-[35rem] xl:h-[25rem] py-2 m-auto my-8">
        <div className=' w-full p-4 flex-col items-center justify-center'>
            <div className=' overflow-hidden flex justify-center items-center text-center'>
             <svg xmlns="http://www.w3.org/2000/svg" fill='#2cc502' height="3em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
            </div>
            <p className=' my-3 text-lg font-medium text-center'> You have successfully logged out of Scroll</p>
            <p className=' my-3 text-[13px] font-medium text-center'>To login back, please click the button below </p>
            <Link to="/">
            <button
                type="submit"
                className="bg-primary text-white rounded-3xl py-2 mt-4 w-full"
              >
                Login
            </button>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default Logout