import React, { useState } from 'react'
import AxiosComman from "../../../Services/axiosComman"
import Edit from './form'
import Swal from 'sweetalert2'
import { View } from './templateProfile';
import { GetAccess, GetRole } from '../../../Utilities/data/role';

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({ fetcher, data, visible, onClose,vendorList }) {
  const [showMyModal, setShowMyModal] = useState(false)
  const handleOnClose = () => setShowMyModal(false)
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false)
  const [viewData, setViewData] = useState([]);


  async function getViewPage() {
    await AxiosComman.get(`/User/${data.userId}/`)  
      .then((res) => {
        res.data?.data &&
        setViewData(res.data?.data?.userDetails);
      })
      .catch((err) => console.log(err));
  }


  const handleDelete = async (id,value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Delete ${value}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          await AxiosComman.delete(`/Vendor-Management/delete/${id}`)
          .then((res) => {
            if (res.status == 200) {
              fetcher();
              Toast.fire({
                icon: "success",
                title:
                  res.data?.message || "User Management Deleted successfully",
              });
            } else {
              Toast.fire({
                icon: "error",
                title:
                  res.data?.message || "User Management delete  unsuccessfully",
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            Toast.fire({
              icon: "error",
              title:
              error?.response.data?.message || "User Management delete  unsuccessfully",
            });
          });
        }
      }
    })
  }
  
  // if (!visible) return null;
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;
  return (
    <>
     <div
        className="hover:drop-shadow-sm mr-2 py-2 px-[14px] rounded-3xl dropdown bg-white cursor-pointer relative"
        // onClick={() => setShowMyMenu(!showMyMenu)}
      >
        <svg className='m-auto w-[4px]' viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        </svg>
      {/* {showMyMenu ? ( */}
        <div className='hidden dropdown-menu'><div id="modal"
          onClick={handleOnCloseMenu}
          className="bg-transparent fixed inset-0 flex justify-center items-center "></div>
          <div className="bg-white rounded-xl w-20 absolute -left-20 -top-1">
            <div className="grid p-1 rounded-t">
              <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => setShowFile(true)}>View</span>              
              {GetAccess('admin_edit') &&<span className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer" onClick={() => setShowMyModal(true)}>Edit</span>}
              {(GetRole()||GetAccess('admin_delete')) &&  <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => {
                  handleDelete(data?.id,data?.vendor_name);
                }
                }>Delete</span>
              }
            </div>
          </div>
        </div>
        {/* ) : null} */}
      </div>

      {/* <div  className="text-[#344767] relative dropdown inline-block z-50">
              <button className="hover:underline underline-offset-8 text-sm mx-auto font-semibold flex items-center">
              <span className='pt-1'>
                {/* <img src={moreIcon} alt='downArrow'/> 
                </span>
              </button>
              <ul className="dropdown-menuOne  text-[#344767] text-left  rounded-lg absolute -left-20 -top-4 overflow-hidden hidden  z-50 shadow-lg">
                <li className="">
                View
                </li>
                <li className="">
                    <Edit data={data} />
                </li>
                <li className="">
                  {/* <a
                    class="bg-white hover:bg-gray-100 py-2 px-2 block whitespace-no-wrap text-xs font-medium"
                    to="/incharges"
                    onClick={()=>{
                        handleDelete(data.incharge_id);
                    }
                    }
                  > 
                    Delete
                  {/* </a> 
                </li>
              </ul>
            </div> */}
      <Edit onClose={handleOnClose} vendorList={vendorList} visible={showMyModal} data={data} refreshData={fetcher} />
      <View data={data} onClose={handleClose} visible={showFile}/>
    </>
  )
}

export default Viewmore