import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../common/Navbar";
import { GetYear } from "../common/GetYear";
import BadgeCheck from "../Image/icons/test";

const linkStyles = ({ isActive }) => {
  return {
    padding: isActive ? "14px" : "14px",
    backgroundColor: isActive ? "white" : "#FDFCFD",
    color: isActive ? "text-gray-900" : "gray",
  };
};

function MainPageLayout({ children, progress = false, dashboard = false }) {
  return (
    <div className=" relative w-full h-screen overflow-hidden">
      <div className=" w-full h-[60px]">
        <Navbar />
      </div>
      <div className=" w-full h-[calc(100vh-80px)]">
        <div className="w-[92.5%] mx-auto py-10">
          <div className="w-[100%]">
            <div className=" flex gap-12 items-center text-center ">
              <ul className="flex flex-wrap text-center ">
                <li>
                  <NavLink
                    to="/standard"
                    style={linkStyles}
                    activeStyle={{
                      backgroundColor: "	#E5E4E2",
                    }}
                    className="shadow-md relative z-10 rounded-t-xl ml-1 text-lg 2xl:text-xl"
                  >
                    Standard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard"
                    style={linkStyles}
                    activeStyle={{
                      backgroundColor: "	#E5E4E2",
                    }}
                    className="shadow-md relative z-10 rounded-t-xl text-lg 2xl:text-xl"
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/progress"
                    style={linkStyles}
                    activeStyle={{
                      backgroundColor: "	#E5E4E2",
                    }}
                    className="shadow-md relative z-10 rounded-t-xl ml-1 text-lg 2xl:text-xl"
                  >
                    Progress
                  </NavLink>
                </li>
              </ul>
              {progress && (
                <div className=" px-2 bg-white shadow-md relative z-10">
                                <div className="mx-6 flex items-center">
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#FF0000] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#FF0000] mx-2"></span>
                    Yet To Start
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#ffd700] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#ffd700] mx-2"></span>
                    In Progress
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#008000] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#008000] mx-2"></span>
                    Completed
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#7030A0] "
                  >
                    <span className=" mx-2">
                      <BadgeCheck width="w-[13px]" height="h-[13px]" />
                    </span>
                    Reviewed & Approved
                  </p>
                </div>
              </div>
                </div>
              )}

              {dashboard && (
                <div className=" px-2 bg-white shadow-md relative z-10">
                  <div className=" xl:flex items-center">
                  <div className=" flex items-center">
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#FF0000] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#FF0000] mx-2"></span>
                    Yet to start
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#ffd700] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#ffd700] mx-2"></span>
                    In progress
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#008000] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#008000] mx-2"></span>
                    Completed
                  </p>
                </div>
              </div>
              <p className="text-[11px] text-left text-gray-500">(Drill Down functionality will work from Standard Dashboard and Progress page only)</p>

                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="relative pt-1 pb-1 z-10">{children}</div>
        </div>
      </div>
      <div className=" w-full h-5">
        <p className=" text-slate-400 text-center w-full text-xs">
          <span className=" text-[15px] font-medium">&copy;</span> {GetYear()}{" "}
          Metatron Infotech.
        </p>
      </div>
    </div>
  );
}

export default MainPageLayout;
