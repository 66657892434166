import React, { useState, useEffect } from "react";
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import Pagination from "../../../Utilities/common/Pagination";
import Import from "./import";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import DateFormat from "../../../Utilities/common/NuDate";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import ApprovelStatus from "../../../Utilities/common/Approvel";
import { GetYear } from "../../../Utilities/common/GetYear";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Export from "../../../Utilities/common/Export";

const fetcher = (url) => Axios.get(url).then((res) => res.data);

function SecurityIncidentIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [relation, setRelation] = useState([]);
  const [selected, setSelected] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [securityIncident, setSecurityIncident] = useState([]);
  const [security, setSecurity] = useState([]);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");

  const { data, error, mutate } = useSWR(
    `/Security-Incident/standard/${id}?page=${pageIndex}&per_page=${dataCount}`,
    fetcher
  );

  const refreshData = () => {
    mutate(fetcher);
  };
  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;

  function getStandard(){
    Axios.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        setStartDate(res?.data?.data.start_date)
        setEndDate(res?.data?.data.end_date)
    }).catch(error=>{
        console.log(error);
    })
  }

  useEffect(() => {
    getSecurity();
    getStandard()
    Relation();
    getSecurityIncident();
  }, []);

  const Relation = async () => {
    await Axios.get("/Master-Data/incident_type").then((res) => {
      let data = res?.data?.data;
      let xyz = [];
      data.forEach((i) => {
        let x = { label: i?.value, value: i?.value };
        xyz.push(x);
      });
      res?.data && setRelation(xyz);
    });
  };

  const getSecurity = () => {
    Axios.get(`/DashBoard/standard/${id}/security-incident/`).then((res) => {
      res?.data && setSecurity(res.data.data);
    });
  };

  const getSecurityIncident = () => {
    Axios.get("/Status/12/").then((res) => {
      let data = [];
      let abc = res.data.data;
      abc.forEach((i) => {
        let item = {
          value: i.status_type,
          label: i.status_type,
        };
        data.push(item);
      });
      res?.data?.data && setSecurityIncident(data);
    });
  };
  return (
    <>
      <MainLayout
        id={id}
        total={data?.total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
        label="Security Incident"
        title="Information"
        setShowApprovalModal={setShowApprovalModal}
        hasApproval={false}
      >
        <div className="py-3 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Security Incident</p>
          <div className="flex items-center text-xs 2xl:text-sm">
            <p className="mr-2">overall</p>
            <span className="bg-login px-2 py-1 rounded-md">
              {security?.complete_percentage || 0}%
            </span>
          </div>
          <div className="flex">
            <div className=" w-8">
              {GetAccess("information_create") && (
                <svg
                  className="w-7 ml-1 cursor-pointer"
                  alt="plus"
                  onClick={() => setShowMyModal(true)}
                  title="Add"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.61084"
                    y="0.335541"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path
                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Add</title>
                </svg>
              )}
            </div>
            <Export
              id={id}
              path={"security_incident"}
              name={`${standardName} Security Incident ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}
            />

            {GetAccess("information_create") && (
              <Import
                getSecurity={getSecurity}
                refreshData={refreshData}
                id={id}
              />
            )}
          </div>
          {/* )} */}
        </div>
        <div className=" w-full h-[calc(100vh-215px)] bg-white shadow-box">
          <div className=" w-full ">
            <table className="table-auto w-full">
              <thead className="bg-tabletitle">
                <tr className=" text-xs 2xl:text-sm font-medium">
                  {header?.map((header) => (
                    <th className={` px-5 py-4 ${header.width}`}>
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className="overflow-y-auto h-[calc(100vh-295px)]">
          <table className="table-auto w-full">
           <tbody className="overflow-y-auto h-[calc(100vh-295px)]">
           {data?.data && data?.data?.map((data, i) => (
             <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1" key={i}>
                      <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                            {data.serial_number || "#"}
                          </td>
                        <td className=" text-center text-gray-900 w-[15%] capitalize ">
                          {data?.systems || "-"}
                        </td>
                        <td className="text-center text-gray-900 w-[10%] capitalize ">
                          {data?.incident_date !=="None" && data?.incident_date !==" " && DateFormat({
                            value: data?.incident_date,
                            format: "DD-MMM-yyyy",
                          }) || "-"}
                        </td>
                        <td className="text-center text-gray-900 w-[10%] capitalize ">
                          {data?.closure_date !=="None" && data?.closure_date !==" " && DateFormat({
                            value: data?.closure_date,
                            format: "DD-MMM-yyyy",
                          }) || "-"}
                        </td>
                        <td className="text-center text-gray-900 w-[15%] capitalize ">
                          {data?.type_of_incident || "-"}
                        </td>
                        <td className=" text-center text-gray-900 w-[15%] capitalize ">
                          {data?.RCA || "-"}
                        </td>
                        <td className="text-center capitalize text-gray-900 w-[10%] ">
                          {data?.status || "-"}
                        </td>
                        <td className=" text-center text-gray-900 w-[15%] capitalize ">
                          {data?.incident_report.length>0 && (
                            <img
                              src={paperclip}
                              alt=""
                              className="w-3 h-4 mx-auto"
                            />
                          )}
                          
                        </td>
                        <td className=" w-[5%] flex justify-center items-center">
                        <ViewMore
                          data={data}
                          relation={relation}
                          securityIncident={securityIncident}
                          fetcher={refreshData}
                          getSecurity={getSecurity}
                        />
                        </td>
                      </tr>
           ))}
           </tbody>
          </table>
          </div>
        </div>
        {/* <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className=" text-xs 2xl:text-sm font-medium">
              {header?.map((header) => (
                <th className={` px-5 py-4 ${header.width}`}>
                  {header.headone}
                </th>
              ))}
              <div
                className={
                  selected
                    ? "flex items-center justify-start w-[20%]"
                    : "flex items-center justify-start w-[4.5%]"
                }
              >
              </div>
            </tr>
          </thead>
          <tbody>
            <tr colspan="6">
              <td colspan="8">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data && data?.data?.map((data, i) => (
                    <div className="">
                      <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                            {data.serial_number || "#"}
                          </td>
                        <td className=" text-center text-gray-900 w-[15%] capitalize ">
                          {data?.systems || "-"}
                        </td>
                        <td className="text-center text-gray-900 w-[10%] capitalize ">
                          {data?.incident_date !=="None" && data?.incident_date !==" " && DateFormat({
                            value: data?.incident_date,
                            format: "DD-MMM-yyyy",
                          }) || "-"}
                        </td>
                        <td className="text-center text-gray-900 w-[10%] capitalize ">
                          {data?.closure_date !=="None" && data?.closure_date !==" " && DateFormat({
                            value: data?.closure_date,
                            format: "DD-MMM-yyyy",
                          }) || "-"}
                        </td>
                        <td className="text-center text-gray-900 w-[15%] capitalize ">
                          {data?.type_of_incident || "-"}
                        </td>
                        <td className=" text-center text-gray-900 w-[15%] capitalize ">
                          {data?.RCA || "-"}
                        </td>
                        <td className="text-center capitalize text-gray-900 w-[10%] ">
                          {data?.status || "-"}
                        </td>
                        <td className=" text-center text-gray-900 w-[15%] capitalize ">
                          {data?.incident_report.length>0 && (
                            <img
                              src={paperclip}
                              alt=""
                              className="w-3 h-4 mx-auto"
                            />
                          )}
                          
                        </td>
                        <td className=" w-[5%] flex justify-center items-center">
                        <ViewMore
                          data={data}
                          relation={relation}
                          securityIncident={securityIncident}
                          fetcher={refreshData}
                          getSecurity={getSecurity}
                        />
                        </td>
                      </tr>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={refreshData}
          relation={relation}
          securityIncident={securityIncident}
          getSecurity={getSecurity}
          id={id}
        />
        <ApprovelStatus
          visible={showApprovalModal}
          onClose={handleApprovalOnClose}
          refreshData={refreshData}
          moduleId={12}
        />
      </MainLayout>
    </>
  );
}

export default SecurityIncidentIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "w-[5%]",
  },
  {
    headone: "System",
    width: "w-[15%]",
  },
  {
    headone: "Incident Date",
    width: "w-[10%]",
  },
  {
    headone: "Closure Date",
    width: "w-[10%]",
  },
  {
    headone: "Type of Incident",
    width: "w-[15%]",
  },
  {
    headone: "RCA Information / Analysis",
    width: "w-[15%]",
  },
  {
    headone: "Status Update",
    width: "w-[10%]",
  },
  {
    headone: "Incident Report / ATR Report",
    width: "w-[15%]",
  },
  {
    headone: " ",
    width: "w-[5%]",
  },
];
