import React, { useState } from "react";
import Swal from "sweetalert2";
import axiosInstance from "../../../Services/axiosComman";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end", 
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function Import({ data, refreshData, id,getImplementation }) {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState("");
  const handleClose = () => {
    setShowModal(false);
    // formik.resetForm({ values: "" });
  };
  const handleShow = () => {
    setShowModal(true);
    // data && setCreateNew(data);
  };
  const handleOnClose = (e) => {
    if (e.target.id === "modal") setShowModal(false);
  };

  const formInputs = [
    {
      type: "NuInput",
      name: "file",
      className: "border w-full h-10 px-3 mt-2 rounded-xl",
      placeholder: "",
      label: "Upload File",
      inputType: "file",
      handleChange: handleImageChange,
    },
  ];
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let reqObj = new FormData();
    reqObj.append("file", files[0]);
    axiosInstance
      .post(`/implementation-checklist/standard/${id}/Import`, reqObj)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false)
          getImplementation()
          handleClose();
          refreshData();
          Toast.fire({
            icon: "success",
            title:  res.data.message || res.data.msg || "Imported Successfully",
          });
        } else {
          setIsLoading(false)
          Toast.fire({
            icon: "error",
            title: res.data.message || res.data.msg || "Import Failed",
          });
        }
      }).catch((err)=>{
        setIsLoading(false)
              Toast.fire({
                icon: "error",
                title: err?.response?.data.message || err?.response?.data.msg || err?.response?.data.error ||"Import Failed",
              });
      });
  };

  function handleImageChange(e) {
    const { files, value } = e.target;
    setFiles(files);
  }

  return (
    <>
      <button onClick={handleShow} className="flex items-center mx-1">
        <svg 
           fill="#67748E" 
           height="19px" 
           width="22px" 
           version="1.1" 
           id="Capa_1" 
           xmlns="http://www.w3.org/2000/svg" 
           viewBox="0 0 60.903 60.903">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
            <g> 
              <path d="M49.561,16.464H39.45v6h10.111c3.008,0,5.341,1.535,5.341,2.857v26.607c0,1.321-2.333,2.858-5.341,2.858H11.34 c-3.007,0-5.34-1.537-5.34-2.858V25.324c0-1.322,2.333-2.858,5.34-2.858h10.11v-6H11.34C4.981,16.466,0,20.357,0,25.324v26.605 c0,4.968,4.981,8.857,11.34,8.857h38.223c6.357,0,11.34-3.891,11.34-8.857V25.324C60.902,20.355,55.921,16.464,49.561,16.464z"></path> 
              <path d="M39.529,29.004c-0.768,0-1.535,0.294-2.121,0.88l-3.756,3.755V20.612v-6V3.117c0-1.656-1.343-3-3-3s-3,1.344-3,3v11.494v6 v13.23l-3.959-3.958c-0.586-0.586-1.354-0.88-2.121-0.88s-1.535,0.294-2.121,0.88c-1.172,1.17-1.172,3.07,0,4.241l8.957,8.957 c0.586,0.586,1.354,0.877,2.12,0.877c0.008,0,0.016,0,0.023,0s0.015,0,0.022,0c0.768,0,1.534-0.291,2.12-0.877l8.957-8.957 c1.172-1.171,1.172-3.071,0-4.241C41.064,29.298,40.298,29.004,39.529,29.004z"></path> 
            </g> 
            </g>
            </svg>

        <h2 className="text-sm font-medium ml-1 hidden xl:block">Import</h2>
      </button>

      {showModal ? (
        <>
          <div
            id="modal"
            // onClick={handleOnClose}
            className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
          >
            <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
              <div className="flex items-start justify-between px-5 py-2  rounded-t">
                <h3 className="text-xs 2xl:text-sm font-semibold">
                  {data ? "Edit Header" : "Import"}
                </h3>
                <span
                  className="h-6 w-6 text-2xl block cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <svg
                    className="w-7 h-7"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.842773"
                      y="0.560791"
                      width="51.1569"
                      height="51.1569"
                      rx="5"
                      fill="#7030A0"
                    />
                    <path
                      d="M26.4219 26.1392L18.8794 33.6816Z"
                      fill="#6E6985"
                    />
                    <path
                      d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                      stroke="white"
                      stroke-width="2.3"
                      stroke-linecap="round"
                    />
                    <title>Close</title>
                  </svg>
                </span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="relative px-6 flex flex-wrap justify-between">
                  <input type="file" onChange={handleImageChange} name="file" />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={() => handleClose()}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
              </form>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
