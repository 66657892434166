import React, { useState } from "react";
import home from "../../Utilities/Image/icons/home.svg";
import { NavLink, useParams } from "react-router-dom";
import StandardLayout from "../../Utilities/Layout/StandardLayout";
import { GetAccess, GetRole } from "../../Utilities/data/role";

function AdminSettingsLayout({ children }) {
  return (
    <StandardLayout hasInnerNavbar={false}>
      <div className="mx-4 xl:mx-12 2xl:mx-24 flex items-center text-xs 2xl:text-sm py-2">
        <img src={home} alt="" className="w-3 2xl:w-5" />
        <p className="ml-2 text-xs 2xl:text-sm text-secondary">
          <span className="text-text font-medium">System Settings</span>
        </p>
      </div>
      <div className="w-[97.5%] mx-4 flex  h-[calc(100vh-120px)]">
        <div className="w-2/12 bg-white shadow-box mr-2 px-2 pt-4 rounded-md overflow-hidden">
          <div className=" w-full h-full overflow-y-auto">
            <ul className="text-xs 2xl:text-sm">
              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/company-info`}>
                    Company Info
                  </NavLink>
                </li>
              )}
              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/email-setup`}>
                    Email Setup
                  </NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/user-management`}>
                    User Management
                  </NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/roles`}>Roles</NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/user-role`}>User Role</NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/standard-reset`}>
                    Standard Reset
                  </NavLink>
                </li>
              )}
              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/action-log`}>
                    Action Log
                  </NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/main-vendor`}>
                    Vendor Management
                  </NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink
                    to={`/settings/admin/certification-calendar-tracker`}
                  >
                    Certification Calendar Tracker
                  </NavLink>
                </li>
              )}

              {GetRole() && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/subscription`}>
                    Subscription
                  </NavLink>
                </li>
              )}

              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/department-type`}>
                    Department Type
                  </NavLink>
                </li>
              )}
              {(GetRole() || GetAccess("admin_view")) && (
                <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
                  <NavLink to={`/settings/admin/backup-restore`}>
                  Backup & Restore
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="w-full pt-1 h-full overflow-y-auto bg-white shadow-box ml-2 rounded-md">
          {children}
        </div>
      </div>
      {/* </div> */}
    </StandardLayout>
  );
}

export default AdminSettingsLayout;
