import React, { useState } from "react";
// import InnerNavbar from "../../Utilities/common/Navbar/Navbar";
import home from "../../Utilities/Image/icons/home.svg";
import { NavLink, useParams } from "react-router-dom";
// import { GetYear } from "../../Utilities/common/GetYear";
import StandardLayout from "../../Utilities/Layout/StandardLayout";


function StandardSettingLayout({ children }) {
  const { id } = useParams();
  return (
      <StandardLayout>
      <div className="mx-4 xl:mx-12 2xl:mx-24 flex items-center text-xs 2xl:text-sm py-2">
        <img src={home} alt="" className="w-3 2xl:w-5" />
        <p className="ml-2 text-xs 2xl:text-sm text-secondary">
          Settings /&nbsp;
          <span className="text-text font-medium">Standard</span>
        </p>
      </div>
      <div className="w-[97.5%] mx-4 flex pt-1 h-[calc(100vh-120px)]">
        <div className="w-2/12 bg-white shadow-box mr-2 px-2 pt-4 rounded-md overflow-hidden">
          <div className=" w-full h-full overflow-y-auto">
          <ul className="text-xs 2xl:text-sm">
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/status/${id}`}>Status</NavLink>
            </li>
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/frequency-settings/${id}`}>
                Frequency
              </NavLink>
            </li>
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/vender-type-settings/${id}`}>
                Vendor Type
              </NavLink>
            </li>
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/incident-type-settings/${id}`}>
                Incident Type
              </NavLink>
            </li>
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/manual-settings/${id}`}>
                Manual Status
              </NavLink>
            </li>
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/approval-status/${id}`}>
                Approval Status
              </NavLink>
            </li>
            {/* <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/Email/${id}`}>
                Send Email
              </NavLink>
            </li>
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/EmailSendingList/${id}`}>
                Email Send List
              </NavLink>
            </li> */}
            {/* <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/email-settings/${id}`}>
                Email Settings
              </NavLink>
            </li> */}
            <li className="p-[6px] text-[13px] rounded-md hover:bg-navhover">
              <NavLink to={`/setting/standard/email-templates/${id}`}>
                Email Templates
              </NavLink>
            </li>
          </ul>
          </div>
        </div>
        <div className="w-full h-full overflow-y-auto bg-white shadow-box ml-2 rounded-md">
          {children}
        </div>
      </div>
      </StandardLayout>
  );
}

export default StandardSettingLayout;
