import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
  } from "@react-pdf/renderer";
  import Logo from "../../../Utilities/Image/img/logo.jpg";
  import DateFormat from "../../../Utilities/common/NuDate";
  
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#fff",
      padding: "15px 30px 60px 30px ",
    },
    sectionMainTop: {
      width: "100%",
      height: "12%",
      flexDirection: "column",
    },
    sectionTop: {
      display:'flex',
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems:"center",
      width:'100%',
      height:"50%",
    },
    pageNumber: {
      fontSize: 9,
      textAlign: "center",
      color: "grey",
    },
    image: {
      width: 35,
      height: "auto",
      paddingVertical: "3px",
    },
    titleCenter: {
      textAlign: "center",
      width: 200,
    },
    titleCenterTop: {
      fontSize: 13,
      marginBottom: 5,
      textAlign: "center",
      fontWeight: "semibold",
    },
    titleCenterBottom: {
      fontSize: "10px",
      color: "black",
      fontWeight: "normal",
      textAlign: "center",
    },
    titleRight: {
      fontSize: "7px",
      color: "grey",
      fontWeight: "bold",
    },
    sectionBottom: {
      width:"100%",
      height:"7%",
      // backgroundColor:'green',
      display:'flex',
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems:'center',
      position:'absolute',
      bottom:"15px",
      left:"30px"
    },
    sectionMain: {
      width: "100%",
      // height:"92%",
      // backgroundColor:'blue'
    },
    sectionInner: {
      width: "100%",

    },
    tableRow: {
      width: "100%",
      height: "50%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F1E0FF",
    },
    tableTitle: {
      fontSize: "9px",
      color: "black",
      fontWeight: "bold",
    },
    tableOne: {
      width: "5%",
      textAlign: "center",
      paddingVertical: "4px",
    },
  
    tableTwo: {
      width: "10%",
      textAlign: "center",
      paddingVertical: "4px",
    },
    tableThree: {
      width: "85%",
      textAlign: "center",
      paddingVertical: "4px",
    },
    company:{
      display:'flex',
      flexDirection:"row",
      gap:"4px",
      justifyContent:"center",
      alignItems:"center"
    },
    bottomCenter:{
      width:"33.3%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign:"center",
      gap:"3px"
    },
    bottomStart:{
      width:"33.3%",
      textAlign:"left",
      gap:"3px"
    },
    bottomEnd:{
      width:"33.3%",
      textAlign:"right",
      gap:"3px"
    },
    textStyle:{
      fontSize:'8px',
      color: 'grey',
    },
    lastItem:{
      display:"flex",
      flexDirection:"column",
      gap:"2px"
    },
    lastItemTitle:{
      fontSize:"6px"
    }
  
  });
  
  let DateNow = DateFormat({ value: new Date(), format: "DD-MMM-YYYY" });
  let TimeNow = DateFormat({ value: new Date(), format: "hh:mm:ss a" });
  
  function Date(data) {
    if (data) {
      return DateFormat({ value: data, format: "DD-MMM-YYYY" });
    } else {
      return " ";
    }
  }
  
  const ActionPDF = ({ company,data}) => {
    function Header({companyName, companyImg}) {
      return (
        <View style={styles.sectionMainTop} fixed>
        <View style={styles.sectionTop} >
          <View style={styles.company}>
          <Image style={styles.image} src={companyImg?companyImg:Logo} />
          <Text style={styles.titleCenterTop}>{`${companyName||""}`}</Text>
          </View>
          <View>
          <Text style={styles.titleCenterBottom}>{``}</Text>
          </View>
          <View>
          </View>
          <View style={styles.lastItem}>
          <Text style={styles.titleCenterBottom}>{`Action Logs`}</Text>
          </View>
  
      </View>
          <View style={styles.tableRow}>
            <View style={styles.tableOne}>
              <Text style={styles.tableTitle}>Sl.No</Text>
            </View>
            <View style={styles.tableTwo}>
              <Text style={styles.tableTitle}>Date</Text>
            </View>
            <View style={styles.tableThree}>
              <Text style={styles.tableTitle}>Action</Text>
            </View>
          </View>
        </View>
      );
    }
  
    function Footer(){
      return(
        <View style={styles.sectionBottom} fixed>
          <View style={styles.bottomStart}>
          </View>
          <View style={styles.bottomCenter}>
          <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
            <Text style={styles.titleRight}>
            {` ${DateNow} / ${TimeNow}`}
          </Text>
          </View>
          <View style={styles.bottomEnd}>
          
          </View>
        </View>
      )
    }
  
    function MainContent() {
      const styleTable = StyleSheet.create({
        table: {
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 0,
        },
      });
      return (
        <View style={styleTable.table}>
          {data &&
            data.map((item, idx) => {
              return <TempComponent data={item} key={idx} />;
            })}
        </View>
      );
    }
  
    return (
      <Document>
        <Page size="A4" style={styles.page} orientation="landscape">
          <Header
            companyName={company?.companyName}
            companyImg={company?.companyLogo}
          />
          <MainContent />
          <Footer/>
        </Page>
        {/* ))
            } */}
      </Document>
    );
  };
  
  export default ActionPDF;
  
  function TempComponent({ data }) {
    const styleTemp = StyleSheet.create({
      tableRow: {
        flexDirection: "row",
        borderStyle: "solid",
        borderWidth: 0.2,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 0,
      },
      tableTitle: {
        fontSize: "8px",
        color: "black",
        // fontWeight:'bold'
      },
      tableOne: {
        width: "5%",
        textAlign: "center",
        paddingVertical: "8px",
      },
      tableTwo: {
        width: "10%",
        textAlign: "center",
        paddingVertical: "8px",
      },
      tableThree: {
        width: "85%",
        textAlign: "center",
        paddingVertical: "8px",
      },
      cell: { margin: "auto", fontSize: "8px" },
      paperclip: {
        width: 6,
        height: "auto",
      },
    });
    return (
      <View style={styleTemp.tableRow}>
        <View style={styleTemp.tableOne}>
          <Text style={styleTemp.tableTitle}>{data?.serial_number||data?.id || ""}</Text>
        </View>
        <View style={styleTemp.tableTwo}>
          <Text style={styleTemp.tableTitle}>{Date(data?.action_date_time)|| ""}</Text>
        </View>
        <View style={styleTemp.tableThree}>
          <Text style={styleTemp.tableTitle}>{data?.action_log|| ""}</Text>
        </View>
      </View>
    );
  }
  