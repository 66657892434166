import React, { useState } from "react";
import InnerNavbar from "../common/Navbar/Navbar";
import Pagination from "../common/Pagination";
import Breadcrumbs from "../common/Breadcrumbs";
import { GetAccess, GetRole } from "../data/role";
import { GetYear } from "../common/GetYear";
import StandardLayout from "./StandardLayout";

function MainLayout({
  id,
  children,
  total,
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  label,
  title,
  hasApproval,
  setShowApprovalModal,
}) {
  return (
    // <div className="bg-background pb-16 h-[100vh]">
      // <InnerNavbar id={id} />
      <StandardLayout>
      <Breadcrumbs title={title} label={label} />
      <div className="w-[97.5%] bg-white shadow-main h-[calc(100vh-120px)] z-[99] rounded-2xl mx-4">
        {children}
        <div className=" flex gap-7 items-center">
          <div className=" w-[70%]">
            <Pagination
              total={total}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              dataCount={dataCount}
              setDataCount={setDataCount}
            />
          </div>
          <div className=" w-[30%]">
          {
           hasApproval &&  (GetRole()||GetAccess("review_and_approve")) && 
            <span className=" cursor-pointer  px-4 py-2 rounded-md bg-slate-400 text-white text-xs" onClick={()=>setShowApprovalModal(true)}>
            Review and Approve
          </span>
          }
        </div>
        </div>
      </div>
      </StandardLayout>
    // </div>
  );
}

export default MainLayout;
