import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosInstance from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
// import Modal from "./modal"

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function Filter({
  formik,
  visible,
  onClose,
  status,

}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };





  const formInputs = [
    {
      inputType: "NuLabel",
      labelName:"Completion :",
      classname: "w-full px-3 mt-2",
      
    },
    {
      inputType: "NuInput",
      name: "start_completion_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "From Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      // customClass: "w-6/12 pl-4",
      customClass: " w-full lg:w-6/12  ",
    //   max:      DateFormat({
    //     value: Date.now(),
    //     format: "yyyy-MM-DD",
    //   })
    },
    {
      inputType: "NuInput",
      name: "end_completion_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "To Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      // customClass: "w-6/12 pl-4",
      customClass: " w-full lg:w-6/12  ",
    //   max:      DateFormat({
    //     value: Date.now(),
    //     format: "yyyy-MM-DD",
    //   })
    },
    {
      inputType: "NuLabel",
      labelName:"Reminder :",
      classname: "w-full px-3 mt-2",
      
    },
    {
      inputType: "NuInput",
      name: "start_reminder_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "From Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      // customClass: "w-6/12 pl-4",
      customClass: " w-full lg:w-6/12  ",
    },
    {
      inputType: "NuInput",
      name: "end_reminder_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "To Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      // customClass: "w-6/12 pl-4",
      customClass: " w-full lg:w-6/12  ",
    },
    {
      inputType: "NuSelect",
      name: "status",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Status",
      label: "Status",
      labelclassname: "w-full",
      customClass: " w-full lg:w-6/12  ",
      Options: status.map((i) => {
        return {
          value: i.status_type,
          label: i.status_type,
        };
      }),
      // isRequired: true,
    },

  ];





  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-50 inset-0 backdrop-blur-sm 
    flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              Filter
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="h-[60vh] overflow-y-auto">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>

              <div className="flex items-center justify-end px-6 py-6 rounded-b">
                <button
                  onClick={onClose}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}