import React, { useState } from "react";
// import AdminLayout from "../index";
import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
// import CreateorUpdate from "./form";
// import { GetAccess } from "../../../Utilities/data/role";
import CreateOrUpdate from "./form";
// import SettingsLayout from "../../Settings";
import AdminSettingsLayout from "../index";
import { GetAccess, GetRole } from "../../../Utilities/data/role";

const fetcher = (url) => Axios.get(url).then((res) => res.data?.data);

function SubcriptionSettingsIndex() {
  // const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  // const [showMyMenu, setShowMyMenu] = useState(false);
  // const handleOnCloseMenu = () => setShowMyMenu(false);
  const [standardId, setStandardId] = useState("");
  // const [check,setCheck] = useState(false);

  const { data, error, mutate } = useSWR(`/Standards/`, fetcher);

  const refreshData = () => {
    mutate(fetcher);
  };

  function handleChange(e, id) {
    setStandardId(id);
    if (e.target.checked) {
      setShowMyModal(true);
    }
  }

  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Standard Subscription
        </h4>
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`px-5 py-4 ${header.width} ${header.align}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              {
                GetRole() ?
                (
                <>
                <div className="overflow-y-auto h-[calc(100vh-220px)] shadow-box">
                {data?.map((data, i) => (
                  <div className="" key={i}>
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[10%]">
                        {data.id}
                      </td>
                      <td className="text-left px-5 text-gray-900 w-[75%] ">
                        {data.name}
                      </td>
                      <td className="text-center relative text-gray-900 w-[15%]">
                        {GetRole()&&
                          <input
                          class="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          checked={data?.isActive && data?.keyActivated}
                          onChange={(e) => handleChange(e, data?.id)}
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                        }
                      </td>
                    </tr>
                  </div>
                ))}
              </div>
                </>
                ):
                (
                  <>
                  <div className=" w-full h-[calc(100vh-220px)] text-sm font-medium flex justify-center items-center">
                    You have no permission to access this menu
                  </div>
                  </>
                )
              }
            </td>
          </tr>
        </tbody>
      </table>
      <CreateOrUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        id={standardId}
      />
    </AdminSettingsLayout>
  );
}

export default SubcriptionSettingsIndex;

const header = [
  {
    headone: "SI. No",
    width: "w-[10%]",
    align: "text-center",
  },
  {
    headone: "Standard Name",
    width: "w-[75%]",
    align: "text-left",
  },
  {
    headone: "Subscription Status",
    width: "w-[15%]",
    align: "text-center",
  },
];
