import React, { useEffect, useState } from "react";

export default function CreateorUpdate({ complete, visible }) {
  const [counter, setCounter] = useState(1);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {

    if(visible){
      const timeInterval = setInterval(() => {
        setCounter(prevCounter => {
          if (prevCounter <= 98) {
            return prevCounter + 1;
          } else {
            clearInterval(timeInterval);
            return prevCounter;
          }
        });
      }, 21000);

      const intervalMiddle = setInterval(() => {
        setCounter(prevCounter => {
          if (prevCounter <= 80) {
            return prevCounter + 1;
          } else {
            clearInterval(intervalMiddle);
            return prevCounter;
          }
        });
      }, 14000);      
  
      const interval = setInterval(() => {
        setCounter(prevCounter => {
          if (prevCounter <= 50) {
            return prevCounter + 1;
          } else {
            clearInterval(interval);
            return prevCounter;
          }
        });
      }, 7000);
  
  
      // Cleanup function when component unmounts
      return () => {
        setIsMounted(false); // Set isMounted to false when component unmounts
        clearInterval(timeInterval);
        clearInterval(intervalMiddle);
        clearInterval(interval);
      };
    }
  }, [visible]);

  useEffect(() => {
    if (!isMounted) {
      setCounter(0); // Reset counter to 0 when the component unmounts
      setIsMounted(true); // Set isMounted back to true for next mount
    }
  }, [isMounted]);

  useEffect(() => {
    if(complete==100){
      setCounter(100)
    }
  },[complete])


  if (!visible) return null;
  const progressMessage = counter <= 100 ? `Backing up... ${counter}%` : "Backup complete!";
  return (
    <>
      <div
        id="modal"
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center"
      >
        <div className="bg-white animate-jump animate-once animate-ease-in-out mt-12 p-2 rounded-xl w-4/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              Backup
            </h3>
          </div>
          <div className="z-50 ">
            <div className="relative px-6 pb-6 flex flex-wrap justify-between">
              <div className=" my-4 w-full flex flex-col justify-center items-center text-sm lg:text-base">
                {progressMessage}
                <p className=" my-2 text-xs lg:text-sm font-medium">In this process, all your data, including files and images, has been backed up</p>
              </div>
              <div className="w-full bg-gray-300 rounded-full">
                <div
                  className={`${counter>=95?"bg-green-500":"bg-blue-600"}  text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`}
                  style={{ width: `${counter===0 ? 0 : counter}%` }}
                >
                  {counter===0 ? 0 : counter}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
