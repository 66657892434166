import React, { useEffect, useState } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import FileDisplay from "../../../Utilities/common/FileDisplay";
import DateFormat from "../../../Utilities/common/NuDate";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateorUpdate({
  data,
  visible,
  onClose,
  refreshData,
  id,
  vendorList
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [isLoading,setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    name:"",
    start_date: "",
    end_date:""
  });

  useEffect(() => {
    setCreateNew({
      name: data?.name,
      start_date: data?.start_date,
      end_date: data?.end_date,
    });
  }, [data]);

  function handleImageChange(e) {
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   name: yup
    //     .string()
    //     .min(3, "Name must be at least 3 characters")
    //     .max(15, "Name cannot exceed 15 characters")
    //     .required("Name is requred"),
    //   email: yup
    //     .string()
    //     .email("Email is invalid")
    //     .required("Email is requred"),
    //   mobile: yup
    //     .string()
    //     .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    //     .required("mobile is requerd"),
    // }),
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
        axiosInstance.put(`/Standards/${data.id}/dates`, value).then((res) => {
          if (res.data) {
           setIsLoading(false);
            resetForm();
            setSelectedFile(null)
            refreshData();
            Toast.fire({
              icon: "success",
              title: res.data.msg || "Dates Updated Successfully",
            });
            onClose();
          } else {
           setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: res.data.msg || "Dates Update Unsuccessfully",
            });
          }
        }).catch(err=>{
          setIsLoading(false);
          Toast.fire({
            icon: "error",
            title: err?.response.data.msg || "Dates Update Unsuccessfully",
          });
        });
     
    },
  });

  const formInputs = [

    {
      inputType: "NuInput",
      name: "name",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Standard Name",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full",
      disabled:true
    },
    {
      inputType: "NuInput",
      name: "start_date",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Start Date",
      labelclassname: "w-full",
      type: "date",
      customClass: "w-full",
      min:data?.last_reset_date?DateFormat({value:data.last_reset_date,format:"YYYY-MM-DD"}) :""
    },
    {
      inputType: "NuInput",
      name: "end_date",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "End Date",
      labelclassname: "w-full",
      type: "date",
      customClass: "w-full",
      min:formik.values.start_date 
    },

  ];

  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-4/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className=" z-50 ">
              <div className="relative px-6 pb-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex flex-wrap my-3">
              {/* {data?.document_type && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))} */}
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={onClose}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
