import React, { useState, useEffect } from "react";
import CreateorUpdate from "./form";
import HeaderForm from "./headerform";
import ViewMore from "./viewMore";
import Axios from "../../../Services/axiosComman";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import { useParams } from "react-router-dom";
import Import from "./import";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GetAccess } from "../../../Utilities/data/role";
import ApprovelStatus from "./approval";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Export from "../../../Utilities/common/Export";
import DateFormat from "../../../Utilities/common/NuDate";


 
const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

function ImplementationChecklistIndex() {
  const { id } = useParams();
  const location = useLocation();
  const history  = useHistory();
  let { statusFilter } = location?.state||"";

  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyHeaderModal, setShowMyHeaderModal] = useState(false);
  const handleHeaderOnClose = () => setShowMyHeaderModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);

  const [selected, setSelected] = useState(null);
  const [headers, setHeaders] = useState();
  const [expandedRow, setexpandedRow] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [implementation, setImplementation] = useState([]);
  const [implementationStatus, setImplementationStatus] = useState([]);
  const [Section, setSection] = useState('');
  const [HeaderId, setHeaderId] = useState('');
  const [total,setTotal] = useState(0)

  const [data, setData] = useState([]);

  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");



  const toggle = (i, section,headerId) => {

    if (selected === i) {
      return setSelected(null);
    }
    setexpandedRow([])
    setSelected(i);
    setSection(section)
    setHeaderId(headerId)
    fetchHeader(section,headerId);
  };

  // var total = {};

  async function fetchHeader(section,headerId) {
    await Axios.get(statusFilter?`/implementation-checklist/standard/${id}/${headerId}/section/${section}/${statusFilter}`:
      `/implementation-checklist/standard/${id}/${headerId}/section/${section}`
    ).then((res) => {
      res.data?.data && setexpandedRow(res.data?.data);
    });
  };





  function getImplementationFilterHeader(){
    Axios.get(`/implementation-checklist/standard/${id}/status/${statusFilter}?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data);
      res?.data?.data && setTotal(res?.data?.total_record)
    }).catch(error=>{
      console.log("error",error);
    })
  }

  function getImplementationHeader(){
    Axios.get(`/implementation-checklist/standard/${id}?page=${pageIndex}&per_page=${dataCount}`).then(res=>{
      res.data && setData(res.data);
      res?.data?.data && setTotal(res?.data?.total_record)
    }).catch(error=>{
      console.log("error",error);
    })
  }

  useEffect(() => {
    if(statusFilter){
      getImplementationFilterHeader()
    }else{
      getImplementationHeader()
    }
  },[pageIndex,dataCount])


  // useEffect(() => {
  //   if(statusFilter){
  //     getImplementationFilterHeader()
  //   }
  // },[statusFilter])



  function getStandard(){
    Axios.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        setStartDate(res?.data?.data.start_date)
        setEndDate(res?.data?.data.end_date)
    }).catch(error=>{
        console.log(error);
    })
  }


  useEffect(() => {
    // getImplementationHeader()
    getStandard()
    getImplementation();
    getImplementationStatus();
  }, []);

  const getImplementation = () => {
    Axios.get(`/DashBoard/standard/${id}/implementation/`).then((res) => {
      res?.data && setImplementation(res.data.data);
    });
  };

  const getImplementationStatus = () => {
    Axios.get("/Status/1/").then((res) => {
        res?.data && setImplementationStatus(res.data?.data);
    });
  };





  const HandleDelete = async (HeaderId,value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Delete ${value}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (HeaderId) {
          await Axios.delete(`/implementation-checklist/standard/${id}/header/${HeaderId}/`).then(res=>{
            if(res.data.status==true){
              // refreshData()
              Toast.fire({
                icon: "success",
                title:
                  res.data.msg ||
                  "implementationChecklist Detele Successfully",
              });
            }else{
              Toast.fire({
                icon: "error",
                title:
                  res.data.msg ||
                  "implementationChecklist Detele Unsuccessfully",
              });
            }
          }).catch(error=>{
            Toast.fire({
              icon: "error",
              title:
                error.data.msg ||
                "implementationChecklist Detele Unsuccessfully",
            });
          })
        }
      }
    })
  }
function clearFilter(){
getImplementationHeader();
setexpandedRow([])
setSelected(null)
history.replace({
  state:null
})
}



  return (
    <>
      <MainLayout
      id={id}
      total={data?.total_record}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      dataCount={dataCount}
      setDataCount={setDataCount}
      label="Implementation Checklist"
      title="Activity"
      setShowApprovalModal={setShowApprovalModal}
      hasApproval={true}
    >
        <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Checklist</p>
          <div className="flex items-center text-xs 2xl:text-sm">
            <p className="mr-2">overall</p>
            <span className="bg-login px-2 py-1 rounded-md">
              {implementation?.complete_percentage||0}%
            </span>
          </div>
          <div className="flex items-center">
            {statusFilter !=null ?<div className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer" onClick={clearFilter}>clear</div>:null}
           <div className=" w-8 mx-1">
            {GetAccess("activity_create") && <svg
              className="w-7 mx-1 cursor-pointer"
              alt="plus"
              onClick={() => setShowMyHeaderModal(true)}
              title="Add"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.61084"
                y="0.335541"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path
                d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Add Header</title>
            </svg>}
            </div>
            <Export id={id} path={'implementation_checklist'} name={`${standardName} Implementation Checklist ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}  />
           {GetAccess("activity_create") && <Import getImplementation={getImplementation} refreshData={getImplementationHeader} id={id} />} 
          </div>
        </div>
        <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className="text-xs 2xl:text-sm font-medium">
              {header.map((header, i) => (
                <th className={`py-4 ${header.width}`} key={i}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr colspan="6 " className="relative px-4">
              <td colspan="6">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data &&
                    data?.data.map((i, indx) => {
                      return (
                        <div className="" key={indx}>
                          <div className="pl-5 pr-2 py-2 mt-1.5 bg-tableeven shadow-box flex justify-between items-center">
                            <span
                              className={
                                selected === indx
                                  ? "text-xs 2xl:text-sm font-semibold text-gray-900 w-full"
                                  : "text-xs 2xl:text-sm font-semibold text-gray-500 w-[40%]"
                              }
                            >
                              <h2 className="leading-5 ">
                                {/* {i?.value[1]} */}
                                {i?.header_no} &nbsp;
                                {i?.header_title}
                              </h2>
                              <h2 className="leading-5">
                                {/* {i?.value[2]} */}
                                {i?.section_no} &nbsp;
                                {i?.section}
                              </h2>
                            </span>
                            
                            <div className="flex justify-end w-[5%]">

                              <span
                                className="bg-white w-7 h-7 flex items-center justify-center px-[10px] rounded-[3px] shadow-box cursor-pointer"
                                onClick={() =>
                                  toggle(
                                    indx,
                                    i?.section_no,
                                    i?.header_id
                                    // , i?.value[0], i?.key
                                  )
                                }
                              >
                                {selected === indx ? (
                                  <i
                                    class="fa fa-angle-up"
                                    style={{ fontSize: 15 }}
                                    title="collapse"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-angle-down"
                                    style={{ fontSize: 15 }}
                                    title="expand"
                                  ></i>
                                )}
                              </span>
                   {
                        GetAccess("activity_create") &&      <span className="w-7 ml-1">
                                <svg
                                  className="w-7  cursor-pointer"
                                  alt="plus"
                                  onClick={() => {
                                    setShowMyModal(true);
                                    setHeaders(i);
                                  }}
                                  title="Add"
                                  viewBox="0 0 52 52"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="0.61084"
                                    y="0.335541"
                                    width="51.1569"
                                    height="51.1569"
                                    rx="5"
                                    fill="#7030A0"
                                  />
                                  <path
                                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                                    stroke="white"
                                    stroke-width="2.3"
                                    stroke-linecap="round"
                                  />
                                  <title>Add</title>
                                </svg>
                              </span>

                  }

                            </div>
                          </div>
                          <div
                            className={
                              selected === indx
                                ? "tabledescription show mr-1 relative"
                                : "tabledescription mr-1 relative"
                            }
                          >
                            {expandedRow &&
                              expandedRow.map((i) => {
                                return (
                                  <tr className="odd:bg-tableodd even:bg-tableeven flex justify-between items-center text-left my-1 text-xs 2xl:text-sm py-2 font-medium">
                                    <td className="px-2 xl:px-8 text-gray-900 whitespace-normal w-[7%]">
                                      {/* {i?.sectionId} */}
                                      {i?.section_no}
                                    </td>
                                    <td className="px-2 xl:px-8 text-gray-900 whitespace-normal w-[10%]">
                                      {/* {i.subSectionId} */}
                                      {i?.sub_section_no}
                                    </td>
                                    <td className="pr-2 text-gray-900 text-justify whitespace-normal w-[45%] break-words">
                                      {i.question_activity}
                                    </td>
                                    <td className="text-gray-900 capitalize whitespace-normal w-[15%]">
                                      {i.status}
                                    </td>
                                    <td className="px-2 xl:px-8 text-gray-900 whitespace-normal w-[7%]">
                                    {i.image_path !==null && i.image_path !=="None" && i.image_path !=="[]" && i.image_path.length>0 && (
                                        <img
                                          src={paperclip}
                                          alt=""
                                          className="w-3 h-4 mx-auto"
                                        />
                                      )}
                                    </td>
                                    <td className=" w-[3%]">
                                    <ViewMore
                                      onClose={handleOnCloseMenu}
                                      visible={showMyMenu}
                                      id={i?.id}
                                      moduleId={i?.sub_section_no}
                                      fetcher={statusFilter?()=>getImplementationFilterHeader:()=>getImplementationHeader}
                                      fetcherHeader={()=>fetchHeader(Section,HeaderId)}
                                      status={implementationStatus}
                                      sectionNO={i?.section_no}
                                      getImplementation={()=>getImplementation()}

                                    />
                                    </td>
                                  </tr>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <HeaderForm
          onClose={handleHeaderOnClose}
          visible={showMyHeaderModal}
          id={id}
          refreshData={statusFilter?getImplementationFilterHeader:getImplementationHeader}
        />
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={statusFilter?getImplementationFilterHeader:getImplementationHeader}
          id={id}
          headerId={headers}
          status={implementationStatus}
          // fetchHeader={fetchHeader}
          fetcherHeader={()=>fetchHeader(Section)}
          getImplementation={()=>getImplementation()}

        />
        <ApprovelStatus
            visible={showApprovalModal}
            onClose={handleApprovalOnClose}
            refreshData={statusFilter?getImplementationFilterHeader:getImplementationHeader}
        />

    </MainLayout>
    </>
  );
}

export default ImplementationChecklistIndex;

const header = [
  {
    headone: "Section",
    width: "w-[7%]",
  },
  {
    headone: "Sub - Section",
    width: "w-[10%]",
  },
  {
    headone: "Questions / Activity",
    width: "w-[47%]",
  },
    {
    headone: "Status",
    width: "w-[15%]",
  },
  {
    headone: "Attachment Artefact",
    width: "w-[18%]",
  },
  {
    headone: "",
    width: "w-[3%]",
  }
];

