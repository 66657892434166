import React from "react";



export default function ShowMsg({
  visible,
  onClose,
  standard=" "
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        onClick={handleOnClose}
        className="bg-black bg-opacity-20 fixed inset-0 backdrop-blur-none
         flex justify-center items-center z-10"
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-4/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-xs 2xl:text-sm font-semibold">
              Standard not subscribed
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
              {/* <img src={Close} alt="close" title="close" /> */}
            </span>
          </div>
          <div className="px-6 text-center">
            <p className=" text-left">Please contact Metatron infotech to subscribe {standard} standard</p> 
            <button className=" text-white rounded-lg px-4 mt-6 py-1 bg-primary " onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
