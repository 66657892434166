import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import axiosInstance from "../../../Services/axiosComman";
import FileDisplay from "../../../Utilities/common/FileDisplay";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({ data, visible, onClose, refreshData, id,status }) {
  // const handleOnClose = (e) => {
  //   if (e.target.id === 'modal') onClose();
  // };

  const [isLoading,setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    start_date:"",
    end_date: "",
    reference_number: "",
    performed_by: "",
    process_description: "",
    audit_description: "",
    auditee: "",
    result: "",
    status: "",
    // remarks: "",
  });
  useEffect(() => {
    setCreateNew({
      "start_date": data?.start_date,
      "end_date": data?.end_date,
      "reference_number": data?.reference_number,
      "performed_by": data?.performed_by,
      "process_description": data?.process_description,
      "audit_description": data?.audit_description,
      "auditee": data?.auditee,
      "result": data?.result,
      "status": data?.status,
      // "remarks": data?.remarks,
    })
    if(data?.attachment !== null){
      setFilePathList(data?.attachment)
      setRemoveFilePathList(data?.attachment)
    }
  }, [data])

  const formInputs = [
    {
      inputType: "NuInput",
      name: "start_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Start Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "end_date",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "End Date",
      labelclassname: "w-full",
      type: "date",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },

    {
      inputType: "NuInput",
      name: "reference_number",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Reference Number",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "performed_by",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Performed By",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    
    {
      inputType: "NuTextArea",
      name: "process_description",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Process Description",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuTextArea",
      name: "audit_description",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Audit Description",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "auditee",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Auditee",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "result",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Results",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "filePath",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Artefact",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleImageChange,
    },
    {
      inputType: "NuSelect",
      name: "status",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select Status",
      label: "Status",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options: status.map((i) => {
        return {
          value: i.status_type,
          label: i.status_type,
        };
      })||[],
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   auditDate: yup.string().required('Name Required'),
    // }),
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
      if (data?.id) {
        if (removefilePathList.length !== filePathList.length) {
          let finalArr = removefilePathList.filter(
            (items) => !filePathList.includes(items)
          );
          value.files_to_remove = finalArr.map(item=>item.replace(window.WEB_APP_API_URL, "localhost"));
        }
        let formData = new FormData()
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formData.append("attachment_artifact", file);
          });
        } else{
          formData.append("attachment_artifact",[]);
        }
        formData.append("audit_schedule", JSON.stringify(value));
        axiosInstance.put(`/Audit-Schedule/${data?.id}/screen_closed`,{"isClosed":false}).then(res=>{
        axiosInstance.put(`/Audit-Schedule/${data.id}/`, formData)
          .then(res => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm();
              refreshData();
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Audit Schedule Updated Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || res.data.error || "Audit Schedule Update Unsuccessfully",
              });
            }
          }).catch(err=>{
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.error || "Audit Schedule Update Unsuccessfully",
            });
          })
        }).catch(err=>{
          setIsLoading(false);
          console.log("err")
        })

      } else {
        let formData = new FormData()
        if(selectedFile !== null){
          selectedFile.forEach((file) => {
            formData.append("attachment_artifact", file);
          });
        } else{
          formData.append("attachment_artifact",[]);
        }
        formData.append("audit_schedule", JSON.stringify(value));
        axiosInstance.post(`/Audit-Schedule/standard/${id}/`, formData)
          .then(res => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm();
              refreshData();
              onClose();
              Toast.fire({
                icon: "success",
                title: res.data.msg || "Audit Schedule Created Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || res.data.error || "Audit Schedule Creation Unsuccessfully",
              });
            }
          }).catch(err=>{
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.error || "Audit Schedule Creation Unsuccessfully",
            });
          })
      }
    }
  })

  function handleImageChange(e) {
    const files = Array.from(e.target.files);
    setSelectedFile(files);
  }
  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }

  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
    setSelectedFile(null)
      formik.resetForm();
      onClose()
    };
  };

  const closeFunction = () =>{
    setSelectedFile(null)
    formik.resetForm()
    onClose()
}

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
      >
        <div className="bg-white mt-1 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              <svg className="w-7 h-7" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.842773" y="0.560791" width="51.1569" height="51.1569" rx="5" fill="#7030A0"/>
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985"/>
              <path d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816" stroke="white" stroke-width="2.3" stroke-linecap="round"/>
              <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="h-[70vh] overflow-y-auto ">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex flex-wrap my-3">
                {data?.attachment && 
                 data?.attachment != null &&
                 data.attachment != "None" &&
                 data?.attachment.length>0 && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
