import React from "react";

function Roll() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width="100"
      height="100"
    //   style="shape-rendering: auto; display: block; background: rgb(255, 255, 255);"
    >
      <g>
        <circle
          stroke-linecap="round"
          fill="none"
          stroke-dasharray="50.26548245743669 50.26548245743669"
          stroke="#7030A0"
          stroke-width="8"
          r="32"
          cy="50"
          cx="50"
        >
          <animateTransform
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1.4492753623188404s"
            repeatCount="indefinite"
            type="rotate"
            attributeName="transform"
          ></animateTransform>
        </circle>
        <g></g>
      </g>
    </svg>
  );
}

export default Roll;
