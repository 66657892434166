import React, { createContext, useContext, useState } from 'react';

const StandardContext = createContext();

export const NumberProvider = ({ children }) => {
  const [standardName, setStandardName] = useState("");
  const [standardId, setStandardId] = useState("");

  const updateName = (newName) => {
    setStandardName(newName);
  };

  const updateId = (newId) => {
    setStandardId(newId);
  };

  return (
    <StandardContext.Provider value={{ standardName,standardId, updateName,updateId }}>
      {children}
    </StandardContext.Provider>
  );
};

export const useStandard = () => {
  const context = useContext(StandardContext);
  if (!context) {
    throw new Error('useStandard must be used within a StandardProvider');
  } 
  return context;
};
