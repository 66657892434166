import React, { useState } from "react";
import InnerNavbar from "../common/Navbar/Navbar";
import Pagination from "../common/Pagination";
import { GetAccess } from "../data/role";

import ToggleViews from "../common/ToggleViews";
import Breadcrumbs from "../common/Breadcrumbs";
import AddIcon from "../Image/svg/add";
import { GetYear } from "../common/GetYear";
import StandardLayout from "./StandardLayout";




function Index({id,children,total,pageIndex,setPageIndex,dataCount,setDataCount,view,listView,cardView,label,setShowMyModal,title,Access}) {
  return (
      <StandardLayout>
      <Breadcrumbs title={title} label={label} />
      <div className="w-[97.5%] bg-white shadow-main h-[calc(100vh-120px)] rounded-2xl mx-4">
        <div className="py-[10px] pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">{label}</p>
          <div className=" flex gap-3 justify-center items-center">
            <ToggleViews view={view} listView={listView} cardView={cardView}/>
            <div className=" w-8">
            {GetAccess(Access) && (
                <AddIcon onClick={() => setShowMyModal(true)} />
            )}
          </div>
          </div>
        </div>
        {children}
        <Pagination
          total={total}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          dataCount={dataCount}
          setDataCount={setDataCount}
        />

      </div>
      </StandardLayout>
  );
}

export default Index;

