import axiosInstance from "../../Services/axiosComman";
import DecryptData from "./Decrypt";

let userData = localStorage.getItem("aTaDrEsU")?DecryptData('aTaDrEsU'):""
let id = userData?.userId;
const logout = () => {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }

  var datetime =
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    now.getHours() +
    ":" +
    now.getMinutes() +
    ":" +
    now.getSeconds();

  let value = {
    test:"",
    datetime: datetime,
    userId: id,
  };
  axiosInstance.post(`Auth/logout`, value).then(res=>{
    if(res.data.status==true){
      window.localStorage.removeItem("aTaDrEsU");
      window.localStorage.removeItem("nOiSsImRePrEsU");
      window.localStorage.removeItem("yEkSseCcaReSu");
      window.localStorage.removeItem("nEkOtResU");
      window.location.replace("/logout");
    }
  }).catch(err=>{
    console.log(err);
  })
};

export default logout;
