import React, { useState } from "react";
import AxiosComman from "../../../Services/axiosComman"
import Edit from "./form";
import Swal from "sweetalert2";
import MaintenanceView from "./MaintenanceProfile";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import { ScreenClose, ScreenOpen } from "../../../Utilities/data/render";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({ id, fetcher, data, visible, onClose,frequency,status,getMaintenance }) {
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => {
    ScreenClose({link:"Maintenance",id:data.id})
    setShowMyModal(false);
  }
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleClose = () => setShowFile(false);
  const [showFile, setShowFile] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [viewData, setViewData] = useState([]);
  
  async function getViewPage() {
      await AxiosComman.get(`/Maintenance/${data.id}/`)  
        .then((res) => {
          res.data?.data &&
          setViewData(res.data?.data);
        })
        .catch((err) => console.log(err));
    }


    const handleDelete = async (id,value) => {
      Swal.fire({
        title: 'Are you sure?',
        text: `You want to Delete Sub Section ${value}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (id) {
            await AxiosComman.delete(`/Maintenance/${data.id}/`)
              .then((res) => {
                if (res.status == 200) {
                  getMaintenance()
                  fetcher()
                  Toast.fire({
                    icon: "success",
                    title: res.data?.message || " Maintenance Deleted successfully",
                  });
                } else {
                  Toast.fire({
                    icon: "error",
                    title: res.data?.message || "  Maintenance Deleted  unsuccessfully",
                  });
                }
              })
              .catch((error) => {
                console.log(error.message);
                Toast.fire({
                  icon: "error",
                  title:error?.response.data?.message || "  Maintenance Deleted  unsuccessfully",
                });
              });
          }
        }
      })
    }

    

    function EditScreen(){
      ScreenOpen({link:"Maintenance",id:data.id})
      setShowMyModal(true)
    }

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;
    return (
    <>
      <div
        className="hover:drop-shadow-sm mr-2 py-2 px-[14px] rounded-3xl bg-white dropdown cursor-pointer relative"
        onClick={() => {setShowMyMenu(!showMyMenu);getViewPage()}}
      >
        <svg className='m-auto w-[4px]' viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        </svg>
        {/* {showMyMenu ? ( */}
          <div className='hidden dropdown-menu'><div id="modal"
            onClick={handleOnCloseMenu}
            className="bg-transparent fixed inset-0 flex justify-center items-center "></div>
            <div className="bg-white rounded-xl text-left w-20 absolute -left-20 -top-1">
              <div className="grid p-1 rounded-t">
                <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => { setShowFile(true) }}>View</span>               
                {GetAccess("activity_edit")&& <span className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer" onClick={EditScreen}>Edit</span>}
                {(GetRole()||GetAccess("activity_delete")) &&
                                 <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => {
                                  handleDelete(data?.id,data?.task||"-");
                                }
                                }>Delete</span>
                }
              </div>
            </div>
          </div>
          {/* ) : null} */}
      </div>
      <Edit onClose={handleOnClose} visible={showMyModal} data={viewData}  refreshData={fetcher} frequency={frequency}
                            getMaintenance={getMaintenance}
                            status={status}/>
      <MaintenanceView
       data={viewData}
       onClose={handleClose}
       visible={showFile}
     />
    </>
  );
}

export default Viewmore;
