import React, { useState } from 'react'
// import moreIcon from '../../../Components/Images/more.svg'
import AxiosComman from "../../../Services/axiosComman"
import Edit from './form'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import menu from "../../../Utilities/Image/icons/menu.svg";
import {Admin,GetAccess,GetRole,SuperAdmin} from "../../../Utilities/data/role";
import { View } from './settingsProfile';

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({ id, fetcher, data, visible, onClose,role,user }) {
  const [showMyModal, setShowMyModal] = useState(false)
  const handleOnClose = () => setShowMyModal(false)
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false)
  //   const handleOnCloseMenu = (e) => {
  //     if(e.target.id === 'modal') onClose();
  // };
  //   const [showSideModal, setShowSideModal] = useState(false);


  
  const handleDelete = async (id,value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Delete ${value}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          await AxiosComman.delete(`/User-Role/${id}`)
            .then((res) => {
              if (res.status==200) {
                fetcher()
                Toast.fire({
                  icon: "success",
                  title: res.data?.message || "Status Deleted successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: res.data?.message || "Status delete  unsuccessfully",
                });
              }
            })
            .catch((error) => {
              console.log(error.message);
              Toast.fire({
                icon: "error",
                title: error?.response.data?.message || "Status delete  unsuccessfully",
              });
            });
        }
      }
    })
  }


  

  return (
    <>
     <div
        className="hover:drop-shadow-sm mr-2 py-2 px-[14px] rounded-3xl bg-white dropdown cursor-pointer relative"
        // onClick={() => setShowMyMenu(!showMyMenu)}
      >
        <svg className='m-auto w-[4px]' viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        </svg>
      {GetAccess('admin_edit')||GetAccess('admin_delete')?
        <div className='hidden dropdown-menu'><div id="modal"
          onClick={handleOnCloseMenu}
          className="bg-transparent fixed inset-0 flex justify-center items-center "></div>
          <div className="bg-white rounded-xl w-20 absolute -left-20 -top-1">
            <div className="grid p-1 rounded-t">
              {
            GetAccess('admin_edit') &&      <span className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer" onClick={() => setShowMyModal(true)}>Edit</span>}
               
               
               {(GetRole()||GetAccess('admin_delete')) &&        <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => {
                  handleDelete(data?.userRoleId,data?.role);
                }
                }>Delete</span>
              }
            </div>
          </div>
        </div>
        : null}      
        </div>


      <Edit onClose={handleOnClose} role={role} user={user} visible={showMyModal} data={data} refreshData={fetcher} />
      <View data={data} onClose={handleClose} visible={showFile}/>
    </>
  )
}

export default Viewmore