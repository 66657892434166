// PieChart.jsx
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const chartData = {
    labels: data.labels,
    datasets: [
      {
        data: data.values,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  return <Doughnut 
  data={chartData} 
  options={{
    plugins:{
      legend:false,
        title:{
          display:false
        },
        tooltip:{
          backgroundColor:'transparent',
        
         bodyFont:{
           size:0
         }
        }
      }
  }}
  />;
};

export default PieChart;