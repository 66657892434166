import React, { useEffect, useState } from "react";
import Axios from "../../Services/axiosComman";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import { Dough } from "../../Utilities/common/Doughnut";
import DoughTwo from "../../Utilities/common/DoughnutTwo";
import DateFormat from "../../Utilities/common/NuDate";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import StandardLayout from "../../Utilities/Layout/StandardLayout";
import { useStandard } from "../../Utilities/context/StandardContext";

function SubDashboardView() {
  const {standardName} = useStandard();

  const history = useHistory();
  const { id } = useParams();
  const [implementation, setImplementation] = useState("");
  const [internal, setInternal] = useState("");
  const [maintenance, setMaintenance] = useState("");
  const [training, setTraining] = useState("");
  const [security, setSecurity] = useState("");
  const [vendor, setVendor] = useState("");
  const [audit, setAudit] = useState("");
  const [mom, setMom] = useState("");
  const [masterListofDocument,setMasterListofDocument] = useState("")

  useEffect(() => {
    getImplementation();
    getInternal();
    getMaintenance();
    getTraining();
    getSecurity();
    getVendor();
    getAudit();
    getMom();
    getMasterListofDocument()
  }, []);

  async function getImplementation() {
    await Axios.get(`/DashBoard/standard/${id}/implementation/`).then((res) => {
      setImplementation(res?.data?.data);
    });
  }
  async function getInternal() {
    await Axios.get(`/DashBoard/standard/${id}/internal_audit/`).then((res) => {
      setInternal(res?.data?.data);
    });
  }
  async function getMaintenance() {
    await Axios.get(`/DashBoard/standard/${id}/maintenance/`).then((res) => {
      setMaintenance(res?.data?.data);
    });
  }
  async function getTraining() {
    await Axios.get(`/DashBoard/standard/${id}/training-calender/`).then(
      (res) => {
        setTraining(res?.data?.data);
      }
    );
  }
  async function getSecurity() {
    await Axios.get(`/DashBoard/standard/${id}/security-incident/`).then(
      (res) => {
        setSecurity(res?.data?.data);
      }
    );
  }
  async function getVendor() {
    await Axios.get(`/DashBoard/standard/${id}/vendor/`).then((res) => {
      setVendor(res?.data?.data);
    });
  }
  async function getAudit() {
    await Axios.get(`/DashBoard/standard/${id}/audit-schedule/`).then((res) => {
      setAudit(res?.data?.data);
    });
  }
  async function getMom() {
    await Axios.get(`/DashBoard/standard/${id}/mom/`).then((res) => {
      setMom(res?.data?.data);
    });
  }
  async function getMasterListofDocument() {
    await Axios.get(`/DashBoard/standard/${id}/master_list_of_document/`).then((res) => {
      // setMom(res?.data?.data);
      setMasterListofDocument(res?.data?.data)
      // console.log("ccc",res?.data?.data);
    });
  }


  function FilterData(data){
    let statusTempData = Object.entries(data);
    let finalData = statusTempData.filter(([key,value])=>key!=='verified_and_approved')
    let justStrings = Object.fromEntries(finalData);
    return justStrings||[];
  }

  function genStatusDataInternal(statusData) {
    let finalData = FilterData(statusData)

    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalData);
    let Y = Object.values(finalData);
    returnValue.label = X;
    returnValue.data = Y;

    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum != 0) {
      return <div className=" cursor-pointer"><Dough dataWithLabel={returnValue} handleSegmentClick={handleSegmentClickInternal} /></div>;
    } else {
      return <DoughTwo />;
    }
  }

  function genStatusDataImplementation(statusData) {
    let finalData = FilterData(statusData)

    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalData);
    let Y = Object.values(finalData);
    returnValue.label = X;
    returnValue.data = Y;
    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum != 0) {
      return <div className=" cursor-pointer"><Dough dataWithLabel={returnValue} handleSegmentClick={handleSegmentClickImplementation} /></div>;
    } else {
      return <DoughTwo />;
    }
  }

  function genStatusDataMaintenance(statusData) {
    let finalData = FilterData(statusData)

    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalData);
    let Y = Object.values(finalData);
    returnValue.label = X;
    returnValue.data = Y;
    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum != 0) {
      return <div className=" cursor-pointer"><Dough dataWithLabel={returnValue} handleSegmentClick={handleSegmentClickMaintenance} /></div>;
    } else {
      return <DoughTwo />;
    }
  }

  function genStatusDataMasterListofDocument(statusData) {
    let finalData = FilterData(statusData)

    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalData);
    let Y = Object.values(finalData);
    returnValue.label = X;
    returnValue.data = Y;
    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum != 0) {
      return <div className=" cursor-pointer"><Dough dataWithLabel={returnValue} handleSegmentClick={handleSegmentClickMasterListofDocument} /></div>;
    } else {
      return <DoughTwo />;
    }
  }



  function handleSegmentClickImplementation(event, chartElements){
    // console.log("event",test);
    // console.log("chart",chartElements[0]?.element?.options?.borderColor);
    let color = chartElements[0]?.element?.options?.borderColor
    if( color =="#007300"){
      history.push({
        pathname: `/activity/implementation-checklist/${id}`,
        state: { statusFilter: 'completed' }
      });
    }else if(color == "#E60000"){
      history.push({
        pathname: `/activity/implementation-checklist/${id}`,
        state: { statusFilter: 'yet to start' }
      });
    }else if(color == "#E6C300"){
            history.push({
        pathname: `/activity/implementation-checklist/${id}`,
        state: { statusFilter: 'in progress' }
      });
    }else{
      return false
    }
  }


  function handleSegmentClickInternal(event, chartElements){
    // console.log("event",test);
    // console.log("chart",chartElements[0]?.element?.options?.borderColor);
    let color = chartElements[0]?.element?.options?.borderColor
    if( color =="#007300"){
      history.push({
        pathname: `/activity/internal-audit/${id}`,
        state: { statusFilter: 'completed' }
      });
    }else if(color == "#E60000"){
      history.push({
        pathname: `/activity/internal-audit/${id}`,
        state: { statusFilter: 'yet to start' }
      });
    }else if(color == "#E6C300"){
            history.push({
        pathname: `/activity/internal-audit/${id}`,
        state: { statusFilter: 'in progress' }
      });
    }else{
      return false
    }
  }

  function handleSegmentClickMaintenance(event, chartElements){
    // console.log("event",test);
    // console.log("chart",chartElements[0]?.element?.options?.borderColor);
    let color = chartElements[0]?.element?.options?.borderColor
    if( color =="#007300"){
      history.push({
        pathname: `/activity/maintenance/${id}`,
        state: { statusFilter: 'completed' }
      });
    }else if(color == "#E60000"){
      history.push({
        pathname: `/activity/maintenance/${id}`,
        state: { statusFilter: 'yet to start' }
      });
    }else if(color == "#E6C300"){
            history.push({
        pathname: `/activity/maintenance/${id}`,
        state: { statusFilter: 'in progress' }
      });
    }else{
      return false
    }
  }

  function handleSegmentClickMasterListofDocument(event, chartElements){
    // console.log("event",test);
    // console.log("chart",chartElements[0]?.element?.options?.borderColor);
    let color = chartElements[0]?.element?.options?.borderColor
    if( color =="#007300"){
      history.push({
        pathname: `/activity/master-list-of-documents/${id}`,
        state: { statusFilter: 'completed' }
      });
    }else if(color == "#E60000"){
      history.push({
        pathname: `/activity/master-list-of-documents/${id}`,
        state: { statusFilter: 'yet to start' }
      });
    }else if(color == "#E6C300"){
            history.push({
        pathname: `/activity/master-list-of-documents/${id}`,
        state: { statusFilter: 'in progress' }
      });
    }else{
      return false
    }
  }

  return (
    <StandardLayout>
      <div className="mx-4 xl:mx-12 2xl:mx-24 flex items-center gap-4 text-xs 2xl:text-sm py-1">
        <p className="ml-2 text-secondary">
          Dashboard /&nbsp;
          <span className="text-text font-medium">{standardName}</span>
        </p>
        <Link to="/progress" className="text-primary border border-primary px-3 py-[2px] text-sm font-medium rounded-md flex items-center"> View Progress </Link>
      </div>
      <div className="h-[83vh] w-11/12 mx-auto bg-white shadow-main rounded-xl overflow-y-auto">
        <div className="w-[96%] h-full mx-auto lg:flex justify-start">
          <div className=" w-full lg:w-8/12 h-full  flex flex-wrap justify-between ">
            <div className=" w-3/12 h-[60%] mt-4">
              <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0">
                <p className="text-center h-[15%] text-sm pt-3 text-[#6E6985]">
                  Implementation Checklist
                </p>
                <div className=" h-[40%] w-[50%] mx-auto mt-4">
                  {implementation ? (
                    genStatusDataImplementation(implementation)
                  ) : (
                    <DoughTwo />
                  )}
                </div>
                <div className="  flex flex-col justify-evenly">
                  <Link to={{
                    pathname:`/activity/implementation-checklist/${id}`,
                    state:{ statusFilter: 'yet to start' }
                  }}>
                  <p className="flex justify-between  items-center text-xs 2xl:text-sm  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#FF0000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#FF0000] mx-1 2xl:mx-2"></div>
                      Yet To Start
                    </span>
                    <span className=" font-medium">
                      {implementation?.Not_yet_start_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/implementation-checklist/${id}`,
                    state:{ statusFilter: 'in progress' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#ffd700]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#ffd700] mx-1 2xl:mx-2"></div>
                      In Progress
                    </span>
                    <span className=" font-medium">
                      {implementation?.in_progress_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/implementation-checklist/${id}`,
                    state:{ statusFilter: 'completed' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#008000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#008000] mx-1 2xl:mx-2"></div>
                      Completed
                    </span>
                    <span className=" font-medium">
                      {implementation?.complete_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  {
                    implementation?.verified_and_approved &&
                    <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#7030A0]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#7030A0] mx-1 2xl:mx-2"></div>
                       Reviewed & Approved
                    </span>
                  </p>
                  }
                </div>
              </div>
            </div>

            <div className=" w-3/12 h-[60%] mt-4">
              <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0">
                <p className="text-center h-[15%] text-sm pt-3 text-[#6E6985]">
                Internal Audit Checklist
                </p>
                <div className=" h-[40%] w-[50%] mx-auto mt-4">
                  {internal ? genStatusDataInternal(internal) : <DoughTwo />}
                </div>
                <div className="  flex flex-col justify-evenly">
                <Link to={{
                    pathname:`/activity/internal-audit/${id}`,
                    state:{ statusFilter: 'yet to start' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#FF0000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#FF0000] mx-1 2xl:mx-2"></div>
                      Yet To Start
                    </span>
                    <span className=" font-medium">
                      {internal?.Not_yet_start_percentage || 0} %
                    </span>
                  </p>
                  </Link>
                  <Link to={{
                    pathname:`/activity/internal-audit/${id}`,
                    state:{ statusFilter: 'in progress' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#ffd700]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#ffd700] mx-1 2xl:mx-2"></div>
                      In Progress
                    </span>
                    <span className=" font-medium">
                      {internal?.in_progress_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/internal-audit/${id}`,
                    state:{ statusFilter: 'completed' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#008000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#008000] mx-1 2xl:mx-2"></div>
                      Completed
                    </span>
                    <span className=" font-medium">
                      {internal?.complete_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  {
                    internal?.verified_and_approved &&
                    <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#7030A0]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#7030A0] mx-1 2xl:mx-2"></div>
                       Reviewed & Approved
                    </span>
                  </p>
                  }

                </div>
              </div>
            </div>

            <div className=" w-3/12 h-[60%] mt-4">
              <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0">
                <p className="text-center h-[15%] text-sm pt-3 text-[#6E6985]">
                  Maintenance
                </p>
                <div className=" h-[40%] w-[50%] mx-auto mt-4">
                  {maintenance ? genStatusDataMaintenance(maintenance) : <DoughTwo />}
                </div>
                <div className="  flex flex-col justify-evenly">
                <Link to={{
                    pathname:`/activity/maintenance/${id}`,
                    state:{ statusFilter: 'yet to start' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#F3564F]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#FF0000] mx-1 2xl:mx-2"></div>
                      Yet To Start
                    </span>
                    <span className=" font-medium">
                      {maintenance?.Not_yet_start_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/maintenance/${id}`,
                    state:{ statusFilter: 'in progress' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#ffd700]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#ffd700] mx-1 2xl:mx-2"></div>
                      In Progress
                    </span>
                    <span className=" font-medium">
                      {maintenance?.in_progress_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/maintenance/${id}`,
                    state:{ statusFilter: 'completed' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#008000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#008000] mx-1 2xl:mx-2"></div>
                      Completed
                    </span>
                    <span className=" font-medium">
                      {maintenance?.complete_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  {
                    maintenance?.verified_and_approved &&
                    <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#7030A0]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#7030A0] mx-1 2xl:mx-2"></div>
                       Reviewed & Approved
                    </span>
                  </p>
                  }
                </div>
              </div>
            </div>

            <div className=" w-3/12 h-[60%] mt-4">
              <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0">
                <p className="text-center h-[15%] text-sm pt-3 text-[#6E6985]">
                Master List of Documents
                </p>
                <div className=" h-[40%] w-[50%] mx-auto mt-4">
                  {masterListofDocument ? genStatusDataMasterListofDocument(masterListofDocument) : <DoughTwo />}
                </div>
                <div className="  flex flex-col justify-evenly">
                <Link to={{
                    pathname:`/activity/master-list-of-documents/${id}`,
                    state:{ statusFilter: 'yet to start' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#FF0000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#FF0000] mx-1 2xl:mx-2"></div>
                      Yet To Start
                    </span>
                    <span className=" font-medium">
                      {masterListofDocument?.Not_yet_start_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/master-list-of-documents/${id}`,
                    state:{ statusFilter: 'in progress' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#ffd700]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#ffd700] mx-1 2xl:mx-2"></div>
                      In Progress
                    </span>
                    <span className=" font-medium">
                      {masterListofDocument?.in_progress_percentage || 0} %
                    </span>
                  </p>
                  </Link>

                  <Link to={{
                    pathname:`/activity/master-list-of-documents/${id}`,
                    state:{ statusFilter: 'completed' }
                  }}>
                  <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#008000]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#008000] mx-1 2xl:mx-2"></div>
                      Completed
                    </span>
                    <span className=" font-medium">
                      {masterListofDocument?.complete_percentage || 0} %
                    </span>
                  </p>
                  </Link>
                  {
                    masterListofDocument?.verified_and_approved &&
                    <p className="flex justify-between text-xs 2xl:text-sm items-center  my-[6px] px-3 ">
                    <span className="flex justify-between items-center font-medium text-[#7030A0]">
                      <div className=" w-2 h-2 2xl:w-3 2xl:h-3 rounded-full bg-[#7030A0] mx-1 2xl:mx-2"></div>
                       Reviewed & Approved
                    </span>
                  </p>
                  }
                </div>
              </div>
            </div>

            <div className=" w-4/12 h-[30%] mt-4">
              <Link to={`/information/training-calendar/${id}`}>
                <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0 cursor-pointer">
                  <p className="text-center text-sm pt-3 text-[#6E6985]">
                    Training Calendar
                  </p>
                  <p className="text-center pt-10 lg:pt-16 text-3xl text-[#6E6985]">
                    {training?.total_count||0}
                  </p>
                </div>
              </Link>
            </div>

            <div className=" w-4/12 h-[30%] mt-4">
            <Link to={`/information/security-incident/${id}`}>
              <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0 cursor-pointer">
                <p className="text-center text-sm pt-3 text-[#6E6985]">
                  Security Incident
                </p>
                <p className="text-center pt-10 lg:pt-16 text-3xl text-[#6E6985]">
                  {security?.total_count||0}
                </p>
              </div>
              </Link>
            </div>

            <div className=" w-4/12 h-[30%] mt-4">
              <Link to={`/information/vendor-management/${id}`}>
              <div className="w-[95%] h-full bg-white shadow-main rounded-md mx-auto md:mx-0">
                <p className="text-center text-sm pt-3 text-[#6E6985]">
                  Total Vendor
                </p>
                <p className="text-center pt-10 lg:pt-16 text-3xl text-[#6E6985]">
                  {vendor||0}
                </p>
              </div>
              </Link>
            </div>
            
          </div>

          <div className=" w-full lg:w-4/12 h-full  pl-4">
            <div className=" w-full h-[45%] mx-auto bg-white shadow-main rounded-md my-5 p-4 overflow-y-scroll">
            <Link to={`/activity/audit-calendar/${id}`}><p className="text-sm text-[#6E6985] pb-5">Audit Schedule</p></Link>
              <div className="w-full flex justify-between ">
                <div className="w-[47%]">
                  <div className="w-full h-8 bg-[#F2E9FF] rounded-md p-1.5">
                    <p className="text-sm text-[#7030A0] pl-2 pb-5">
                      Previous Date
                    </p>
                  </div>
                  {audit?.previous_date?.map((i,idx) => (
                    <p className="py-3 pl-5 text-sm text-[#8C87A6]" key={idx}>
                      {" "}
                      {<DateFormat value={i} format="DD-MMM-YYYY" /> ||
                        "-"}{" "}
                    </p>
                  ))}
                </div>
                <div className="w-[47%]">
                  <div className="w-full h-8 bg-[#F2E9FF] rounded-md p-1.5">
                    <p className="text-sm text-[#7030A0] pl-2 pb-5">
                      Upcoming Date
                    </p>
                  </div>
                  {audit?.upcoming_date?.map((i,idx) => (
                    <p className="py-3 pl-5 text-sm text-[#8C87A6]" key={idx}>
                      {<DateFormat value={i} format="DD-MMM-YYYY" /> || "-"}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className=" w-full h-[45%] mx-auto bg-white shadow-main rounded-md my-5 p-4 overflow-y-scroll">
            <Link to={`/activity/ciso-mom/${id}`}><p className="text-sm text-[#6E6985] pb-5">CISO/MRM Meeting MoM</p></Link> 
              <div className="w-full flex justify-between ">
                <div className="w-[47%]">
                  <div className="w-full h-8 bg-[#F2E9FF] rounded-md p-1.5">
                    <p className="text-sm text-[#7030A0] pl-2 pb-5">
                      Previous Date
                    </p>
                  </div>
                  {mom?.previous_date?.map((i,idx) => (
                    <p className="py-3 pl-5 text-sm text-[#8C87A6]" key={idx}>
                      {<DateFormat value={i} format="DD-MMM-YYYY" /> || "-"}
                    </p>
                  ))}
                </div>
                <div className="w-[47%]">
                  <div className="w-full h-8 bg-[#F2E9FF] rounded-md p-1.5">
                    <p className="text-sm text-[#7030A0] pl-2 pb-5">
                      Upcoming Date
                    </p>
                  </div>
                  {mom?.upcoming_date?.map((i,idx) => (
                    <p className="py-3 pl-5 text-sm text-[#8C87A6]" key={idx}>
                      {<DateFormat value={i} format="DD-MMM-YYYY" /> || "-"}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardLayout>
  );
}

export default SubDashboardView;

