import React,{useEffect,useState} from "react";
import left from "../Image/icons/left.svg";
import leftdouble from "../Image/icons/leftdouble.svg";
import right from "../Image/icons/right.svg";
import rightdouble from "../Image/icons/rightdouble.svg";

export default function Pagination({
  pageIndex,
  setPageIndex,
  dataCount,
  setDataCount,
  total
}) {
  function qwer() {
    let x = document.getElementById('page');
    setDataCount(x[x.selectedIndex].innerHTML)
    setPageIndex(1)
}
const [totalPages, setTotalPages] = useState(0);
useEffect(() => {
  if (total > 0 && dataCount > 0)
      setTotalPages(Math.ceil(total / dataCount));
}, [total, dataCount]);

  return (
    <div className="flex items-center justify-start w-full h-10">
      <div className="flex justify-between items-center w-3/12 ml-10 text-center text-xs 2xl:text-sm">
        <button onClick={() => setPageIndex(1)}>
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.90191 1.21179C9.12854 1.43849 9.25586 1.74592 9.25586 2.06648C9.25586 2.38703 9.12854 2.69446 8.90191 2.92116L2.91793 8.90514L8.90191 14.8891C9.12212 15.1171 9.24397 15.4225 9.24122 15.7395C9.23846 16.0564 9.11132 16.3596 8.88719 16.5838C8.66305 16.8079 8.35985 16.935 8.04288 16.9378C7.72591 16.9406 7.42055 16.8187 7.19255 16.5985L0.353877 9.75983C0.127246 9.53313 -6.90303e-05 9.2257 -6.90163e-05 8.90514C-6.90023e-05 8.58459 0.127246 8.27716 0.353877 8.05046L7.19255 1.21179C7.41925 0.985163 7.72668 0.857849 8.04723 0.857849C8.36778 0.857849 8.67521 0.985163 8.90191 1.21179Z"
              fill="#67748E"
            />
            <path
              d="M15.8804 1.21179C16.1071 1.43849 16.2344 1.74592 16.2344 2.06648C16.2344 2.38703 16.1071 2.69446 15.8804 2.92116L9.89644 8.90514L15.8804 14.8891C16.1006 15.1171 16.2225 15.4225 16.2197 15.7395C16.217 16.0564 16.0898 16.3596 15.8657 16.5838C15.6416 16.8079 15.3384 16.935 15.0214 16.9378C14.7044 16.9406 14.3991 16.8187 14.1711 16.5985L7.33239 9.75983C7.10576 9.53313 6.97845 9.2257 6.97845 8.90514C6.97845 8.58459 7.10576 8.27716 7.33239 8.05046L14.1711 1.21179C14.3978 0.985163 14.7052 0.857849 15.0257 0.857849C15.3463 0.857849 15.6537 0.985163 15.8804 1.21179Z"
              fill="#67748E"
            />
          </svg>
        </button>
        <button onClick={() => setPageIndex(pageIndex > 1 ? pageIndex - 1 : 1)}>
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.39019 1.21179C9.61683 1.43849 9.74414 1.74592 9.74414 2.06648C9.74414 2.38703 9.61683 2.69446 9.39019 2.92116L3.40621 8.90514L9.39019 14.8891C9.6104 15.1171 9.73225 15.4225 9.7295 15.7395C9.72674 16.0564 9.59961 16.3596 9.37547 16.5838C9.15133 16.8079 8.84813 16.935 8.53116 16.9378C8.2142 16.9406 7.90883 16.8187 7.68083 16.5985L0.842158 9.75983C0.615527 9.53313 0.488212 9.2257 0.488212 8.90514C0.488212 8.58459 0.615527 8.27716 0.842158 8.05046L7.68083 1.21179C7.90753 0.985163 8.21496 0.857849 8.53551 0.857849C8.85607 0.857849 9.1635 0.985163 9.39019 1.21179Z"
              fill="#67748E"
            />
          </svg>
        </button>
        <p>
          Page &nbsp;
          <span className="border border-pageborder py-1 px-2">
            {pageIndex 
            // > 0 ?  pageIndex+1 : 1
          }
          </span>
          &nbsp; of {totalPages > 0 ? totalPages: 1 }
        </p>
        <button 
        onClick={() => setPageIndex( 
          totalPages > 0 ? (pageIndex < totalPages ? 
            pageIndex + 1 
            :  totalPages) :1
             )}
        // onClick={()=>setPageIndex(pageIndex +1)}
        >
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.877383 16.5839C0.650752 16.3572 0.523438 16.0497 0.523438 15.7292C0.523438 15.4086 0.650752 15.1012 0.877384 14.8745L6.86137 8.89051L0.877385 2.90652C0.657177 2.67853 0.535327 2.37316 0.538082 2.05619C0.540836 1.73923 0.667974 1.43602 0.892111 1.21188C1.11625 0.987747 1.41945 0.860611 1.73642 0.857857C2.05338 0.855103 2.35875 0.976952 2.58675 1.19716L9.42542 8.03583C9.65205 8.26253 9.77937 8.56996 9.77937 8.89051C9.77937 9.21106 9.65205 9.51849 9.42542 9.74519L2.58675 16.5839C2.36005 16.8105 2.05262 16.9378 1.73207 16.9378C1.41151 16.9378 1.10408 16.8105 0.877383 16.5839Z"
              fill="#67748E"
            />
          </svg>
        </button>
        <button onClick={() => setPageIndex(totalPages > 0 ? totalPages : 1)}>
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.36566 16.5839C7.13903 16.3572 7.01172 16.0497 7.01172 15.7292C7.01172 15.4086 7.13903 15.1012 7.36566 14.8745L13.3497 8.89051L7.36567 2.90652C7.14546 2.67853 7.02361 2.37316 7.02636 2.05619C7.02912 1.73923 7.15625 1.43602 7.38039 1.21188C7.60453 0.987747 7.90773 0.860611 8.2247 0.857857C8.54167 0.855103 8.84703 0.976952 9.07503 1.19716L15.9137 8.03583C16.1403 8.26253 16.2676 8.56996 16.2676 8.89051C16.2676 9.21106 16.1403 9.51849 15.9137 9.74519L9.07503 16.5839C8.84833 16.8105 8.5409 16.9378 8.22035 16.9378C7.89979 16.9378 7.59236 16.8105 7.36566 16.5839Z"
              fill="#67748E"
            />
            <path
              d="M0.387149 16.5839C0.160518 16.3572 0.0332032 16.0497 0.0332033 15.7292C0.0332033 15.4086 0.160518 15.1012 0.387149 14.8745L6.37114 8.89051L0.387151 2.90652C0.166942 2.67853 0.0450929 2.37316 0.0478473 2.05619C0.0506017 1.73923 0.177739 1.43602 0.401877 1.21188C0.626014 0.987747 0.929218 0.860611 1.24618 0.857857C1.56315 0.855103 1.86852 0.976952 2.09652 1.19716L8.93519 8.03583C9.16182 8.26253 9.28913 8.56996 9.28913 8.89051C9.28913 9.21106 9.16182 9.51849 8.93519 9.74519L2.09651 16.5839C1.86981 16.8105 1.56238 16.9378 1.24183 16.9378C0.921278 16.9378 0.613849 16.8105 0.387149 16.5839Z"
              fill="#67748E"
            />
          </svg>
        </button>
      </div>
      <div className="w-6/12 text-xs 2xl:text-sm">
      <span className="text-main-title mx-3 text-tiny font-medium">Per Page :
                    <select onClick={() => qwer()} id='page'>
                    {/* <option >1</option>   */}
                        <option >20</option>
                        <option >40</option>
                        <option >60</option>
                        <option >80</option>
                        <option >100</option>
                    </select>
                </span>
        {/* <button onClick={() => setDataCount(dataCount + 20)}>
          {" "}
          <span className="text-main-title mx-3 text-tiny font-medium">
            Per Page : {dataCount}
          </span>
        </button> */}
      </div>
    </div>
  );
}
