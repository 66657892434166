import React, { useEffect, useState } from "react";
// import AdminLayout from "../index";
import CreateOrUpdate from "./form";
import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
import ViewMore from "./viewMore";
import { GetAccess } from "../../../Utilities/data/role";
import AdminSettingsLayout from "..";
// import SettingsLayout from "../../Settings";

const fetcher = (url) => Axios.get(url).then((res) => res.data?.data);

function UserRoleSettingsIndex() {
  // const fetcher = (url) => Axios.get(url).then((res) => res.data);
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [role, setRole] = useState([]);
  const [user, setUser] = useState([]);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const { data, error, mutate } = useSWR(`/User-Role/`, fetcher);
  const refreshData = () => {
    mutate(fetcher);
  };

  const getRole = () => {
    Axios.get("/Role/").then((res) => {
      res?.data && setRole(res?.data?.data);
    });
  };

  const getUser = () => {
    Axios.get("/User/").then((res) => {
      console.log("ususus",res?.data?.userDetails);
      let item = res?.data?.userDetails;
      let final =[]
      res?.data?.userDetails && item.forEach((item)=>{
        console.log("i",item?.user);
        let temData = item?.user;
        final.push(temData)
      })
      res?.data && setUser(final);
    });
  };


  useEffect(()=>{
    getRole();
    getUser();
  },[])



  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Role
        </h4>
      
        {
            GetAccess('admin_create') &&       <svg
          className="w-7 ml-1 cursor-pointer"
          alt="plus"
          onClick={() => setShowMyModal(true)}
          title="Add"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.61084"
            y="0.335541"
            width="51.1569"
            height="51.1569"
            rx="5"
            fill="#7030A0"
          />
          <path
            d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
            stroke="white"
            stroke-width="2.3"
            stroke-linecap="round"
          />
          <title>Add</title>
        </svg>
}
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-4 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[70vh] shadow-box">
                {data?.map((data, i) => (
                  <div className="">
                    {/* {data?.role?.user} */}
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[15%]">
                        {data?.number}
                      </td>
                      <td className="text-center capitalize text-gray-900 w-[40%] ">
                        {data?.role?.user||"-"}
                      </td>
                      <td className="text-center capitalize text-gray-900 w-[40%] ">
                        {data?.role?.role||"-"}
                      </td>

                      <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data?.role}
                        fetcher={refreshData}
                        user={user}
                        role={role}
                      />
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateOrUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        role={role}
        user={user}
      />
    </AdminSettingsLayout>
  );
}

export default UserRoleSettingsIndex;

const header = [
  {
    headone: "SI.No",
    width: "w-[15%]",
  },
  {
    headone: "User Name",
    width: "w-[40%]",
  },
  {
    headone: "Role",
    width: "w-[40%]",
  },
];
