import React from "react";
// import NuInput from "./Input";
import NuLabel from "./label";
import NuSelect, { NuMutiSelect } from "./Select";
import { NuTextArea, NuDate,NuInput } from "./Input";

export function MainForm({ formInputs, formik }) {
  return (
    <>
      {formInputs.map((formInput, idx) => {
        return (
          <MainFormInput key={idx} formInput={formInput} formik={formik} />
        );
      })}
    </>
  );
}

export function MainFormInput({ formInput, formik }) {
  switch (formInput.inputType) {
    case "NuInput":
      return (
        <NuInput
          type={formInput.type}
          name={formInput.name}
          classname={formInput.classname}
          placeholder={formInput.placeholder}
          label={formInput.label}
          labelclassname={formInput.labelclassname}
          handlechange={formInput.handlechange}
          customClass={formInput.customClass}
          onChange={formInput.onChange}
          formik={formik}
          disabled={formInput.disabled}
          max={formInput.max}
          min={formInput.min}
          maxLength={formInput.maxLength}
        />
      );
    case "NuTextArea":
      return (
        <NuTextArea
          classname={formInput.classname}
          name={formInput.name}
          placeholder={formInput.placeholder}
          label={formInput.label}
          labelclassname={formInput.labelclassname}
          handlechange={formInput.handlechange}
          customClass={formInput.customClass}
          // onChange={formInput.onChange}
          formik={formik}
          maxLength={formInput.maxLength}
        />
      );
    case "NuDate":
      return (
        <NuDate
          dateclassname={formInput.dateclassname}
          timeclassname={formInput.timeclassname}
          datename={formInput.datename}
          timename={formInput.timename}
          dateplaceholder={formInput.dateplaceholder}
          timeplaceholder={formInput.timeplaceholder}
          datevalue={formInput.datevalue}
          timevalue={formInput.timevalue}
          dateid={formInput.dateid}
          timeid={formInput.timeid}
          dateonclick={formInput.dateonclick}
          timeonclick={formInput.timeonclick}
          label={formInput.label}
          labelclassname={formInput.labelclassname}
          datehandlechange={formInput.datehandlechange}
          timehandlechange={formInput.timehandlechange}
          customClass={formInput.customClass}
          // dateonchange={formInput.dateonchange}
          // timeonchange={formInput.timeonchange}
          formik={formik}
        />
      );
    case "NuSelect":
      return (
        <NuSelect
          Classname={formInput.Classname}
          name={formInput.name}
          placeholder={formInput.placeholder}
          onChange={formInput.onChange}
          formik={formik}
          label={formInput.label}
          Options={formInput.Options}
          customClass={formInput.customClass}
          labelclassname={formInput.labelclassname}
          isRequired={formInput.isRequired}

        />
      );
      case "NuMutiSelect":
        return (
          <NuMutiSelect
            Classname={formInput.Classname}
            name={formInput.name}
            placeholder={formInput.placeholder}
            onChange={formInput.onChange}
            formik={formik}
            label={formInput.label}
            Options={formInput.Options}
            customClass={formInput.customClass}
            labelclassname={formInput.labelclassname}
            isRequired={formInput.isRequired}
  
          />
        );
    case "NuLabel":
      return <NuLabel labelName={formInput.labelName} classname={formInput.classname} />;

    default:
      return null;
  }
}
