import React, { useState } from "react";
// import AdminLayout from "../index";
import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import ViewMore from "./viewMore";
import CreateorUpdate from "./form";
import { GetAccess } from "../../../Utilities/data/role";
import StandardSettingLayout from "../index";


const fetcher = (url) => Axios.get(url).then((res) => res.data);

function EmailTemplatesSettingIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);

  const { data, error, mutate } = useSWR(`/email_templates/standard/${id}`, fetcher);


  const refreshData = () => {
    mutate(fetcher);
  };

  return (
    <StandardSettingLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          Email Templates
        </h4>
        {GetAccess('admin_create') &&  <svg
          className="w-7 ml-1 cursor-pointer"
          alt="plus"
          onClick={() => setShowMyModal(true)}
          title="Add"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.61084"
            y="0.335541"
            width="51.1569"
            height="51.1569"
            rx="5"
            fill="#7030A0"
          />
          <path
            d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
            stroke="white"
            stroke-width="2.3"
            stroke-linecap="round"
          />
          <title>Add</title>
        </svg>
}
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-4 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[calc(100vh-225px)] shadow-box">
                {data?.data?.map((data, i) => (
                  <div className="" key={i}>
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[5%]">
                        {data.serial_number||data?.id||""}
                      </td>
                      <td className="text-left text-gray-900 w-[20%] ">
                        {data.status_name == "completed" ? "Completed"
                         :data.status_name == "in progress"? "In Progress"
                         :data.status_name == "yet to start"? "Yet to Start"
                         :data.status_name == "review_and_approved"? "Reviewed and Approved"
                         :data.status_name == "reminder"? "Reminder"
                         :data.status_name == "one month alert"? "Standard Expiry Alert Before One Month"
                         :data.status_name == "one week alert"? "Standard Expiry Alert Before One Week"
                         :data.status_name == "one day before alert"? "Standard Expiry Alert Before One Day"
                         :data.status_name == "after one day alert"? "Standard Expiry Alert After One Day"
                         :data.status_name== "Standard Reset"? "Standard Reset"
                         :""
                         || "-"}
                      </td>
                      <td className="text-center text-gray-900 w-[25%] ">
                        {data.subject|| "-"}
                      </td>
                      <td className=" text-justify text-gray-900 w-[40%] ">
                        {data.message|| "-"}
                      </td>
                      <td className=" w-[3%]">
                        <div>
                        <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data}
                        fetcher={refreshData}
                      />
                        </div>
                      </td>
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateorUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        id={id}
      />
    </StandardSettingLayout>
  );
}

export default EmailTemplatesSettingIndex;

const header = [
  {
    headone: "Sl.No",
    width: "w-[5%]",
  },
  // {
  //   headone: "Module",
  //   width: "w-[15%]",
  // },
  {
    headone: "Status",
    width: "w-[20%]",
  },
  {
    headone: "Subject",
    width: "w-[25%]",
  },
  {
    headone: "Message",
    width: "w-[40%]",
  },
  {
    headone: " ",
    width: "w-[3%]",
  }
];
