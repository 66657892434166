import React, { useEffect, useState } from "react";
// import AdminLayout from "../index";
import CreateOrUpdate from "./form";
// import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
// import ViewMore from "./viewMore";
import { GetAccess } from "../../../Utilities/data/role";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DateFormat from "../../../Utilities/common/NuDate";
// import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import {
  NuVerticalInput,
  // NuVerticalSelect,
} from "../../../Utilities/common/Input";
import { VerticalSelect } from "../../../Utilities/common/Select";
// import SettingsLayout from "../../Settings";
import AdminSettingsLayout from "..";
import ActionPDF from "./ActionLogPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";

// const fetcher = (url) => Axios.get(url).then((res) => res.data?.data);

function ActionLogIndex() {
  const [searchString,setSearchString] = useState("");
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [isFilter, setIsFilter] = useState(false);
  const [userList,setUserList] = useState([])
  const [standard,setStandard] =useState([])
  const [data, setData] = useState([]);
  const [createNew, setCreateNew] = useState({
    user_id:"",
    standard_id:"",
    start_date: "",
    end_date: "",
  });
  const [company,setCompany] = useState("");
  const refreshData = () => {
    Axios.get(`/action_log/`).then((res) => {
      res.data?.data && setData(res?.data?.data);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: yup.object({
    //   status: yup.string().required("Status is Required")
    // }),
    onSubmit: (value) => {
      setIsFilter(true);
      // setFilterData(value);
      Axios.get(`/search/actionLogSearch/?user_id=${value.user_id}&standard_id=${value.standard_id}&from_date=${value.start_date}&to_date=${value.end_date}&search_keyword=`)
        .then((res) => {
          console.log("filter Data", res?.data?.data);
          res?.data?.data && setData(res?.data?.data);
          // handleFilterClose()
        })
        .catch((err) => console.log(err));
    },
  });


  function GetUsers(){
    Axios.get(`/User/`).then(res=>{
      let list = res.data.userDetails
      let finalList =[];
      res.data.userDetails && list.forEach(item=>{
        let Temp = {
          value:item?.user?.userId,
          label:item?.user?.user_name
        }
        finalList.push(Temp)
      })
      setUserList(finalList)
    }).catch(err=>{
      console.log(err);
    })
  }


  function GetStandard(){
    Axios.get(`/Standards/`).then(res=>{
      console.log("sss",res.data);
      let list = res.data?.data
      let finalList =[];
      res.data?.data && list.forEach(item=>{
        if(item?.isActive&&item?.keyActivated){
          let Temp = {
            value:item?.id,
            label:item?.name
          }
          finalList.push(Temp)
        }
      })
      setStandard(finalList)
    }).catch(err=>{
      console.log(err);
    })
  }


  async function getCompanyInfo() {
    await Axios.get(`/company-info/`).then((res) => {
      setCompany(res?.data?.data[0]);
      console.log("ccc", res?.data?.data[0]);
    });
  }


  useEffect(() => {
    GetStandard();
    GetUsers();
    getCompanyInfo()
    refreshData();
  }, []);


  function ClearFilter(){
    formik.resetForm();
    refreshData()
    setIsFilter(false)
  }

  function handleSearch(){
    if(searchString !=""){
      Axios.get(`/search/actionLogSearch/?user_id=${formik.values.user_id}&standard_id=${formik.values.standard_id}&from_date=${formik.values.start_date}&to_date=${formik.values.end_date}&search_keyword=${searchString}`).then(res=>{
        res.data?.data && setData(res?.data?.data);
      })
    }
  }

  useEffect(()=>{
    if(searchString==""){
      refreshData()
    }
  },[searchString])


  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const worker = new Worker(new URL('./pdfWorker.js', import.meta.url));

    worker.onmessage = function(e) {
      setPdfUrl(e.data);
      // setIsDataLoading(false);
    };


    if (data.length > 0) {
      worker.postMessage({
        data,
        company,
      });
    }

    return () => worker.terminate();
  }, [data, company]);



  return (
    <AdminSettingsLayout>
      <div className=" w-full p-1">
        <div className="py-[2px] pl-5 flex justify-between items-center w-[98.5%]">
          <div className=" w-[33.3%] flex justify-start items-center">
          <h4 className="text-xs 2xl:text-sm font-bold text-heading ">
            Action Log
          </h4>
          </div>
          
          <div className=" w-[33.3%] flex justify-center items-center">
          <div className=" border rounded-lg overflow-hidden w-[400px] flex">
            <input type="text" onChange={(e)=>setSearchString(e.target.value)} className=" w-full px-3 py-2 outline-none border-none text-xs 2xl:text-sm" />
            <button className=" py-2 px-2 bg-primary text-white text-xs 2xl:text-sm" onClick={handleSearch}>Search</button>
          </div>
          </div>
          
          <div className=" w-[33.3%] flex justify-end items-center">


          {
   pdfUrl ? 
   <a href={URL.createObjectURL(pdfUrl)} download={
    `Action Logs ${
                  DateFormat({
                    value: Date (),
                    format: "DD-MMM-YYYY",
                  })
                }.pdf`
   }>
                       <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="22px"
                      width="22px"
                      //   {...props}
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 00.161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 00-.46-.477c-.2-.12-.443-.179-.732-.179zm.545 1.333a.795.795 0 01-.085.38.574.574 0 01-.238.241.794.794 0 01-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 00.595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 00-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 01.354.454c.079.201.118.452.118.753a2.3 2.3 0 01-.068.592 1.14 1.14 0 01-.196.422.8.8 0 01-.334.252 1.298 1.298 0 01-.483.082h-.563v-2.707zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896z"
                      />
                    </svg>
 </a>
 :
 <span className=" w-5 h-6 px-1 overflow-hidden border border-black rounded rounded-tr-lg">
 ...
</span>
}


          {/* <PDFDownloadLink
                document={
                  <ActionPDF
                    company={company}
                    data={data}
                  />
                }
                fileName={`Action Logs ${
                  DateFormat({
                    value: Date (),
                    format: "DD-MMM-YYYY",
                  })
                }.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <span className=" text-primary text-sm font-semibold">... Download</span>
                  ) : (
                    <div className=" flex gap-2 items-center">
                                          <svg
                      fill="#7030A0"
                      viewBox="0 0 16 16"
                      height="22px"
                      width="22px"
                      //   {...props}
                    >
                      <path
                        fillRule="evenodd"
                        d="M14 4.5V14a2 2 0 01-2 2h-1v-1h1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5L14 4.5zM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 00.161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 00-.46-.477c-.2-.12-.443-.179-.732-.179zm.545 1.333a.795.795 0 01-.085.38.574.574 0 01-.238.241.794.794 0 01-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 00.595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 00-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 01.354.454c.079.201.118.452.118.753a2.3 2.3 0 01-.068.592 1.14 1.14 0 01-.196.422.8.8 0 01-.334.252 1.298 1.298 0 01-.483.082h-.563v-2.707zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896z"
                      />
                    </svg>
                    <span className=" text-primary text-sm font-semibold">Download</span>
                    </div>
                  )
                }
          </PDFDownloadLink> */}

          </div>

        </div>
        <div className=" w-full flex items-center">
          <div className=" w-[95%] mx-auto">
          <form onSubmit={formik.handleSubmit}>
            <div className=" w-full">
              <div className="relative w-full px-6 flex justify-between  items-center">
                {/* <MainForm formInputs={formInputs} formik={formik} /> */}
                <VerticalSelect
                  name="user_id"
                  classname=" px-3 rounded-lg"
                  placeholder="Select User"
                  label="Standard"
                  formik={formik}
                  labelclassname=""
                  customClass=" w-full lg:w-6/12  "
                  Options={userList}
                />
                <VerticalSelect
                  name="standard_id"
                  classname=" px-3 rounded-lg "
                  placeholder="Select Standard"
                  label="User"
                  formik={formik}
                  labelclassname=""
                  customClass=" w-full lg:w-6/12  "
                  Options={standard}
                />
                <NuVerticalInput
                  name="start_date"
                  classname="border w-full h-10 px-3  rounded-3xl"
                  placeholder=""
                  label="From"
                  formik={formik}
                  type="date"
                  // customClass: "w-6/12 pl-4",
                  customClass=" w-full lg:w-6/12  "
                /> 

                <NuVerticalInput
                  name="end_date"
                  classname="border w-full h-10 px-3 rounded-3xl"
                  placeholder=""
                  label="To"
                  formik={formik}
                  type="date"
                  // customClass: "w-6/12 pl-4",
                  customClass=" w-full lg:w-6/12  "
                />

                <button className="bg-primary hover:shadow-button text-white h-9 px-6 py-1 mr-2 rounded-lg">
                  Submit
                </button>
              </div>
            </div>
          </form>
          </div>
          {isFilter && (
            <button onClick={ClearFilter} className=" bg-slate-400 hover:shadow-button text-white h-9 px-6 py-1  rounded-lg">
              Clear
            </button>
          )}
        </div>
        <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className="flex items-center text-xs 2xl:text-sm font-medium">
              {header?.map((header) => (
                <th className={`text-center px-5 py-4 ${header.width}`}>
                  {header?.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr colspan="3">
              <td colspan="3">
                <div className="overflow-y-auto h-[calc(100vh-295px)] shadow-box">
                  {data?.map((data, i) => (
                    <div className="">
                      <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                        <td className="text-center text-gray-900 w-[10%]">
                          {data?.serial_number||data?.id}
                        </td>
                        <td className="text-center capitalize text-gray-900 w-[20%] ">
                          {/* {data.action_date_time} */}
                          <DateFormat
                            value={data.action_date_time}
                            format="DD-MMM-YYYY "
                          />
                          <small>
                            <DateFormat
                              value={data.action_date_time}
                              format=" hh:mm:ss"
                            />
                          </small>
                        </td>
                        <td className=" text-justify capitalize text-gray-900 w-[70%] ">
                          {data.action_log}
                        </td>
                      </tr>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <CreateOrUpdate
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
      />
    </AdminSettingsLayout>
  );
}

export default ActionLogIndex;

const header = [
  {
    headone: "SI. No",
    width: "w-[10%]",
  },
  {
    headone: "Date",
    width: "w-[20%]",
  },
  {
    headone: "Actions",
    width: "w-[70%]",
  },
];
