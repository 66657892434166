import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import ViewMore from "./viewMore";
import CreateorUpdate from "./form";
import { GetAccess } from "../../../Utilities/data/role";
import AdminSettingsLayout from "../index";


const fetcher = (url) => Axios.get(url).then((res) => res.data);

function UserManagementSettingsIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const [standard,setStandard] = useState([])
  const handleOnClose = () => setShowMyModal(false);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [relation, setRelation] = useState([]);


  const { data, error, mutate } = useSWR(`/User/`, fetcher);


  const refreshData = () => {
    mutate(fetcher);
  };

  function getAllStandard(){
    Axios.get(`/Standards/`).then(res=>{
      let tempdata = res?.data?.data||[] 
      let finalArr = []
      res?.data?.data && tempdata.forEach(item=>{
        if(item?.isActive&&item?.keyActivated){
          let temp = {
            value:item?.id,
            label:item?.name
          }
          finalArr.push(temp);
        }
      })
      setStandard(finalArr)
    }).catch(err=>{
      console.log(err.response?.data);
    })
  }


  const Relation = async () => {
    await Axios.get("/Master-Data/department_type").then((res) => {
      let data = res?.data?.data;
      let xyz = [];
      data.forEach((i) => {
        let x = { label: i?.value, value: i?.value };
        xyz.push(x);
      });
      res?.data && setRelation(xyz);
    });
  };

  useEffect(()=>{
    getAllStandard()
    Relation()
  },[])

  return (
    <AdminSettingsLayout>
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <h4 className="text-xs 2xl:text-sm font-bold text-heading">
          User Managment
        </h4>
        <div className=" w-8">
          {
            GetAccess('admin_create') &&         <svg
            className="w-7 ml-1 cursor-pointer"
            alt="plus"
            onClick={() => setShowMyModal(true)}
            title="Add"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.61084"
              y="0.335541"
              width="51.1569"
              height="51.1569"
              rx="5"
              fill="#7030A0"
            />
            <path
              d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
              stroke="white"
              stroke-width="2.3"
              stroke-linecap="round"
            />
            <title>Add</title>
          </svg>
          }
        </div>
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="flex items-center text-xs 2xl:text-sm font-medium">
            {header?.map((header) => (
              <th className={`text-center px-5 py-4 ${header.width}`}>
                {header?.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr colspan="6">
            <td colspan="6">
              <div className="overflow-y-auto h-[70vh] shadow-box">
                {data?.userDetails?.map((data, i) => (
                  <div className="" key={i}>
                    <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm font-medium py-1 flex justify-between items-center my-1">
                      <td className="text-center text-gray-900 w-[10%]">
                        {data.number}
                      </td>
                      <td className="text-center text-gray-900 w-[20%] ">
                        {data?.user?.name||"-"}
                      </td>
                      <td className="text-center text-gray-900 w-[20%] ">
                        {data?.user?.user_name||"-"}
                      </td>
                      <td className="text-center text-gray-900 w-[25%]">
                        {data?.user?.email||"-"}
                      </td>
                      <td className="text-center text-gray-900 w-[25%]">
                        {data?.user?.mobile||"-"}
                      </td>
                      <ViewMore
                        onClose={handleOnCloseMenu}
                        visible={showMyMenu}
                        data={data?.user}
                        viewData={data}
                        fetcher={refreshData}
                        standard={standard}
                        depList={relation}
                      />
                    </tr>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateorUpdate
        standard={standard}
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        id={id}
        depList={relation}
      />
    </AdminSettingsLayout>
  );
}

export default UserManagementSettingsIndex;

const header = [
  {
    headone: "SI. No",
    width: "w-[10%]",
  },
  {
    headone: "Name",
    width: "w-[20%]",
  },
  {
    headone: "User Name",
    width: "w-[20%]",
  },
  {
    headone: "Email",
    width: "w-[25%]",
  },
  {
    headone: "Mobile",
    width: "w-[25%]",
  },
];
