import DecryptData from "../Decrypt";

const userPermission = localStorage.getItem("nOiSsImRePrEsU")
  ? DecryptData("nOiSsImRePrEsU")
  : [];

let InformationView = userPermission
  ? userPermission.some(
      (permission) => permission.permissionName == "information_view"
    )
  : false;
let ActivityView = userPermission
  ? userPermission.some(
      (permission) => permission.permissionName == "activity_view"
    )
  : false;
let GlossaryView = userPermission
  ? userPermission.some(
      (permission) => permission.permissionName == "glossary_view"
    )
  : false;
let ReportView = userPermission
  ? userPermission.some(
      (permission) => permission.permissionName == "reports_view"
    )
  : false;

export const links = [
  {
    name: "Information",
    access: InformationView,
    submenu: true,
    sublinks: [
      {
        name: "Standard Information",
        link: "/information/standard-information",
      },
      {
        name: "Calendar Management",
        link: "/information/calendar-management",
      },
      {
        name: "Task Management",
        link: "/information/task-management",
      },
      { name: "Vendor Management", link: "/information/vendor-management" },
      { name: "Security Incident", link: "/information/security-incident" },
      { name: "Training Calendar", link: "/information/training-calendar" },
    ],
  },
  {
    name: "Activity",
    access: ActivityView,
    submenu: true,
    sublinks: [
      {
        name: "Implementation Checklist",
        link: `/activity/implementation-checklist`,
      },
      {
        name: "Master List of Documents",
        link: "/activity/master-list-of-documents",
      },
      { name: "Audit Schedule", link: "/activity/audit-calendar" },
      { name: "Internal Audit Checklist", link: "/activity/internal-audit" },
      { name: "CISO / MRM Meeting MoM", link: "/activity/ciso-mom" },
      { name: "Maintenance", link: "/activity/maintenance" },
    ],
  },

  {
    name: "Reference Docs",
    access: GlossaryView,
    submenu: true,
    sublinks: [
      {
        name: "Reference Doc Format",
        link: "/reference-doc/reference-doc-format",
      },
      {
        name: "Website Tracking",
        link: `/reference-doc/website-tracking`,
      },
      {
        name: "Consent Management",
        link: "/reference-doc/consent-management",
      },
      {
        name: "Certification Tracking",
        link: "/reference-doc/certification-tracking",
      },
      {
        name: "Statutory Reporting",
        link: "/reference-doc/statutory-reporting",
      },
      {
        name: "Cookie Management",
        link: "/reference-doc/cookie-management",
      },
      {
        name: "Glossary / Abbreviations",
        link: "/reference-doc/abbreviations",
      },
      { name: "Frequently Asked Questions", link: "/reference-doc/help" },
      {
        name: "Standard Comparisions",
        link: "/reference-doc/standard-comparisions",
      },
      { name: "Diagrams / Logo's", link: "/reference-doc/diagramsandlogos" },
    ],
  },
  {
    name: "Reports",
    access: ReportView,
    submenu: true,
    sublinks: [{ name: "Reports List", link: "/reports/genarate/reports-list" }],
  },
];
