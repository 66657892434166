import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { MainForm } from "../../../Utilities/common/MainForm";
import Add from "../../../Utilities/Image/icons/plus.svg";
import Close from "../../../Utilities/Image/icons/close.svg";
import axiosComman from "../../../Services/axiosComman";
import FileDisplay from "../../../Utilities/common/FileDisplay";
// import Modal from "./modal"

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateOrEdit({ data, visible,status, onClose, refreshData, id,getMasterList }) {
  // const handleOnClose = (e) => {
  //   if (e.target.id === "modal") onClose();
  // };
  const [isLoading,setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePathList,setFilePathList] =useState([])
  const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    document_name: "",
    document_description: "",
    version: "",
    status:""
  });

  useEffect(() => {
    setCreateNew({
      document_name: data?.document_name,
      document_description: data?.document_description,
      version: data?.version,
      status:data?.status,

    })
    setFilePathList(data?.image_path)
    setRemoveFilePathList(data?.image_path)
  }, [data])

  function handleImageChange(e) {
    const files = Array.from(e.target.files);
    setSelectedFile(files)
  }

  const formInputs = [
    {
      inputType: "NuInput",
      name: "document_name",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Document Name",
      labelclassname: "w-full",
      type: "text",
      style: "w-full",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "version",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Version",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },

    {
      inputType: "NuTextArea",
      name: "document_description",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Enter the description",
      label: "Document Description",
      labelclassname: "w-full",
      customClass:"w-full"
    },
    {
      inputType: "NuInput",
      name: "filePath",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Artefact",
      labelclassname: "w-full",
      type: "file",
      customClass: "w-full lg:w-6/12",
      onChange: handleImageChange
    },
    {
      inputType: "NuSelect",
      name: "status",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "Select status",
      label: "Status",
      labelclassname: "w-full",
      customClass: "w-full lg:w-6/12",
      Options:
        status?.map((i) => {
          return {
            value: i.status_type,
            label: i.status_type,
          };
        }),
      // isRequired: true
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   document_name:yup.string().required("Document Name is Required"),
    //   // document_description:yup.string().required("Document Description is Required")
    // }),
    onSubmit: async (value,{resetForm}) => {
      setIsLoading(true);
      if (data?.id) {
        if(removefilePathList){
          let finalArr = removefilePathList.filter(items=>  !filePathList.includes(items) )
          value.files_to_remove = finalArr.map(item=>item.replace(window.WEB_APP_API_URL, "localhost"));
        }else{
          value.files_to_remove = []
        }
        let reqObjs = new FormData();
        if(selectedFile){
          selectedFile.forEach((file) => {
            reqObjs.append("attachment_artifact", file);
          });
        }else{
          reqObjs.append("attachment_artifact", selectedFile);
        }
        reqObjs.append("master_list", JSON.stringify(value));
        
        axiosComman.put(`/Master-List-Of-Document/${data?.id}/screen_closed`,{"isClosed":false}).then(res=>{
        axiosComman
          .put(`/Master-List-Of-Document/${data.id}/`, reqObjs)
          .then(res => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm()
              getMasterList()
              onClose();
              refreshData();
              setSelectedFile(null)
              Toast.fire({
                icon: "success",
                title: res.data.msg || "MasterListDocument Updated Successfully",
              });
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || res.data.error || "MasterListDocument Update Unsuccessfully",
              });
            }
          }).catch((err) => {
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.error || "MasterListDocument Update Unsuccessfully",
            });
          });
        }).catch(err=>{
          setIsLoading(false);
          console.log("err")
        })
      } else {
        let reqObjs = new FormData();
        if(selectedFile){
          selectedFile.forEach((file) => {
            reqObjs.append("attachment_artifact", file);
          });
        }else{
          reqObjs.append("attachment_artifact", selectedFile);
        }
        reqObjs.append("master_list", JSON.stringify(value));
        axiosComman
          .post(`/Master-List-Of-Document/standard/${id}/`, reqObjs)
          .then(res => {
            if (res.data.status) {
              setIsLoading(false);
              refreshData();
              getMasterList()
              setSelectedFile(null)
              Toast.fire({
                icon: "success",
                title: res.data.msg || "MasterListDocument Created Successfully",
              });
              onClose();
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || res.data.error || "MasterListDocument Creation Unsuccessfully",
              });
            }
          }).catch((err) => {
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.error || "MasterListDocument Update Unsuccessfully",
            });
          })
      }
    }
  });


  function UpdateAttachmentList(item){
    let filterList = filePathList.filter(itemList=> itemList !==item )
    setFilePathList(filterList);
  }

  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
    setSelectedFile(null)
      formik.resetForm();
      onClose()
    };
  };

  const closeFunction = () =>{
    setSelectedFile(null)
    formik.resetForm()
    onClose()
}

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
      >
        <div className="bg-white mt-8 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-xs 2xl:text-sm font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={closeFunction}
            >
              <svg className="w-7 h-7" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.842773" y="0.560791" width="51.1569" height="51.1569" rx="5" fill="#7030A0" />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816" stroke="white" stroke-width="2.3" stroke-linecap="round" />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="relative px-6 flex flex-wrap justify-between text-left">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex flex-wrap my-3">
                {   filePathList &&
                    filePathList != null &&
                    filePathList != "None" &&
                    filePathList.length>0 && (
                                    filePathList.map(i =>{
                                      return(
                                        <div className=" m-1">
                                          <div className=" relative">
                                            <div className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-bold flex justify-center items-center  -top-2 right-2 z-10" onClick={()=>UpdateAttachmentList(i)}>
                                              -
                                            </div>
                                          <FileDisplay data={i} view={true}/>
                                          </div>
                                        </div>
                                      )
                                    }
                                        
                ))}
                </div>
                <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={closeFunction}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
