import React, { useEffect, useRef, useState } from "react";
import Axios from "../../../Services/axiosComman";
import { NavLink, Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import avatar from "../../Image/img/avatar.png";
import Logout from "../Logout";
import DecryptData from "../Decrypt";
import Swal from 'sweetalert2'
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useSwr from "swr";
import DateFormat from "../NuDate";
import { GetAccess, GetRole } from "../../data/role";
import { useStandard } from "../../context/StandardContext";
import HelpIcon from "../../Image/icons/Help";
// import moment from "react-moment";
// import Moment from "react-moment";




const Navbar = () => {
  const {standardName,standardId,updateName,updateId} = useStandard();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [noti, setNoti] = useState([]);
  const [count, setCount] = useState(0);


  const fetcher = (url) =>
  Axios.get(url).then((res) => {
    res?.data?.data&& setCount(res?.data?.data?.count);
    res?.data?.data?.notifications&&
      setNoti(res?.data?.data?.notifications);
  });
const { data, mutate } = useSwr(`/notification/user`, fetcher, {
  refreshInterval: 30000,
});


const [isOpen, setIsOpen] = useState(false);
const notificationRef = useRef(null);
const toggleNotificationMenu = () => {
  setIsOpen(!isOpen);
};




  useEffect(() => {
    if(!standardName&&!standardId){
    standard();
    }
  }, [standardName,standardId]);

  async function standard() {
    await Axios.get(
      // `/Standards/1`
      `Standards/${id}`
    ).then((res) => {
      // console.log("testing");
      res?.data?.data.name && updateName(res?.data?.data.name)
      res?.data?.data.id && updateId(res?.data?.data.id)
      // setStandardName();
    });
  }


  function logOut() {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Log out ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Logout()
      }
    })
  }



  const userData = localStorage.getItem("aTaDrEsU")?DecryptData("aTaDrEsU"):[];
  const Username = userData?.user_name;
  const Userimg = userData?.image_path;
  let role = userData?.role[0].roleName


  // const isActive = localStorage.getItem("aTaDrEsU")?DecryptData("aTaDrEsU"):[];
  // let role = isActive?.role[0].roleName||""
  // let roleLower = role.toLowerCase()||""

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

    const handleRead = async (id) => {
      Axios
        .put(`/notification/update/is_read/${id}`, {
          is_read: 1,
        })
        .then((res) => {
          mutate();
          console.log(res.data);
        });
      // const data = 1
      // fetch(axiosInstance.put(`/notification/update/is_read/4`),{
      //   headers:{
      //     'Content-Type': 'application/json'
      //   },
      //   body:JSON.stringify(data)
      // })
      // .then((res)=>{
      //   res=1
      // })
      // .catch((error)=>{
      //   error?.
      // })
    };

    function ClearAll(){
      Axios.delete(`/notification/delete`).then((res) => {
        mutate();
      }).catch(err=>{})
    }

    function MarkAll(){
      Axios.get(`/notification/update/mark_all_as_read`).then((res) => {
        mutate();
      }).catch(err=>{})
    }

  return (
    <nav className="bg-primary z-50" >
      <div className="py-0 lg:py-1 w-11/12 m-auto flex items-center justify-between">
        <div className="md:w-auto w-full flex justify-between">
          <NavLink to="/standard">
            <h1 className="text-2xl 2xl:text-3xl text-white font-semibold">
              Scroll
            </h1>
          </NavLink>
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <p className="text-sm w-[30%]  text-center 2xl:text-base text-white font-semibold">
          {standardName||""}
        </p>
        <ul className="md:flex hidden h-[52px] items-center gap-4">
          <li>
            <NavLink to={`/dashboard/SubDashboard/${standardId}`}>
              <svg
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0135 1.15432C11.6942 0.852498 11.2716 0.684326 10.8322 0.684326C10.3929 0.684326 9.97023 0.852498 9.65098 1.15432L1.32204 9.02589C1.06702 9.26722 0.863952 9.55808 0.725287 9.88064C0.586622 10.2032 0.515278 10.5507 0.515626 10.9018V20.479C0.516082 21.1628 0.788015 21.8184 1.27165 22.3017C1.75529 22.785 2.41104 23.0565 3.09478 23.0565H5.67393C6.35796 23.0565 7.01398 22.7847 7.49767 22.3011C7.98135 21.8174 8.25308 21.1614 8.25308 20.4773V16.1787C8.25308 15.9507 8.34366 15.7321 8.50489 15.5708C8.66612 15.4096 8.88479 15.319 9.1128 15.319H12.5517C12.7797 15.319 12.9984 15.4096 13.1596 15.5708C13.3208 15.7321 13.4114 15.9507 13.4114 16.1787V20.4773C13.4114 21.1614 13.6831 21.8174 14.1668 22.3011C14.6505 22.7847 15.3065 23.0565 15.9905 23.0565H18.5697C19.2537 23.0565 19.9097 22.7847 20.3934 22.3011C20.8771 21.8174 21.1488 21.1614 21.1488 20.4773V10.9001C21.1487 10.5491 21.077 10.2019 20.9381 9.87964C20.7991 9.55738 20.5958 9.26687 20.3407 9.02589L12.0135 1.15088V1.15432Z"
                  fill="white"
                />
              </svg>
            </NavLink>
          </li>
          <NavLinks />
        </ul>
        {/* {
         (GetRole()||GetAccess('admin_view'))  &&  <div className="relative" ref={notificationRef}>
          <div
            className="p-1 relative text-white  rounded-full cursor-pointer"
            onClick={toggleNotificationMenu}
          >
            {
              count > 0 &&             <div className=" absolute h-[15px] w-[15px] rounded-full -top-1 -right-[2px] bg-red-400 text-white font-semibold flex justify-center items-center text-[9px]" style={{border:'1px solid white'}}>
              <span>{count||0}</span>
            </div>
            }
            <svg xmlns="http://www.w3.org/2000/svg " fill="white" height="1em" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/></svg>
          </div>

        
          <div
            className={`${isOpen ? 'block' : 'hidden'} absolute top-7 right-0 z-10 bg-white shadow-menu w-64 h-96 overflow-hidden rounded-lg`}
          >
         <div className=" w-full py-2 px-2 bg-slate-100 flex justify-between items-center">
          <p className="font-medium text-sm ">Notification</p>
          <div className=" flex items-center gap-2">
            {count>0?<button className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-slate-400 text-white cursor-pointer" onClick={MarkAll}>Marks as read</button>:null}
            {noti.length>0?<button className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-slate-400  text-white cursor-pointer" onClick={ClearAll}>Clear All</button>:null}
          </div>
          </div>
          <div className="w-full max-h-[calc(24rem-40px)] overflow-y-auto  overflow-x-hidden">
          {noti?.map((notification) => {
            return (
              <>
                <div
                  className={`${notification?.is_read?"bg-white":" bg-slate-100"} w-[97%] m-1 p-1 rounded flex justify-between items-center cursor-pointer`}
                  onClick={() => handleRead(notification?.notificationId)}
                >
                  <div className="w-[15%]">
                    <div className=" w-7 h-7 bg-primary  flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden"> 
                    {
                      notification.sender_imageUrl && notification.sender_imageUrl!=""&&notification.sender_imageUrl!=null?
                      <img
                      src={
                        notification.sender_imageUrl
                          ? notification.sender_imageUrl
                          : null
                      }
                      width="50px"
                      alt={`${notification.sender_imageUrl}`}
                    />:
                    `${notification?.sender_name.charAt(0)}`
                    }

                    </div>
                  </div>
                  <div className={`w-[85%] ${notification?.is_read?" font-normal ":"font-medium  "}`}>
                    <p className="pb-1 text-ellipsis leading-2 text-[12px]">
                      {notification?.msg}
                    </p>
                    <p className="text-xs">
                    <DateFormat
                          value={`${notification?.date} ${notification?.time}`}
                          format="DD-MM-YYYY"
                        />
                      <span className="text-[10px]">
                        <DateFormat
                          value={`${notification?.date} ${notification?.time}`}
                          format="hh:mm a"
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </>
            );
          })}
          </div>
          </div>
        </div>
        } */}

<Link to={`/help/${id}`}>
<HelpIcon/>
</Link>
        <div className="px-3 py-1 text-left md:cursor-pointer group relative">
          <a
            href="#!"
            className=" flex items-center md:pr-0 pr-5 group text-white text-xs 2xl:text-sm border-l border-white"
          >
            <img 
            src={Userimg || avatar} 
            width="110%"
            alt="" className="w-7 overflow-hidden h-7 mx-2 rounded-full" />
            <p className="mr-12 text-white text-xs 2xl:text-sm capitalize">{Username}</p>
          </a>
          <div className="absolute z-10 top-[1.8rem] hidden group-hover:md:block hover:md:block">
            <div className="bg-white shadow-box rounded-md px-1 py-3 grid grid-cols-1 w-24">
              <div>
                {
                  (role =="scroll_admin"||role =="admin"||GetAccess('admin_view'))&&<>
                                <span className=" px-2 font-light text-xs 2xl:text-sm">Settings :</span>
              <li className="list-none p-2 hover:bg-navhover rounded-md">
                  <Link
                    to={`/setting/standard/status/${id}`}
                    className="text-xs 2xl:text-sm pl-1"
                    // onClick={logOut}
                  >
                    Standard
                  </Link>
                </li>
              {/* <li className="list-none p-2 hover:bg-navhover rounded-md">
                  <Link
                    to="/settings/company-info"
                    className="text-xs 2xl:text-sm pl-1"
                    // onClick={logOut}
                  >
                    System
                  </Link>
                </li> */}
                  </>
                }
                <li className="list-none p-2 hover:bg-navhover rounded-md">
                  <Link
                    to="#!"
                    className="text-xs 2xl:text-sm"
                    onClick={logOut}
                  >
                    Logout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-primary fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <NavLinks />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;


// import React, { useState, useEffect, useRef } from "react";
// import NotificationMenu from "../Notification";

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const notificationRef = useRef(null);

//   // Function to toggle the notification menu
//   const toggleNotificationMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   // Add a click event listener to close the notification menu when clicking anywhere in the nav
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (notificationRef.current && !notificationRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener("click", handleClickOutside);

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, []);

//   return (
//     <nav className="bg-primary">
//       <div className="py-0 lg:py-1 w-11/12 m-auto flex items-center justify-between">
//         {/* ... Other Navbar content ... */}

//         {/* Notification button */}
//         <div className="relative" ref={notificationRef}>
//           <div
//             className="bg-blue-500 text-white p-2 rounded-full cursor-pointer"
//             onClick={toggleNotificationMenu}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-6 w-6"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               {/* Your notification icon SVG */}
//             </svg>
//           </div>

//           {/* Notification menu */}
//           <div
//             className={`${isOpen ? 'block' : 'hidden'} absolute top-10 right-0 z-10 bg-white shadow-menu w-64 rounded-lg`}
//           >
//             {/* Notification items */}
//             <div className="p-4">
//               {/* Your notification items */}
//               <p>One</p>
//               <p>One</p>
//               <p>One</p>
//               <p>One</p>
//               <p>One</p>
//             </div>
//           </div>
//         </div>

//         {/* ... Other Navbar content ... */}
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

