import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { GetAccess, GetRole } from '../../../Utilities/data/role';
import axiosInstance from '../../../Services/axiosComman';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

function Viewmore({ data,fetcher }) {
  const [filePath, setFilePath] = useState('');
  const fetchFilePath = async () => {
    
    let value = ""
    let dataLink = data?.file_url;

    if(dataLink.includes('Manual_backups')){
      value = dataLink.replace("Backups/Manual_backups","Auth/Manual_backups")
      console.log("value",value);

    }else if(dataLink.includes('Upload_backups')){
      value = dataLink.replace("Backups/Upload_backups","Auth/Upload_backups")
      console.log("value",value);

    }else if(dataLink.includes('backups')){
      value = dataLink.replace("backups","Auth/backups")
      console.log("value",value);
    }

    try {
      const response = await fetch(value);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setFilePath(url);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };



  const handleDownload = () => {
    // Fetch the file path when the user clicks the "Download" button
    fetchFilePath();
  };

  useEffect(() => {
    // Trigger download when filePath changes
    if (filePath !== '') {
      const downloadLink = document.createElement('a');
      downloadLink.href = filePath;
      downloadLink.download = data?.filename; // Set the desired filename here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [filePath]);



  const handleRestore = async (value) => {
    let splitPath = value?.split(".").reverse()[0];
    let path = {}
    if(splitPath == "zip") {
      path = {
        sql_path:"",
        zip_path: value,
      }
    }else if(splitPath == "sql"){
      path = {
        sql_path: value,
        zip_path:""
      }
    }
    Swal.fire({
      title: 'Confirm Database Restoration',
      text: `Are you sure you want to restore the database? This action will revert the system to the selected database state, and any current data will be permanently removed from the database.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d5d5d5',
      confirmButtonText: 'Restore',
      width:"45%",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (value) {
          await axiosInstance.post(`/Auth/reset`,path)
            .then((res) => {
              if (res.status==200) {
                Toast.fire({
                  icon: "success",
                  title: res.data?.message || "Successfully restored",
                });
                fetcher()
              } else {
                Toast.fire({
                  icon: "error",
                  title: res.data?.message || "Database not restored",
                });
              }
            })
            .catch((error) => {
              console.log(error.message);
              Toast.fire({
                icon: "error",
                title: error?.response.data?.message || "Database not restored",
              });
            });
        }
      }
    })
  }
  

  const handleDelete = async (value,name) => {
    // let splitPath = value?.split(".").reverse()[0];
    let path = {
      file_path:value
    }
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Delete the ${name}? This  data will be permanently removed from the database.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d5d5d5',
      confirmButtonText: 'Delete',
      width:"30%",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (value) {
          await axiosInstance.post(`/Auth/delete_file`,path)
            .then((res) => {
              if (res.status==200) {
                Toast.fire({
                  icon: "success",
                  title: res.data?.message || "Successfully Deleted",
                });
                fetcher()
              } else {
                Toast.fire({
                  icon: "error",
                  title: res.data?.message || "File not Delete",
                });
              }
            })
            .catch((error) => {
              console.log(error.message);
              Toast.fire({
                icon: "error",
                title: error?.response.data?.message || "File not Delete",
              });
            });
        }
      }
    })
  }
  

  return (
    <>
      <div className="hover:drop-shadow-sm mr-2 py-2 px-[14px] rounded-3xl dropdown bg-white cursor-pointer relative z-10">
        <svg
          className="m-auto w-[4px]"
          viewBox="0 0 4 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#8C87A6" />
          <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#8C87A6" />
          <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#8C87A6" />
        </svg>
        <div className="hidden dropdown-menu z-[999]">
          <div
            id="modal"
            className="bg-transparent fixed inset-0 flex justify-center items-center"
          ></div>
          <div className="bg-white rounded-xl w-24 absolute -left-20 -top-1 z-[999]">
            <div className="grid p-1 rounded-t">
              { (GetRole()||GetAccess('admin_view'))  && (
                <span
                  className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer "
                  onClick={handleDownload}
                >
                  Download
                </span>
              )}
              {(GetRole()||GetAccess('admin_edit')) && (
                 
                <span
                  className="p-1  hover:bg-navhover hover:rounded-lg " 
                  onClick={() => {
                    handleRestore(data?.file_url);
                  }}
                >
                  Restore
                </span>
                
              )}
              {(GetRole()||GetAccess('backup_delete')) && (
                 
                 <span
                   className="p-1  hover:bg-navhover hover:rounded-lg" 
                   onClick={() => {
                     handleDelete(data?.file_url,data?.filename);
                   }}
                 >
                   Delete
                 </span>
                 
               )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Viewmore;
