import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useEffect } from "react";
import Logo from "../Utilities/Image/img/logo.jpg";
// import axiosInstance from "../Services/axiosComman";
import CryptoJS from "crypto-js";
// import DecryptData from "../Utilities/common/Decrypt";
import { GetYear } from "../Utilities/common/GetYear";

const SERVER_URL = window.WEB_APP_API_URL;

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Login() {
  const [isActive,setIsActive] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("nEkOtResU")) {
      window.location.replace("/standard");
    }
  }, []);

  const history = useHistory();

  const encryptData = async (data,location) =>{
    let Test = JSON.stringify(data)
    const Value = CryptoJS.AES.encrypt(Test,process.env.REACT_APP_ACCESS_KEY).toString();
    localStorage.setItem(location,Value);
    // console.log('EnC',Value)
  }




  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },


    

    validationSchema: yup.object({
      username: yup.string().required("Username is Required"),
      password: yup.string().required("Password is Required"),
    }),
    onSubmit: (values) => {
      setIsActive(true)
      axios
        .post(`${SERVER_URL}/Auth/login`, {
          user_name: values.username,
          password: values.password,
        })
        .then(async function (apiData) {
          if (apiData.data.token) {
            setIsActive(false)
            const userToken = apiData.data.token;
            await accessKey(userToken)
            await encryptData(userToken,"nEkOtResU");
            // encryptData("abcd","test");
            const userData = apiData.data.data;
            await encryptData(userData,"aTaDrEsU");
            const userPermission = apiData.data.permissions || "";
            await encryptData(userPermission,"nOiSsImRePrEsU");
            // localStorage.setItem("userToken", JSON.stringify(userToken));
            //session storage
            // localStorage.setItem("userData", JSON.stringify(userData));
            // localStorage.setItem(
            //   "userPermission",
            //   JSON.stringify(userPermission)
            // );
            
            Toast.fire({
              icon: "success",
              title: apiData.data.msg || "Login successfully",
            });
             window.location.replace("/standard");
          } else {
            setIsActive(false)
            Toast.fire({
              icon: "error",
              title:
                apiData.data.msg ||
                "Username or Password is wrong or Network Error",
            });
            history.push("/");
          }
        })
        .catch(function (error) {
          setIsActive(false)
          Toast.fire({
            icon: "error",
            title:error?.response?.data?.msg||error?.response?.data?.error ||"Connection cannot be established",
          });
        });
    },
  });

  async function accessKey(token){
    await axios({
      url: `${window.WEB_APP_API_URL}/Standards/`,
      method: 'get',
      headers: {
          'access_token':token,
          'Content-Type': 'application/json'
      }
   })
   .then(response => {
     let x =  response.data.data||[]
     let accessKey = []
     let final = x.filter((x)=> x.isActive==true && x.keyActivated)
     final.map((key)=>{
      accessKey.push(key.id);
     })
     encryptData(accessKey, "yEkSseCcaReSu")
    //  localStorage.setItem("userAccessKey", JSON.stringify(accessKey));
   }) 
   .catch(err => {
      console.log(err);
   });
  }


  return (
    <div className="grid items-center relative bg py-[1.30rem] bg-center bg-origin-border bg-cover h-screen">
      <div className="lg:flex justify-evenly items-center rounded-3xl shadow-box bg-login w-9/12 lg:w-5/12 h-[30rem] md:h-[33rem] lg:h-[35rem] xl:h-[25rem] py-2 m-auto my-8">
        <div className="block lg:hidden">
          <img className="w-5/12 m-auto" src={Logo} alt="scroll" />
        </div>
        <div className="px-4 lg:pl-8 py-6 xl:py-12 md:w-[55%] lg:w-[85%]">
          <div className="mb-5">
            <h1 className="text-[25px] xl:text-[35px] text-text font-semibold mb-4">
              Login
            </h1>
            <h4 className="text-sm lg:text-base font-medium text-secondary">
              Hello! Welcome back.
            </h4>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full xl:my-5">
              <label className="block mt-8 text-text font-medium">
                Username*
                <input
                  type="text"
                  id="email"
                  name="username"
                  className="w-full border border-gray-300 border-1 rounded-3xl px-5 py-1 bg-login mt-2"
                  placeholder="Please enter your username"
                  // value={formik.values.username}
                  onChange={formik.handleChange}
                />
                {formik.errors.username ? (
                  <div className=" text-red-500 text-xs font-medium mx-auto w-11/12 h-1 mt-1 ">
                    {formik.errors.username}
                  </div>
                ) : (
                  <div className=" text-red-500 text-xs font-medium mx-auto w-10/12 h-2"></div>
                )}
              </label>
              <label className="text-text font-medium block mt-4">
                Password*
                <input
                  type="password"
                  className="w-full border border-gray-300 border-1 rounded-3xl px-5 py-1 bg-login mt-2"
                  placeholder="Please enter password"
                  // value={formik.values.password}
                  onChange={formik.handleChange}
                  id="password"
                  name="password"
                />
                {formik.errors.password ? (
                  <div className="text-red-500 text-xs font-medium mx-auto w-11/12 h-1 mt-1 ">
                    {formik.errors.password}
                  </div>
                ) : (
                  <div className=" text-red-500 text-xs font-medium mx-auto w-10/12 h-2"></div>
                )}
              </label>
              <button
                type="submit"
                className={`${isActive?"bg-slate-400 cursor-wait": "bg-primary"}  text-white rounded-3xl py-2 mt-4 w-full`}
                disabled={isActive}
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <div className="hidden lg:block">
          <img className="w-11/12 m-auto" src={Logo} alt="scroll" />
        </div>
      </div>
      <p className=" absolute bottom-0 text-slate-400 text-center w-full text-xs">
      <span className=" text-[15px] font-medium">&copy;</span> {GetYear()} Metatron Infotech Ltd.
      </p>
    </div>
  );
}

export default Login;
