import React from "react";
import {ReactComponent as Notfound} from "../Image/icons/notFound.svg";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";

function NotFound() {
    let history = useHistory();
    return (
        <>
            <div className="w-full h-full flex flex-col justify-center items-center mt-4 mx-auto text-center">
                <div className="w-[35%]">
                    <Notfound/>
                </div>
                <div className=" flex items-center gap-5">
                <NavLink to="/standard"><button className=" bg-primary px-2 text-white py-1 rounded-md" style={{border:"1px solid #7030A0"}}>Home</button></NavLink> 
                <button onClick={history.goBack} className=" px-3 text-primary py-1 rounded-md " style={{border:"1px solid #7030A0"}}>Back</button>
                    </div>
            </div>
        </>
    )
}

export default NotFound