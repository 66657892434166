import React, { useEffect, useState,useMemo } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import FileDisplay from "../../../Utilities/common/FileDisplay";
import { CountryMIN } from "../../../Utilities/data/CountryMini";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function ChangePassword({
  data,
  visible,
  onClose,
  refreshData,
}) {

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [filePathList,setFilePathList] =useState([])
  const [passwordCheck,setPasswordCheck] = useState("")
//   const [removefilePathList,setRemoveFilePathList] =useState([])
  const [createNew, setCreateNew] = useState({
    password: "",
    retypePassword:"",
  });

//   useEffect(() => {
//     setCreateNew({
//       name: data?.name,
//       user_name: data?.user_name,
//       email: data?.email,
//       mobile: data?.mobile,
//       // password: data?.password,
//       standards:data?.standards||[],
//       country:data?.country,
//       location:data?.location
//     });
//   }, [data]);


  const formInputs = [
{
      inputType: "NuInput",
      name: "password",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Password",
      labelclassname: "w-full",
      type: "password",
      customClass: "w-full ",
    },
    {
      inputType: "NuInput",
      name: "retypePassword",
      classname: "border w-9/12 h-10 px-3 rounded-lg",
      placeholder: "",
      label: "Retype Password",
      labelclassname: "w-full",
      type: "password",
      customClass: "w-full ",
    },
    
  ];


  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      password:yup.string().min(8,"Password must be Min 8 char").matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9!@#$%^&*()-_=+;:'",.<>?/\\|[\]{} ]+$/,"Password with in alphanumeric and Special char"),
      retypePassword:yup.string().matches(passwordCheck,"Passwords not match").min(8,"Retype Password must be Min 8 char").required("Retype Password is requerd"),
    }),
    onSubmit: (value,{resetForm}) => {
        delete value.retypePassword        
        axiosInstance.put(`/User/change_password/${data.userId}`, value).then((res) => {
          if (res.data) {
            resetForm()
            onClose();
            refreshData();
            Toast.fire({
              icon: "success",
              title: res.data.message || "User Password Updated Successfully",
            });
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.message || "User Password Update Unsuccessfully",
            });
          }
        });
    },
  });


  useMemo(()=>{
    setPasswordCheck(formik.values.password)
    // console.log(formik.values.password);
  },[formik.values.password])

  const handleOnClose = (e) => {
    if (e.target.id === "modal") {
     formik.resetForm()
      onClose();
    }
  };

  const resetClose = ()=>{
    formik.resetForm()
    onClose()
  }

  if (!visible) return null;


  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-4/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={resetClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="overflow-y-auto">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className="flex items-center justify-end px-6 py-6 rounded-b">
                <button
                  onClick={resetClose}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
