import React, { useState } from "react";
import CreateorUpdate from "./form";
import Axios from "../../../Services/axiosComman";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import TableView from "../../../Utilities/common/TableView";
import CardView from "../../../Utilities/common/CardView";
import ListLayout from "../../../Utilities/Layout/ListLayout"

const fetcher = (url) => Axios.get(url).then((res) => res.data);

function StandardInformationIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [view,setView] = useState(localStorage.getItem('view'))
  function listView(){
    localStorage.setItem('view',false);
    let View = localStorage.getItem('view')
    setView(false)
  }
  function cardView(){
    localStorage.setItem('view',true);
    let View = localStorage.getItem('view')
    setView(true)
  }

  const { data, error, mutate } = useSWR(
    `/standard-document/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`,
    fetcher
  );

  const refreshData = () => {
    mutate(fetcher);
  };

  let link = "standard-document"

  return (
    <ListLayout
     label="Standard Information"
     title="Information"
     id={id} 
     total={data?.total} 
     setDataCount={setDataCount} 
     dataCount={dataCount} 
     pageIndex={pageIndex} 
     setPageIndex={setPageIndex} 
     view={view} 
     listView={listView} 
     cardView={cardView} 
     setShowMyModal={setShowMyModal}
     Access="information_create"
     >
     {
      view == true? <CardView data={data} link={link} accessString="information_delete" fetcher={refreshData}/> : <TableView data={data} fetcher={refreshData} link={link} />
     }
         <CreateorUpdate
           onClose={handleOnClose}
           visible={showMyModal}
           refreshData={refreshData}
           id={id}
         />
    </ListLayout>
  );
}

export default StandardInformationIndex;

