import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import avatar from "../Image/img/avatar.png";
import Logout from "./Logout";
import DecryptData from "./Decrypt";
import Swal from "sweetalert2";
import axiosInstance from "../../Services/axiosComman";
import useSWR from "swr";
import DateFormat from "./NuDate";
import { GetAccess, GetRole } from "../data/role";
import HelpIcon from "../Image/icons/Help";

function Navbar() {
  const [open, setOpen] = useState(false);
  const userData = localStorage.getItem("aTaDrEsU")?DecryptData("aTaDrEsU"):[];
  const Username = userData?.user_name;
  const Userimg = userData?.image_path;
  let role = userData?.role[0].roleName

  const [noti, setNoti] = useState([]);
  const [count, setCount] = useState(0);
  const fetcher = (url) =>
  axiosInstance.get(url).then((res) => {
    res?.data?.data&& setCount(res?.data?.data?.count);
    res?.data?.data?.notifications&&
      setNoti(res?.data?.data?.notifications);
  });
const { data, mutate } = useSWR(`/notification/user`, fetcher, {
  refreshInterval: 30000,
});

const [isOpen, setIsOpen] = useState(false);
const notificationRef = useRef(null);
const toggleNotificationMenu = () => {
  setIsOpen(!isOpen);
};


  function logOut() {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Log out ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout'
    }).then(async (result) => {
      if (result.isConfirmed) {
        Logout()
      }
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleRead = async (id) => {
    
    axiosInstance.put(`/notification/update/is_read/${id}`, {
        is_read: 1,
      })
      .then((res) => {
        mutate();
        console.log(res.data);
      });
    // const data = 1
    // fetch(axiosInstance.put(`/notification/update/is_read/4`),{
    //   headers:{
    //     'Content-Type': 'application/json'
    //   },
    //   body:JSON.stringify(data)
    // })
    // .then((res)=>{
    //   res=1
    // })
    // .catch((error)=>{
    //   error?.
    // })
  };

  function ClearAll(){
    axiosInstance.delete(`/notification/delete`).then((res) => {
      mutate();
    }).catch(err=>{})
  }

  function MarkAll(){
    axiosInstance.get(`/notification/update/mark_all_as_read`).then((res) => {
      mutate();
    }).catch(err=>{})
  }

  return (
    <nav className="bg-primary">
      <div className="py-0 lg:py-1 w-11/12 m-auto flex items-center justify-between">
        <div className="md:w-auto w-full flex justify-between">
          <NavLink
            to="/standard"
            className="md:cursor-pointer text-2xl 2xl:text-3xl py-2 text-white font-semibold"
          >
            Scroll
          </NavLink>
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <div className=" flex gap-2 items-center">
        {/* {
          GetRole() &&        <div className="relative" ref={notificationRef}>
          <div
            className="p-1 relative text-white  rounded-full cursor-pointer"
            onClick={toggleNotificationMenu}
          >
            {
              count > 0 &&             <div className=" absolute h-[15px] w-[15px] rounded-full -top-1 -right-[2px] bg-red-400 text-white font-semibold flex justify-center items-center text-[9px]" style={{border:'1px solid white'}}>
              <span>{count||0}</span>
            </div>
            }
            <svg xmlns="http://www.w3.org/2000/svg " fill="white" height="1em" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/></svg>
          </div>

        
          <div
            className={`${isOpen ? 'block' : 'hidden'} absolute top-7 right-0 z-50 bg-white shadow-menu w-64 h-96 overflow-hidden rounded-lg`}
          >
         <div className=" w-full py-2 px-2 bg-slate-100 flex justify-between items-center">
          <p className="font-medium text-sm ">Notification</p>
          <div className=" flex items-center gap-2">
            {count>0?<button className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-slate-400 text-white cursor-pointer" onClick={MarkAll}>Marks as read</button>:null}
            {noti.length>0?<button className=" outline-none border-none text-[10px] font-normal px-2 py-1 rounded bg-slate-400  text-white cursor-pointer" onClick={ClearAll}>Clear All</button>:null}
          </div>
          </div>
          <div className="w-full max-h-[calc(24rem-40px)] overflow-y-auto  overflow-x-hidden">
          {noti?.map((notification) => {
            return (
              <>
                <div
                  className={`${notification?.is_read?"bg-white":" bg-slate-100"} w-[97%] m-1 p-1 rounded flex justify-between items-center cursor-pointer`}
                  onClick={() => handleRead(notification?.notificationId)}
                >
                  <div className="w-[15%]">
                    <div className=" w-7 h-7 bg-primary  flex justify-center items-center text-white font-medium text-lg rounded-full overflow-hidden"> 
                    {
                      notification.sender_imageUrl && notification.sender_imageUrl!=""&&notification.sender_imageUrl!=null?
                      <img
                      src={
                        notification.sender_imageUrl
                          ? notification.sender_imageUrl
                          : null
                      }
                      width="50px"
                      alt={`${notification.sender_imageUrl}`}
                    />:
                    `${notification?.sender_name.charAt(0)}`
                    }

                    </div>
                  </div>
                  <div className={`w-[85%] ${notification?.is_read?" font-normal ":"font-medium  "}`}>
                    <p className="pb-1 text-ellipsis leading-2 text-[12px]">
                      {notification?.msg}
                    </p>
                    <p className="text-xs">
                    <DateFormat
                          value={`${notification?.date} ${notification?.time}`}
                          format="DD-MM-YYYY"
                        />
                      <span className="text-[10px]">
                        <DateFormat
                          value={`${notification?.date} ${notification?.time}`}
                          format="hh:mm a"
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </>
            );
          })}
          </div>
          </div>
        </div>
        } */}

<Link to="/help">
<HelpIcon/>
</Link>
        <div className="px-3 py-1 text-left md:cursor-pointer group relative">
          <a
            href="#!"
            className="flex items-center md:pr-0 pr-5 group text-white text-xs 2xl:text-sm border-l border-white"
          >
            <img 
            // src={avatar}
            src={Userimg || avatar} 
            alt="" className="w-7 h-7 mx-2 rounded-full" />
            <p className="mr-12 text-white text-sm 2xl:text-sm capitalize">{Username}</p>
          </a>
          <div className="absolute z-50 top-[1.8rem] hidden group-hover:md:block hover:md:block">
            <div className="bg-white shadow-box rounded-md px-1 py-3 grid grid-cols-1 w-24">
              <div>
                {
                  (role ==="scroll_admin"|| role ==="admin"||GetAccess('admin_view')) &&              
                   <li className="list-none p-2 hover:bg-navhover rounded-md">
                  <Link
                    to="/settings/admin/company-info"
                    className="text-xs 2xl:text-sm"
                    // onClick={logOut}
                  >
                    Settings
                  </Link>
                </li>
                }
                <li className="list-none p-2 hover:bg-navhover rounded-md">
                  <Link
                    to="#!"
                    className="text-xs 2xl:text-sm"
                    onClick={logOut}
                  >
                    Logout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
