import React from 'react'
import DateFormat from './NuDate';
import IconDisplay from './IconDisplay';
import Viewmore from './CardViewMore';
import { GetAccess, GetRole } from '../data/role';

function TableView({data=[],fetcher,link,accessString}) {
  console.log("ffffff",(GetAccess("dddd")||GetRole()));
    const headers = [

        {
          label: "Name",
          width: "w-[47%]",
          isLeft:true,
        },
        {
          label: "Date",
          width: "w-[15%]",
        },
        {
          label: "Size",
          width: "w-[15%]",
        },
        {
          label: "View",
          width: "w-[20%]",
        },
        {
          label:"",
          width:"w-[3%]"
        }
      ];
  return (
    <div className="w-full h-[calc(100vh-220px)] overflow-hidden">
    <div className="w-full">
      <div className="bg-[#F1E0FF] w-full">
        <table className="w-full">
          <thead>
            <tr className="text-[13px] font-medium">
              {headers &&
                headers.map((header, i) => (
                  <th
                    className={`px-2 py-4 font-semibold ${header.isLeft?'text-left':'text-center'}  ${header.width}`}
                    key={i}
                  >
                    {header.label || " "}
                  </th>
                ))}
            </tr>
          </thead>
        </table>
      </div>
      <div className="max-h-[calc(70vh-40px)] overflow-y-auto">
        <table className="w-full table-auto border-collapse">
          <tbody className="text-[13px] w-full">
            {data?.data&&data?.data.map((item, i) => {
              return (
                <tr className="odd:bg-white even:bg-slate-50" key={i}>
                  <td className="w-[47%] px-2 py-3 text-left ">{item?.image_name||"-"}</td>
                  <td className="w-[15%] px-2 py-3 text-center "><DateFormat value={item?.modified_on} format="DD-MMM-YYYY" /> <small><DateFormat value={item?.modified_on} format="hh:mm" /></small></td>
                  {/* <td className="w-[15%] px-2 py-3 text-center ">{item?.file_type||""}</td> */}
                  <td className="w-[15%] px-2 py-3 text-center">{item?.file_size||""}</td>
                  <td className="w-[20%] px-2 py-2">
                    <div className='w-full  flex justify-center items-center'>
                      <IconDisplay data={item?.image_path} view={true} />
                    </div>
                  </td>
                  <td className='w-[3%] px-2 py-2'>
                    {/* <div className=' w-full h-full'> */}
                    {
                      (GetAccess(accessString)||GetRole()) && <><Viewmore id={item?.id} link={link} fetcher={fetcher} /></> 
                    } 
                    {/* </div> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default TableView