import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProductRoutes from "./Services/ProductRoutes";
import Login from "./Login/Login";
import NotFound from "./Utilities/common/notFound";
import Logout from "./Login/Logout";
import { AdminFun, RenderFun } from "./Utilities/data/render";
import DashboardMain from "./Views/Main/MainDashboard";
import MainStandard from "./Views/Main/MainStandard";
import MainProgress from "./Views/Main/MainProgress";
import SubDashboardView from "./Views/Dashboard/SubDashboard";
import StandardInformationIndex from "./Views/Information/StandardInformation";
import TaskManagementIndex from "./Views/Information/TaskManagement";
import VendorManagementIndex from "./Views/Information/VendorManagement";
import SecurityIncidentIndex from "./Views/Information/SecurityIncident";
import TrainingCalenderIndex from "./Views/Information/TrainingCalender";
import ImplementationChecklistIndex from "./Views/Activity/ImplementationCheckList";
import MasterListOfDocumentIndex from "./Views/Activity/MasterList";
import AuditCalenderIndex from "./Views/Activity/AuditCalender";
import InternalAuditIndex from "./Views/Activity/InternalAduit";
import MaintenanceIndex from "./Views/Activity/Maintenance";
import ReferenceDocFormatIndex from "./Views/ReferenceDocs/ReferenceDocsFormat";
import WebsiteTrackingIndex from "./Views/ReferenceDocs/WebsiteTracking";
import ConsentManagementIndex from "./Views/ReferenceDocs/ConsentManagement";
import CertificationTrackingIndex from "./Views/ReferenceDocs/CertificationTracking";
import StatutoryReportingIndex from "./Views/ReferenceDocs/StatutoryReporting";
import CookieManagementIndex from "./Views/ReferenceDocs/CookieManagment";
import AbbreviationIndex from "./Views/ReferenceDocs/Abbreviations";
import HelpIndex from "./Views/ReferenceDocs/QandAHELP";
import StandardComparisionsIndex from "./Views/ReferenceDocs/StandardComparisions";
import DiagramsandLogosIndex from "./Views/ReferenceDocs/DiagramsOrLogos";
import ReportsListIndex from "./Views/Reports/ReportsList/ReportsList";
import GenerateReportList from "./Views/Reports/ReportsList/GenerateReport";
import ImplementationChecklistReportIndex from "./Views/Reports/Activity/ImplementationReport";
import InternalAuditReportIndex from "./Views/Reports/Activity/InternalAuditReport";
import MaintenanceReportIndex from "./Views/Reports/Activity/MaintenanceReports";
import MasterReportIndex from "./Views/Reports/Activity/MasterReport";
import MOMReportIndex from "./Views/Reports/Activity/MomReport";
import AuditCalenderReportIndex from "./Views/Reports/Activity/AuditSchedule";
import SecurityIncidentReportIndex from "./Views/Reports/Information/SecurityIncident";
import TrainingCalenderReportIndex from "./Views/Reports/Information/TrainingCalender";
import VendorManagementReportIndex from "./Views/Reports/Information/VendorManagement";
import OverAllProgressIndex from "./Views/Reports/OverAllProgress";
import CompanyInfoIndex from "./Views/AdminSettings/CompanyInfo";
import UserManagementSettingsIndex from "./Views/AdminSettings/UserManagement";
import UserRoleSettingsIndex from "./Views/AdminSettings/UserRole";
import RoleSettingsIndex from "./Views/AdminSettings/RoleSettings";
import StandardResettingIndex from "./Views/AdminSettings/Reset";
import ActionLogIndex from "./Views/AdminSettings/ActinLog";
import CommonVendorList from "./Views/AdminSettings/CommonVendor";
import CertificationCalenderTrackerSettings from "./Views/AdminSettings/CertificationCalendarTracker";
import SubcriptionSettingsIndex from "./Views/AdminSettings/Subscription";
import BackupAndRestoreSeetingIndex from "./Views/AdminSettings/BackupRestore";
import StatusSettingsIndex from "./Views/StandardSettings/Status";
import FrequencySettingsIndex from "./Views/StandardSettings/Frequency";
import IncidentSettingIndex from "./Views/StandardSettings/Incident/IncidentType";
import VenderSettingIndex from "./Views/StandardSettings/Vendor/VenderType";
import ManualSettingsIndex from "./Views/StandardSettings/Manual";
import ApprovalStatusSettingIndex from "./Views/StandardSettings/ApprovalStatus";
import EmailTemplatesSettingIndex from "./Views/StandardSettings/EmailTemplate";
import EmailSettingsIndex from "./Views/StandardSettings/EmailSettings";
import MOMIndex from "./Views/Activity/MOM";
import CalenderManagementIndex from "./Views/Information/CalenderManagement";
import DetailTaskView from "./Views/Information/CalenderManagement/DetailTaskView";
import DepartmentSettingIndex from "./Views/AdminSettings/Department/DepartmentType";
import EmailSetupIndex from "./Views/AdminSettings/EmailSetup";
import Help from "./Views/Help/Help";


function App() {
  
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/dashboard">
          <ProductRoutes comp={DashboardMain} />
        </Route>
        <Route exact path="/standard">
          <ProductRoutes comp={MainStandard} />
        </Route>
        <Route exact path="/progress">
          <ProductRoutes comp={MainProgress} />
        </Route>
        <Route exact path="/dashboard/SubDashboard/:id" render={({ match }) => RenderFun(match,SubDashboardView)} />
       {/* Information */}
       <Route exact path="/information/standard-information/:id" render={({ match }) => RenderFun(match,StandardInformationIndex)}/>
       <Route exact path="/information/task-management/:id" render={({ match }) => RenderFun(match,TaskManagementIndex)}/>
       <Route exact path="/information/vendor-management/:id" render={({ match }) => RenderFun(match,VendorManagementIndex)}/>
       <Route exact path="/information/security-incident/:id" render={({ match }) => RenderFun(match,SecurityIncidentIndex)}/>
       <Route exact path="/information/training-calendar/:id" render={({ match }) => RenderFun(match,TrainingCalenderIndex)}/>
       <Route exact path="/information/calendar-management/:id" render={({ match }) => RenderFun(match,CalenderManagementIndex)}/>
       <Route exact path="/information/task-detail/view/:id/:taskId" render={({ match }) => RenderFun(match,DetailTaskView)}/>
       
       
       {/* Activity */}
       <Route exact path="/activity/implementation-checklist/:id" render={({ match }) => RenderFun(match,ImplementationChecklistIndex)}/>
       <Route exact path="/activity/master-list-of-documents/:id" render={({ match }) => RenderFun(match,MasterListOfDocumentIndex)}/>
       <Route exact path="/activity/audit-calendar/:id" render={({ match }) => RenderFun(match,AuditCalenderIndex)}/>
       <Route exact path="/activity/internal-audit/:id" render={({ match }) => RenderFun(match,InternalAuditIndex)}/>
       <Route exact path="/activity/maintenance/:id" render={({ match }) => RenderFun(match,MaintenanceIndex)}/>
       <Route exact path="/activity/ciso-mom/:id" render={({ match }) => RenderFun(match,MOMIndex)}/>
       

       {/* Reference */}
       <Route exact path="/reference-doc/reference-doc-format/:id" render={({ match }) => RenderFun(match,ReferenceDocFormatIndex)}/>
       <Route exact path="/reference-doc/website-tracking/:id" render={({ match }) => RenderFun(match,WebsiteTrackingIndex)}/>
       <Route exact path="/reference-doc/consent-management/:id" render={({ match }) => RenderFun(match,ConsentManagementIndex)}/>
       <Route exact path="/reference-doc/certification-tracking/:id" render={({ match }) => RenderFun(match,CertificationTrackingIndex)}/>
       <Route exact path="/reference-doc/statutory-reporting/:id" render={({ match }) => RenderFun(match,StatutoryReportingIndex)}/>
       <Route exact path="/reference-doc/cookie-management/:id" render={({ match }) => RenderFun(match,CookieManagementIndex)}/>
       <Route exact path="/reference-doc/abbreviations/:id" render={({ match }) => RenderFun(match,AbbreviationIndex)}/>
       <Route exact path="/reference-doc/help/:id" render={({ match }) => RenderFun(match,HelpIndex)}/>
       <Route exact path="/reference-doc/standard-comparisions/:id" render={({ match }) => RenderFun(match,StandardComparisionsIndex)}/>
       <Route exact path="/reference-doc/diagramsandlogos/:id" render={({ match }) => RenderFun(match,DiagramsandLogosIndex)}/>



       {/* Report List */}
       <Route exact path="/reports/pre-defined/reports-list/:id" render={({ match }) => RenderFun(match,ReportsListIndex)}/>
       <Route exact path="/reports/genarate/reports-list/:id" render={({ match }) => RenderFun(match,GenerateReportList)}/>
       <Route exact path="/report/pre-defined/implementationckeck-list/:id" render={({ match }) => RenderFun(match,ImplementationChecklistReportIndex)}/>
       <Route exact path="/report/pre-defined/internal-audit/:id" render={({ match }) => RenderFun(match,InternalAuditReportIndex)}/>
       <Route exact path="/report/pre-defined/maintenance-report/:id" render={({ match }) => RenderFun(match,MaintenanceReportIndex)}/>
       <Route exact path="/report/pre-defined/master-report/:id" render={({ match }) => RenderFun(match,MasterReportIndex)}/>
       <Route exact path="/report/pre-defined/mrm-report/:id" render={({ match }) => RenderFun(match,MOMReportIndex)}/>
       <Route exact path="/report/pre-defined/audit-calendar-report/:id" render={({ match }) => RenderFun(match,AuditCalenderReportIndex)}/>
       <Route exact path="/report/pre-defined/vendor-management-report/:id" render={({ match }) => RenderFun(match,VendorManagementReportIndex)}/>
       <Route exact path="/report/pre-defined/security-incident-report/:id" render={({ match }) => RenderFun(match,SecurityIncidentReportIndex)}/>
       <Route exact path="/report/pre-defined/training-calendar-report/:id" render={({ match }) => RenderFun(match,TrainingCalenderReportIndex)}/>
       <Route exact path="/report/pre-defined/overallprogress/:id" render={({ match }) => RenderFun(match,OverAllProgressIndex)}/>

       {/* Admin setting */}
       <Route exact path="/settings/admin/company-info" render={({match})=>AdminFun(match,CompanyInfoIndex)} />
       <Route exact path="/settings/admin/user-management" render={({match})=>AdminFun(match,UserManagementSettingsIndex)}/>
       <Route exact path="/settings/admin/user-role" render={({match})=>AdminFun(match,RoleSettingsIndex)}/>
       <Route exact path="/settings/admin/roles" render={({match})=>AdminFun(match,UserRoleSettingsIndex)}/>
       <Route exact path="/settings/admin/standard-reset" render={({match})=>AdminFun(match,StandardResettingIndex)}/>
       <Route exact path="/settings/admin/action-log" render={({match})=>AdminFun(match,ActionLogIndex)}/>
       <Route exact path="/settings/admin/main-vendor" render={({match})=>AdminFun(match,CommonVendorList)}/>
       <Route exact path="/settings/admin/certification-calendar-tracker" render={({match})=>AdminFun(match,CertificationCalenderTrackerSettings)}/>
       <Route exact path="/settings/admin/subscription" render={({match})=>AdminFun(match,SubcriptionSettingsIndex)}/>
       <Route exact path="/settings/admin/backup-restore" render={({match})=>AdminFun(match,BackupAndRestoreSeetingIndex)}/>
       <Route exact path="/settings/admin/department-type" render={({match})=>AdminFun(match,DepartmentSettingIndex)}/>
       <Route exact path="/settings/admin/email-setup" render={({match})=>AdminFun(match,EmailSetupIndex)}/>


       {/* Standard Settings */}
       <Route exact path="/setting/standard/status/:id" render={({match})=>RenderFun(match,StatusSettingsIndex)}/>
       <Route exact path="/setting/standard/frequency-settings/:id" render={({match})=>RenderFun(match,FrequencySettingsIndex)}/>
       <Route exact path="/setting/standard/incident-type-settings/:id" render={({match})=>RenderFun(match,IncidentSettingIndex)}/>
       <Route exact path="/setting/standard/vender-type-settings/:id" render={({match})=>RenderFun(match,VenderSettingIndex)}/>
       <Route exact path="/setting/standard/manual-settings/:id" render={({match})=>RenderFun(match,ManualSettingsIndex)}/>
       <Route exact path="/setting/standard/approval-status/:id" render={({match})=>RenderFun(match,ApprovalStatusSettingIndex)}/>
       <Route exact path="/setting/standard/email-settings/:id" render={({match})=>RenderFun(match,EmailSettingsIndex)}/>
       <Route exact path="/setting/standard/email-templates/:id" render={({match})=>RenderFun(match,EmailTemplatesSettingIndex)}/>


       {/* Help */}
       <Route exact path="/help/:id" >
           <ProductRoutes comp={Help} />
       </Route>
       <Route exact path="/help" >
           <ProductRoutes comp={Help} />
       </Route>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/logout">
          <Logout/>
        </Route>
        <Route exact path="/*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
