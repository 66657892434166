import React, { useState, useEffect } from "react";
import InnerNavbar from "../../../Utilities/common/Navbar/Navbar";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
import Pagination from "../../../Utilities/common/Pagination";
import DateFormat from "../../../Utilities/common/NuDate";
import Import from "./import";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import { GetAccess, GetRole } from "../../../Utilities/data/role";
import ApprovelStatus from "../../../Utilities/common/Approvel";
import { GetYear } from "../../../Utilities/common/GetYear";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import Export from "../../../Utilities/common/Export";

const fetcher = (url) => Axios.get(url).then((res) => res.data);

function TrainingCalenderIndex() {
  const { id } = useParams();
  const location = useLocation();
  let { statusFilter } = location?.state || "";
  const history = useHistory();
  console.log("status", statusFilter);
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [frequency, setFrequency] = useState([]);
  const [trainingCalender, setTrainingCalender] = useState([]);
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [selected, setSelected] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [total, setTotal] = useState([]);
  const [training, setTraining] = useState([]);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [data, setData] = useState([]);
  const [standardName,setStandardName]=useState("");
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");

  const refreshData = () => {
    // mutate(fetcher);
    Axios.get(
      `/Training-Calender/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`
    ).then((res) => {
      res?.data && setData(res?.data);
    });
  };
  // const { data, error, mutate } = useSWR(
  //   `/Training-Calender/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`,
  //   fetcher
  // );

  // const userToken = JSON.parse(localStorage.getItem("userToken"));
  // const roleId = userToken?.user?.roleId;

  function getStandard(){
    Axios.get(`Standards/${id}`).then(res=>{
        setStandardName(res?.data?.data.name);
        setStartDate(res?.data?.data.start_date)
        setEndDate(res?.data?.data.end_date)
    }).catch(error=>{
        console.log(error);
    })
  }


  useEffect(() => {
    getTraining();
    getStandard()
    getTrainingCalender();
    getFrequency();
  }, []);

  const getTraining = () => {
    Axios.get(`/DashBoard/standard/${id}/training-calender/`).then((res) => {
      res?.data && setTraining(res.data.data);
    });
  };

  const getTrainingCalender = () => {
    Axios.get("/Status/16/").then((res) => {
      let data = [];
      let frequencyStatus = res?.data?.data;
      frequencyStatus.forEach((i) => {
        let item = { value: i.status_type, label: i.status_type };
        data.push(item);
      });
      res?.data && setTrainingCalender(data);
    });
  };

  async function getFrequency() {
    await Axios.get(`/Frequency/`)
      .then((res) => {
        let data = [];
        let frequencyList = res?.data?.data;
        frequencyList.forEach((i) => {
          let item = { value: i.frequency_type, label: i.frequency_type };
          data.push(item);
        });
        res.data.data && setFrequency(data);
      })
      .catch((err) => console.log(err));
  }

  function getFilterData() {
    Axios.get(
      `/Training-Calender/standard/${id}/status/${statusFilter}?page=${pageIndex}&per_page=${dataCount}`
    )
      .then((res) => {
        if(res?.data?.status_code == 200) {
          res.data && setData(res.data);
        }else{
          setData({
            data:[],
            error:"",
            msg:"No data",
            status: true,
            status_code:404,
            total:0
          });
        }
      })
      .catch((error) => {
        setData({
          data:[],
          error:"",
          msg:"No data",
          status: true,
          status_code:404,
          total:0
        });
        console.log(error);
      });
  }

  function getTrainingData() {
    if (statusFilter) {
      getFilterData();
    } else {
      refreshData();
    }
  }

  useEffect(() => {
    if (statusFilter) {
      getFilterData();
    } else {
      refreshData();
    }
  }, [pageIndex, dataCount]);

  useEffect(() => {
    if (statusFilter) {
      getFilterData();
    }
  }, [statusFilter]);

  function clearFilter() {
    refreshData();
    history.replace({
      state: null,
    });
  }

  return (
    <>
      <MainLayout
        id={id}
        total={data?.total}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        dataCount={dataCount}
        setDataCount={setDataCount}
        label="Training Calendar"
        title="Information"
        setShowApprovalModal={setShowApprovalModal}
        hasApproval={true}
      >
        <div className="py-3 pl-5 flex justify-between items-center w-[98.5%]">
          <p className="text-xs 2xl:text-sm text-text">Training Calendar</p>
          <div className="flex items-center text-xs 2xl:text-sm">
            <p className="mr-2">overall</p>
            <span className="bg-login px-2 py-1 rounded-md">
              {training?.complete_percentage || 0}%
            </span>
          </div>
          <div className="flex">
            {statusFilter != null ? (
              <div
                className=" py-1 px-2 rounded-md bg-slate-400 text-xs text-white cursor-pointer"
                onClick={clearFilter}
              >
                clear
              </div>
            ) : null}

            <div className=" w-8">
              {GetAccess("information_create") && (
                <svg
                  className="w-7 ml-1 cursor-pointer"
                  alt="plus"
                  onClick={() => setShowMyModal(true)}
                  title="Add"
                  viewBox="0 0 52 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.61084"
                    y="0.335541"
                    width="51.1569"
                    height="51.1569"
                    rx="5"
                    fill="#7030A0"
                  />
                  <path
                    d="M26.1896 36.5806V25.914M26.1896 25.914V15.2473M26.1896 25.914H36.8563M26.1896 25.914H15.5229"
                    stroke="white"
                    stroke-width="2.3"
                    stroke-linecap="round"
                  />
                  <title>Add</title>
                </svg>
              )}
            </div>
            <Export
              id={id}
              path={"training_calender"}
              name={`${standardName} Training Calendar ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`}
            />

            {GetAccess("information_create") && (
              <Import refreshData={refreshData} id={id} getTraining={getTraining} />
            )}
          </div>
        </div>

        <div className=" w-full h-[calc(100vh-215px)] bg-white shadow-box">
          <div className=" w-full ">
            <table className="table-auto w-full">
              <thead className="bg-tabletitle">
                <tr className=" text-xs 2xl:text-sm font-medium">
                  {header?.map((header) => (
                    <th className={` px-5 py-4 ${header.width}`}>
                      {header.headone}
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
          </div>
          <div className="overflow-y-auto h-[calc(100vh-295px)]">
            <table className="table-auto w-full">
              <tbody className="overflow-y-auto h-[calc(100vh-295px)]">
                {data?.data &&
                  data?.data?.map((i,data) => (
                    <tr
                      className="odd:bg-tableodd even:bg-tableeven py-1 text-xs 2xl:text-sm font-medium flex justify-between items-center capitalize my-1"
                      key={data}
                    >
                      <td className=" w-[5%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.serial_number || "#"}
                      </td>
                      <td className=" w-[12%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.training_program || "-"}
                      </td>
                      <td className=" w-[11%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.function_responsibility || "-"}
                      </td>
                      <td className=" w-[10%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.frequency || "-"}
                      </td>
                      <td className=" w-[11%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.participants || "-"}
                      </td>
                      <td className=" w-[12%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.mandatory || "-"}
                      </td>
                      <td className=" w-[10%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.detail_plan || "-"}
                      </td>
                      <td className=" w-[10%] mb-2 text-center text-gray-900 ">
                        {(i?.planned_date != "None" &&
                          i?.planned_date != " " &&
                          DateFormat({
                            value: i?.planned_date,
                            format: "DD-MMM-yyyy",
                          })) ||
                          "-"}
                      </td>
                      <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap w-[8%]">
                        {i?.artifact && i?.artifact.length > 0 && (
                          <img
                            src={paperclip}
                            alt=""
                            className="w-3 h-4 mx-auto"
                          />
                        )}
                      </td>
                      <td className=" w-[8%] mb-2 text-center text-gray-900 capitalize ">
                        {i?.status || "-"}
                      </td>
                      <td className=" w-[3%]">
                        <ViewMore
                          frequency={frequency}
                          trainingCalender={trainingCalender}
                          data={i}
                          fetcher={getTrainingData}
                          getTraining={() => getTraining()}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* <table className="table-auto w-full">
          <thead className="bg-tabletitle">
            <tr className=" text-xs 2xl:text-sm font-medium">
              {header.map((header) => (
                <th className={`text-center px-3 py-3 ${header.width}`}>
                  {header.headone}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr colspan="6">
              <td colspan="6">
                <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                  {data?.data &&
                    data?.data?.map((i) => {
                      return (
                        <div className="">
                          <tr
                            className="odd:bg-tableodd even:bg-tableeven py-1 text-xs 2xl:text-sm font-medium flex justify-between items-center capitalize my-1"
                            key={i}
                          >
                            <td className=" w-[5%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.serial_number || "#"}
                            </td>
                            <td className=" w-[15%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.training_program || "-"}
                            </td>
                            <td className=" w-[14%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.function_responsibility || "-"}
                            </td>
                            <td className=" w-[10%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.frequency || "-"}
                            </td>
                            <td className=" w-[11%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.participants || "-"}
                            </td>
                            <td className=" w-[16%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.mandatory || "-"}
                            </td>
                            <td className=" w-[10%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.detail_plan || "-"}
                            </td>
                            <td className=" w-[12%] mb-2 text-center text-gray-900 ">
                              {(i?.planned_date != "None" &&
                                i?.planned_date != " " &&
                                DateFormat({
                                  value: i?.planned_date,
                                  format: "DD-MMM-yyyy",
                                })) ||
                                "-"}
                            </td>
                            <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap w-[8%]">
                              {i?.artifact && i?.artifact.length > 0 && (
                                <img
                                  src={paperclip}
                                  alt=""
                                  className="w-3 h-4 mx-auto"
                                />
                              )}
                            </td>
                            <td className=" w-[8%] mb-2 text-center text-gray-900 capitalize ">
                              {i?.status || "-"}
                            </td>

                            <ViewMore
                              frequency={frequency}
                              trainingCalender={trainingCalender}
                              data={i}
                              fetcher={getTrainingData}
                              getTraining={()=>getTraining()}
                            />
                          </tr>
                        </div>
                      );
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={getTrainingData}
          frequency={frequency}
          trainingCalender={trainingCalender}
          id={id}
          getTraining={() => getTraining()}
        />

        <ApprovelStatus
          visible={showApprovalModal}
          onClose={handleApprovalOnClose}
          refreshData={getTrainingData}
          moduleId={16}
        />
      </MainLayout>
    </>
  );
}

export default TrainingCalenderIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "w-[5%]",
  },
  {
    headone: "Training Program",
    width: "w-[12%]",
  },
  {
    headone: "Function Responsibility",
    width: "w-[11%]",
  },
  {
    headone: "Frequency",
    width: "w-[10%]",
  },
  {
    headone: "Participants",
    width: "w-[11%]",
  },
  {
    headone: "Mandatory for the organization",
    width: "w-[12%]",
  },
  {
    headone: "Detail Plan",
    width: "w-[10%]",
  },
  {
    headone: "Planned Date",
    width: "w-[10%]",
  },
  {
    headone: "Artefact",
    width: "w-[8%]",
  },
  {
    headone: "Status",
    width: "w-[8%]",
  },
  {
    headone: "",
    width: "w-[3%]",
  },
];
