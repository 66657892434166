// DataviewTest.js
import React, { useEffect, useState } from "react";
import DateFormat from "../../../Utilities/common/NuDate";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";
import { OnDownload } from "../../../Utilities/data/render";
import ImplementationSummaryXlsx from "./xlsx/ImplementationSummaryXlsx";
import ImplementationDetailsXlsx from "./xlsx/ImplementationDetailsXlsx";
import MRMSummaryXlsx from "./xlsx/MRMSummaryXlsx";
import MRMDetailXlsx from "./xlsx/MRMDetailsXlsx";
import AuditXlsx from "./xlsx/AuditXlsx";
import MaintenanceXlsx from "./xlsx/MaintenanceDetailXlsx";
import MasterXlsx from "./xlsx/MasterDetailsXlsx";
import SecurityXlsx from "./xlsx/SecurityDetailXlsx";
import TaskMangementXlsx from "./xlsx/TaskManagementXlsx";
import TrainingXlsx from "./xlsx/TrainingDetailXlsx";
import VendorXlsx from "./xlsx/VendorDetailXlsx";

function DataViewTest({ data = [], status, module, view, format, company, standardname, Name, review, startDate, endDate, standardId, buttonClick, DataLoading, dataLength, PDFLenth, SetPDFLength,NoData }) {

  const [pdfBlob, setPdfBlob] = useState(null);

    function GetHeader(label, view) {
    if (label == 1 || label == 2) {
      if (view == "Summary") {
        return (
          <tr className="text-xs 2xl:text-sm font-medium">
            <th className="py-4 w-[5%]">Sl.No </th>
            <th className=" py-4 w-[21%]">Section </th>
            <th className="py-4 w-[10%]">Sub - Section</th>
            <th className="py-4 w-[27%]">Questions / Activity</th>
            <th className="py-4 w-[10%]">Completion Date</th>
            <th className="py-4 w-[10%]">Status</th>
            <th className="py-4 w-[10%]">Last Update by</th>
            <th className="py-4 w-[7%]">Attachment </th>
          </tr>
        );
      } else if (view == "Detailed") {
        return (
          <tr className="text-xs 2xl:text-sm font-medium">
            <th className="py-4 w-[4%]">Sl.No</th>
            <th className=" py-4 w-[15%]">Section </th>
            <th className="py-4 w-[7%]">Sub - Section</th>
            <th className="py-4 w-[18%]">Questions / Activity</th>
            <th className="py-4 w-[12%]">User Response</th>
            <th className="py-4 w-[12%]">User Comment</th>
            <th className="py-4 w-[9%]">Completion Date</th>
            <th className="py-4 w-[7%]">Status</th>
            <th className="py-4 w-[10%]">Last Update by</th>
            <th className="py-4 w-[6%]">Attachment</th>
          </tr>
        );
      }
    }
    if (label == 18) {
      if (view == "Summary") {
        return (
          <tr className="text-xs 2xl:text-sm font-medium">
            <th className="py-4 w-[4%]">Sl.No</th>
            <th className=" py-4 w-[18%]">Heading </th>
            <th className="py-4 w-[15%]">Group</th>
            <th className="py-4 w-[15%]">Date</th>
            <th className="py-4 w-[20%]">Type of Meeting</th>
            <th className="py-4 w-[10%]">Status</th>
            <th className="py-4 w-[10%]">Last Update by</th>
            <th className="py-4 w-[8%]">Attachment</th>
          </tr>
        );
      } else if (view == "Detailed") {
        return (
          <tr className="text-xs 2xl:text-sm font-medium">
            <th className="py-4 w-[4%]">Sl.No</th>
            <th className=" py-4 w-[11%]">Heading </th>
            <th className="py-4 w-[10%]">Group</th>
            <th className="py-4 w-[7%]">Date</th>
            <th className="py-4 w-[10%]">Type of Meeting</th>
            <th className="py-4 w-[10%]">Regrets</th>
            <th className="py-4 w-[12.5%]">Chaired By</th>
            <th className="py-4 w-[12.5%]">Minutes By</th>
            <th className="py-4 w-[7%]">Status</th>
            <th className="py-4 w-[10%]">Last Update by</th>
            <th className="py-4 w-[6%]">Attachment</th>
          </tr>
        );
      }
    }
    if (label == 3) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[5%]">Sl.No </th>
          <th className="py-4 w-[30%]">Maintenance Task</th>
          <th className="py-4 w-[15%]">Frequency</th>
          <th className="py-4 w-[20%]">Comments</th>
          <th className="py-4 w-[10%]">Status</th>
          <th className="py-4 w-[10%]">Last Update by</th>
          <th className="py-4 w-[10%]">Artefact</th>
        </tr>
      );
    }

    if (label == 4) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[5%]">Sl.No </th>
          <th className="py-4 w-[30%]">Document Name</th>
          <th className="py-4 w-[20%]">Document Description</th>
          <th className="py-4 w-[10%]">Version</th>
          <th className="py-4 w-[10%]">Latest Updated</th>
          <th className="py-4 w-[10%]">Status</th>
          <th className="py-4 w-[10%]">Last Update by</th>
          <th className="py-4 w-[5%]">Artefact</th>
        </tr>
      );
    }

    if (label == 6) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[5%]">Sl.No </th>
          <th className="py-4 w-[9%]">Ref Number</th>
          <th className="py-4 w-[10%]">Performed By</th>
          <th className="py-4 w-[10%]">Process Description</th>
          <th className="py-4 w-[10%]">Audit Description</th>
          <th className="py-4 w-[9%]">Auditee</th>
          <th className="py-4 w-[8%]">Start Date</th>
          <th className="py-4 w-[8%]">End Date</th>
          <th className="py-4 w-[9%]">Results</th>
          <th className="py-4 w-[7%]">Status</th>
          <th className="py-4 w-[10%]">Last Update by</th>
          <th className="py-4 w-[5%]">Artefact</th>
        </tr>
      );
    }

    if (label == 16) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[5%]">Sl.No </th>
          <th className="py-4 w-[12%]">Training Program</th>
          <th className="py-4 w-[12%]">Function Responsibility</th>
          <th className="py-4 w-[8%]">Frequency</th>
          <th className="py-4 w-[10%]">Participants</th>
          <th className="py-4 w-[12%]">Mandatory for the organization</th>
          <th className="py-4 w-[10%]">Detail Plan</th>
          <th className="py-4 w-[8%]">Planned Date</th>
          <th className="py-4 w-[8%]">Status</th>
          <th className="py-4 w-[10%]">Last Update By</th>
          <th className="py-4 w-[5%]">Artefact</th>
        </tr>
      );
    }

    if (label == 12) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[5%]">Sl.No </th>
          <th className="py-4 w-[15%]">System</th>
          <th className="py-4 w-[9%]">Incident Date</th>
          <th className="py-4 w-[9%]">Closure Date</th>
          <th className="py-4 w-[12%]">Type of Incident</th>
          <th className="py-4 w-[15%]">RCA Information / Analysis</th>
          <th className="py-4 w-[10%]">Status Update</th>
          <th className="py-4 w-[10%]">Last Update by</th>
          <th className="py-4 w-[15%]">Incident Report / ATR Report</th>
        </tr>
      );
    }

    if (label == 10) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[5%]">Sl.No </th>
          <th className="py-4 w-[15%]">Task</th>
          <th className="py-4 w-[20%]">Title</th>
          <th className="py-4 w-[18%]">Comments</th>
          <th className="py-4 w-[11%]">Completion&nbsp;Date</th>
          <th className="py-4 w-[11%]">Reminder&nbsp;Date</th>
          <th className="py-4 w-[10%]">Status </th>
          <th className="py-4 w-[10%]">Last Update by</th>
          {/* <th className="py-4 w-[15%]">Incident Report / ATR Report</th> */}
        </tr>
      );
    }

    if (label == 11) {
      return (
        <tr className="text-xs 2xl:text-sm font-medium">
          <th className=" py-4 w-[10%]">Sl.No </th>
          <th className="py-4 w-[30%]">Vendor Name</th>
          <th className="py-4 w-[20%]">Vendor Type</th>
          <th className="py-4 w-[20%]">Documents Type - NDA, SLA, Contract</th>
          <th className="py-4 w-[10%]">OnBoard Date</th>
          <th className="py-4 w-[10%]">Last Update by</th>

        </tr>
      );
    }
  }

  const renderTableRows = (label, view) => {
    if (label == 1 || label == 2) {
      if (view == "Summary") {
        return data.map((item, index) => (
          <tr
            className="text-xs 2xl:text-sm text-gray-900 whitespace-normal"
            key={index}
          >
            <td className=" py-4 pl-3 text-center w-[5%]">{item?.serial_number || ""}</td>
            <td className=" py-4 pl-3  w-[21%]">{item?.section || ""}</td>
            <td className="py-4 text-center w-[10%]">
              {item?.sub_section_no || ""}
            </td>
            <td className="py-4 w-[27%]">{item?.question_activity || ""}</td>
            <td className="py-4 text-center w-[10%]">
              {item?.completion_date!="" && item?.completion_date!="None" && <DateFormat value={item?.completion_date} format="DD-MMM-YYYY" />  || ""}
            </td>
            <td className="py-4 text-center capitalize w-[10%]">
              {item?.status_name || ""}
            </td>
            <td className="py-4 text-center w-[10%]">
              {item?.last_modified_name || item?.created_name || item?.created_by_name || ""}
            </td>
            <td className="py-4 text-center w-[7%]">
              {(item?.attachment && (
                <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
              )) ||
                ""}
            </td>
          </tr>
        ));
      } else if (view == "Detailed") {
        return data.map((item, index) => (
          <tr
            className="text-xs 2xl:text-sm text-gray-900 whitespace-normal"
            key={index}
          >
            <td className="py-4 text-center w-[4%]">{item?.serial_number || ""}</td>
            <td className=" py-4 pl-2 text-center w-[15%]">
              {item?.section || ""}
            </td>
            <td className="py-4 text-center w-[7%]">{item?.sub_section_no || ""}</td>
            <td className="py-4 w-[18%]">{item?.question_activity || ""}</td>
            <td className="py-4 px-1 w-[12%]">{item?.user_response || ""}</td>
            <td className="py-4 px-1 w-[12%]">{item?.user_comment || ""}</td>
            <td className="py-4 text-center w-[9%]">
            {item?.completion_date!="" && item?.completion_date!="None" && <DateFormat value={item?.completion_date} format="DD-MMM-YYYY" />  || ""}
            </td>
            <td className="py-4 text-center capitalize w-[7%]">
              {item?.status_name || ""}
            </td>
            <td className="py-4 text-center w-[10%]">
              {item?.last_modified_name || item?.created_name || item?.created_by_name || ""}
            </td>
            <td className="py-4 text-center w-[6%]">
              {(item?.attachment && (
                <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
              )) ||
                ""}
            </td>
          </tr>
        ));
      }
    }

    if (label == 18) {
      if (view == "Summary") {
        return data.map((item, index) => (
          <tr className="text-xs 2xl:text-sm font-medium" key={index}>
            <td className=" py-4 pl-2 w-[4%] text-center">{item?.serial_number || ""}</td>
            <td className=" py-4 pl-2 w-[18%] text-center">{item?.heading || ""}</td>
            <td className="py-4 w-[15%] text-center">
              {item?.group_data || ""}
            </td>
            <td className="py-4 w-[15%] text-center">
              {(item?.mom_date != "" && item?.mom_date != "None" && (
                <DateFormat value={item?.mom_date} format="DD-MMM-YYYY" />
              )) ||
                ""}
            </td>
            <td className="py-4 w-[20%] text-center">
              {item?.type_of_meeting || ""}
            </td>
            <td className="py-4 w-[10%] capitalize text-center">{item?.status || ""}</td>
            <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
            <td className="py-4 w-[8%] text-center">
              {(item?.attachment && (
                <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
              )) ||
                ""}
            </td>
          </tr>
        ));
      } else if (view == "Detailed") {
        return data.map((item, index) => (
          <tr className="text-xs 2xl:text-sm font-medium" key={index}>
            <td className=" py-4 pl-2 w-[4%]">{item?.serial_number || ""}</td>
            <td className=" py-4 pl-2 w-[11%]">{item?.heading || ""}</td>
            <td className="py-4 w-[10%] text-center">
              {item?.group_data || ""}
            </td>
            <td className="py-4 w-[7%] text-center">
              {(item?.mom_date != "" && item?.mom_date != "None" && (
                <DateFormat value={item?.mom_date} format="DD-MMM-YYYY" />
              )) ||
                ""}
            </td>
            <td className="py-4 w-[10%] ">{item?.type_of_meeting || ""}</td>
            <td className="py-4 w-[10%] text-center">{item?.regrets || ""}</td>
            <td className="py-4 w-[12.5%] text-center">
              {item?.chaired_by || ""}
            </td>
            <td className="py-4 w-[12.5%] text-center">
              {item?.minute_by || ""}
            </td>
            <td className="py-4 w-[10%] capitalize text-center">{item?.status || ""}</td>
            <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
            <td className="py-4 w-[6%] text-center">
              {(item?.attachment && (
                <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
              )) ||
                ""}
            </td>
          </tr>
        ));
      }
    }

    if (label == 3) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm" key={index}>
          <td className=" py-4 w-[5%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[30%]">{item?.task || ""}</td>
          <td className="py-4 w-[15%] text-center">{item?.frequency_name|| ""}</td>
          <td className="py-4 w-[20%] text-center">{item?.comments || ""}</td>
          <td className="py-4 w-[10%] capitalize text-center">{item?.status || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
          <td className="py-4 w-[10%] text-center">
            {(item?.attachment && (
              <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
            )) ||
              ""}
          </td>
        </tr>
      ));
    }

    if (label == 4) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm" key={index}>
          <td className=" py-4 w-[5%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[30%]">{item?.document_name || ""}</td>
          <td className="py-4 w-[20%] text-center">{item?.document_description || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.version || ""}</td>
          <td className="py-4 w-[10%] text-center">
            {(item?.latest_update != "" && item?.latest_update != "None" && (
              <DateFormat value={item?.latest_update} format="DD-MMM-YYYY" />
            )) ||
              ""}
          </td>
          <td className="py-4 w-[10%] capitalize text-center">{item?.status || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
          <td className="py-4 w-[5%] text-center">
            {(item?.attachment && (
              <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
            )) ||
              ""}
          </td>
        </tr>
      ));
    }

    if (label == 6) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm font-medium" key={index}>
          <td className=" py-4 w-[5%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[9%] text-center">
            {item?.reference_number || ""}
          </td>
          <td className="py-4 w-[10%] text-center">
            {item?.performed_by || ""}
          </td>
          <td className="py-4 w-[10%]">{item?.process_description || ""}</td>
          <td className="py-4 w-[10%]">{item?.audit_description || ""}</td>
          <td className="py-4 w-[9%] text-center">{item?.auditee || ""}</td>
          <td className="py-4 w-[8%] text-center">
            {(item?.start_date != "" && item?.start_date != "None" && (
              <DateFormat value={item?.start_date} format="DD-MMM-YYYY" />
            )) ||
              ""}
          </td>
          <td className="py-4 w-[8%] text-center">
            {(item?.end_date != "" && item?.end_date != "None" && (
              <DateFormat value={item?.end_date} format="DD-MMM-YYYY" />
            )) ||
              ""}
          </td>
          <td className="py-4 w-[9%] text-center">{item?.result || ""}</td>
          <td className="py-4 w-[7%] capitalize text-center">{item?.status || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
          <td className="py-4 w-[5%] text-center">
            {(item?.attachment && (
              <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
            )) ||
              ""}
          </td>
        </tr>
      ));
    }

    if (label == 12) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm font-medium" key={index}>
          <td className=" py-4 w-[5%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[15%] text-center">{item?.systems || ""}</td>
          <td className="py-4 w-[9%] text-center">
            {(item?.incident_date !== "None" &&
              item?.incident_date !== " " &&
              DateFormat({
                value: item?.incident_date,
                format: "DD-MMM-yyyy",
              })) ||
              "-"}
          </td>
          <td className="py-4 w-[9%] text-center">
            {(item?.closure_date !== "None" &&
              item?.closure_date !== " " &&
              DateFormat({
                value: item?.closure_date,
                format: "DD-MMM-yyyy",
              })) ||
              "-"}
          </td>
          <td className="py-4 w-[12%] text-center">
            {item?.type_of_incident || ""}
          </td>
          <td className="py-4 w-[15%] text-center">{item?.RCA || ""}</td>
          <td className="py-4 w-[10%] capitalize text-center">{item?.status || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
          <td className="py-4 w-[15%] text-center">
            {(item?.attachment && (
              <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
            )) ||
              ""}
          </td>
        </tr>
      ));
    }


    if (label == 10) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm font-medium" key={index}>
          <td className=" py-4 w-[5%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[15%] text-center">
          {item?.task_id == 1
                              ? "Implementation Checklist"
                              : item?.task_id == 2
                              ? "Internal Audit"
                              : item?.task_id == 3
                              ? "Maintenance"
                              : item?.task_id == 4
                              ? "Master List of Documents"
                              : item?.task_id == 5
                              ? "CISO / MRM Meeting"
                              : item?.task_id == 6
                              ? "Audit Schedule"
                              : "-"}
          </td>
          <td className="py-4 w-[20%] text-left">
            {item?.title || ""}
          </td>
          <td className="py-4 w-[18%] text-center">{item?.comment || ""}</td>
          <td className="py-4 w-[11%] text-center">
            {(item?.completion_date !== "None" &&
              item?.completion_date !== " " &&
              DateFormat({
                value: item?.completion_date,
                format: "DD-MMM-yyyy",
              })) ||
              "-"}
          </td>
          <td className="py-4 w-[11%] text-center">
            {(item?.reminder_date !== "None" &&
              item?.reminder_date !== " " &&
              DateFormat({
                value: item?.reminder_date,
                format: "DD-MMM-yyyy",
              })) ||
              "-"}
          </td>
          <td className="py-4 w-[10%] capitalize text-center">{item?.status || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
        </tr>
      ));
    }

    if (label == 11) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm font-medium" key={index}>
          <td className=" py-4 w-[10%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[30%] text-center">{item?.vendor_name || ""}</td>
          <td className="py-4 w-[20%] text-center">{item?.vendor_type || ""}</td>
          <td className="py-4 w-[20%] text-center">            {(item?.attachment && (
              <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
            )) ||
              ""}</td>
          <td className="py-4 w-[10%] text-center">
          {(item?.on_board_date !== "None" &&
              item?.on_board_date !== " " &&
              DateFormat({
                value: item?.on_board_date,
                format: "DD-MMM-yyyy",
              })) ||
              "-"}
          </td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
        </tr>
      ));
    }


    if (label == 16) {
      return data.map((item, index) => (
        <tr className="text-xs 2xl:text-sm font-medium" key={index}>
          <td className=" py-4 w-[5%] text-center">{item?.serial_number || ""}</td>
          <td className="py-4 w-[12%] text-center">{item?.training_program || ""}</td>
          <td className="py-4 w-[12%] text-center">{item?.function_responsibility || ""}</td>
          <td className="py-4 w-[8%] text-center">{item?.frequency || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.participants || ""}</td>
          <td className="py-4 w-[12%] text-center">{item?.mandatory || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.detail_plan || ""}</td>
          <td className="py-4 w-[8%] text-center">
            {(item?.planned_date !== "None" &&
              item?.planned_date !== " " &&
              DateFormat({
                value: item?.planned_date,
                format: "DD-MMM-yyyy",
              })) ||
              "-"}
          </td>
          <td className="py-4 w-[8%] capitalize text-center">{item?.status || ""}</td>
          <td className="py-4 w-[10%] text-center">{item?.last_modified_name || item?.created_name || item?.created_by_name || ""}</td>
          <td className="py-4 w-[5%] text-center">
            {(item?.attachment && (
              <img src={paperclip} alt="" className="w-3 h-4 mx-auto" />
            )) ||
              ""}
          </td>
        </tr>
      ));
    }
  };

  useEffect(() => {
    if (format === "pdf" && data.length > 0) {
      const worker = new Worker(new URL('./pdfWorker.js', import.meta.url));
      
      worker.onmessage = function(e) {
        setPdfBlob(e.data);
        DataLoading(false);
      };

      worker.postMessage({
        module,
        view,
        data,
        status,
        company,
        standardname,
        Name,
        review,
        startDate,
        endDate,
      });

      return () => worker.terminate();
    }
  }, [format, data, module, view, status, company, standardname, Name, review, startDate, endDate]);



  useEffect(()=>{
    if (format === "xlsx" ){
      if(dataLength==PDFLenth){
        DataLoading(false)
      }
    }
  },[PDFLenth])

  useEffect(()=>{
    if(data.length ==0 && NoData ==true){
      DataLoading(false)
    }
  },[data])

  

  return (
    <div className="w-full relative">
     
      {format !== "" &&
      <>        
          {format === "pdf" && pdfBlob && (
            <div className="bg-primary w-24 text-sm hover:shadow-button text-white px-3 py-2 mb-2 flex justify-center items-center ml-4 rounded-3xl">
            <a href={URL.createObjectURL(pdfBlob)} download={`${standardname}-${Name} ${view} Report.pdf`}>
              <span onClick={() => OnDownload(standardId, Name)}>Download</span>
            </a>
            </div>
          )}
          {
  format=="xlsx" &&
  <div className="bg-primary w-24 text-sm hover:shadow-button text-white px-3 py-2 mb-2 flex justify-center items-center ml-4 rounded-3xl">
  <div>{
    module == 1 && view=="Summary" ?<ImplementationSummaryXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Implementation Checklist Detail Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} Module={Name} standardId={standardId} updateLenth={SetPDFLength} />
    :module == 1 && view=="Detailed"?<ImplementationDetailsXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `}  fileName={`${standardname}-Implementation Checklist Summary Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength} />
    :module == 2 && view=="Summary" ?<ImplementationSummaryXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Internal Audit Detail Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength} />
    :module == 2 && view=="Detailed"?<ImplementationDetailsXlsx  status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Internal Audit Summary Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength} />
    :module == 18 && view=="Summary" ?<MRMSummaryXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-CISO/MRM Meeting Detail Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength} />
    :module == 18 && view=="Detailed"?<MRMDetailXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-CISO/MRM Meeting Summary Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength} />
    :module == 6?<AuditXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `}  fileName={`${standardname}-Audit Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength}/>
    :module == 3?<MaintenanceXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Maintenance Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength}/>
    :module == 4?<MasterXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Master List Of Documents Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength}/>
    :module == 12?<SecurityXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Security Incident Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data}  updateLenth={SetPDFLength}/>
    :module == 10?<TaskMangementXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Task Management Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength}/>
    :module == 16?<TrainingXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `} fileName={`${standardname}-Training Calendar Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength}/>
    :module == 11?<VendorXlsx status={status} company={company} standardname={`Standard ${standardname}`} review={review} startDate={startDate} endDate={endDate} Name={`${Name} ${view} Report `}  fileName={`${standardname}-Vendor Management Report ${startDate && DateFormat({value:startDate, format:"MMM-YYYY"})} ${endDate && "to"} ${endDate && DateFormat({value:endDate,format:"MMM-YYYY"})}`} data={data} updateLenth={SetPDFLength}/>
    :""
  }</div>
  </div>
}

        </>

      }
      <table className="w-full">
        <thead className="bg-tabletitle">
          {GetHeader(module, view)}
        </thead>
        <tbody>{renderTableRows(module, view)}</tbody>
      </table>
      {buttonClick === true && data.length === 0 && <div className="w-full font-semibold text-sm text-center flex h-40 justify-center items-center">NO DATA</div>}
    </div>
  );
}

export default DataViewTest;
