import React, { useState, useEffect } from "react";
import CreateorUpdate from "./form";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import Axios from "../../../Services/axiosComman";
import CardView from "../../../Utilities/common/CardView";
import TableView from "../../../Utilities/common/TableView";
import ListLayout from "../../../Utilities/Layout/ListLayout";

const fetcher = (url) => Axios.get(url).then((res) => res.data);

function AbbreviationIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [view,setView] = useState(localStorage.getItem('view'))
  function listView(){
    localStorage.setItem('view',false);
    let View = localStorage.getItem('view')
    setView(false)
  }
  function cardView(){
    localStorage.setItem('view',true);
    let View = localStorage.getItem('view')
    setView(true)
  }
  const { data, error, mutate } = useSWR(
    `/abbreviation/standard/${id}/?page=${pageIndex}&per_page=${dataCount}`,
    fetcher
  );

  const refreshData = () => {
    mutate(fetcher);
  };

  let link ="abbreviation"
  return (
    <ListLayout
    label=" Glossary / Abbreviations"
    title="Reference Docs"
    id={id} 
    total={data?.total} 
    setDataCount={setDataCount} 
    dataCount={dataCount} 
    pageIndex={pageIndex} 
    setPageIndex={setPageIndex} 
    view={view} 
    listView={listView} 
    cardView={cardView} 
    setShowMyModal={setShowMyModal}
    Access="glossary_create"
    >
    {
     view == true? <CardView data={data} accessString="glossary_delete" link={link} fetcher={refreshData}/> : <TableView accessString="glossary_delete" data={data} link={link} fetcher={refreshData}/>
    }
        <CreateorUpdate
          onClose={handleOnClose}
          visible={showMyModal}
          refreshData={refreshData}
          id={id}
        />
   </ListLayout>
  );
}

export default AbbreviationIndex;
