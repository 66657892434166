import React, { useEffect, useState } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import * as yup from "yup";
import logout from "../../../Utilities/common/Logout";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export default function CreateOrUpdate({
  data,
  visible,
  onClose,
  refreshData,
  role,
  user,
  id,
}) {

  console.log("jj",id);
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [isLoading,setIsLoading] = useState(false);

  const [createNew, setCreateNew] = useState({
    standard_id:"",
    product_access:""
  });
  

  const formInputs = [
    {
      inputType: "NuInput",
      name: "product_access",
      classname: "border w-full h-10 px-3 mt-2 rounded-3xl",
      placeholder: "",
      label: "Please Enter the 16 digit Subscription Key",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full",
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   userId: yup.string().required("select is required"),
    //   roleId: yup.string().required("status is rquierd"),
    // }),
    onSubmit: (value,{resetForm}) => {
      setIsLoading(true);
      value.standard_id = id
          axiosInstance
          .post(`/standard_tokens/checking`, value)
          .then((res) => {
            if (res.data.status) {
              setIsLoading(false);
              resetForm()
              onClose();
              refreshData();
              Toast.fire({
                icon: "success",
                title:
                  res.data.msg || "Subscription Updated Successfully",
              });
              handleReset()
              // logout();
            } else {
              setIsLoading(false);
              Toast.fire({
                icon: "error",
                title: res.data.msg || "Subscription Update Unsuccessfully",
              });
            }
          }).catch(err=>{
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data?.msg || err?.response?.data?.error || "Role is not Created",
            });
          });
      // if (data?.userRoleId) {
      //   data.userId = value.userId;
      //   data.roleId = value.roleId;

      // } else {
      //   formik.resetForm({ values: {} });
      //   axiosInstance.post("/User-Role/", value).then((res) => {
      //     if (res.data.status) {
      //       refreshData();
      //       onClose();
      //       Toast.fire({
      //         icon: "success",
      //         title: res.data.msg || "User Role is Assigned",
      //       });
      //     } else {
      //       Toast.fire({
      //         icon: "error",
      //         title: res.data.msg || "User Role is not Assigned",
      //       });
      //     }
      //   });
      // }
    },
  });

  const handleReset = async () => {
    Swal.fire({
      title: 'Please Logout',
      text: `New Subcription change will implemented after logout. Otherwise the changes not implemented for you`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#DEDEDE',
      confirmButtonText: 'Logout'
    }).then(async (result) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }

  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center  h-auto"
      >
        <div className="bg-white mt-12 p-2 h-auto rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {"New subscription"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className=" h-44">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className="flex items-center justify-end px-6 py-6 rounded-b">
              <button
                  onClick={onClose}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
