import CryptoJS from "crypto-js";

function DecryptData(dncToken){
    let value = localStorage.getItem(dncToken)
    let data = CryptoJS.AES.decrypt(value,'hCeToFnInOrTaTeMlLoRcS')
    let final = data.toString(CryptoJS.enc.Utf8);
    let before = JSON.parse(final);
    return before;
  }

export default DecryptData;