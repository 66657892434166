import React, { useState } from "react";
import FileDisplay from "../../../Utilities/common/FileDisplay";
import FileView from "../../../Utilities/common/fileView";
import DateFormat from "../../../Utilities/common/NuDate";


export default function MomView({ viewData, onClose, visible }) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);
  const [showData, setShowData] = useState("");
  if (!visible) return null;
  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
    >
      <div className="bg-white p-2 rounded w-10/12 h-[95vh] ">
        <div className="flex items-start justify-between px-5 py-2  rounded-t">
          <h3 className="text-base 2xl:text-xl font-semibold">
            {viewData?.name || "MoM View"}
          </h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={onClose}
          >
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.354492"
                y="0.22583"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path
                d="M25.9336 25.8041L18.3911 33.3465L25.9336 25.8041Z"
                fill="#6E6985"
              />
              <path
                d="M33.4761 33.3465L25.9336 25.8041M25.9336 25.8041L18.3911 18.2616M25.9336 25.8041L33.4761 18.2616M25.9336 25.8041L18.3911 33.3465"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </div>
        <div className="overflow-auto h-[87vh]">
          <div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Heading
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {viewData?.heading || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Group
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {viewData?.group_data || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Meeting Type
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {viewData?.type_of_meeting || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
               Date
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
              { viewData?.mom_date != "None" && viewData?.mom_date != " " && DateFormat({
                                value: viewData?.mom_date,
                                format: "DD-MMM-yyyy",
                              })||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Start Time
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
              { viewData?.start_time ||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                End Time
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8">
                {viewData?.end_time || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Regrets
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {viewData?.regrets || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Venue
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {viewData?.venue || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Chaired By
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {viewData?.chaired_by || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Minute By
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
 
                             { viewData?.minute_by||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Minute By
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
 
                             { viewData?.status||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Attachment Artefact
              </p>
              <div className=" flex flex-wrap">
              {viewData?.attachments && (
                                                    viewData?.attachments.map(i =>{
                                                      return(
                                                        <div className=" m-1">
                                                          <FileDisplay data={i} view={true}/>
                                                          </div>
                                                      )
                                                    }
                                                        
                                )
              )}
              </div>
            </div>
          </div>
        </div>
        <FileView
          path={showData}
          // id={showid}
          // data={viewData}
          onClose={handleClose}
          visible={showFile}
        />
      </div>
    </div>
  );
}
