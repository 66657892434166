import React from "react";
import Close from "../../../Utilities/Image/icons/close.svg";

export function View({ visible, onClose, data }) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  if (!visible) return null;

  const userRole = [
        {
          id: 1,
          name: "Information View",
        },
        {
          id: 2,
          name: "Information Create",
        },
        {
          id: 3,
          name: "Information Edit",
        },
        {
          id: 4,
          name: "Information Delete",
        },
        {
          id: 5,
          name: "Activity View",
        },
        {
          id: 6,
          name: "Activity Create",
        },
        {
          id: 7,
          name: "Activity Edit",
        },
        {
          id: 8,
          name: "Activity Delete",
        },

        {
          id: 9,
          name: "Reference Docs View",
        },
        {
          id: 10,
          name: "Reference Docs Create",
        },
        {
          id: 11,
          name: "Reference Docs Edit",
        },
        {
          id: 12,
          name: "Reference Docs Delete",
        },

        {
          id: 13,
          name: "Reports View",
        },

        {
          id: 14,
          name: "Admin/Settings View",
        },
        {
          id: 15,
          name: "Admin/Settings Create",
        },
        {
          id: 16,
          name: "Admin/Settings Edit",
        },
        {
          id: 17,
          name: "Admin/Settings Delete",
        },
        {
          id:18,
          name:"Review & Approve",
        },
        {
          id:19,
          name:"Standard Reset",
        },
        {
          id:20,
          name:"Standard View"
        },
        {
          id:21,
          name:"Standard Create",
        },
        {
          id:22,
          name:"Standard Edit",
        },
        {
          id:23,
          name:"Standard Delete",
        },



  ]



  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
    >
      <div className="bg-white p-2 rounded w-1/2 h-[80vh] ">
        <div className="flex items-start justify-between px-5 py-2  rounded-t">
          <h3 className="text-xs 2xl:text-sm font-semibold">{"Role View"}</h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={onClose}
          >
            {/* <img src={Close} alt="close" title="close" /> */}
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.842773"
                y="0.560791"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
              <path
                d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Close</title>
            </svg>
          </span>
        </div>
        <div className=" h-[66vh]">
          <div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
               Role Name
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8">
                {data.roleName}
              </p>
            </div>


            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 px-8">
                Permissions
              </p>
              <div className="text-xs grid md:grid-cols-3 lg:grid-cols-4 gap-3 text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 px-8">
                {
                  userRole.filter(role=> data?.permissions.includes(role.id)).map(item=>{
                    return (
                      <div key={item.id} className=" w-full bg-slate-100 p-3 rounded-md">
                      {item.name}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
