import React, { useEffect, useState } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
import { NuInput } from "../../../Utilities/common/Input";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateorUpdate({
  data,
  visible,
  onClose,
  refreshData,
  id,
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [roleInfo, setRoleInfo] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [createNew, setCreateNew] = useState({
    role:"",
    permissions:[]
  });

  useEffect(() => {
    setCreateNew({
      role:data?.roleName,
    });
    setRoleInfo(data?.permissions||[])
  }, [data]);
  function handleImageChange(e) {
    const { files, value } = e.target;
    setSelectedFile(files);
  }
 
  const userRole = [
    {
      name: "Information",
      roles: [
        {
          id: 1,
          name: "View",
        },
        {
          id: 2,
          name: "Create",
        },
        {
          id: 3,
          name: "Edit",
        },
        {
          id: 4,
          name: "Delete",
        },
      ],
    },
    {
      name: "Activity",
      roles: [
        {
          id: 5,
          name: "View",
        },
        {
          id: 6,
          name: "Create",
        },
        {
          id: 7,
          name: "Edit",
        },
        {
          id: 8,
          name: "Delete",
        },
      ],
    },
    {
      name: "Reference Docs",
      roles: [
        {
          id: 9,
          name: "View",
        },
        {
          id: 10,
          name: "Create",
        },
        {
          id: 11,
          name: "Edit",
        },
        {
          id: 12,
          name: "Delete",
        },
      ],
    },
    {
      name: "Report",
      roles: [
        {
          id: 13,
          name: "View",
        },
      ],
    },
    {
      name: "Admin/Settings",
      roles: [
        {
          id: 14,
          name: "View",
        },
        {
          id: 15,
          name: "Create",
        },
        {
          id: 16,
          name: "Edit",
        },
        {
          id: 17,
          name: "Delete",
        },
      ],
    },
    {
      name: "Review & Approve",
      roles: [
        {
          id: 18,
          name: "Permission",
        }
      ]
    },
    {
      name: "Standard Reset",
      roles: [
        {
          id: 19,
          name: "Permission",
        }
      ]
    },
    {
      name: "Backup Delete",
      roles: [
        {
          id: 20,
          name: "Permission",
        }
      ]
    }
  ];


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema: yup.object({
    //   companyName: yup
    //     .string()
    //     .min(3, "Name must be at least 3 characters")
    //     .max(15, "Name cannot exceed 15 characters")
    //     .required("Name is requred"),
    //   // roleId:yup.string()
    //   //           .required("Select the roleId"),
    //   // userName:yup.string()
    //   //             .min(3, "Username must be at least 3 characters")
    //   //             .max(15, "Username cannot exceed 15 characters")
    //   //             .required("Username is requred"),
    //   email: yup
    //     .string()
    //     .email("Email is invalid")
    //     .required("Email is requred"),
    //   mobile: yup
    //     .string()
    //     .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    //     .required("mobile is requerd"),
    // }),
    onSubmit: (value,{resetForm}) => {
      if (data?.roleId ) {
        value.permissions = roleInfo;
        axiosInstance
          .put(`/Role-Permission/multi-update`, value)
          .then((res) => {
            if (res.data) {
              resetForm()
              refreshData();
              setRoleInfo([])
              Toast.fire({
                icon: "success",
                title:
                  res.data.message || "Role Updated Successfully",
              });
              onClose();
            } else {
              Toast.fire({
                icon: "error",
                title:
                  res.data.message || "Role Update Unsuccessfully",
              });
            }
          }).catch(err=>{
            Toast.fire({
              icon: "error",
              title: err?.response?.data?.message || err?.response?.data?.error || "Role is not Created",
            });
          });
      } else {
        value.permissions = roleInfo;
        axiosInstance.post("/Role-Permission/add", value).then((res) => {
          if (res.data) {
            resetForm()
            refreshData();
            setRoleInfo([])
            Toast.fire({
              icon: "success",
              title:
                res.data.message || "Role Created Successfully",
            });
            onClose();
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.message || "Role is not Created",
            });
          }
        }).catch(err=>{
          Toast.fire({
            icon: "error",
            title: err?.response?.data?.message || err?.response?.data?.error || "Role is not Created",
          });
        });
      }
    },
  });
  if (!visible) return null;


  const selectRow = (name, id) => {
    let testUser;
    if (roleInfo && roleInfo.some((Userid) => Userid === id)) {
      testUser = roleInfo.filter((roleId) => roleId !== id);
      setRoleInfo(testUser);
    } else {
      setRoleInfo([...roleInfo, id]);
    }
  };


  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center "
      >
        <div className="bg-white mt-12 p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="overflow-y-auto">
              <div className="relative px-6 flex flex-wrap justify-between">
                {/* <MainForm formInputs={formInputs} formik={formik} /> */}
                <div className=" w-full">
                  <label className=" font-semibold text-sm">Role Name:</label>
                  <NuInput
                    disabled={data?true:false}
                    name="role"
                    type="text"
                    formik={formik}
                    className={`rounded-lg w-full p-1 outline-none border-2`}
                  />
                </div>
                <div className=" w-full">
                {userRole.map((item, index) => {
          return (
            <div key={index}>
              {/* <NuLabel label={item?.name} /> */}
              <p className=" my-2 px-4 font-semibold">{item?.name}</p>
              <div className=" flex  items-center">
                {item.roles.map((role, index) => {
                  return (
                    <div key={index} className=" w-1/4 text-center">
                      <input
                        type="checkbox"
                        className="px-3 mr-2 py-2 accent-main-blue"
                        // name={user.name}
                        // checked={users.some((u) => u?.id === user?.id)}
                        // disabled={DisableFun(role.id)}
                        checked={roleInfo.some((id) => id === role.id)}
                        onChange={() => selectRow(item.name, role.id)}
                      />
                      <span>{role.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
                </div>
              </div>
              <div className="flex items-center justify-end px-6 py-6 rounded-b">
                <button
                  onClick={onClose}
                  className="bg-text hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primary hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
