import ProductRoutes from "../../Services/ProductRoutes";
import axiosInstance from "../../Services/axiosComman";
import AccessDenied from "../common/AccessDenied";
import DecryptData from "../common/Decrypt";
import { GetAccess } from "./role";

const userAccess = localStorage.getItem("yEkSseCcaReSu")?DecryptData("yEkSseCcaReSu"):[];


export function RenderFun (match,component){
    // console.log("fun");
  const roleName = localStorage.getItem("aTaDrEsU")?DecryptData("aTaDrEsU"):[];
  let role = roleName?.role[0].roleName||""
  let roleLower = role.toLowerCase()||""
  const { id } = match.params;
  const isActive = userAccess? userAccess.some((permission) => permission == id): false;
  if (isActive||roleLower=="super-admin"||roleLower=="super_admin"||roleLower=="scroll_admin"||roleLower=="scroll-admin") {
    return <ProductRoutes comp={component} />;
  } else {
    return <AccessDenied />;
  }
}


export function AdminFun(match,component){
  let {path} = match
  const isActive = localStorage.getItem("aTaDrEsU")?DecryptData("aTaDrEsU"):[];
  let role = isActive?.role[0].roleName||""
  let roleLower = role.toLowerCase()||""
  if (roleLower=="super-admin"||roleLower=="super_admin"||roleLower=="scroll_admin"||roleLower=="scroll-admin"||GetAccess('admin_view')) {
    return <ProductRoutes comp={component} />;
  } else {
    return <AccessDenied />;
  }
}



export function ScreenOpen({link,id}){
  axiosInstance.put(`/${link}/${id}/screen_open`,{"isOpen":true}).then(res=>{
    // console.log("ture");
  }).catch(err=>{
    // console.log("err")
  })
}

export function ScreenClose({link,id}){
  axiosInstance.put(`/${link}/${id}/screen_closed`,{"isClosed":false}).then(res=>{
    // console.log("ture");
  }).catch(err=>{
    // console.log("err")
  })
}



export function OnDownload(id,name){
  axiosInstance.get(`/export/pdf_download?standard_id=${id}&module_name=${name}`).then(res=>{
    // console.log("clicked");
  }).catch(err=>{
    // console.log(err);
  })
}