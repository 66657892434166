import React, { useState } from "react";
import FileView from "../../../Utilities/common/fileView";
import DateFormat from "../../../Utilities/common/NuDate";
import FileDisplay from "../../../Utilities/common/FileDisplay";

export default function InternalAudit({ data, onClose, visible }) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);
  const [showData, setShowData] = useState("");
  if (!visible) return null;
  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center z-10"
    >
      <div className="bg-white p-2 rounded w-10/12 h-[95vh] ">
        <div className="flex items-start justify-between px-5 py-2  rounded-t">
          <h3 className="text-base 2xl:text-xl capitalize font-semibold">
            {data?.name || "Internal Audit View"}
          </h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={onClose}
          >
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.842773"
                y="0.560791"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
              <path
                d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Close</title>
            </svg>
          </span>
        </div>
        <div className="overflow-auto h-[87vh]">
          <div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Section No.
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.section_no || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Section
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.section || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Sub Section No.
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.sub_section_no || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Sub Section
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.sub_section || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Questions / Activity
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.question_activity || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                User Response
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8">
                {data?.user_response || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                User Comment
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.user_comment || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Status
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                {data?.status_id || "-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Completion Date
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
              { ((data?.completion_date !== "None" )&& (data?.completion_date !== " ") && DateFormat({
                                value: data?.completion_date,
                                format: "DD-MMM-yyyy",
                              }))||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Reminder Date
              </p>
              <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
 
              { (data?.reminder_date !== "None" && data?.reminder_date !== " " && DateFormat({
                                value: data?.reminder_date,
                                format: "DD-MMM-yyyy",
                              }))||"-"}
              </p>
            </div>
            <div className="my-3 text-left">
              <p className="text-sm text-[#1D1818] font-semibold mb-1 pl-8">
                Attachment Artefact
              </p>
              <div className=" flex flex-wrap">
                {(data?.image_path !== null &&
                  data?.image_path &&
                  data.image_path !== "None" &&
                  data?.image_path.map((i) => {
                    return (
                      <div className=" m-1">
                        <FileDisplay data={i} view={true} />
                      </div>
                    );
                  })) || (
                  <p className="text-xs text-[#6E6985] font-semibold text-main-title bg-[#FDFAFF] py-3 pl-8 capitalize ">
                    -
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <FileView
          path={showData}
          // id={showid}
          // data={data}
          onClose={handleClose}
          visible={showFile}
        />
      </div>
    </div>
  );
}
