import DecryptData from "../common/Decrypt";


export function GetAccess(accessText){
    const userAccess = localStorage.getItem("nOiSsImRePrEsU")?DecryptData("nOiSsImRePrEsU"):[]
    const access = userAccess?userAccess.some((permission)=>permission.permissionName == accessText ):false;
    return access;
}




export function GetRole(){
    const userData = localStorage.getItem("aTaDrEsU")?DecryptData("aTaDrEsU"):[];
    const role = userData?.role[0].roleName
    if(role ==="scroll_admin"||role==="admin"){
        return true
    }else{
        return false
    }
}
