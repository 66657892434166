import React, { createContext, useContext, useState } from 'react';

const DateContext = createContext();

export const DateProvider = ({ children }) => {

  const [selectedDate, setSelectedDate] = useState("");

  const updateDate = (newName) => {
    // console.log("fun", newName);
    setSelectedDate(newName);
  };


  return (
    <DateContext.Provider value={{ selectedDate,updateDate }}>
      {children}
    </DateContext.Provider>
  );
};

export const useSelecteddate = () => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useStandard must be used within a StandardProvider');
  } 
  return context;
};
