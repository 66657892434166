import React, { useEffect, useState } from "react";
import { MainForm } from "../../../Utilities/common/MainForm";
import { useFormik } from "formik";
import * as yup from "yup";
import axiosInstance from "../../../Services/axiosComman";
import Swal from "sweetalert2";
// import { CountryMIN } from "../../../Utilities/data/CountryMini";


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export default function CreateorUpdate({
  data,
  visible,
  onClose,
  refreshData,
  id,
}) {
  const handleOnClose = (e) => {
    if (e.target.id === "modal") onClose();
  };

  const [isLoading,setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [show,setShow] = useState(false);
  const [createNew, setCreateNew] = useState({
    server_name:"",
    port:"",
    useremail:"",
    passwordOrAuthkey:""
  });

  useEffect(() => {
    setCreateNew({
      companyName: data?.companyName,
      address: data?.address,
      email: data?.email,
      mobile: data?.mobile,
      companyWebsite: data?.companyWebsite,
      country:data?.country,
      city:data?.city,
      server_name:data?.server_name,
      port:data?.port,
      useremail:data?.useremail,
      passwordOrAuthkey:data?.passwordOrAuthkey
    });
  }, [data]);
  function handleImageChange(e) {
    const { files, value } = e.target;
    setSelectedFile(files);
  }



  const formInputs = [
    {
      inputType: "NuInput",
      name: "server_name",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Server Name",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
      
    },
    {
      inputType: "NuInput",
      name: "port",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Port",
      labelclassname: "w-full",
      type: "number",
      customClass: "w-full lg:w-6/12",
      
    },
    {
      inputType: "NuInput",
      name: "useremail",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Email / User Name",
      labelclassname: "w-full",
      type: "text",
      customClass: "w-full lg:w-6/12",
    },
    {
      inputType: "NuInput",
      name: "passwordOrAuthkey",
      classname: "w-full h-10 px-3 mt-2",
      placeholder: "",
      label: "Password / Auth Key",
      labelclassname: "w-full",
      type:show==true?"text":"password",
      customClass: "w-full lg:w-6/12",
    },
  ];

  
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
     validationSchema:yup.object({
          // companyName:yup.string()
          //         .min(3, "Name must be at least 3 characters")
          //         .max(45, "Name cannot exceed 45 characters")
          //         .required("Name is requred"),
          // // roleId:yup.string()
          // //           .required("Select the roleId"),
          // // userName:yup.string()
          // //             .min(3, "Username must be at least 3 characters")
          // //             .max(15, "Username cannot exceed 15 characters")
          // //             .required("Username is requred"),
          // email:yup.string()
          //         .email("Email is invalid")
          //         .required("Email is requred"),
          // mobile:yup.string()
          //           .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
          //           .required("mobile is requerd"),
     }),
    onSubmit: (value) => {
      setIsLoading(true);
      if (data?.id) {
        // let reqObjs = new FormData();
        // reqObjs.append("company_info", JSON.stringify(value));
        // reqObjs.append("company_logo",selectedFile !== null ? selectedFile[0] : [{FileStorage: ''}])

        axiosInstance.put(`/server_email/${data?.id}`, value).then((res) => {
          if (res.data) {
            setIsLoading(false);
            onClose();
            refreshData();
            Toast.fire({
              icon: "success",
              title: res.data.message || res.data.msg || "Email Setup Successfully",
            });
          } else {
            setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: res.data.message || res.data.msg || "Email Setup Unsuccessfully",
            });
          }
        }).catch(err=>{
          setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.message || err?.response?.data.error || "Email Setup Unsuccessfully",
            });
        });
      } else {
        // let reqObjs = new FormData();
        // reqObjs.append("company_info", JSON.stringify(value));
        // reqObjs.append("company_logo",selectedFile !== null ? selectedFile[0] : selectedFile)
        axiosInstance.post(`/server_email/`, value).then((res) => {
          if (res.data) {
          setIsLoading(false);
        formik.resetForm({ values: "" });
            refreshData();
            onClose();
            Toast.fire({
              icon: "success",
              title: res.data.message || res.data.msg || "Email Setup Successfully",
            });
          } else {
          setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: res.data.message || res.data.msg || "Email Setup Unsuccessfully",
            });
          }
        }).catch(err=>{
          setIsLoading(false);
            Toast.fire({
              icon: "error",
              title: err?.response?.data.msg || err?.response?.data.message || err?.response?.data.error || "Email Setup Unsuccessfully",
            });
        });;
      }
    },
  });
  if (!visible) return null;
  return (
    <>
      <div
        id="modal"
        // onClick={handleOnClose}
        className="bg-black bg-opacity-30 fixed z-10 inset-0 backdrop-blur-sm 
        flex justify-center items-center "
      >
        <div className="bg-white mt-8 max-h-[50vh] overflow-hidden p-2 rounded-xl w-6/12">
          <div className="flex items-start justify-between px-5 py-2  rounded-t">
            <h3 className="text-base 2xl:text-lg font-semibold">
              {data ? "Edit" : "New"}
            </h3>
            <span
              className="h-6 w-6 text-2xl block cursor-pointer"
              onClick={onClose}
            >
              {/* <img src={Close} alt="close" title="close" /> */}
              <svg
                className="w-7 h-7"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.842773"
                  y="0.560791"
                  width="51.1569"
                  height="51.1569"
                  rx="5"
                  fill="#7030A0"
                />
                <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
                <path
                  d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                  stroke="white"
                  stroke-width="2.3"
                  stroke-linecap="round"
                />
                <title>Close</title>
              </svg>
            </span>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="overflow-y-auto  max-h-[40vh]">
              <div className="relative px-6 flex flex-wrap justify-between">
                <MainForm formInputs={formInputs} formik={formik} />
              </div>
              <div className=" w-full flex justify-end">
                <div className=" w-1/2 px-6">
                <input type="checkbox" onChange={(e)=>setShow(e.target.checked)} /><span className=" text-xs ml-2">Show Password / Auth Key</span>
                </div>
              </div>
              <div className="flex items-center justify-end px-6 py-6 rounded-b text-xs 2xl:text-sm">
                <button
                  onClick={onClose}
                  className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
                  disabled={isLoading?true:false}
                >
                  Cancel
                </button>
                <button type="submit" disabled={isLoading?true:false} className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
