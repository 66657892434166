import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";
import { useParams } from "react-router-dom";

const NavLinks = () => {
  const { id } = useParams();
  // console.log('rererererd',id);
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link,idx) => (
        <div key={idx}>
          {link.access && (
            <div className="group inline-block">
              {
                link.submenu == false && link.link ? <Link to={`${link.link}/${id}`}>
                  <button className="outline-none flex justify-center items-center focus:outline-none mx-4">
                  <span className="py-3 flex justify-between items-center md:pr-0 pr-5 group text-white font-medium text-xs 2xl:text-sm">
                  {link.name}
                </span>
                  </button>
                </Link>:
                              <button className="outline-none flex justify-center items-center focus:outline-none mx-4">
                              <span className="py-3 flex justify-between items-center md:pr-0 pr-5 group text-white font-medium text-xs 2xl:text-sm">
                                {link.name}
                              </span>
                              <span>
                                <svg
                                  className=" h-4 w-4 transform group-hover:-rotate-180
                                 transition duration-150 ease-in-out"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="#fff"
                                >
                                  <path
                                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                    stroke="#fff"
                                  />
                                </svg>
                              </span>
                            </button>
              }

              {link.submenu && (
                <ul
                  className="bg-white rounded-xl shadow-sm transform scale-0 group-hover:scale-100 absolute -mt-3
                transition duration-150 ease-in-out origin-top min-w-32 py-2 px-1 z-10"
                >
                  {link.sublinks.map((mysublinks,idx) => (
                    <>
                      {mysublinks.submenu ? (
                        <>
                          <li className="p-2 hover:bg-navhover rounded-md relative">
                            <button className="w-full text-left flex items-center outline-none focus:outline-none">
                              <span className="pr-1 flex-1 text-xs 2xl:text-sm">{mysublinks.name}</span>
                              <span className="mr-auto">
                                <svg
                                  className="fill-current h-4 w-4
                          transition duration-150 ease-in-out"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </span>
                            </button>
                            <ul
                              className="bg-white rounded-md absolute top-0 right-0 
                transition duration-150 ease-in-out origin-top-left
                min-w-32 z-20 py-2 px-1 shadow-sm"
                            >
                              {
                                mysublinks.sublinks.map((sublink,idx)=>(
                                  <Link
                                  to={`${sublink.link}/${id}`}
                                  className="text-xs 2xl:text-sm"
                                  key={idx}
                                >
                                  <li className="p-2 hover:bg-navhover rounded-md">
                                    {sublink.name}
                                  </li>
                                </Link>
                                ))
                              }
                            </ul>
                          </li>
                        </>
                      ) : (
                        <Link
                          to={`${mysublinks.link}/${id}`}
                          className="text-xs 2xl:text-sm"
                          key={idx}
                        >
                          <li className="p-2 hover:bg-navhover rounded-md">
                            {mysublinks.name}
                          </li>
                        </Link>
                      )}
                    </>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default NavLinks;
