import React, { useState, useEffect } from "react";
import axiosInstance from "../../Services/axiosComman";
import { DoughThree } from "../../Utilities/common/DoughnutThree";
import DoughTwo from "../../Utilities/common/DoughnutTwo";
import MainPageLayout from "../../Utilities/Layout/MainPageLayout";
import DecryptData from "../../Utilities/common/Decrypt";

function DashboardMain() {
  const [data, setData] = useState([]);

  async function getData() {
    await axiosInstance
      .get(`/DashBoard/standard/overallPercentage`)
      .then((res) => {
        res.data?.data && setData(res.data?.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getData();
    // getImplementation();
  }, []);

  function genStatusData(statusData) {
    let finalData = {
      completed: statusData?.completed || 0,
      in_progress: statusData?.in_progress || 0,
      yet_to_start: statusData?.yet_to_start || 0,
    };
    let returnValue = {
      label: [],
      data: [],
    };
    let X = Object.keys(finalData);
    let Y = Object.values(finalData);
    returnValue.label = X;
    returnValue.data = Y;

    let sum = Y.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    if (sum != 0) {
      return <DoughThree dataWithLabel={returnValue} />;
    } else {
      return <DoughTwo />;
    }
  }

  const userData = localStorage.getItem("aTaDrEsU")
    ? DecryptData("aTaDrEsU")
    : [];

  return (
    <MainPageLayout dashboard={true}>
      <div className="w-full h-[calc(100vh-170px)] overflow-hidden bg-white shadow-main rounded-r-xl rounded-b-xl p-8 flex gap-3">
        <div className="w-full h-full overflow-y-scroll ">
          <div className="">
            <div className=" flex gap-2 items-center mb-5">
              <h2 className="text-xl font-semibold ">Dashboard</h2>

              {/* <div className="mx-6 flex items-center">
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#FF0000] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#FF0000] mx-2"></span>
                    Yet To Start
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#ffd700] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#ffd700] mx-2"></span>
                    In Progress
                  </p>
                </div>
                <div>
                  <p
                    title=""
                    className=" flex items-center text-[11px] px-[10px] py-2 font-medium text-[#008000] "
                  >
                    <span className=" w-[10px] h-[10px] rounded-full bg-[#008000] mx-2"></span>
                    Completed
                  </p>
                </div>
              </div> */}
              {/* <p className="text-[11px] text-left text-gray-500">
                (Drill Down functionality will work from Standard Dashboard and
                Progress page only)
              </p> */}
            </div>
            <div className="grid lg:grid-cols-4 grid-cols-2 gap-[11px]">
              {userData?.role[0].roleName == "scroll_admin" ||
              userData?.role[0].roleName == "admin" ? (
                <>
                  {data &&
                    data.map((i, idx) => {
                      let completed = Number(i?.completed || 0);
                      let completedValue = completed.toFixed(1);
                      let yetToStart = Number(i?.yet_to_start || 0);
                      let yetToStartValue = yetToStart.toFixed(1);
                      let inProgress = Number(i?.in_progress || 0);
                      let inProgressValue = inProgress.toFixed(1);

                      return (
                        <div
                          className="px-4 py-2 rounded-[20px] shadow-main"
                          key={idx}
                        >
                          {/* {i.id && getData(i.id)} */}

                          <div className="text-right text-[10px] h-32  font-normal text-[#B9B7B7] py-1 flex justify-between items-center gap-3">
                            <div className=" w-1/2 h-full overflow-hidden flex  flex-col justify-center items-center">
                              <div className=" w-[60%] h-[100%] overflow-hidden mx-auto">
                                <img src={i.image_path} width="100%" alt="#" />
                              </div>
                              <h4 className="text-[#7030A0] h-[15%] font-extrabold text-[12px]">
                                {i.name}
                              </h4>
                            </div>
                            <div className=" w-1/2 h-full flex justify-center items-center">
                              <div className=" w-24 h-24">
                                {genStatusData(i)}
                              </div>
                            </div>
                          </div>

                          <div className=" flex items-center justify-between">
                            <div>
                              <div
                                title=""
                                className=" text-center text-[10px] px-[10px] py-2 font-semibold text-[#FF0000] "
                              >
                                {/* <span className=" w-[10px] h-[10px] rounded-full bg-[#FF0000] mx-2"></span> */}
                                <p>Yet To Start</p>
                                <p className=" text-[11px]">
                                  {yetToStartValue || 0} %
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                title=""
                                className=" text-center text-[10px] px-[10px] py-2 font-semibold text-[#ffd700] "
                              >
                                {/* <span className=" w-[10px] h-[10px] rounded-full bg-[#ffd700] mx-2"></span> */}

                                <p>In Progress</p>
                                <p className=" text-[11px]">
                                  {inProgressValue || 0} %
                                </p>
                              </p>
                            </div>
                            <div>
                              <p
                                title=""
                                className=" text-center text-[10px] px-[10px] py-2 font-semibold text-[#008000] "
                              >
                                {/* <span className=" w-[10px] h-[10px] rounded-full bg-[#008000] mx-2"></span> */}
                                <p>Completed</p>
                                <p className=" text-[11px]">
                                  {completedValue || 0} %
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {data &&
                    data
                    .filter((item) => userData?.standards.includes(item?.standard_id))
                    .map((i, idx) => {
                      console.log("item",i);
                      let completed = Number(i?.completed || 0);
                      let completedValue = completed.toFixed(1);
                      let yetToStart = Number(i?.yet_to_start || 0);
                      let yetToStartValue = yetToStart.toFixed(1);
                      let inProgress = Number(i?.in_progress || 0);
                      let inProgressValue = inProgress.toFixed(1);

                      return (
                        <div
                          className="px-4 py-2 rounded-[20px] shadow-main"
                          key={idx}
                        >
                          {/* {i.id && getData(i.id)} */}

                          <div className="text-right text-[10px] h-32  font-normal text-[#B9B7B7] py-1 flex justify-between items-center gap-3">
                            <div className=" w-1/2 h-full overflow-hidden flex  flex-col justify-center items-center">
                              <div className=" w-[60%] h-[100%] overflow-hidden mx-auto">
                                <img src={i.image_path} width="100%" alt="#" />
                              </div>
                              <h4 className="text-[#7030A0] h-[15%] font-extrabold text-[12px]">
                                {i.name}
                              </h4>
                            </div>
                            <div className=" w-1/2 h-full flex justify-center items-center">
                              <div className=" w-24 h-24">
                                {genStatusData(i)}
                              </div>
                            </div>
                          </div>

                          <div className=" flex items-center justify-between">
                            <div>
                              <div
                                title=""
                                className=" text-center text-[10px] px-[10px] py-2 font-semibold text-[#FF0000] "
                              >
                                {/* <span className=" w-[10px] h-[10px] rounded-full bg-[#FF0000] mx-2"></span> */}
                                <p>Yet To Start</p>
                                <p className=" text-[11px]">
                                  {yetToStartValue || 0} %
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                title=""
                                className=" text-center text-[10px] px-[10px] py-2 font-semibold text-[#ffd700] "
                              >
                                {/* <span className=" w-[10px] h-[10px] rounded-full bg-[#ffd700] mx-2"></span> */}

                                <p>In Progress</p>
                                <p className=" text-[11px]">
                                  {inProgressValue || 0} %
                                </p>
                              </p>
                            </div>
                            <div>
                              <p
                                title=""
                                className=" text-center text-[10px] px-[10px] py-2 font-semibold text-[#008000] "
                              >
                                {/* <span className=" w-[10px] h-[10px] rounded-full bg-[#008000] mx-2"></span> */}
                                <p>Completed</p>
                                <p className=" text-[11px]">
                                  {completedValue || 0} %
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainPageLayout>
  );
}

export default DashboardMain;
