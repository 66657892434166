import React from 'react'
import InnerNavbar from '../../../Utilities/common/Navbar/Navbar';
import { NavLink, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GetYear } from '../../../Utilities/common/GetYear';
import StandardLayout from '../../../Utilities/Layout/StandardLayout';

const linkStyles = ({ isActive }) => {
    return {
      padding: isActive ? "14px" : "14px",
      backgroundColor: isActive ? "white" : "#FDFCFD",
      color: isActive ? "text-gray-900" : "gray",
    };
  };

function ReportIndex({children}) {
    const { id } = useParams();

    
  return (
    <StandardLayout>
    <div className="w-[92.5%] mx-auto pt-10">
      <div className="w-[90%]">
        <div className="flex justify-between items-center text-center ">
          <ul className="flex flex-wrap text-center ">
            <li>
              <NavLink
                to={`/reports/genarate/reports-list/${id}`}
                style={linkStyles}
                activeStyle={{
                  backgroundColor:'	#E5E4E2'
                }}
                className="shadow-md relative  rounded-t-xl text-lg 2xl:text-xl"
              >
                Generate Report
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/reports/pre-defined/reports-list/${id}`}
                style={linkStyles}
                activeStyle={{
                  backgroundColor:'	#E5E4E2'
                }}
                className="shadow-md relative  rounded-t-xl ml-1 text-lg 2xl:text-xl"
              >
                 Pre-Defined Reports
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="relative pt-1 pb-1 z-[6] bg-white">{children}</div>
    </div>
    </StandardLayout>
  )
}

export default ReportIndex;