import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import axiosComman from "../../../Services/axiosComman";
import paperclip from "../../../Utilities/Image/img/paper-clip.png";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

function SubModal({ data, visible, onClose, id, refreshData }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*',
    // maxFiles: 10,
    //     maxSize:2097152,/*2MB*/
    onDrop: (acceptedFiles) => {
      setUFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uFiles, setUFiles] = useState([]);
  const [createNew, setCreateNew] = useState({
    standardId: "",
    name: "",
    imagePath: "",
  });
  const removeFile = (file) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    let test = acceptedFiles.filter(item=> item!==file);
    setUFiles(test);
  };

  const files = acceptedFiles.map((file) => (
    <li
      key={file.path}
      className="flex justify-start items-center rounded-md bg-gray-200 m-auto my-1 px-4 py-2 w-full"
    >
      <img src={paperclip} alt="" className="h-4 " />
      <p className="px-4 text-xs 2xl:text-sm w-full">{file.path}</p>
      <span className="cursor-pointer w-[10px]" onClick={removeFile(file)}>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.320903 1.10828C0.461528 0.967826 0.652152 0.888936 0.850903 0.888936C1.04965 0.888936 1.24028 0.967826 1.3809 1.10828L4.6009 4.32828L7.8209 1.10828C7.88956 1.03459 7.97236 0.975487 8.06436 0.934495C8.15636 0.893503 8.25568 0.871461 8.35638 0.869685C8.45708 0.867908 8.55711 0.886432 8.6505 0.924153C8.74389 0.961874 8.82872 1.01802 8.89994 1.08924C8.97116 1.16046 9.0273 1.24529 9.06503 1.33868C9.10275 1.43207 9.12127 1.5321 9.11949 1.6328C9.11772 1.7335 9.09568 1.83281 9.05468 1.92481C9.01369 2.01681 8.95459 2.09961 8.8809 2.16828L5.6609 5.38828L8.8809 8.60828C8.95459 8.67694 9.01369 8.75974 9.05468 8.85174C9.09568 8.94374 9.11772 9.04305 9.11949 9.14375C9.12127 9.24446 9.10275 9.34449 9.06503 9.43787C9.0273 9.53126 8.97116 9.6161 8.89994 9.68731C8.82872 9.75853 8.74389 9.81468 8.6505 9.8524C8.55711 9.89012 8.45708 9.90864 8.35638 9.90687C8.25568 9.90509 8.15636 9.88305 8.06436 9.84206C7.97236 9.80107 7.88956 9.74196 7.8209 9.66828L4.6009 6.44828L1.3809 9.66828C1.23873 9.80076 1.05068 9.87288 0.85638 9.86945C0.662079 9.86602 0.476694 9.78731 0.339281 9.6499C0.201868 9.51248 0.123156 9.3271 0.119728 9.1328C0.116299 8.9385 0.188423 8.75045 0.320903 8.60828L3.5409 5.38828L0.320903 2.16828C0.180452 2.02765 0.101562 1.83703 0.101562 1.63828C0.101562 1.43953 0.180452 1.2489 0.320903 1.10828Z" fill="#6E6985" />
        </svg>
      </span>
    </li>
  ));

  async function handleSubmit() {
    setIsLoading(true);
    const formData = new FormData();

    let value = createNew;

    if (uFiles) {
      value.standardId = {
        id: id,
      };
      value.name = uFiles[0]?.name;
      value.imagePath = uFiles[0]?.path;

      if(uFiles !== null||uFiles != []){
        uFiles.forEach((file) => {
          formData.append("q_and_help", file);
        });
      } 

      await axiosComman.post(`/q-and-help/standard/${id}/`, formData)
      .then((res) => {
        if (res?.data) {
          acceptedFiles.splice(0,acceptedFiles.length);
          setIsLoading(false);
          setUFiles([])
          refreshData();
          onClose();
          Toast.fire({
            icon: "success",
            title: res.data.msg || "Document add Successfully",
          });
        } else {
          setIsLoading(false);
          Toast.fire({
            icon: "error",
            title: res.data.msg ||  
            // "Fail to  add document"
            "File with same name exist"
            ,
          });
        }
      }).catch(err=>{
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: err?.response.data.msg || "File with same name exist",
        });

      })
    } else {
      setIsLoading(false);
      Toast.fire({
        icon: "error",
        title: "Please add document",
      });
    }
  }

  const handleOnClose = (e) => {
    if (e.target.id === "modal"){
      acceptedFiles.splice(0,acceptedFiles.length);
      setUFiles([])
      onClose();
    } 

  };

  const closeFunction = () =>{
    acceptedFiles.splice(0,acceptedFiles.length);
    setUFiles([])
      onClose();
  }


  if (!visible) return null;
  return (
    <div
      id="modal"
      // onClick={handleOnClose}
      className="bg-black bg-opacity-30 fixed inset-0 backdrop-blur-sm 
    flex justify-center items-center "
    >
      <div className="bg-white p-2 rounded w-4/12">
        <div className="flex items-start justify-between px-5 py-2  rounded-t">
          <h3 className="text-xs 2xl:text-sm font-semibold">
            {data ? "Edit" : "New"}
          </h3>
          <span
            className="h-6 w-6 text-2xl block cursor-pointer"
            onClick={closeFunction}
          >
            {/* <img src={Close} alt="close" title="close" /> */}
            <svg
              className="w-7 h-7"
              viewBox="0 0 52 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.842773"
                y="0.560791"
                width="51.1569"
                height="51.1569"
                rx="5"
                fill="#7030A0"
              />
              <path d="M26.4219 26.1392L18.8794 33.6816Z" fill="#6E6985" />
              <path
                d="M33.9643 33.6816L26.4219 26.1392M26.4219 26.1392L18.8794 18.5967M26.4219 26.1392L33.9643 18.5967M26.4219 26.1392L18.8794 33.6816"
                stroke="white"
                stroke-width="2.3"
                stroke-linecap="round"
              />
              <title>Close</title>
            </svg>
          </span>
        </div>
        <div className="h-[23rem]">
          <div className="relative px-6 h-[70%]">
            <section className="container">
              <div
                {...getRootProps({ className: "dropzone" })}
                className="cursor-pointer m-auto pb-4 w-11/12"
              >
                <input {...getInputProps()} />
                <svg className="m-auto" width="93" height="72" viewBox="0 0 93 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M46.9527 30.559C46.8764 30.4615 46.7789 30.3826 46.6675 30.3284C46.5562 30.2741 46.4339 30.2459 46.3101 30.2459C46.1862 30.2459 46.064 30.2741 45.9526 30.3284C45.8413 30.3826 45.7438 30.4615 45.6674 30.559L34.2425 45.0136C34.1483 45.1339 34.0898 45.2783 34.0738 45.4302C34.0578 45.5821 34.0849 45.7355 34.1519 45.8727C34.2189 46.01 34.3233 46.1257 34.4529 46.2064C34.5826 46.2872 34.7324 46.3299 34.8851 46.3295H42.4235V71.0564C42.4235 71.5053 42.7908 71.8725 43.2396 71.8725H49.3601C49.809 71.8725 50.1762 71.5053 50.1762 71.0564V46.3398H57.735C58.4185 46.3398 58.7959 45.5543 58.3777 45.0238L46.9527 30.559Z" fill="#7030A0" />
                  <path d="M76.8515 21.1436C72.1796 8.82101 60.2752 0.0584717 46.3306 0.0584717C32.386 0.0584717 20.4816 8.81081 15.8096 21.1334C7.06749 23.4286 0.610352 31.3955 0.610352 40.8619C0.610352 52.1338 9.74012 61.2636 21.0019 61.2636H25.0924C25.5412 61.2636 25.9085 60.8964 25.9085 60.4475V54.327C25.9085 53.8782 25.5412 53.511 25.0924 53.511H21.0019C17.5642 53.511 14.3305 52.144 11.9231 49.6652C9.5259 47.1966 8.25079 43.8711 8.363 40.4233C8.45481 37.7302 9.37289 35.2004 11.0356 33.0684C12.7392 30.8957 15.1262 29.3145 17.7784 28.6107L21.6445 27.6008L23.0624 23.8673C23.9397 21.5415 25.1638 19.3687 26.7041 17.3999C28.2248 15.4486 30.0261 13.7332 32.0494 12.3097C36.2419 9.36165 41.1791 7.80092 46.3306 7.80092C51.482 7.80092 56.4192 9.36165 60.6118 12.3097C62.6417 13.7378 64.4371 15.4516 65.957 17.3999C67.4974 19.3687 68.7215 21.5517 69.5987 23.8673L71.0064 27.5906L74.8624 28.6107C80.3912 30.1 84.2574 35.129 84.2574 40.8619C84.2574 44.2384 82.9415 47.421 80.5545 49.808C79.3838 50.9855 77.9913 51.9191 76.4574 52.5547C74.9235 53.1903 73.2788 53.5153 71.6185 53.511H67.528C67.0791 53.511 66.7119 53.8782 66.7119 54.327V60.4475C66.7119 60.8964 67.0791 61.2636 67.528 61.2636H71.6185C82.8802 61.2636 92.01 52.1338 92.01 40.8619C92.01 31.4057 85.5733 23.449 76.8515 21.1436Z" fill="#1D1818" />
                </svg>
                {/* <img src={dropzone} alt="" className="m-auto" /> */}
                <p className="text-xs 2xl:text-sm text-center">
                  Drop files to attach, or Browse
                  <br />
                  (Individual upload size limit 1024 MB)
                </p>
              </div>
              <aside>
                <ul className="h-40 overflow-y-auto pr-1">{files}</ul>
              </aside>
            </section>
          </div>
          
          <div className="flex items-center justify-end text-xs 2xl:text-sm px-6 pt-10 rounded-b">
            <button
              onClick={closeFunction}
              className={`${isLoading?" bg-slate-400 cursor-wait":"bg-text"}  hover:shadow-menu text-white px-6 py-2 mr-2 rounded-3xl`}
              disabled={isLoading?true:false}
            >
              Cancel
            </button>
            <button
               disabled={isLoading?true:false} 
               className={ ` ${isLoading?" bg-purple-400 cursor-wait":"bg-primary"} hover:shadow-button text-white px-6 py-2 mr-2 rounded-3xl`}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubModal;
