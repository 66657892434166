import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);



export function Dough(props) {
  const {dataWithLabel,handleSegmentClick=()=>{}}=props
  const data = {
    labels:[],
      datasets: [
        {
          // label: [' '],
          data:dataWithLabel?.data||[],
          backgroundColor: [
            '#FF0000',
            '#008000',
            '#ffd700',
          ],
          borderColor: [
            '#FF0000',
            '#008000',
            '#ffd700',
        ],
        borderWidth: 1,
      },
    ],
  };


  
  return(
      
    <Doughnut data={data}
        options={{
          onClick: handleSegmentClick,
          plugins:{
            legend:false,
              title:{
                display:false
              },
              tooltip:{
                backgroundColor:'transparent',
              
               bodyFont:{
                 size:0
               }
              }
            }
        }}
      />
    
  )
  
  
}