import React, { useEffect, useState } from "react";
import CreateorUpdate from "./form";
import ViewMore from "./viewMore";
import { useParams } from "react-router-dom";
import Axios from "../../../Services/axiosComman";
import DateFormat from "../../../Utilities/common/NuDate";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Filter from "./fiterForm";
import { useFormik } from "formik";
import ApprovelStatus from "../../../Utilities/common/Approvel";
import MainLayout from "../../../Utilities/Layout/MainLayout";
import AddIcon from "../../../Utilities/Image/svg/add";
import SearchIcon from "../../../Utilities/Image/svg/search";

function TaskManagementIndex() {
  const { id } = useParams();
  const [showMyModal, setShowMyModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const handleOnClose = () => setShowMyModal(false);
  const handleFilterClose = () => setShowFilterModal(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleApprovalOnClose = () => setShowApprovalModal(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [dataCount, setDataCount] = useState(20);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [filterData, setFilterData] = useState({});

  const [createNew, setCreateNew] = useState({
    status: "",
    start_completion_date: "",
    end_completion_date: "",
    start_reminder_date: "",
    end_reminder_date: "",
  });

  const refreshData = () => {
    Axios.get(
      `/Calender-Management/current_task/${id}?page=${pageIndex}&per_page=${dataCount}`
    )
      .then((res) => {
        res?.data && setData(res?.data);
      })
      .catch();
  };

  useEffect(() => {
    getCalenderStatus();
    refreshData();
  }, []);
  const getCalenderStatus = () => {
    Axios.get("/Status/10/").then((res) => {
      res?.data?.data && setStatus(res.data?.data);
    });
  };

  function SearchData(e) {
    e.preventDefault();
    if (searchQuery != "") {
      Axios.get(
        `/search/taskSearch/${id}/${searchQuery}?page=${pageIndex}&per_page=${dataCount}`
      )
        .then((res) => {
          res?.data && setData(res?.data);
          // console.log("search", res.data?.data);
        })
        .catch((err) => console.log(err));
    } else {
      refreshData();
    }
  }

  function SearchDataPage() {
    // e.preventDefault();
    if (searchQuery != "") {
      Axios.get(
        `/search/taskSearch/${id}/${searchQuery}?page=${pageIndex}&per_page=${dataCount}`
      )
        .then((res) => {
          res?.data && setData(res?.data);
          // console.log("search", res.data?.data);
        })
        .catch((err) => console.log(err));
    } else {
      refreshData();
    }
  }

  useEffect(() => {
    if (searchQuery == "") {
      refreshData();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery !== "") {
      SearchDataPage();
    } else if (isFilter) {
      filterDataPage();
    } else {
      refreshData();
    }
  }, [pageIndex, dataCount]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: yup.object({
    //   status: yup.string().required("Status is Required")
    // }),
    onSubmit: (value) => {
      setIsFilter(true);
      setFilterData(value);
      Axios.post(
        `/search/taskFilter/${id}?page=${pageIndex}&per_page=${dataCount}`,
        value
      )
        .then((res) => {
          console.log("filter Data", res?.data);
          res?.data && setData(res?.data);
          handleFilterClose();
        })
        .catch((err) => console.log(err));
    },
  });

  function filterDataPage() {
    Axios.post(
      `/search/taskFilter/${id}?page=${pageIndex}&per_page=${dataCount}`,
      filterData
    )
      .then((res) => {
        res?.data && setData(res?.data);
        handleFilterClose();
      })
      .catch((err) => console.log(err));
  }

  function clearFilter() {
    setIsFilter(false);
    refreshData();
    formik.resetForm();
  }
  return (
    <MainLayout
      id={id}
      total={data?.total}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      dataCount={dataCount}
      setDataCount={setDataCount}
      label="Task Management"
      title="Information"
      setShowApprovalModal={setShowApprovalModal}
      hasApproval={true}
    >
      <div className="py-2 pl-5 flex justify-between items-center w-[98.5%]">
        <p className="text-xs 2xl:text-sm text-text">Task Management</p>
        <div className="flex items-center gap-3">
          <Link to={`/information/calendar-management/${id}`}>
            {" "}
            <p className=" text-[10px] text-blue-700 bg-gray-100 p-2 rounded shadow-box ">
              Calendar
            </p>
          </Link>
          <div>
            <form className="flex items-center mx-0" onSubmit={SearchData}>
              <div
                className="w-80 h-[34px] relative rounded-md p-1 flex justify-between items-center"
                style={{ border: "1px solid #EAEBED" }}
              >
                <input
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Here"
                  className=" w-[90%] text-[13px] px-2 outline-none border-none"
                />
                <button
                  className="p-[5.5px] outline-none border-none  cursor-pointer bg-violet-50  rounded-md flex items-center"
                  type="submit"
                >
                  <SearchIcon/>
                </button>
              </div>
            </form>
          </div>
          <button
            className="bg-[#7030A0] px-3 py-1 text-sm rounded-md text-white"
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </button>
          {isFilter && (
            <button
              className=" px-3 py-1 text-sm rounded-md text-[#7030A0]"
              onClick={clearFilter}
              style={{ border: "1px solid #7030A0" }}
            >
              clear
            </button>
          )}

          <AddIcon onClick={() => setShowMyModal(true)} />
        </div>
      </div>
      <table className="table-auto w-full">
        <thead className="bg-tabletitle">
          <tr className="text-xs 2xl:text-sm font-medium">
            {header.map((header, i) => (
              <th className={`px-2 xl:px-[25px] py-4 ${header.width}`} key={i}>
                {header.headone}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr colspan="6 " className="relative">
            <td colspan="8">
              <div className="overflow-y-auto h-[calc(100vh-265px)] shadow-box">
                {data?.data &&
                  data?.data
                    // ?.sort(function (a, b) {
                    //   return (
                    //     new Date(a.completion_date) -
                    //     new Date(b.completion_date)
                    //   );
                    // })
                    .map((i) => {
                      return (
                        <tr className="odd:bg-tableodd even:bg-tableeven text-xs 2xl:text-sm  font-medium py-1 flex justify-between items-center">
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[5%] capitalize">
                            {i.serial_number || "#"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900  w-[15%] break-words capitalize text-left">
                            {i?.task_id == 1
                              ? "Implementation Checklist"
                              : i?.task_id == 2
                              ? "Internal Audit"
                              : i?.task_id == 3
                              ? "Maintenance"
                              : i?.task_id == 4
                              ? "Master List of Documents"
                              : i?.task_id == 5
                              ? "CISO/DPO/ISF Meeting MoM"
                              : i?.task_id == 6
                              ? "Audit Schedule"
                              : "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 truncate whitespace-nowrap text-center w-[20%] capitalize">
                            {i.title || "_"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 truncate text-justify w-[21%] capitalize">
                            {i.comment || "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[12%] capitalize">
                            {(i.completion_date !== "None" && (
                              <Link
                                to={`/information/calendar-management/${id}`}
                              >
                                <DateFormat
                                  value={i.completion_date}
                                  format="DD-MMM-YYYY"
                                />
                              </Link>
                            )) ||
                              "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 whitespace-nowrap text-center w-[12%] capitalize">
                            {(i.reminder_date !== "None" && (
                              <Link
                                to={`/information/calendar-management/${id}`}
                              >
                                <DateFormat
                                  value={i.reminder_date}
                                  format="DD-MMM-YYYY"
                                />
                              </Link>
                            )) ||
                              "-"}
                          </td>
                          <td className="px-2 xl:px-8 text-gray-900 capitalize whitespace-nowrap text-center w-[10%]">
                            {i.status || "-"}
                          </td>
                          <td className=" w-[3%] flex justify-center items-center">
                            <ViewMore
                              fetcher={refreshData}
                              data={i}
                              status={status}
                            />
                          </td>
                        </tr>
                      );
                    })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <CreateorUpdate
        id={id}
        onClose={handleOnClose}
        visible={showMyModal}
        refreshData={refreshData}
        status={status}
      />
      <Filter
        onClose={handleFilterClose}
        visible={showFilterModal}
        status={status}
        formik={formik}
      />

      <ApprovelStatus
        visible={showApprovalModal}
        onClose={handleApprovalOnClose}
        refreshData={refreshData}
        moduleId={10}
      />
    </MainLayout>
  );
}

export default TaskManagementIndex;

const header = [
  {
    headone: <span>Sl&nbsp;No.</span>,
    width: "w-[5%]",
  },
  {
    headone: "Task",
    width: "w-[15%]",
  },
  {
    headone: "Title",
    width: "w-[20%]",
  },
  {
    headone: "Comments",
    width: "w-[21%]",
  },
  {
    headone: <span>Completion&nbsp;Date</span>,
    width: "w-[12%]",
  },
  {
    headone: <span>Reminder&nbsp;Date</span>,
    width: "w-[12%]",
  },
  {
    headone: "Status",
    width: "w-[10%]",
  },
  {
    headone: " ",
    width: "w-[3%]",
  },
];
