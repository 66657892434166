import React, { useState } from 'react'
import AxiosComman from "../../../Services/axiosComman"
import Edit from './form'
import Swal from 'sweetalert2'
import AuditView from './AuditProfile';
import { GetAccess, GetRole } from '../../../Utilities/data/role';
import { ScreenClose, ScreenOpen } from '../../../Utilities/data/render';


const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
function Viewmore({ id, fetcher, data, visible, onClose,status }) {
  const [showMyModal, setShowMyModal] = useState(false)
  const handleOnClose = () => {
    ScreenClose({link:"Audit-Schedule",id:data.id})
    setShowMyModal(false);
  }
  const [showMyMenu, setShowMyMenu] = useState(false);
  const handleOnCloseMenu = () => setShowMyMenu(false);
  const [showFile, setShowFile] = useState(false);
  const handleClose = () => setShowFile(false);

  const handleDelete = async (id) => {

    Swal.fire({
      title: 'Are you sure?',
      text: `You want to Delete Audit Calendar Entry`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (id) {
          await AxiosComman.delete(`/Audit-Schedule/${id}/`)
            .then((res) => {
              if (res.status == 200) {
                fetcher()
                Toast.fire({
                  icon: "success",
                  title: res.data?.message || " Audit Calendar Deleted successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: res.data?.message || " Audit Calendar Deleted  unsuccessfully",
                });
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: "error",
                title: error?.response.data?.message || " Audit Calendar Deleted  unsuccessfully",
              });
              console.log(error.message);
            });
        }
      }
    })

  }

  function EditScreen(){
    setShowMyModal(true)
    ScreenOpen({link:"Audit-Schedule",id:data.id})
  }

  return (
    <>
      <div
        className="hover:drop-shadow-sm mr-2 py-2 px-[14px] rounded-3xl bg-white dropdown cursor-pointer relative"
        // onClick={() => setShowMyMenu(!showMyMenu)}
      >
        <svg className='m-auto w-[4px]' viewBox="0 0 4 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="1.83391" cy="2.38902" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="7.30914" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        <ellipse cx="1.83391" cy="12.2293" rx="1.83" ry="1.82988" fill="#8C87A6"/>
        </svg>
        {/* {showMyMenu ? ( */}
          <div className='hidden dropdown-menu'><div id="modal"
            onClick={handleOnCloseMenu}
            className="bg-transparent fixed inset-0 flex justify-center items-center "></div>
            <div className="bg-white rounded-xl w-20 absolute -left-20 -top-1">
              <div className="grid p-1 rounded-t">
                <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => { setShowFile(true) }}>View</span>               
                 {GetAccess("activity_edit")&& <span className="p-1 hover:bg-navhover hover:rounded-lg cursor-pointer" onClick={EditScreen}>Edit</span>}
              {(GetRole()||GetAccess("activity_delete")) &&
              <span className="p-1 hover:bg-navhover hover:rounded-lg" onClick={() => {
                handleDelete(data.id);
              }
              }>Delete</span>
              }  
                {/* </>
                 )} */}
              </div>
            </div>
          </div>
          {/* ) : null} */}
      </div>
      <Edit onClose={handleOnClose} status={status} visible={showMyModal} data={data} refreshData={fetcher} />
      <AuditView
        data={data}
        onClose={handleClose}
        visible={showFile}
      />
    </>
  )
}

export default Viewmore