import React from "react";
import InnerNavbar from "../common/Navbar/Navbar";

import { GetYear } from "../common/GetYear";
import Navbar from "../common/Navbar";

function StandardLayout({ children,hasInnerNavbar=true }) {
  return (
    <div className=" relative w-full h-screen overflow-hidden">
      <div className=" w-full h-[60px]">
        {
          hasInnerNavbar?<InnerNavbar />:<Navbar/>
        }
        
      </div>
      <div className=" w-full h-[calc(100vh-80px)]">{children}</div>
      <div className=" w-full h-5">
        <p className=" text-slate-400 text-center w-full text-xs">
          <span className=" text-[15px] font-medium">&copy;</span> {GetYear()}{" "}
          Metatron Infotech.
        </p>
      </div>
    </div>
  );
}

export default StandardLayout;
